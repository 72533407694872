import { environment } from "../../environments/environment";

import { Injectable } from "@angular/core";
import { URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";
import { UsuarioHasSetorEntidade } from "../core/model/usuarioHasSetorEntidade.model";
import { AuthService } from "../seguranca/auth.service";

export class UsuarioHasSetorEntidadeFiltro {
  usuario:any
  setorEntidade:any
  nomeUsuario:string
  nomeSetorEntidade:string
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class UsuarioHasSetorEntidadeService {
  url: string;
 

  constructor(
    private http: AuthHttp, 
    public auth: AuthService
  ) {
    this.url = `${environment.apiUrl}/usuario-has-setor-entidade`;
  }

  pesquisar(filtro: UsuarioHasSetorEntidadeFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.usuario) {
      params.set("usuario", filtro.usuario);
    }
    if (filtro.setorEntidade) {
      params.set("setorEntidade", filtro.setorEntidade);
    }

    if (filtro.nomeUsuario) {
      params.set("nomeUsuario", filtro.nomeUsuario);
    }

    if (filtro.nomeSetorEntidade) {
      params.set("nomeSetorEntidade", filtro.nomeSetorEntidade);
    }

    return this.http.get(`${this.url}`, { search: params }).toPromise()
      .then(response => {
        const responseJson = response.json();
        const retorno = responseJson.content;

        const resultado = {
          retorno,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    return this.http
      .get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: UsuarioHasSetorEntidade): Promise<UsuarioHasSetorEntidade> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: UsuarioHasSetorEntidade): Promise<UsuarioHasSetorEntidade> {
    return this.http.put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as UsuarioHasSetorEntidade);
  }

  buscarPorCodigo(id: number): Promise<UsuarioHasSetorEntidade> {
    return this.http.get(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as UsuarioHasSetorEntidade;
        return entidade;
      });
  }
}
