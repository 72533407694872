import { LogoutService } from './../../seguranca/logout.service';
import { UsuarioHasEntidadeFiltro, UsuarioHasEntidadeService } from './../../core/usuarioHasEntidade.service';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { OscService } from '../../core/services/proponente/osc.service';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { NotificacaoHasUsuario } from './../../core/model';
import { CelebracaoService } from './../../core/services/celebracao/celebracao.service';
import { ConcedenteService } from './../../core/services/concedente/concedente.service';
import { NotificacaoHasUsuarioFiltro, NotificacaoHasUsuarioService } from './../../core/services/notificacao/notificacaoHasUsuario.service';
import { AuthService } from './../../seguranca/auth.service';
import { UsuarioService } from './../../usuario/usuario.service';
import { UsuarioHasEntidade } from '../../core/model/usuarioHasEntidade.model';
import { ConfirmationService } from 'primeng/primeng';

declare let AdminLTE: any;

@Component({
  selector: "app-home",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"]
})
export class HomePageComponent implements OnInit {

  filtroNotificacaoHasUsuario = new NotificacaoHasUsuarioFiltro();
  notificacaoHasUsuario = new NotificacaoHasUsuario();
  listaNotificacao = [];
  openModalAviso:boolean;
  listaVinculos: any;
  openModalEntidades: boolean;

  constructor(
    public auth: AuthService,
    private title: Title,
    private notificacaoHasUsuarioService: NotificacaoHasUsuarioService,
    private usuarioService: UsuarioService,
    private proponentesService: OscService,
    private celebracaoService: CelebracaoService,
    private concedenteService: ConcedenteService,
    private router: Router,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    private usuarioHasEntidadeService: UsuarioHasEntidadeService,
    private confirmation: ConfirmationService,
    private logoutService: LogoutService,

  ) { }

  ngOnInit() {
    AdminLTE.init();
    this.title.setTitle("Home");

    this.quantidadeUsuarios();
    this.quantidadeProponentes();
    this.quantidadeConcedentes();
    this.quantidadeProjetos();

    

   
    this.listarNotificacoesHasUser();
    if(sessionStorage.getItem("urlLogin")){
      sessionStorage.removeItem("urlLogin");
    //  this.openModalAviso = true;
      this.pesquisarEntidadesVinculadas(this.auth.jwtPayload.id);
    }
  }

  pesquisarEntidadesVinculadas(idUsuario: any) {
    let filtro = new UsuarioHasEntidadeFiltro();
    filtro.usuario = idUsuario;
    this.usuarioHasEntidadeService.pesquisar(filtro)
      .then((resultado:any) => {
        this.listaVinculos = resultado.content;
        if(this.listaVinculos.length > 1){
          this.openModalEntidades= true
          
          }
      });
  }

  atualizarEntidadePrincipal(item:UsuarioHasEntidade) {
    //SETAR ENTIDADE ANTIGA COMO NÃO PRINCIPAL
    let entidadeAntiga = this.listaVinculos.filter(x=> x.principal)[0]
    entidadeAntiga.principal = false;
    let entidadeNova  = item;
    entidadeNova.principal = true;
  this.atualizarUsuarioHasEntidade(entidadeAntiga);
  this.atualizarUsuarioHasEntidade(entidadeNova)

    let usuario = this.listaVinculos[0].usuario
    if (item.entidadeConcedente) {
      this.usuarioService
        .atualizarUsuarioConcedente(usuario, usuario.id, item.entidadeConcedente.id)
        .then((usuario) => {
          this.toasty.success("Entidade Principal Atualizada com sucesso!");
        })
    }else
    if (item.entidadeProponente) {
      this.usuarioService
        .atualizarUsuarioProponente(usuario, usuario.id, item.entidadeProponente.id)
        .then((usuario) => {
          this.toasty.success("Entidade Principal Atualizada com sucesso!");
        })
  }
 
  this.openInfoTrocaEntidadePrincipal();
}

atualizarUsuarioHasEntidade(usuarioHasEntidade:UsuarioHasEntidade) {
  this.usuarioHasEntidadeService.atualizar(usuarioHasEntidade).then(() => {
    this.pesquisarEntidadesVinculadas(this.auth.jwtPayload.id)
  //  this.toasty.success("Entidade atualizada com sucesso!");
  })
}

openInfoTrocaEntidadePrincipal() {
  setTimeout(() => {
    this.confirmation.confirm({
      message: '<b>Para que a troca de entidade seja efetuada, o usuário deverá fazer o login novamente. Deseja continuar?</b>',
      accept: () => {
    
          this.logoutService.logout()
            .then(() => {
              this.router.navigate(['/login']);
            })
            .catch(erro => this.errorHandler.handle(erro));
        
      },
    });
},1000)
}


  //==================Listagem dos Cards
  countUsuarios
  quantidadeUsuarios() {
    this.usuarioService.countUsuario().then(retorno => {
      this.countUsuarios = retorno
    })
  }

  countProponentes
  quantidadeProponentes() {
    this.proponentesService.countEntidade().then(retorno => {
      this.countProponentes = retorno
    })
  }

  countConcedentes
  quantidadeConcedentes() {
    this.concedenteService.countEntidade().then(retorno => {
      this.countConcedentes = retorno
    })
  }

  countProjetos
  quantidadeProjetos() {
    this.celebracaoService.countProjetos().then(retorno => {
      this.countProjetos = retorno
    })
  }
  //===========.Fim listagem 

  listarNotificacoesHasUser() {
    this.filtroNotificacaoHasUsuario.itensPorPagina = 5;
    this.filtroNotificacaoHasUsuario.usuario = this.auth.jwtPayload.id
    this.notificacaoHasUsuarioService.pesquisar(this.filtroNotificacaoHasUsuario).then(lista => {
      this.listaNotificacao = lista.content
    })
  }

  setaNotificacaoSessao(notificacao) {
    if (notificacao.statusNotificacao === 'Lida') {
      sessionStorage.setItem('verNotificacao', JSON.stringify(notificacao));
      this.router.navigate(['/notificacao/visualizar']);
    } else {
      notificacao.statusNotificacao = 'Lida'
      this.notificacaoHasUsuarioService.atualizar(notificacao)
        .then(resultado => {
          this.toasty.info('Notificacao atualizada para visualizada.');
          sessionStorage.setItem('verNotificacao', JSON.stringify(resultado));
          this.router.navigate(['/notificacao/visualizar']);
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

}