import { environment } from "../../environments/environment";


import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";
import { AuthService } from "../seguranca/auth.service";
import { SetorEntidade } from "../core/model/setorEntidade.model";

export class SetorFiltro {
  nome: string;
  entidadeConcedente: any;
  entidadeProponente: any;
  nomeConcedente: string;
  nomeProponente: string;
  email: string;
  usuarioCadastro: any;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class SetorService {
  comissaoSelecaoUrl: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.comissaoSelecaoUrl = `${environment.apiUrl}/setor-entidade`;
  }

  pesquisar(filtro: SetorFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }
    if (filtro.entidadeConcedente) {
      params.set("entidadeConcedente", filtro.entidadeConcedente);
    }
    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }

    if (filtro.nomeConcedente) {
      params.set("nomeConcedente", filtro.nomeConcedente);
    }
    if (filtro.nomeProponente) {
      params.set("nomeProponente", filtro.nomeProponente);
    }

    if (filtro.email) {
      params.set("email", filtro.email);
    }

    if (filtro.usuarioCadastro) {
      params.set("usuarioCadastro", filtro.usuarioCadastro);
    }

    return this.http
      .get(`${this.comissaoSelecaoUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        return responseJson;
      });
  }

  listarTodos(): Promise<any> {
    return this.http
      .get(this.comissaoSelecaoUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  listarComissaoDaEntidade(id: any): Promise<any> {
    const params = new URLSearchParams();
    params.set("entidadeConcedente", id);

    return this.http
      .get(`${this.comissaoSelecaoUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.comissaoSelecaoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(setorEntidade: SetorEntidade): Promise<SetorEntidade> {
    return this.http
      .post(this.comissaoSelecaoUrl, JSON.stringify(setorEntidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(setorEntidade: SetorEntidade): Promise<SetorEntidade> {
    return this.http
      .put(
        `${this.comissaoSelecaoUrl}/${setorEntidade.id}`,
        JSON.stringify(setorEntidade)
      )
      .toPromise()
      .then(response => response.json() as SetorEntidade);
  }

  buscarPorCodigo(id: number): Promise<SetorEntidade> {
    return this.http
      .get(`${this.comissaoSelecaoUrl}/${id}`)
      .toPromise()
      .then(response => {
        const setorEntidade = response.json() as SetorEntidade;

        return setorEntidade;
      });
  }
}
