import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { AuthHttp } from "angular2-jwt";
import { AuthService } from "../../../seguranca/auth.service";
import { HabilitacaoHasProponente } from "../../model";
import { URLSearchParams } from "@angular/http";
import * as moment from "moment";
export class HabilitacaoHasProponenteFiltro {
  statusProcesso;
  habilitacao;
  entidadeProponente;
  pagina = 0;
  itensPorPagina = 5000;
}
@Injectable()
export class HabilitacaoHasProponenteService {
  url: string;
  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/habilitacao-has-proponente`;
  }
  pesquisar(filtro: HabilitacaoHasProponenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.habilitacao) {
      params.set("habilitacao", filtro.habilitacao);
    }

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }

    if (filtro.statusProcesso) {
      params.set("statusProcesso", filtro.statusProcesso);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const itens = responseJson.content;
        const resultado = {
          itens,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }
  buscarPorCodigo(codigo: number): Promise<HabilitacaoHasProponente> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as HabilitacaoHasProponente;
        return entidade;
      });
  }
  adicionar(entidade: HabilitacaoHasProponente): Promise<HabilitacaoHasProponente> {
    entidade.horaAgendamento = new Date(entidade.horaAgendamento).getTime();
    return this.http
      .post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }
  
  atualizar(
    entidade: HabilitacaoHasProponente
  ): Promise<HabilitacaoHasProponente> {
    return this.http
      .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as HabilitacaoHasProponente);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }
}
