import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { EntidadeProponenteHasAreaDeAtuacao } from '../core/model';
import { AuthService } from '../seguranca/auth.service';


export class EntidadeProponenteHasAreaDeAtuacaoFiltro {
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class EntidadeProponenteHasAreaDeAtuacaoService {

  entidadeUrl: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.entidadeUrl = `${environment.apiUrl}/entidade-proponente-has-area-de-atuacao`;
  }

  excluir(id: number): Promise<void> {
    return this.http.delete(`${this.entidadeUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidadeProponenteHasAreaDeAtuacao: EntidadeProponenteHasAreaDeAtuacao): Promise<EntidadeProponenteHasAreaDeAtuacao> {
    return this.http.post(this.entidadeUrl, JSON.stringify(entidadeProponenteHasAreaDeAtuacao))
      .toPromise()
      .then(response => response.json());
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.entidadeUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  buscarJaVinculadas(idProponente: any): Promise<any> {
    const params = new URLSearchParams();
    params.set('entidadeProponente', idProponente);

    return this.http.get(`${this.entidadeUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const objeto = responseJson.content;

        const resultado = {
          objeto,
          total: responseJson.totalElements
        };
       // //console.log(resultado)
        return resultado;
      })
  }

  verificaProponenteVinculado(idProponente: any, idArea: any): Promise<any> {
    const params = new URLSearchParams();
    params.set('areaDeAtuacao', idArea);
    params.set('entidadeProponente', idProponente);

    return this.http.get(`${this.entidadeUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

}
