import "rxjs/add/operator/map";
import { Injectable, EventEmitter } from "@angular/core";
import { Habilitacao } from "../../model";
import { AuthHttp } from "angular2-jwt";
import { AuthService } from "../../../seguranca/auth.service";
import { environment } from "../../../../environments/environment";
import { URLSearchParams } from "@angular/http";

export class HabilitacaoFiltro {
  nome: string;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class HabilitacaoService {
  url: string;
  //static setaHabilitacao = new EventEmitter<Habilitacao>();
  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/habilitacao`;
  }

  pesquisar(filtro: HabilitacaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const itens = responseJson.content;
        const resultado = {
          itens,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }
  buscarPorCodigo(codigo: number): Promise<Habilitacao> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as Habilitacao;
        return entidade;
      });
  }
  adicionar(entidade: Habilitacao): Promise<Habilitacao> {
    return this.http
      .post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }
  atualizar(entidade: Habilitacao): Promise<Habilitacao> {
    return this.http
      .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Habilitacao);
  }
  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }
}
