import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import * as moment from 'moment';

import { EtapaCronogramaFisico } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class EtapaCronogramaFisicoFiltro {
  especificacao
  cronogramaFisicoExecucaoObjeto
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class EtapaCronogramaFisicoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/etapa-cronograma-fisico`;
  }

  pesquisar(filtro: EtapaCronogramaFisicoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.especificacao) {
      params.set('especificacao', filtro.especificacao);
    }
    if (filtro.cronogramaFisicoExecucaoObjeto) {
      params.set('cronogramaFisicoExecucaoObjeto', filtro.cronogramaFisicoExecucaoObjeto);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: EtapaCronogramaFisico): Promise<EtapaCronogramaFisico> {

    entidade.dataIni = moment(entidade.dataIni).add(3, 'hours')

    entidade.dataFim = new Date(entidade.dataFim).getTime()
    entidade.dataIni = new Date(entidade.dataIni).getTime()

    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: EtapaCronogramaFisico): Promise<EtapaCronogramaFisico> {

    //entidade.dataIni = moment(entidade.dataIni).add(3, 'hours')
    //entidade.dataIni = moment(entidade.dataIni).subtract(3, 'hours')

    entidade.dataFim = new Date(entidade.dataFim).getTime()
    entidade.dataIni = new Date(entidade.dataIni).getTime()
    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as EtapaCronogramaFisico);
  }

  buscarPorCodigo(codigo: number): Promise<EtapaCronogramaFisico> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as EtapaCronogramaFisico;      
        return entidade;
      });
  }

  buscarPorCodigoDTO(codigo: number): Promise<EtapaCronogramaFisico> {
    return this.http.get(`${this.url}/dto/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as EtapaCronogramaFisico;      
        return entidade;
      });
  }


  atualizarPercentual(id, percentual, tipo) {
    let url = null
    if(tipo === 'evidencia')
      url = `${this.url}/${id}/evidencia`
    if(tipo === 'despesa')
      url = `${this.url}/${id}/despesa`

    return this.http.put(url, JSON.stringify(percentual))
      .toPromise();
  }

}
