import { LogoutService } from './../../seguranca/logout.service';
import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from 'primeng/components/common/api';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Usuario } from '../../core/model';
import { UsuarioHasEntidade } from '../../core/model/usuarioHasEntidade.model';
import { Page } from '../../core/pagination/pagination.model';
import { UsuarioFiltro, UsuarioService } from '../usuario.service';
import { ConcedenteFiltro, ConcedenteService } from './../../core/services/concedente/concedente.service';
import { ProponenteFiltro, ProponenteService } from './../../core/services/proponente/proponente.service';
import { UsuarioHasEntidadeFiltro, UsuarioHasEntidadeService } from './../../core/usuarioHasEntidade.service';
import { AuthService } from './../../seguranca/auth.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-usuario-listar',
  templateUrl: './usuario-listar.component.html',
})
export class UsuarioListarComponent implements OnInit {
  totalRegistros = 0;

  filtroConcedente = new ConcedenteFiltro();
  filtroProponente = new ProponenteFiltro();

  filtroUsuario = new UsuarioFiltro();

  usuarios = [];
  loading;
  usuario = new Usuario;
  modalaVisualizarUsuario: boolean;
  entidade: any;
  entidadePrincipal: any;

  constructor(
    private usuarioService: UsuarioService,
    private concedenteService: ConcedenteService,
    private proponenteService: ProponenteService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private logoutService: LogoutService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService,
    private router: Router,
    private usuarioHasEntidadeService: UsuarioHasEntidadeService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Listagem de usuários');

    this.filtroUsuario.itensPorPagina = 20
    this.pesquisar();

  }

  //PESQUISAR
  page: Page;
  changePage(event) {
    this.filtroUsuario.itensPorPagina = event.size
    this.filtroUsuario.pagina = event.page
    this.usuarioService.pesquisar(this.filtroUsuario).then(res => {
      this.page = res
      this.usuarios = res.content;
    });
  }
  pesquisar(pagina = 0) {

    if (this.auth.jwtPayload.proponente) {
      this.filtroUsuario.entidadeProponente = this.auth.jwtPayload.proponente
    }
    if (this.auth.jwtPayload.concedente) {
      this.filtroUsuario.entidadeConcedente = this.auth.jwtPayload.concedente
    }

    // ROLE_LISTAR_TODOS_USUARIOS
    if (this.auth.temPermissao('ROLE_LISTAR_TODOS_USUARIOS')) {
      this.filtroUsuario.entidadeConcedente = null
      this.filtroUsuario.entidadeProponente = null
    }


    this.filtroUsuario.pagina = pagina;
    this.usuarioService.pesquisar(this.filtroUsuario)
      .then(resultado => {
        this.loading = true
        this.usuarios = resultado.content;
        this.page = resultado;
      });
  }

  listaVinculos:UsuarioHasEntidade[] = [];
  pesquisarEntidadesVinculadas(idUsuario: any) {
    let filtro = new UsuarioHasEntidadeFiltro();
    filtro.usuario = idUsuario;
    this.usuarioHasEntidadeService.pesquisar(filtro)
      .then((resultado:any) => {
        this.loading = true
        this.listaVinculos = resultado.content;
        if(this.listaVinculos.length > 0){
          this.entidadePrincipal = this.listaVinculos.filter(x=>x.principal)[0]
          this.entidadePrincipal = this.entidadePrincipal.entidadeConcedente?this.entidadePrincipal.entidadeConcedente:this.entidadePrincipal.entidadeProponente
        }
      });
  }

  confirmarRemoverVinculo(item:UsuarioHasEntidade) {
    this.confirmation.confirm({
      message: 'Tem certeza que remover vinculo a entidade?',
      accept: () => {
        this.excluirVinculoEntidade(item);
      },
    });
  }

  excluirVinculoEntidade(item:UsuarioHasEntidade) {
    this.usuarioHasEntidadeService
      .excluir(item.id)
      .then(() => {
        this.pesquisarEntidadesVinculadas(this.usuarioSelecionado.id);
        if(this.listaVinculos.length == 1 || 
          this.listaVinculos.filter(x => x.usuario.id == item.usuario.entidadeConcedente ?
            item.entidadeConcedente.id : item.entidadeProponente.id).length == 0) {
              let usuario = new Usuario();
              usuario = item.usuario;
              usuario.entidadeConcedente = null;
              usuario.entidadeProponente = null;

          this.atualizarUsuario(usuario);
        }
        this.toasty.success('Vinculo removido com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarUsuario(usuario:Usuario) {
    this.usuarioService
      .atualizar(usuario)
      .then(() => {
        this.toasty.success('Usuário alterado com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //EXCLUIR
  confirmarExclusao(pessoa: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(pessoa);
      },
    });
  }
  excluir(pessoa: any) {
    this.usuarioService
      .excluir(pessoa.id)
      .then(() => {
        this.pesquisar();
        this.toasty.success('Pesssoa excluída com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //ALTERAR
  gotoEditar(param) {
    this.router.navigate(["/cadastros/usuario/editar"]);
    sessionStorage.setItem('usuario', param)
  }


  //VINCULAR ENTIDADE
  filtroNomeEntidade
  filtroCnpjEntidade
  listaEntidadesTitulo
  listaEntidades = []
  viewVincularEntidade
  usuarioSelecionado
  openVincularEntidade(item) {
    this.viewVincularEntidade = true
    this.usuarioSelecionado = item
    // === Limpando variaveis
    this.filtroNomeEntidade = null
    this.filtroCnpjEntidade = null
    this.listaEntidadesTitulo = null
    this.listaEntidades = []
    
  }
  getListaConcedentes(pagina = 0) {
    this.listaEntidadesTitulo = "Concedentes";

    this.filtroConcedente.cnpj = this.filtroCnpjEntidade
    this.filtroConcedente.nome = this.filtroNomeEntidade

    this.filtroConcedente.pagina = pagina;
    this.concedenteService.pesquisar(this.filtroConcedente).then(resultado => {
      this.listaEntidades = resultado.content;
    });
  }
  getListaProponentes(pagina = 0) {
    this.listaEntidadesTitulo = "Proponentes";

    this.filtroProponente.cnpj = this.filtroCnpjEntidade
    this.filtroProponente.nome = this.filtroNomeEntidade

    this.filtroProponente.pagina = pagina;
    this.proponenteService.pesquisar(this.filtroProponente).then(resultado => {
      this.listaEntidades = resultado.content;
    });
  }
  confirmarVinculo(item) {
    this.confirmation.confirm({
      message: '<br><br>Tem certeza que vincular a entidade: <br><b>' + item.nome + '</b> <br>ao usuário: <br><b>' + this.usuarioSelecionado.nome + '</b> ?',
      accept: () => {
        this.atualizarEntidade(item.id);
      },
    });
  }

  atualizarEntidadePrincipal(item:UsuarioHasEntidade) {
    //SETAR ENTIDADE ANTIGA COMO NÃO PRINCIPAL
    let entidadeAntiga = this.listaVinculos.filter(x=> x.principal)[0]
    entidadeAntiga.principal = false;
    let entidadeNova  = item;
    entidadeNova.principal = true;
  this.atualizarUsuarioHasEntidade(entidadeAntiga);
  this.atualizarUsuarioHasEntidade(entidadeNova)

    let usuario = this.usuarioSelecionado
    if (item.entidadeConcedente) {
      this.usuarioService
        .atualizarUsuarioConcedente(usuario, usuario.id, item.entidadeConcedente.id)
        .then((usuario:Usuario) => {
          this.entidadePrincipal = item.entidadeConcedente
          this.toasty.success("Entidade Principal Atualizada com sucesso!");
        })
    }else
    if (item.entidadeProponente) {
      this.usuarioService
        .atualizarUsuarioProponente(usuario, usuario.id, item.entidadeProponente.id)
        .then((usuario:Usuario) => {
          this.entidadePrincipal = item.entidadeProponente
          this.toasty.success("Entidade Principal Atualizada com sucesso!");
        })
  }
  this.pesquisarEntidadesVinculadas(this.usuarioSelecionado.id)
  this.openInfoTrocaEntidadePrincipal();
}

  atualizarEntidade(entidadeId) {
   
    let usuario = this.usuarioSelecionado
    let usuarioHasEntidade = new UsuarioHasEntidade();
    usuarioHasEntidade.usuario.id = this.usuarioSelecionado.id;
    if (this.listaEntidadesTitulo === 'Concedentes' && this.listaVinculos.length == 0) {
      this.usuarioService
        .atualizarUsuarioConcedente(usuario, usuario.id, entidadeId)
        .then(() => {
          usuarioHasEntidade.entidadeConcedente.id = entidadeId
          usuarioHasEntidade.entidadeProponente = null
          usuarioHasEntidade.principal = true
          this.adicionarUsuarioHasEntidade(usuarioHasEntidade);
          this.toasty.success("Entidade Atualizada com sucesso!");
        })
    }else
    if (this.listaEntidadesTitulo === 'Proponentes'  && this.listaVinculos.length == 0) {
      this.usuarioService
        .atualizarUsuarioProponente(usuario, usuario.id, entidadeId)
        .then(() => {
          this.toasty.success("Entidade Atualizada com sucesso!");
          usuarioHasEntidade.entidadeProponente.id = entidadeId
          usuarioHasEntidade.entidadeConcedente = null
          usuarioHasEntidade.principal = true
          this.adicionarUsuarioHasEntidade(usuarioHasEntidade);
        })
    }else{
      if(this.listaVinculos.filter(x => (x.entidadeConcedente && x.entidadeConcedente.id == entidadeId) || (x.entidadeProponente && x.entidadeProponente.id == entidadeId)).length == 0) {
        this.listaEntidadesTitulo === 'Concedentes' ? usuarioHasEntidade.entidadeConcedente.id = entidadeId :
         usuarioHasEntidade.entidadeProponente.id = entidadeId;
         usuarioHasEntidade.principal = false

         this.listaEntidadesTitulo === 'Concedentes' ? usuarioHasEntidade.entidadeProponente = null : usuarioHasEntidade.entidadeConcedente = null;
        this.adicionarUsuarioHasEntidade(usuarioHasEntidade);
      }else{
        this.toasty.warning("Ja existe um vinculo com esta entidade");
      }
    }

    //this.viewVincularEntidade = false
  }

  adicionarUsuarioHasEntidade(usuarioHasEntidade:UsuarioHasEntidade) {
    this.usuarioHasEntidadeService.adicionar(usuarioHasEntidade).then(() => {
      this.pesquisarEntidadesVinculadas(this.usuarioSelecionado.id);
      this.toasty.success("Entidade vinculada com sucesso!");
    })
  }

  atualizarUsuarioHasEntidade(usuarioHasEntidade:UsuarioHasEntidade) {
    this.usuarioHasEntidadeService.atualizar(usuarioHasEntidade).then(() => {
      this.pesquisarEntidadesVinculadas(this.usuarioSelecionado.id);
    //  this.toasty.success("Entidade atualizada com sucesso!");
    })
  }

  //SITUAÇÃO
  confereSituacao(situacao) {
    let r
    if (situacao == "Inativo") {
      r = false
    } else {
      r = true
    }
    return r
  }
  alterarSituacao(event, usuario: Usuario) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      usuario.situacao = "Ativo"
    } else {
      usuario.situacao = "Inativo"
    }
    this.usuarioService.atualizar(usuario)
      .then(() => {
        this.toasty.success('Ação realizada com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  openVisualizar(usuario: Usuario) {
    this.usuarioService.buscarPorCodigo(usuario.id)
      .then(u => {
        this.usuario = u
        this.modalaVisualizarUsuario = true
        if(u.entidadeConcedente){
          this.entidade = u.entidadeConcedente.nome
        }else if(u.entidadeProponente){
          this.entidade = u.entidadeProponente.nome
        }else{
          this.entidade = null
        }
      }).catch(erro => this.errorHandler.handle(erro));
  }
  openInfoTrocaEntidadePrincipal() {
    setTimeout(() => {
      this.confirmation.confirm({
        message: '<b>Para que a troca de entidade seja efetuada, o usuário deverá fazer o login novamente. Deseja continuar?</b>',
        accept: () => {
      
            this.logoutService.logout()
              .then(() => {
                this.router.navigate(['/login']);
              })
              .catch(erro => this.errorHandler.handle(erro));
          
        },
      });
  },1000)
  }

}
