import { environment } from "../../../../environments/environment";

import { PessoalProjeto } from "../../model";

import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";

export class PessoalProjetoFiltro {
  pessoalProjeto: string;
  pthpId
  tipo
  pagina = 0;
  itensPorPagina = 2000;
  planoTrabalho
}

@Injectable()
export class PessoalProjetoService {
  _Url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this._Url = `${environment.apiUrl}/pessoal-projeto`;
  }

  pesquisar(filtro: PessoalProjetoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.pessoalProjeto) {
      params.set("detFinHasEtCronFis", filtro.pessoalProjeto);
    }
    if (filtro.planoTrabalho) {
      params.set("planoTrabalho", filtro.planoTrabalho);
    }
    if (filtro.pthpId) {
      params.set("pthpId", filtro.pthpId);
    }
    if (filtro.tipo) {
      params.set("tipo", filtro.tipo);
    }


    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dados = responseJson.content;

        const resultado = {
          dados,
          total: responseJson.totalElements
        };
        ////console.log(resultado)
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();
    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this._Url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: PessoalProjeto): Promise<PessoalProjeto> {
    return this.http
      .post(this._Url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: PessoalProjeto): Promise<PessoalProjeto> {
    return this.http
      .put(`${this._Url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as PessoalProjeto);
  }

  buscarPorCodigo(codigo: number): Promise<PessoalProjeto> {
    return this.http
      .get(`${this._Url}/${codigo}`)
      .toPromise()
      .then(response => {
        const retorno = response.json() as PessoalProjeto;
        return retorno;
      });
  }
}
