import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from "primeng/components/common/api";
import { ErrorHandlerService } from '../../core/error-handler.service';
import { AuthService } from '../../seguranca/auth.service';


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-proposta-gerenciar',
  templateUrl: './proposta-gerenciar.component.html'
})
export class PropostaGerenciarComponent implements OnInit {


  constructor(
    public auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title
  ){}

  ngOnInit(){

    // Update the AdminLTE layouts
     AdminLTE.init();

    this.title.setTitle('Gerenciar Proposta');

  }

}
