import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { RoteiroParecer, ModelResponderRoteiro } from "../../core/model";
import { ResponderRoteiroService, ResponderRoteiroFiltro } from "../../core/services/modelos-sistema/responderRoteiro.service";
import { GenerateParamPipe } from "../../core/my-pipes/generate-param.pipe";


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-roteiro-visualizar",
  templateUrl: "./roteiro-visualizar.component.html"
})
export class RoteiroVisualizarComponent implements OnInit {

  roteiro = new RoteiroParecer
  modelResponderRoteiro = new ModelResponderRoteiro
  respostas = []
  responderRoteiro;
  finalizarRoteiro;
  roteiroJaSubmetido;
  loading
  introducao
  mostraModalIntro
  paramHash

  constructor(
    private title: Title, 
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Visualizar Parecer');

    this.paramHash = this.route.snapshot.paramMap.get("param-hash");

    ResponderRoteiroService.atualizaVisualizacao.subscribe(() => (this.setaAnalise()))
    this.setaAnalise()
  
  }

  async setaAnalise(){
    this.responderRoteiro = JSON.parse(sessionStorage.getItem("visualizarParecer"));
    this.roteiro = this.responderRoteiro.roteiroParecer
        this.respostas = this.responderRoteiro.respostaQuestaoParecer;
        
        await this.verificaSeTodasForamRespondidas()
        await this.processarAnalise()
        
        this.loading = true
  }

  goBack() {
    this.location.back();
  }

  verIntroducao(intro) {
    this.introducao = intro
    this.mostraModalIntro = true
  }

  verificaSeTodasForamRespondidas() {
    let faltaResponder = false
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta
          }
        });
        if (questao.resposta == null) {
          faltaResponder = true
        }
      });
    });
    if (!faltaResponder) {
      this.finalizarRoteiro = true
    }
  }

  imprimirAnalise() {
  
    if (this.responderRoteiro.situacao == 'ATIVO') {
      this.router.navigate(['/controle-interno/gestao/analise-concluida/imprimir', new GenerateParamPipe().transform(''), this.responderRoteiro.hash]);
    } else {
      this.router.navigate(['/controle-interno/gestao/analise-concluida/imprimir', new GenerateParamPipe().transform(''), this.responderRoteiro.hash]);
    }

  }

  situacao
  conclusao
  processarAnalise() {

    let secaoCountIndice = 0
    let questaoCountIndice = 0
    let observacoes = []
    let qtdQuestoes = 0
    let favoravel = 0
    let naoFavoravel = 0
    let qtdRespostas = 0
    let qtdParecer = 0
    let qtdObsFavoravel = 0
    let qtdObsNaoFavoravel = 0
    let reprovado = 0
    let aprovado = 0

    this.roteiro.secao.forEach(secao => {

      secaoCountIndice++
      questaoCountIndice = 0

      secao.questaoParecer.forEach(questao => {
        questaoCountIndice++
        qtdQuestoes++
        let value = secaoCountIndice + "." + questaoCountIndice


        // verifica as questoes que foram respondidas
        if (questao.resposta != null) {

          //Incrementa a quantidade de questões que contém respostas
          qtdRespostas++

          if (questao.resposta.observacao != null && questao.resposta.observacao != "") {
            // observacoes.push(value)
            //Para obter a quantidade de questões é necessário utilizar a função length ou fazer um for para buscar as observações
          }

          if (questao.resposta.respostaEnum) {
            
            qtdParecer++

            // verifica as questoes favoraveis e que nao tiveram obs
            if (questao.resposta.respostaEnum === 'Sim') {
              favoravel++
            }

            if (questao.resposta.respostaEnum === 'Nao') {

              if (questao.continuar === 'Nao') {

                observacoes.push(value)

                reprovado++

              } else {

                aprovado++

              }
            }
            naoFavoravel++
          }
        }

      });

    });

    if (qtdRespostas === favoravel) { //Verifico se as questões que estão como favorável são iguais as questões que solicitam parecer

      if (observacoes.length === 0) {
        this.situacao = "Aprovado"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do/de "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento às demais etapas subsequentes.";

      } else {
        this.situacao = "Aprovado com recomendação"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do/de "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento " +
          "às demais etapas subsequentes, desde que, a(s) seguinte(s) OBSERVAÇÃO(ÕES) da(s) questão(ões): "
          + observacoes.toString() +
          " seja(m) realizado(s) o(s) devido(s) ajuste(s)."
      }

    } else {

      if (observacoes.length === 1) {
        this.situacao = "Reprovado com recomendação"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do/de "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo NÃO ESTÁ DE ACORDO com a legislação vigente e INAPTO para que seja dado prosseguimento a(s) estapa(s) subsequente(s). " +
          "Recomenda-se o(s) ajuste(s) e observação(ões) supracitado(s) no(s) item(s): "
          + observacoes.toString() +
          " . Para dar continuidade ao processo."

      } else {
        this.situacao = "Reprovado"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do/de "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo NÃO ESTÁ DE ACORDO com a legislação vigente e INAPTO para que seja dado prosseguimento a(s) estapa(s) subsequente(s). " +
          "Recomenda-se realizar o(s) ajuste(s) supracitado(s) no(s) item(s): "
          + observacoes.toString() +
          " . Para dar continuidade ao processo."

      }
    }


  }
 


}
