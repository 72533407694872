import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../seguranca/auth.service';
import { LogoutService } from '../../seguranca/logout.service';
import { ErrorHandlerService } from '../error-handler.service';
import { NotificacaoHasUsuarioFiltro, NotificacaoHasUsuarioService } from './../services/notificacao/notificacaoHasUsuario.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  contadorNotificaoNaoLidas;
  listaNotificacaoHasUser;

  constructor(
    public auth: AuthService,
    private logoutService: LogoutService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notificacaoHasUsuarioService: NotificacaoHasUsuarioService,
  ) { }

  ngOnInit() {
    this.listarNotificacoesHasUser(this.auth.jwtPayload.id);
  }

  logout() {
    this.logoutService.logout()
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  listarNotificacoesHasUser(idUsuario) {
    this.contadorNotificaoNaoLidas = 0
    let filtroNotificacaoHasUsuario = new NotificacaoHasUsuarioFiltro;
    filtroNotificacaoHasUsuario.usuario = idUsuario
    this.notificacaoHasUsuarioService.pesquisar(filtroNotificacaoHasUsuario).then(lista => {
      this.listaNotificacaoHasUser = lista.content
      this.listaNotificacaoHasUser.forEach(element => {
        if (element.statusNotificacao != "Lida") {
          this.contadorNotificaoNaoLidas++
        }
      });
    }).catch(erro => this.errorHandler.handle(erro));
  }

}