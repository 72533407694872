import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TrumbowygModule } from "ng2-lazy-trumbowyg";

import { MyPipesModule } from './../core/my-pipes/my-pipes.module';

import { PaginationModule } from "../core/pagination/pagination.module";
import { SharedsModule } from "../shareds/shareds.module";

import { PlanoTrabalhoHasProponenteService } from "../core/services/plano-trabalho/planoTrabalhoHasProponente.service";
import { AjustePlanoMenuComponent } from "./menu/ajuste-plano-menu.component";
import { AjustePlanoListarComponent } from "./listar/ajuste-plano-listar.component";
import { VersaoPlanoService } from "../core/services/alteracao-plano/versaoPlano.service";
import { AjustePlanoNovoComponent } from "./cadastrar/ajuste-plano-novo.component";
import { AjustePlanoAlterarComponent } from "./alterar/ajuste-plano-alterar.component";
import { AlterarDescricaoRealidadeComponent } from "./alterar/descricao-realidade/alterar-descricao-realidade.component";
import { AlterDescricaoRealidadeService } from "../core/services/alteracao-plano/alterDescricaoRealidade.service";
import { AlterarSintesePropostaComponent } from "./alterar/sintese-proposta/alterar-sintese-proposta.component";
import { AlterSintesePropostaService } from "../core/services/alteracao-plano/alterSinteseProposta.service";
import { AlterarMonitoramentoComponent } from "./alterar/monitoramento/alterar-monitoramento.component";
import { AlterMonitoramentoAvaliacaoService } from "../core/services/alteracao-plano/alterMonitoramentoAvaliacao.service";
import { AlterarCapacidadeInstaladaComponent } from "./alterar/capacidade-instalada/alterar-capacidade-instalada.component";
import { CapacidadeInstaladaService } from "../core/services/plano-trabalho/capacidadeInstalada.service";
import { InstalacoesFisicasService } from "../core/services/plano-trabalho/instalacoesFisicas.service";
import { EquipeService } from "../core/services/plano-trabalho/equipe.service";
import { EquipamentosDisponiveisService } from "../core/services/plano-trabalho/equipamentosDisponiveis.service";
import { AlterarCronogramaFisicoComponent } from "./alterar/cronograma-fisico/alterar-cronograma-fisico.component";
import { AlterCronogramaFisicoService } from "../core/services/alteracao-plano/alterCronogramaFisico.service";
import { AlterEtapaCronogramaService } from "../core/services/alteracao-plano/alterEtapaCronograma.service";
import { AlterarDetalhamentoFinanceiroComponent } from "./alterar/detalhamento-financeiro/alterar-detalhamento-financeiro.component";
import { AlterDetalhamentoFinanceiroService } from "../core/services/alteracao-plano/alterDetalhamentoFinanceiro.service";
import { AlterDetalhamentoHasEtapaService } from "../core/services/alteracao-plano/alterDetalhamentoHasEtapa.service";
import { CategoriaService } from "../core/services/fornecedor/categoria.service";
import { DetalhamentoFinanceiroHasEtapaCronogramaFisicoService } from "../core/services/plano-trabalho/detalhamentoFinanceiroHasEtapaCronogramaFisico.service";
import { CronogramaFisicoExecucaoObjetoService } from "../core/services/plano-trabalho/cronogramaFisicoExecucaoObjeto.service";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { AjustePlanoAvaliarComponent } from "./avaliar/ajuste-plano-avaliar.component";
import { AvaliarDescricaoRealidadeComponent } from "./avaliar/descricao-realidade/avaliar-descricao-realidade.component";
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import { AvaliarSintesePropostaComponent } from "./avaliar/sintese-proposta/avaliar-sintese-proposta.component";
import { AvaliarMonitoramentoComponent } from "./avaliar/monitoramento/avaliar-monitoramento.component";
import { AvaliarCronogramaFisicoComponent } from "./avaliar/cronograma-fisico/avaliar-cronograma-fisico.component";
import { AvaliarDetalhamentoFinanceiroComponent } from "./avaliar/detalhamento-financeiro/avaliar-detalhamento-financeiro.component";
import { UploadServiceModule } from "../core/upload-service/upload-service.module";

@NgModule({
  imports: [
    MatTabsModule,
    MatCardModule,
    CommonModule,
    FormsModule,
    RouterModule,
    PaginationModule,

    CurrencyMaskModule,
    SharedsModule,
    MyPipesModule,

    TrumbowygModule.forRoot({
      plugins: ["colors", "noembed", "preformatted", "pasteimage", "upload"],
      version: "2.8.0"
    }),

    UploadServiceModule
  ],

  declarations: [
    AjustePlanoMenuComponent,
    AjustePlanoListarComponent,
    AjustePlanoNovoComponent,
    AjustePlanoAlterarComponent,
    AlterarDescricaoRealidadeComponent,
    AlterarSintesePropostaComponent,
    AlterarMonitoramentoComponent,
    AlterarCapacidadeInstaladaComponent,
    AlterarCronogramaFisicoComponent,
    AlterarDetalhamentoFinanceiroComponent,

    AjustePlanoAvaliarComponent,
    AvaliarDescricaoRealidadeComponent,
    AvaliarSintesePropostaComponent,
    AvaliarMonitoramentoComponent,
    AvaliarCronogramaFisicoComponent,
    AvaliarDetalhamentoFinanceiroComponent
  ],

  exports:[
    AjustePlanoMenuComponent,
    AjustePlanoListarComponent,
    AjustePlanoNovoComponent,
    AjustePlanoAlterarComponent,
    AlterarDescricaoRealidadeComponent,
    AlterarSintesePropostaComponent,
    AlterarMonitoramentoComponent,
    AlterarCapacidadeInstaladaComponent,
    AlterarCronogramaFisicoComponent,
    AlterarDetalhamentoFinanceiroComponent,

    AjustePlanoAvaliarComponent,
    AvaliarDescricaoRealidadeComponent,
    AvaliarSintesePropostaComponent,
    AvaliarMonitoramentoComponent,
    AvaliarCronogramaFisicoComponent,
    AvaliarDetalhamentoFinanceiroComponent
  ],
  
  providers: [
    VersaoPlanoService,
    PlanoTrabalhoHasProponenteService,
    AlterDescricaoRealidadeService,
    AlterSintesePropostaService,
    AlterMonitoramentoAvaliacaoService,
    CapacidadeInstaladaService,
    InstalacoesFisicasService,
    EquipeService,
    EquipamentosDisponiveisService,
    AlterCronogramaFisicoService,
    AlterEtapaCronogramaService,
    AlterDetalhamentoFinanceiroService,
    AlterDetalhamentoHasEtapaService,
    CategoriaService,
    DetalhamentoFinanceiroHasEtapaCronogramaFisicoService,
    CronogramaFisicoExecucaoObjetoService
  ]
})
export class PlanoTrabalhoAjusteModule { }
