import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

declare let AdminLTE: any;

@Component({
  selector: 'app-mandato-listar',
  templateUrl: './mandato-listar.component.html'
})
export class MandatoListarComponent implements OnInit {
  
  constructor(
    private title: Title,
  ) { }

  ngOnInit() {
    AdminLTE.init();
    this.title.setTitle('Mandato listar');
   
  }

  mandatoVer(){    
    // this.router.navigate(["/osc/mandato-ver"]);
  }

}
