import { Injectable } from '@angular/core';
import { Http, URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import * as moment from 'moment';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { Entidade, Usuario, ConcedenteUsuarioNovoDTO } from '../../model';
import { AuthService } from '../../../seguranca/auth.service';

export class ConcedenteFiltro {
  nome: string;
  cnpj: string;
  tipoConcedente: string;
  ofertaCurso: any;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ConcedenteService {

  url: string;
  usuarioChefe = new Usuario();

  constructor(private http: AuthHttp,
    private httpPub: Http,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/entidade-concedente`;
  }

  pesquisaPublica(filtro: ConcedenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set('nome', filtro.nome);
    }
    if (filtro.cnpj) {
      params.set('cnpj', filtro.cnpj);
    }
    if (filtro.tipoConcedente) {
      params.set('tipoConcedente', filtro.tipoConcedente);
    }
    if (filtro.ofertaCurso) {
      params.set('ofertaCurso', filtro.ofertaCurso);
    }

    return this.httpPub.get(`${this.url}/concedentes-publicos`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  pesquisar(filtro: ConcedenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set('nome', filtro.nome);
    }
    if (filtro.cnpj) {
      params.set('cnpj', filtro.cnpj);
    }
    if (filtro.tipoConcedente) {
      params.set('tipoConcedente', filtro.tipoConcedente);
    }
    if (filtro.ofertaCurso) {
      params.set('ofertaCurso', filtro.ofertaCurso);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();
    params.set('size', '100');

    if (this.auth.jwtPayload.perfil == "Chefe de Concedente") {
      params.set('chefe', this.auth.jwtPayload.id);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  countEntidade(): Promise<any> {
    return this.http
      .get(`${this.url}/count`)
      .toPromise()
      .then(response => response.json());
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Entidade): Promise<Entidade> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }


  adicionarNovo(concedenteUsuarioNovoDTO: ConcedenteUsuarioNovoDTO): Promise<ConcedenteUsuarioNovoDTO> {
    return this.http
      .post(`${this.url}/cadastro-novo`, JSON.stringify(concedenteUsuarioNovoDTO))
      .toPromise()
      .then(response => response.json() as ConcedenteUsuarioNovoDTO);
  }

  atualizar(entidade: Entidade): Promise<Entidade> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Entidade);
  }

  buscarPorCodigo(codigo: number): Promise<Entidade> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as Entidade;

        this.converterStringsParaDatas([entidade]);

        return entidade;
      });
  }

  private converterStringsParaDatas(editais: Entidade[]) {
    for (const entidade of editais) {
      if (entidade.dataCadastro) {
        entidade.dataCadastro = moment(entidade.dataCadastro,
          'YYYY-MM-DD').toDate();
      }

    }
  }


}
