import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../seguranca/auth.guard";
import { ConviteComponent } from './convite/convite.component';
import { UsuarioCadastroComponent } from './usuario-cadastro/usuario-cadastro.component';
import { UsuarioGerenciarComponent } from './usuario-gerenciar/usuario-gerenciar.component';
import { UsuarioListarComponent } from './usuario-listar/usuario-listar.component';

const routers: Routes = [
  { path: '', component: UsuarioGerenciarComponent, canActivate: [AuthGuard],
  children: [
    { path: 'listar', component: UsuarioListarComponent, canActivate: [AuthGuard] },
    { path: 'novo', component: UsuarioCadastroComponent, canActivate: [AuthGuard] },
    { path: 'editar', component: UsuarioCadastroComponent, canActivate: [AuthGuard] },
    { path: 'convite', component: ConviteComponent, canActivate: [AuthGuard] },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routers)],
  exports: [RouterModule]
})
export class UsuarioRoutingModule {}
