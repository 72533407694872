import { environment } from '../../environments/environment';

import { EmendaParlamentar } from '../core/model';

import { Headers, URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';
import { AuthHttp } from 'angular2-jwt';

import { LazyLoadEvent } from "primeng/components/common/api";

import 'rxjs/add/operator/toPromise';
import * as moment from 'moment';

export class EmendaParlamentarFiltro {
  titulo: string;
  entidadeConcedente: string
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class EmendaParlamentarService {

  emendaParlamentarUrl: string;

  constructor(private http: AuthHttp) {
    this.emendaParlamentarUrl = `${environment.apiUrl}/emenda-parlamentar`;
  }

  pesquisar(filtro: EmendaParlamentarFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }
    if (filtro.entidadeConcedente) {
      params.set('entidadeConcedente', filtro.entidadeConcedente);
    }

    return this.http.get(`${this.emendaParlamentarUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dados = responseJson.content;

        const resultado = {
          dados,
          total: responseJson.totalElements
        };
        // //console.log(resultado)
        return resultado;

      })
  }

  listarEmendaDaEntidade(idEntidade: any): Promise<any> {
    const params = new URLSearchParams();
    params.set('entidadeConcedente', idEntidade);

    return this.http.get(`${this.emendaParlamentarUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.emendaParlamentarUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.emendaParlamentarUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: EmendaParlamentar): Promise<EmendaParlamentar> {
    
    if(entidade.entidadeProponente.id == null){
      entidade.entidadeProponente = null
      entidade.tipo = true
    }else{
      entidade.tipo = false
    }

    return this.http.post(this.emendaParlamentarUrl, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: EmendaParlamentar): Promise<EmendaParlamentar> {
    return this.http.put(`${this.emendaParlamentarUrl}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => {
        const objetoAlterado = response.json() as EmendaParlamentar;

        this.converterStringsParaDatas([objetoAlterado]);

        return objetoAlterado;
      });
  }

  buscarPorCodigo(codigo: number): Promise<EmendaParlamentar> {
    return this.http.get(`${this.emendaParlamentarUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as EmendaParlamentar;

        this.converterStringsParaDatas([entidade]);

        return entidade;
      });
  }

  buscarPorCodigoSemConverterData(codigo: number): Promise<EmendaParlamentar> {
    return this.http.get(`${this.emendaParlamentarUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as EmendaParlamentar;
        return entidade;
      });
  }

  buscarRetornandoDataconvertidaESemConverter(codigo: number): Promise<any>{
    return this.http.get(`${this.emendaParlamentarUrl}/${codigo}`)
    .toPromise()
    .then(response => {
      const entidade = response.json() as EmendaParlamentar;
      const entidadeConvertida = response.json() as EmendaParlamentar;
      this.converterStringsParaDatas([entidadeConvertida])
      
      const resultado = {
        semConverter: entidade,
        convertida: entidadeConvertida
      }
      return resultado;
    });
  }

  private converterStringsParaDatas(objArray: EmendaParlamentar[]) {
    for (const iteracaoAtributo of objArray) {
      if (iteracaoAtributo.dataPublicacao) {
        iteracaoAtributo.dataPublicacao = moment(iteracaoAtributo.dataPublicacao,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoIniManifestacaoInteresse) {
        iteracaoAtributo.periodoIniManifestacaoInteresse = moment(iteracaoAtributo.periodoIniManifestacaoInteresse,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoFimManifestacaoInteresse) {
        iteracaoAtributo.periodoFimManifestacaoInteresse = moment(iteracaoAtributo.periodoFimManifestacaoInteresse,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoIniAutenticarDoc) {
        iteracaoAtributo.periodoIniAutenticarDoc = moment(iteracaoAtributo.periodoIniAutenticarDoc,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoFimAutenticarDoc) {
        iteracaoAtributo.periodoFimAutenticarDoc = moment(iteracaoAtributo.periodoFimAutenticarDoc,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoPublicPreliminar) {
        iteracaoAtributo.periodoPublicPreliminar = moment(iteracaoAtributo.periodoPublicPreliminar,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoIniRecurso) {
        iteracaoAtributo.periodoIniRecurso = moment(iteracaoAtributo.periodoIniRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoFimRecurso) {
        iteracaoAtributo.periodoFimRecurso = moment(iteracaoAtributo.periodoFimRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoIniAnalRecurso) {
        iteracaoAtributo.periodoIniAnalRecurso = moment(iteracaoAtributo.periodoIniAnalRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.periodoFimAnalRecurso) {
        iteracaoAtributo.periodoFimAnalRecurso = moment(iteracaoAtributo.periodoFimAnalRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (iteracaoAtributo.dataPublicacaoFinal) {
        iteracaoAtributo.dataPublicacaoFinal = moment(iteracaoAtributo.dataPublicacaoFinal,
          'YYYY-MM-DD').toDate();
      }
    }
  }

}
