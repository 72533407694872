import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";

import { environment } from "../../../../environments/environment";

@Injectable()
export class ConsultaCepimService {

  constructor(private http: Http) {}

  consultaCepim(cnpj: any): Promise<any> {
    const headers = new Headers();
    // headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    return this.http
      .post(`${environment.cepim}`, JSON.stringify(cnpj), { headers })
      .toPromise()
      .then(response => {
        return response.json();
    });

    // let url = `https://www.transparencia.gov.br/api-de-dados/cepim?cnpjSancionado=${cnpj}&pagina=1`;
    // return this.http
    //   .get(`https://www.sigrp.pmt.pi.gov:8888/?link=${url}`, { headers })
    //   .toPromise()
    //   .then(response => {
    //     return response.json();
    //   });

  }
}
