import { Component, OnInit } from '@angular/core';

import { DescricaoRealidade, AlterDescricaoRealidade } from '../../../core/model';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { Subject } from 'rxjs';
import { ToastyService } from 'ng2-toasty';
import { AlterDescricaoRealidadeFiltro, AlterDescricaoRealidadeService } from '../../../core/services/alteracao-plano/alterDescricaoRealidade.service';
import { AuthService } from '../../../seguranca/auth.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-alterar-descricao-realidade',
  templateUrl: './alterar-descricao-realidade.component.html',
})
export class AlterarDescricaoRealidadeComponent implements OnInit {
  public showPreview: boolean = false;
  public update$: Subject<any> = new Subject();
  public options1: any = {
    autogrow: true,
    removeformatPasted: true,
    semantic: false,
    btns: [
      ["viewHTML"],
      ["undo", "redo"], // Only supported in Blink browsers
      ["formatting"],
      ["strong", "em", "del"],
      ["superscript", "subscript"],
      ["link"],
      ["insertImage"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
      ["unorderedList", "orderedList"],
      ["horizontalRule"],
      ["removeformat"] 
    ]
  };
  
  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  descricaoRealidade = new DescricaoRealidade();
  atualizar: boolean;

  loading
  alterDescricaoRealidade = new AlterDescricaoRealidade();

  constructor(
    private auth: AuthService,
    private alterDescricaoRealidadeService: AlterDescricaoRealidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.descricaoRealidade = this.planoTrabalhoHasProponente.descricaoRealidade[0];
    this.procuraAlteracaoDescricao()

  }

  // ==== PROCURAR DESCRICAO REALIDADE ==== //
  procuraAlteracaoDescricao(){
    let filtro = new AlterDescricaoRealidadeFiltro
    filtro.descricaoRealidade = this.descricaoRealidade.id
    filtro.statusAlteracao = "2" //Submetido    
    
    this.alterDescricaoRealidadeService.pesquisar(filtro)
    .then(retorno => {
      if(retorno.dado[0] != null){   
        this.alterDescricaoRealidade = retorno.dado[0] 
        this.descricaoRealidade.descricaoRealidade = this.alterDescricaoRealidade.descricaoRealidadeDepois
        this.atualizar = true
      }
      this.loading = true
    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  // ==== MODIFICAR DESCRICAO REALIDADE ==== //
  modificaDescricaoRealidade(){
    this.update$.next();

    if(this.atualizar){
      this.alterDescricaoRealidade.data = new Date().getTime()
      this.alterDescricaoRealidade.usuarioSubmeteu = this.auth.jwtPayload.id
      this.alterDescricaoRealidade.descricaoRealidadeDepois = this.descricaoRealidade.descricaoRealidade 
      this.atualizaAlterDescricaoRealidade()
    }else{

      let adr = new AlterDescricaoRealidade()
      adr.versaoPlano = this.versaoPlano.id
      adr.usuarioSubmeteu = this.auth.jwtPayload.id
      adr.descricaoRealidade = this.descricaoRealidade.id
      adr.pthpId = this.planoTrabalhoHasProponente.id    
      adr.data = new Date().getTime()
      adr.descricaoRealidadeDepois = this.descricaoRealidade.descricaoRealidade
      adr.statusAlteracao = "2" //submetido
      adr.tipoAlteracao = "0" //Alterado
  
      this.addAlterDescricaoRealidade(adr)
    }
  }

  addAlterDescricaoRealidade(adr){
    this.alterDescricaoRealidadeService.adicionar(adr)
    .then(retorno => {
      this.atualizar = true
      this.alterDescricaoRealidade = retorno
      this.toasty.success("Ajuste realizado com Sucesso!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterDescricaoRealidade(){
    this.alterDescricaoRealidadeService.atualizar(this.alterDescricaoRealidade)
    .then(() => {
      this.toasty.success("Ajuste realizado com Sucesso!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }


}
