import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { CelebracaoHasAtendidosProjeto } from '../../model';
import { AtendidosProjeto } from './../../model';

export class CelebracaoHasAtendidosProjetoFiltro {
  celebracao;
  atendidosProjeto = new AtendidosProjeto();
  status
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class CelebracaoHasAtendidosProjetoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/celebracao-has-atendidos-projeto`;
  }

  pesquisar(filtro: CelebracaoHasAtendidosProjetoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.celebracao) {
      params.set('celebracao', filtro.celebracao);
    }
    if (filtro.atendidosProjeto.cpf) {
      params.set('cpf', filtro.atendidosProjeto.cpf);
    }
    if (filtro.atendidosProjeto.nome) {
      params.set('nome', filtro.atendidosProjeto.nome);
    }
    if (filtro.status) {
      params.set('status', filtro.status);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: CelebracaoHasAtendidosProjeto): Promise<CelebracaoHasAtendidosProjeto> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: CelebracaoHasAtendidosProjeto): Promise<CelebracaoHasAtendidosProjeto> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as CelebracaoHasAtendidosProjeto);
  }

  buscarPorCodigo(codigo: number): Promise<CelebracaoHasAtendidosProjeto> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as CelebracaoHasAtendidosProjeto;
        return entidade;
      });
  }

}
