import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

declare let AdminLTE: any;

@Component({
  selector: 'app-convite-osc',
  templateUrl: './convite-osc.component.html',
})
export class ConviteOSCComponent implements OnInit {
  public showPreview: boolean = false;
  public initialContentOne: string = 'Carregando conteúdo inicial...';
  public contentOne: string;
  public update$: Subject<any> = new Subject();
  public options1: any = {
    autogrow: true,
    removeformatPasted: true,
    semantic: false,
    btns: [
      ['bold', 'italic'],
      ['link'],
      ['foreColor', 'backColor'],
      ['preformatted'],
      ['noembed'],
    ],
  };

  togglePreview() {
    this.showPreview = !this.showPreview;
    if (this.showPreview) this.update$.next();
  }

  constructor() {
    //Initial content update.
    setTimeout(() => {
      this.initialContentOne = '<p>Digite aqui o conteúdo</p>';
      this.update$.next();
    }, 2000);
  }

  ngOnInit() {
    AdminLTE.init();
  }
 
}
