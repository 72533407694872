import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedsModule } from '../../shareds/shareds.module';

import { ComissaoAcompanhamentoPesquisaComponent } from './comissao-acompanhamento-pesquisa/comissao-acompanhamento-pesquisa.component';
import { ComissaoAcompanhamentoCadastroComponent } from './comissao-acompanhamento-cadastro/comissao-acompanhamento-cadastro.component';
import { ComissaoAcompanhamentoEditarComponent } from './comissao-acompanhamento-editar/comissao-acompanhamento-editar.component';

import { DragulaModule } from 'ng2-dragula';
import { PaginationModule } from '../../core/pagination/pagination.module';
import { ComissaoAcompanhamentoService } from './comissaoAcompanhamento.service';
import { ComissaoAcompanhamentoHasUsuarioConcedenteService } from './comissaoAcompanhamentoHasUsuarioConcedente.service';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      SharedsModule,
      PaginationModule,

      DragulaModule.forRoot()
    ],
    declarations: [
        ComissaoAcompanhamentoPesquisaComponent,
        ComissaoAcompanhamentoCadastroComponent,
        ComissaoAcompanhamentoEditarComponent
    ],
    exports: [],
    providers:[
      ComissaoAcompanhamentoService,
      ComissaoAcompanhamentoHasUsuarioConcedenteService,
    ]
  })
  export class ComissaoAcompanhamentoModule { }