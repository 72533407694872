import { ProponenteFiltro } from './../../../core/services/proponente/proponente.service';
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { Subject } from "rxjs";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { DispensaInexigibilidade, PlanoTrabalho, AnexoProposta } from "../../../core/model";
import { DispensaInexigibilidadeService } from "../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service";
import { ProponenteService } from "../../../core/services/proponente/proponente.service";
import { EditalCredenciamentoService } from "../../../core/services/edital-credenciamento/EditalCredenciamento.service";
import { EixoProgramaService } from "../../../core/services/edital-credenciamento/EixoPrograma.service";
import { AuthService } from "../../../seguranca/auth.service";
import { EixoProgramaHasProponenteCredenciadoService } from "../../../core/services/eixo-programa-has-proponente-credenciado/eixo-programa-has-proponente-credenciado.service";
import { ConcedenteFiltro, ConcedenteService } from '../../../core/services/concedente/concedente.service';
import { AnexoPropostaService } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { environment } from '../../../../environments/environment';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-contrato-cadastro",
  templateUrl: "./contrato-cadastro.component.html"
})
export class ContratoCadastroComponent implements OnInit {
  entidadesConcedente: any;
  dispensaInexigibilidade = new DispensaInexigibilidade();
  planoTrabalho = new PlanoTrabalho();
  planosDaDispensa = []
  proponenteFiltro = new ProponenteFiltro()
  listaOscs
  anexoProposta = new AnexoProposta()
  concedenteFiltro = new ConcedenteFiltro()
  etapa1: boolean;
  etapa2: boolean;
  etapa3: boolean;
  etapa4: boolean;
  etapa5: boolean;


  dataUploadArray = [];
  openModalViewUpload: boolean;
  tipoUpload: string;
  displayAnexo: boolean;
  nomeAnexo: any;
  urlDocumento: string;
  viewDownload: boolean;

  

  constructor(
    private eixoProgramaHasProponenteCredenciadoService: EixoProgramaHasProponenteCredenciadoService,
    private eixoProgramaService: EixoProgramaService,
    private entidadeProponenteService: ProponenteService,
    private editalCredenciamentoService: EditalCredenciamentoService,
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private dispensaInexigibilidadeService: DispensaInexigibilidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private anexoPropostaService: AnexoPropostaService
  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    this.verificaSeAdm()
    this.title.setTitle("Novo Contrato de Gestão");

    //Inicializando obj para richtexteditor
   this.dispensaInexigibilidade.descricao = "Descrição..." 
   this.planoTrabalho.justificativa = "Justificativa..." 
  }


  
   //======== UPLOAD
 
 openModalUpload(tipo){
  this.openModalViewUpload = true
  this.tipoUpload = tipo
  

}


adicionarArquivo(){
  let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
  sessionStorage.removeItem('upload');
  if(!fileItem || !fileItem.responseStatus)
  return this.toasty.error("É necessário efetivar o upload");

  if (this.tipoUpload == "contrato") {
    this.dataUploadArray.push(fileItem.response.data);
  }


  this.openModalViewUpload=false
}

removeFileProposta(item, tipo) {
  if (tipo == "contrato") {
    var index = this.dataUploadArray.indexOf(item);
    if (index > -1) {
      this.dataUploadArray.splice(index, 1);
    }
  }
}

async salvarPropostaAnexo(proposta) {

  this.dataUploadArray.forEach(element => {
    this.anexoProposta.proposta = element.originalname; 
    this.anexoProposta.hashFile = element.filename;
    this.anexoProposta.dispensaInexigibilidade.id = proposta.id 
    this.anexoProposta.descricaoAnexo = "Contrato"
    

    this.anexoPropostaService
      .adicionar(this.anexoProposta)
      .then(() => {
        this.toasty.success( element.originalname +" "+ "cadastrado com sucesso!");
        this.dataUploadArray = [];
      })
      .catch(erro => this.errorHandler.handle(erro));
  });
}
//========== ./UPLOAD

// ################# VIEW ANEXO 
openModalAnexo(anexo){
  
  this.displayAnexo = true
  this.nomeAnexo = anexo.originalname
  this.urlDocumento = environment.downloadFileUrl + "/" + anexo.filename;
  anexo.filename.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
}
//######################./ VIEW ANEXO
 
  // ==== SE ADMINISTRADOR ====//
 
  verificaSeAdm() {
    if (this.auth.jwtPayload.perfil == "Administrador") {
      this.carregarEntidadesConcedente();
    } else {
      this.dispensaInexigibilidade.entidadeConcedente.id = this.auth.jwtPayload.concedente
    }
  } 

  carregarEntidadesConcedente() {
    this.concedenteFiltro.tipoConcedente = "1"
    this.concedenteService.pesquisar(this.concedenteFiltro)
      .then(entidades => {
        this.entidadesConcedente = entidades.content.map(c=>({label:c.nome, value:c.id}))
        
       
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarOscs(){
    this.proponenteFiltro.tipoProponente = "0"
  this.entidadeProponenteService.pesquisar(this.proponenteFiltro).then(res =>{
    this.listaOscs = res.content.map(c=>({label: c.nome, value: c.id}))
    
    
    
  })
    
  }
 
  
  entidadeSelecionada(idEntidade) {
    this.dispensaInexigibilidade.entidadeConcedente.id = idEntidade
  }

  
  oscSelecionada(idOsc){
           
        this.buscarProponente(idOsc)
      
  }
  //====== RETORNA RESUMO ETAPA V
  retornaTipoProposta(){
  let nome
    if(this.dispensaInexigibilidade.tipoProposta == "2"){
      nome = "Contratdo de Gestão"
      return nome
    }
  }

  retornaNomeEntidade(){
    let nome
    this.listaOscs.forEach(entidade => {
      if(entidade.value == this.planoTrabalho.entidadeProponente.id){
        nome = entidade.label
      }
    });
    return nome
  }
  

 
// === ADICIONA PROPOSTA TIPO CONTRATO DE GESTAO ===//

  

  salvar() { 

   //PLANO DE TRABALHO 
   this.planoTrabalho.usuario.id = this.auth.jwtPayload.id
   this.planoTrabalho.chamamentoPublico = null
   this.planoTrabalho.dispensaInexigibilidade = null
   this.planoTrabalho.editalCredenciamento = null
   this.planoTrabalho.eixoPrograma = null
   this.planoTrabalho.emendaParlamentar = null 
   this.planoTrabalho.celebracao = null
   this.planoTrabalho.editalCredenciamento = null
   this.planoTrabalho.eixoPrograma = null
   this.planoTrabalho.emendaParlamentar = null
 
   
   
   this.planosDaDispensa [0] = this.planoTrabalho
 
  
 
  //INICIANDO VARIAVEIS DISPENSAINEGIBILIDADE
  this.dispensaInexigibilidade.usuario.id = this.auth.jwtPayload.id
  this.dispensaInexigibilidade.tipoProposta = "2" //Proposta por Contrato de Gestao
  this.dispensaInexigibilidade.dispensa = false
  this.dispensaInexigibilidade.inexigibilidade = false
  this.dispensaInexigibilidade.dataPublicacao = null
  this.dispensaInexigibilidade.dataAnaliseProp=null
  this.dispensaInexigibilidade.dataCadastroProp = null
  this.dispensaInexigibilidade.usuarioAvaliouProposta = null
  this.dispensaInexigibilidade.usuarioEnvioProposta = null
  this.dispensaInexigibilidade.statusAvaliacao = null
  this.dispensaInexigibilidade.statusProposta = null
  this.dispensaInexigibilidade.logAvaliacao = null
  this.dispensaInexigibilidade.origemProposta = null
  this.dispensaInexigibilidade.anexoJustificativa = null
  this.dispensaInexigibilidade.anexoJustificativaHashFile = null
  this.dispensaInexigibilidade.planoTrabalho = this.planosDaDispensa
  
  
 this.dispensaInexigibilidadeService.adicionar(this.dispensaInexigibilidade)
    .then(objAdd => {  
        this.salvarPropostaAnexo(objAdd)     
        this.toasty.success("Proposta tipo contrato de gestão adicionado com sucesso!");        
        this.router.navigate(['proposta/contrato/listar']);
    })
    .catch(erro => this.errorHandler.handle(erro));
  }
    

  
  novo(form: FormControl) {
    form.reset();

    //Necessário para que "new" seja executado depois de "form.reset"
    setTimeout(
      function () {
        this.dispensaInexigibilidade = new DispensaInexigibilidade();
        this.verificaSeAdm()
        this.fecharTodasEtapas()
      }.bind(this),
      1
    );


  }

  


  buscarProponente(id){
  this.entidadeProponenteService.buscarPorCodigo(id).then( proponent=>{
    this.planoTrabalho.entidadeProponente = proponent
   
  })
  }


  

  

  //==== ETAPAS =====//
  openEtapa1() {
    this.etapa1 = true
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa2() {
    this.etapa1 = false
    this.etapa2 = true
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa3() {
   
    this.dispensaInexigibilidade.tipoProposta = "2"
    this.carregarOscs();

    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = true
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa4() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = true
    this.etapa5 = false
  }
  openEtapa5() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = true
  }
  fecharTodasEtapas() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }






}
