import { Component, OnInit } from '@angular/core';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-contrapartida',
  templateUrl: './contrapartida.component.html',
})
export class ContrapartidaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
  }

}
