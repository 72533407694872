import { Injectable } from "@angular/core";
import { URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";
import "rxjs/add/operator/toPromise";
import { environment } from "../../../../environments/environment";
import { EntidadeProponenteHasUsuario } from "../../../core/model";
import { AuthService } from "../../../seguranca/auth.service";

export class EntidadeProponenteHasUsuarioFiltro {
  entidadeProponente: string;
  usuario: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class EntidadeProponenteHasUsuarioService {
  entidadeProponenteHasUsuarioUrl: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.entidadeProponenteHasUsuarioUrl = `${
      environment.apiUrl
    }/entidade-proponente-has-usuario`;
  }

  pesquisar(filtro: EntidadeProponenteHasUsuarioFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }
    if (filtro.usuario) {
      params.set("usuario", filtro.usuario);
    }

    return this.http
      .get(`${this.entidadeProponenteHasUsuarioUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const entidadeProponenteHasUsuarios = responseJson.content;

        const resultado = {
          entidadeProponenteHasUsuarios,
          total: responseJson.totalElements
        };
        // //console.log(resultado);
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();

    return this.http
      .get(`${this.entidadeProponenteHasUsuarioUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.entidadeProponenteHasUsuarioUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(
    entidadeProponenteHasUsuario: EntidadeProponenteHasUsuario
  ): Promise<EntidadeProponenteHasUsuario> {
    return this.http
      .post(
        this.entidadeProponenteHasUsuarioUrl,
        JSON.stringify(entidadeProponenteHasUsuario)
      )
      .toPromise()
      .then(response => response.json());
  }

  atualizar(
    entidadeProponenteHasUsuario: EntidadeProponenteHasUsuario
  ): Promise<EntidadeProponenteHasUsuario> {
    return this.http
      .put(
        `${this.entidadeProponenteHasUsuarioUrl}/${
          entidadeProponenteHasUsuario.id
        }`,
        JSON.stringify(entidadeProponenteHasUsuario)
      )
      .toPromise()
      .then(response => response.json() as EntidadeProponenteHasUsuario);
  }

  buscarPorCodigo(codigo: number): Promise<EntidadeProponenteHasUsuario> {
    return this.http
      .get(`${this.entidadeProponenteHasUsuarioUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidadeProponenteHasUsuario = response.json() as EntidadeProponenteHasUsuario;

        return entidadeProponenteHasUsuario;
      });
  }
}
