import { NotificacaoHasUsuarioService } from './../../core/services/notificacao/notificacaoHasUsuario.service';
import { Location } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Entidade, EntidadeConcedenteHasUsuario, Osc, Usuario, NotificacaoHasUsuarioDTO } from "../../core/model";
import { ConcedenteFiltro, ConcedenteService } from "../../core/services/concedente/concedente.service";
import { ProponenteFiltro, ProponenteService } from "../../core/services/proponente/proponente.service";
import { AuthService } from "../../seguranca/auth.service";
import { UsuarioFiltro, UsuarioService } from "../usuario.service";
import { PessaoUtil } from "./../../core/pessoa.util";
import { PerfilHasPerfilFiltro, PerfilHasPerfilService } from './../../core/services/perfil-permissao/perfil-has-perfil.service';
import { GetAdress } from './../../core/services/utils/get-address.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-usuario-cadastro",
  templateUrl: "./usuario-cadastro.component.html"
})
export class UsuarioCadastroComponent implements OnInit {
  usuario = new Usuario();

  filtroConcedente = new ConcedenteFiltro();
  filtroProponente = new ProponenteFiltro();

  filtroUsuario = new UsuarioFiltro();
  pessoaCpf = new PessaoUtil();
  cpfTemp: any
  emailTemp: any

  concedente = new Entidade();
  proponente = new Osc();

  entidade;
  tipoEntidade;

  entidadesDoConcedente: any;
  perfis = [];
  entidadeConcedenteHasUsuario = new EntidadeConcedenteHasUsuario();

  passwordAlso;
  password;
  confirmPassword;

  constructor(
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private proponenteService: ProponenteService,
    private perfilHasPerfilService: PerfilHasPerfilService,
    private getAdress: GetAdress,
    private usuarioService: UsuarioService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private location: Location,
    private confirmation: ConfirmationService,
    private notificacaoHasUsuarioService: NotificacaoHasUsuarioService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
    this.title.setTitle("Novo usuário");
    
    // //console.log(this.router.url);
    
    if ( this.router.url === '/cadastros/usuario/editar') {
      const codigoPessoa = sessionStorage.getItem("usuario");
      this.carregarPessoa(codigoPessoa);
      this.title.setTitle("Editar usuário");
    }else{
      sessionStorage.removeItem("usuario")
    }

    this.carregarPerfis(this.auth.jwtPayload.perfil_id);

    this.selecionarEntidade();
  }


  goBack() {
    this.location.back();
  }

  checkEfetividade(event) {
    if (event.target.checked) {
      this.usuario.efetivo = 1
    } else {
      this.usuario.efetivo = 0
    }
  }

  // =====  VIEWS STEPS
  etapa1;
  etapa2;
  etapa3;
  etapa4;
  etapa5;
  etapa6;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa5() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = true;
    this.etapa6 = false;
  }
  openEtapa6() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = true;
  }

  getConcedente(id) {
    this.tipoEntidade = 'Concedente'
    this.concedenteService
      .buscarPorCodigo(id)
      .then(retorno => {
        this.entidade = retorno;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  getProponente(id) {
    this.tipoEntidade = 'Proponente'
    this.proponenteService
      .buscarPorCodigo(id)
      .then(retorno => {
        this.entidade = retorno;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // getListaConcedentes(pagina = 0) {
  //   this.listaEntidadesTitulo = "Concedentes";
  //   this.filtroConcedente.pagina = pagina;
  //   this.concedenteService.pesquisar(this.filtroConcedente).then(resultado => {
  //     this.listaEntidades = resultado.content;
  //   });
  // }
  // getListaProponentes(pagina = 0) {
  //   this.listaEntidadesTitulo = "Proponentes";
  //   this.filtroProponente.pagina = pagina;
  //   this.proponenteService.pesquisar(this.filtroProponente).then(resultado => {
  //     this.listaEntidades = resultado.content;
  //   });
  // }

  selecionarEntidade() {
    if (this.auth.jwtPayload.proponente) {
      this.getProponente(this.auth.jwtPayload.proponente);
    } else if (this.auth.jwtPayload.concedente) {
      this.getConcedente(this.auth.jwtPayload.concedente);
    }
  }

  carregarEntidadesDoChefeConcedente() {
    return this.concedenteService
      .listarTodos()
      .then(entidadesConcedente => {
        this.entidadesDoConcedente = entidadesConcedente.map(c => ({
          label: c.nome,
          value: c.id
        }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarPessoa(codigo) {
    this.usuarioService
      .buscarPorCodigo(codigo)
      .then(pessoa => {
        this.usuario = pessoa;
        this.cpfTemp = pessoa.cpf
        this.emailTemp = pessoa.email
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  get editando() {
    return Boolean(this.usuario.id);
  }

  salvar() {
    if (this.editando) {
      this.atualizarUsuario();
    } else {
      this.adicionarPessoa();
    }
  }

  adicionarPessoa() {
    if (this.password === this.confirmPassword) {
      this.usuario.senha = this.password;

      this.usuarioService
        .adicionar(this.usuario)
        .then(pessoaAdicionada => {

          // ===== EFETIVAR CADASTRO DE NOTIFICACAO-HAS-USUARIOS
          this.enviarMensagem(pessoaAdicionada)

          // ===== Após add o usuário, vincula ele a entidade
          if (this.tipoEntidade === 'Concedente') {
            this.usuarioService
              .atualizarUsuarioConcedente(pessoaAdicionada, pessoaAdicionada.id, this.entidade.id)
              .then(retorno => {
                this.toasty.success("Pessoa adicionada com sucesso!");
                this.router.navigate(["/cadastros/usuario/listar"]);
              })
          }
          if (this.tipoEntidade === 'Proponente') {
            this.usuarioService
              .atualizarUsuarioProponente(pessoaAdicionada, pessoaAdicionada.id, this.entidade.id)
              .then(retorno => {
                this.toasty.success("Pessoa adicionada com sucesso!");
                this.router.navigate(["/cadastros/usuario/listar"]);
              })
          }

          if (this.tipoEntidade != 'Concedente' && this.tipoEntidade != 'Proponente') {
            this.toasty.success("Pessoa adicionada com sucesso!");
            this.router.navigate(["/cadastros/usuario/listar"]);
          }


        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.errorHandler.handle("Senhas não conferem.");
    }
  }

  async enviarMensagem(usuario){

    let usuariosLista = [];
    usuariosLista.push(usuario)
 
    var notificacaoHasUsuarioDTO = new NotificacaoHasUsuarioDTO()

    notificacaoHasUsuarioDTO.dataEnvio = new Date().getTime()
    notificacaoHasUsuarioDTO.statusNotificacao = 'Nao_lida'
    notificacaoHasUsuarioDTO.favorito = 0
    notificacaoHasUsuarioDTO.lixeira = 0
    notificacaoHasUsuarioDTO.usuarioLista = usuariosLista
    notificacaoHasUsuarioDTO.notificacao.id = 1

    await this.notificacaoHasUsuarioService.adicionarDTO(notificacaoHasUsuarioDTO).then(() => {
      // registro realizado com sucesso!
    }).catch(erro => this.errorHandler.handle(erro));
  }



  filtroNomeEntidade
  filtroCnpjEntidade
  listaEntidadesTitulo
  listaEntidades = []
  getListaConcedentes(pagina = 0) {
    this.listaEntidadesTitulo = "Concedentes";

    this.filtroConcedente.cnpj = this.filtroCnpjEntidade
    this.filtroConcedente.nome = this.filtroNomeEntidade

    this.filtroConcedente.pagina = pagina;
    this.concedenteService.pesquisar(this.filtroConcedente).then(resultado => {
      this.listaEntidades = resultado.content;
    });
  }
  getListaProponentes(pagina = 0) {
    this.listaEntidadesTitulo = "Proponentes";

    this.filtroProponente.cnpj = this.filtroCnpjEntidade
    this.filtroProponente.nome = this.filtroNomeEntidade

    this.filtroProponente.pagina = pagina;
    this.proponenteService.pesquisar(this.filtroProponente).then(resultado => {
      this.listaEntidades = resultado.content;
    });
  }

  confirmarVinculo(item) {
    this.confirmation.confirm({
      message: '<br><br>Tem certeza que vincular a entidade: <br><b>' + item.nome + '</b> <br>ao usuário: <br><b>' + this.usuario.nome + '</b> ?',
      accept: () => {
        this.atualizarEntidade(item.id);
      },
    });
  }

  atualizarEntidade(entidadeId) {

    let usuario = this.usuario

    if (this.listaEntidadesTitulo === 'Concedentes') {
      this.usuarioService
        .atualizarUsuarioConcedente(usuario, usuario.id, entidadeId)
        .then(() => {
          this.toasty.success("Entidade Atualizada com sucesso!");
        })
    }
    if (this.listaEntidadesTitulo === 'Proponentes') {
      this.usuarioService
        .atualizarUsuarioProponente(usuario, usuario.id, entidadeId)
        .then(() => {
          this.toasty.success("Entidade Atualizada com sucesso!");
        })
    }
  }





  atualizarUsuario() {
    if (this.password && this.confirmPassword) {
      if (this.password === this.confirmPassword) {
        this.usuario.senha = this.password;
        this.usuario.senha_antiga = this.passwordAlso;

        this.usuarioService
          .atualizar(this.usuario)
          .then(retorno => {
            this.usuario = retorno;
            this.toasty.success("Usuário alterado com sucesso!");

            this.router.navigate(["/cadastros/usuario/listar"]);
          })
          .catch(erro => this.errorHandler.handle(erro));
      } else {
        this.errorHandler.handle("Senhas não conferem.");
      }
    } else {
      this.usuarioService
        .atualizar(this.usuario)
        .then(pessoa => {

          this.toasty.success("Usuário alterado com sucesso!");

          this.router.navigate(["/cadastros/usuario/listar"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  resetarSenha() {
    // //console.log(this.usuario);
    
    this.usuarioService
      .resetarSenha(this.usuario)
      .then(pessoa => {
        this.usuario = pessoa;
        this.toasty.success("Senha resetada com sucesso!");
        this.router.navigate(["/cadastros/usuario/listar"]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }



  async carregarPerfis(perfilPrincipal) {
    let phpf = new PerfilHasPerfilFiltro()
    phpf.perfilPrincipal = perfilPrincipal;
    await this.perfilHasPerfilService
      .pesquisar(phpf)
      .then(async resultado => {
        let perfisArray = [];
        await resultado.dados.forEach(vinculado => {
          perfisArray.push(vinculado.perfilPermitido);
        });

        this.perfis = perfisArray.map(p => ({ label: p.nome, value: p.id }));
      });
  }
  
  // carregarPerfis() {
  //   return this.perfilService
  //     .listarTodos()
  //     .then(perfis => {
  //       let perfisArray = [];
  //       perfisArray = perfis.map(p => ({ label: p.nome, value: p.id }));

  //       this.perfis = perfisArray;

  //       if (this.auth.jwtPayload.perfil == "Gestor de Concedente") {
  //         this.perfis = [];
  //         perfisArray.forEach(element => {
  //           if (
  //             element.label === "Analista Jurídico" ||
  //             element.label === "Analista Técnico" ||
  //             element.label === "Executor Concendente" ||
  //             element.label === "Gestor de Parceria" ||
  //             element.label === "Membro de Comissão" ||
  //             element.label === "Assessor Controle Interno"
  //           ) {
  //             this.perfis.push(element);
  //           }
  //         });
  //       }

  //       if (this.auth.jwtPayload.perfil == "Gestor de Proponente") {
  //         this.perfis = [];
  //         perfisArray.forEach(element => {
  //           if (
  //             element.label === "Executor Proponente" ||
  //             element.label === "Contador Proponente"
  //           ) {
  //             this.perfis.push(element);
  //           }
  //         });
  //       }

  //       if (this.auth.jwtPayload.perfil == "Gestor Controle Interno") {
  //         this.perfis = [];
  //         perfisArray.forEach(element => {
  //           if (
  //             element.label === "Gestor Controle Interno" ||
  //             element.label === "Assessor Controle Interno"
  //           ) {
  //             this.perfis.push(element);
  //           }
  //         });
  //       }


  //     })
  //     .catch(erro => this.errorHandler.handle(erro));
  // }

  getEnderecoCep(event) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return;
          }
          this.usuario.endereco.cep = data.cep;
          this.usuario.endereco.endereco = data.logradouro;
          this.usuario.endereco.complemento = data.complemento;
          this.usuario.endereco.bairro = data.bairro;
          this.usuario.endereco.cidade = data.localidade;
          this.usuario.endereco.estado = data.uf;
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }

  // ========== VALIDACAO CPF E EMAIL

  verificarCpf() {

    if (this.pessoaCpf.vercpf(this.usuario.cpf) != false) {

      if (this.usuario.cpf != null && this.usuario.cpf.length > 10) {
        // //console.log(this.usuario.cpf.length)
        if (this.cpfTemp != this.usuario.cpf) {

          this.filtroUsuario.cpf = this.usuario.cpf

          this.usuarioService.pesquisar(this.filtroUsuario).then(resultado => {

            if (resultado.totalElements == 1) {

              this.toasty.error("CPF já cadastrado!")

              this.usuario.cpf = null
            }

          });
        }

      } else {
        this.toasty.warning("Por Favor preencha o CPF !")
      }

    } else {
      this.toasty.info("CPF INVÁLIDO")
      this.usuario.cpf = null
    }

  }

  verificarEmail() {
    this.usuario.email = this.usuario.email.toLowerCase();

    if (this.usuario.email != null && this.usuario.email.length > 8) {

      if (this.emailTemp != this.usuario.email) {

        this.filtroUsuario.email = this.usuario.email

        this.usuarioService.pesquisar(this.filtroUsuario).then(resultado => {

          if (resultado.totalElements != 0) {

            this.toasty.error("E-mail já cadastrado!")

            this.usuario.email = null
          }

        });

      }
    } else {

      this.toasty.warning("Por favor preencha o email !")
    }

  }



  camposFuncaoEMatricula: boolean = false
  verificaCamposFuncaoEMatricula(perfil) {

    let perfilNome
    this.perfis.forEach(element => {
      if (element.value == perfil)
        perfilNome = element.label
    });

    let permitidos = [
      { perfil: 'Administrador' },
      { perfil: 'Gestor de Concedente' },
      { perfil: 'Analista Técnico' },
      { perfil: 'Auditor' },
      { perfil: 'Procurador' },
      { perfil: 'Auditor TCE' },
      { perfil: 'Executor Concedente' },
      { perfil: 'Membro de Comissão' },
      { perfil: 'Gestor Controle Interno' },
      { perfil: 'Assessor Controle Interno' },
    ]

    // //console.log(perfilNome, perfil)

    permitidos.forEach(element => {
      if (element.perfil === perfilNome) {
        // //console.log("Encontrou o perfil: ",perfil)
        this.camposFuncaoEMatricula = true
      } else {
        this.camposFuncaoEMatricula = false
      }
    });
  }

}
