import { URLSearchParams } from '@angular/http';
import { EventEmitter, Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { Celebracao, CancelaCelebracao, AprovacaoGestor, CelebracaoAddDTO } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class CelebracaoFiltro {
  et1Titulo;
  nomeConcedente;
  nomeProponente;

	et1TipoDemanda;
	et1EntidadeConcedente
	et1DispensaInexigibilidade;
	et1EmendaParlamentar;
  et1ChamamentoPublico;
  
  status;

  sig3Proponente
  numeroTermo

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class CelebracaoService {

  static moduloCarregado = new EventEmitter();
  static moduloCarregadoExecucao = new EventEmitter();
  static moduloCarregadoPrestacao = new EventEmitter();

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/celebracao`;
  }

  moduloCarregado(){
    CelebracaoService.moduloCarregado.emit()
  }
  moduloCarregadoExecucao(){
    CelebracaoService.moduloCarregadoExecucao.emit()
  }
  moduloCarregadoPrestacao(){
    CelebracaoService.moduloCarregadoPrestacao.emit()
  }

  pesquisar(filtro: CelebracaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.et1Titulo) {
      params.set('et1Titulo', filtro.et1Titulo);
    }
    if (filtro.nomeConcedente) {
      params.set('nomeConcedente', filtro.nomeConcedente);
    }
    if (filtro.nomeProponente) {
      params.set('nomeProponente', filtro.nomeProponente);
    }
    if (filtro.et1TipoDemanda) {
      params.set('et1TipoDemanda', filtro.et1TipoDemanda);
    }
    if (filtro.et1EntidadeConcedente) {
      params.set('et1EntidadeConcedente', filtro.et1EntidadeConcedente);
    }
    if (filtro.et1DispensaInexigibilidade) {
      params.set('et1DispensaInexigibilidade', filtro.et1DispensaInexigibilidade);
    }
    if (filtro.et1EmendaParlamentar) {
      params.set('et1EmendaParlamentar', filtro.et1EmendaParlamentar);
    }
    if (filtro.et1ChamamentoPublico) {
      params.set('et1ChamamentoPublico', filtro.et1ChamamentoPublico);
    }
    
    if (filtro.status) {
      params.set('status', filtro.status);
    }

    if (filtro.sig3Proponente) {
      params.set('sig3Proponente', filtro.sig3Proponente);
    }

    if (filtro.numeroTermo) {
      params.set('numeroTermo', filtro.numeroTermo);
    }
    
    
    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
       /* const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };*/
        return responseJson;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }


  countProjetos(): Promise<any> {
    return this.http
      .get(`${this.url}/count`)
      .toPromise()
      .then(response => response.json());
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Celebracao): Promise<Celebracao> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  adicionarSig3(entidade: any): Promise<Celebracao> {
    return this.http.post(`${this.url}/sig3`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  adicionarTransfEspecial(entidade: any): Promise<CelebracaoAddDTO> {
    return this.http.post(`${this.url}/transf-especiais`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: Celebracao): Promise<Celebracao> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Celebracao);
  }

  buscarPorCodigo(codigo: number): Promise<Celebracao> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as Celebracao;
        return entidade;
      });
  }

  cancelaCelebracao(ccm: CancelaCelebracao){
    return this.http.put(`${this.url}/cancela-celebracao`, ccm)
      .toPromise()
      .then(() => null);
  }

  aprovacaoGestor(ag: AprovacaoGestor){
    return this.http.put(`${this.url}/aprovacao-gestor`, ag)
      .toPromise()
      .then(() => null);
  }

}
