import { ActivatedRoute, Router } from '@angular/router';
import { Page } from './../../core/pagination/pagination.model';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConcedenteFiltro, ConcedenteService } from '../../core/services/concedente/concedente.service';
import { Usuario } from "../../core/model";
import { AuthService } from "../../seguranca/auth.service";
import { ConfirmationService, LazyLoadEvent } from 'primeng/primeng';


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-concedente-listar',
  templateUrl: './concedente-listar.component.html'
})
export class ConcedenteListarComponent implements OnInit {

  opcoesStatusEdital = [
    { label: "Selecione", velue: null },
    { label: "Recebendo manifestação de interesse", value: "0" },
    { label: "Aguardando autenticação de documentos", value: "1" },
    { label: "Encerrado", value: "2" },
    { label: "Cancelado", value: "3" }
  ];

  totalRegistros = 0;
  filtro = new ConcedenteFiltro();
  // @ViewChild("tabela") grid;
  usuario = new Usuario();
  loading: boolean;
  
  entidades = [];
  param

  constructor(
    private concedenteService: ConcedenteService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Gerenciar entidade concedente");
    this.usuario.perfil = this.auth.jwtPayload.perfil;

    //Pegar na rota o tipo de entidadeproponente
    this.route.params.subscribe(
      (params: any) => {
        this.param = params["tipo"]
        this.pesquisar()
      }
    );
 
    this.pesquisar();

    sessionStorage.removeItem('viewEntidade')
    sessionStorage.removeItem('editEntidade')
  }

  // ===  CONFIGURACOES DE PAGINACAO
  page : Page;
  changePage(event){
    this.filtro.itensPorPagina = event.size
    this.filtro.pagina = event.page
    this.concedenteService.pesquisar(this.filtro).then(res => {
      this.page = res
      this.entidades = res.content;
    });
  }

  pesquisar(pagina = 0) {
    this.filtro.pagina = pagina;

    if(this.param == "ua") this.filtro.tipoConcedente = "0"
    if(this.param == "concedente") this.filtro.tipoConcedente = "1"

    this.concedenteService.pesquisar(this.filtro).then(resultado => {
      this.loading=true
      this.entidades = resultado.content;
      this.page = resultado;
    });
  }

  aoMudarPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.pesquisar(pagina);
  }

  confirmarExclusao(entidade: any) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluir(entidade);
      }
    });
  }

  excluir(entidade: any) {
    this.concedenteService
      .excluir(entidade.id)
      .then(() => {
        this.pesquisar();
        // if (this.grid.first === 0) {
        // } else {
        //   this.grid.first = 0;
        // }
        this.toasty.success("Entidade excluída com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  gotoVisualizarEntidade(param) {
    this.router.navigate(["/cadastros/concedente/visualizar"]);
    sessionStorage.setItem('viewEntidade', param)
  }

  gotoEditEntidade(param) {
    this.router.navigate(["/cadastros/concedente/concedente-editar"]);
    sessionStorage.setItem('editEntidade', param)
  }

}