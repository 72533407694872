import { environment } from './../../../../environments/environment';
import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";

@Injectable()
export class SendMail {
  constructor(private http: Http) {}

  sendMailAgengamento(data): Promise<any> {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(`${environment.agendamento}`, JSON.stringify(data), { headers })
      .toPromise()
      .then(response => {
        return response;
        // return response.json();
      });

  }
}
