import { Injectable } from "@angular/core";
import { URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";
import "rxjs/add/operator/toPromise";
import { environment } from "../../../../environments/environment";
import { EntidadeConcedenteHasUsuario } from "../../../core/model";
import { AuthService } from "../../../seguranca/auth.service";

export class EntidadeConcedenteHasUsuarioFiltro {
  entidadeConcedente: string;
  usuario: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class EntidadeConcedenteHasUsuarioService {
  entidadeConcedenteHasUsuarioUrl: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.entidadeConcedenteHasUsuarioUrl = `${
      environment.apiUrl
    }/entidade-concedente-has-usuario`;
  }

  pesquisar(filtro: EntidadeConcedenteHasUsuarioFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.entidadeConcedente) {
      params.set("entidadeConcedente", filtro.entidadeConcedente);
    }
    if (filtro.usuario) {
      params.set("usuario", filtro.usuario);
    }

    return this.http
      .get(`${this.entidadeConcedenteHasUsuarioUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const entidadeConcedenteHasUsuarios = responseJson.content;

        const resultado = {
          entidadeConcedenteHasUsuarios,
          total: responseJson.totalElements
        };
        // //console.log(resultado);
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();

    return this.http
      .get(`${this.entidadeConcedenteHasUsuarioUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.entidadeConcedenteHasUsuarioUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(
    entidadeConcedenteHasUsuario: EntidadeConcedenteHasUsuario
  ): Promise<EntidadeConcedenteHasUsuario> {
    return this.http
      .post(
        this.entidadeConcedenteHasUsuarioUrl,
        JSON.stringify(entidadeConcedenteHasUsuario)
      )
      .toPromise()
      .then(response => response.json());
  }

  atualizar(
    entidadeConcedenteHasUsuario: EntidadeConcedenteHasUsuario
  ): Promise<EntidadeConcedenteHasUsuario> {
    return this.http
      .put(
        `${this.entidadeConcedenteHasUsuarioUrl}/${
          entidadeConcedenteHasUsuario.id
        }`,
        JSON.stringify(entidadeConcedenteHasUsuario)
      )
      .toPromise()
      .then(response => response.json() as EntidadeConcedenteHasUsuario);
  }

  buscarPorCodigo(codigo: number): Promise<EntidadeConcedenteHasUsuario> {
    return this.http
      .get(`${this.entidadeConcedenteHasUsuarioUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidadeConcedenteHasUsuario = response.json() as EntidadeConcedenteHasUsuario;

        return entidadeConcedenteHasUsuario;
      });
  }
}
