import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Entidade } from '../../core/model';
import { UsuarioService } from '../../usuario/usuario.service';
import { ConcedenteService } from '../../core/services/concedente/concedente.service';
import { AuthService } from './../../seguranca/auth.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-concedente-view',
  templateUrl: './concedente-view.component.html',
})
export class ConcedenteViewComponent implements OnInit, OnDestroy {

  usuarioVinculados = []
  entidade = new Entidade();
  codigoEntidade;

  constructor(
    private usuarioService: UsuarioService,
    private concedenteService: ConcedenteService,
    private errorHandler: ErrorHandlerService,
    private title: Title,
    public auth: AuthService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Informações Concedente');

    if (this.auth.jwtPayload.concedente) this.codigoEntidade = this.auth.jwtPayload.concedente
    if (sessionStorage.getItem('viewEntidade')) this.codigoEntidade = sessionStorage.getItem('viewEntidade')

    if (this.codigoEntidade) {
      this.carregarEntidade(this.codigoEntidade);
      this.carregarPessoasConcedente(this.codigoEntidade);
    } else {
      this.codigoEntidade = false
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem("viewEntidade");
  }

  carregarEntidade(codigo: number) {
    this.concedenteService
      .buscarPorCodigo(codigo)
      .then(entidade => {
        this.entidade = entidade;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarPessoasConcedente(concedente) {
    return this.usuarioService
      .listarTodosConcedente(concedente)
      .then(retorno => {
        this.usuarioVinculados = retorno
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

}