import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { AnaliseRepasse } from '../../model';

export class AnaliseRepasseFiltro {
  repasseId
  nomeAnaliseRepasse
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class AnaliseRepasseService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/analise-repasse`;
  }

  pesquisar(filtro: AnaliseRepasseFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.repasseId) {
      params.set('repasseId', filtro.repasseId);
    }
    if (filtro.nomeAnaliseRepasse) {
      params.set('nomeAnaliseRepasse', filtro.nomeAnaliseRepasse);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        return responseJson;
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: AnaliseRepasse): Promise<AnaliseRepasse> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: AnaliseRepasse): Promise<AnaliseRepasse> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AnaliseRepasse);
  }

  buscarPorCodigo(codigo: number): Promise<AnaliseRepasse> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as AnaliseRepasse;
        return entidade;
      });
  }

}
