import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedsModule } from "../shareds/shareds.module";
import { MapaComponent } from './mapa.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    SharedsModule,

    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBfshNztujAJ-ew1cur20eevGpBDOq-BCQ',
    //   libraries: ["places"]
    // })
  ],
  declarations: [MapaComponent]
})
export class MapaModule { }
