import { OscService } from '../../core/services/proponente/osc.service';
import { ProponenteFiltro } from './../../core/services/proponente/proponente.service';
import { ConcedenteService } from '../../core/services/concedente/concedente.service';
import { TipoAvaliacaoPlanoService, TipoAvaliacaoPlanoFiltro } from '../../core/services/avaliacao-plano/tipoAvaliacaoPlano.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AuthService } from '../../seguranca/auth.service';
import { ProponenteService } from '../../core/services/proponente/proponente.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-pendencia-cadastrar',
  templateUrl: './pendencia-cadastrar.component.html'
})
export class PendenciaCadastrarComponent implements OnInit {
  listaPendencia = [
     'Convênio', 
     'Parceria'
  ]
  situacaoPendencia = [
    {label: 'Inadimplente', value: 0},
    {label: 'Inadimplência Suspensa', value: 1},
    {label: 'Inadimplência Ativa', value: 2},
  ]
  modalPendencia: boolean

  listaConcedente
  listaOsc
  listaPrefeitura
  filtroPropoenente = new ProponenteFiltro();
  constructor(
    private title: Title,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    public auth: AuthService,
    private concedenteSevice: ConcedenteService,
    private proponenteService: ProponenteService,
    
    
    
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Cadastro de Pendência');
    this.listarConcedente()
    this.listarOsc()
    this.listarPrefeitura()
   
  }
//+++++++++++++++LISTAGEM
  listarConcedente(){
    this.concedenteSevice.listarTodos().then(lista=>{
      this.listaConcedente = lista
      
    })
  }

  listarOsc(){
    this.filtroPropoenente.tipoProponente = "0"
    this.proponenteService.pesquisar(this.filtroPropoenente).then(lista=>{
      this.listaOsc = lista.content
      
    })
   
  }

  listarPrefeitura(){
    this.filtroPropoenente.tipoProponente = "3"
    this.proponenteService.pesquisar(this.filtroPropoenente).then(lista=>{
      this.listaPrefeitura = lista.content
      
    })
  }
//+++++++++++++++.LISTAGEM

  openModalPendencia(){
    this.modalPendencia=true
  }

  
}
