import { CronogramaFinanceiroFiltro, CronogramaFinanceiroService } from '../cronograma-financeiro.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';

import { LazyLoadEvent, ConfirmationService } from "primeng/components/common/api";
import { ToastyService } from 'ng2-toasty';

import { ErrorHandlerService } from '../../core/error-handler.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-cronograma-financeiro-pesquisa',
  templateUrl: './cronograma-financeiro-pesquisa.component.html'
})
export class CronogramaFinanceiroPesquisaComponent implements OnInit {

  totalRegistros = 0;
  filtro = new CronogramaFinanceiroFiltro();
  cronogramas = [];
  @ViewChild('tabela') grid;

  constructor(
    private cronogramaFinanceiroService: CronogramaFinanceiroService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title
  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Gerenciar cronogramas');
  }

  pesquisar(pagina = 0) {
    this.filtro.pagina = pagina;

    this.cronogramaFinanceiroService.pesquisar(this.filtro)
      .then(resultado => {
        this.totalRegistros = resultado.total;
        this.cronogramas = resultado.dados;
      });
  }

  aoMudarPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.pesquisar(pagina);
  }

  confirmarExclusao(entidade: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(entidade);
      }
    });
  }

  excluir(entidade: any) {
    this.cronogramaFinanceiroService.excluir(entidade.id)
      .then(() => {
        if (this.grid.first === 0) {
          this.pesquisar();
        } else {
          this.grid.first = 0;
        }

        this.toasty.success('Cronograma excluído com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

}