import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { DispensaInexigibilidade } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

import * as moment from 'moment';

export class DispensaInexigibilidadeFiltro {
  titulo
  entidadeConcedente
  tipoProposta
  statusAvaliacao
  statusProposta
  origemProposta
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class DispensaInexigibilidadeService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/dispensa-inexigibilidade`;
  }

  pesquisar(filtro: DispensaInexigibilidadeFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }
    if (filtro.entidadeConcedente) {
      params.set('entidadeConcedente', filtro.entidadeConcedente);
    }
    if (filtro.tipoProposta) {
      params.set('tipoProposta', filtro.tipoProposta);
    }
    if (filtro.statusAvaliacao) {
      params.set('statusAvaliacao', filtro.statusAvaliacao);
    }
    if (filtro.statusProposta) {
      params.set('statusProposta', filtro.statusProposta);
    }
    if (filtro.origemProposta) {
      params.set('origemProposta', filtro.origemProposta);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
       /* const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };*/
        return responseJson;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: DispensaInexigibilidade): Promise<DispensaInexigibilidade> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: DispensaInexigibilidade): Promise<DispensaInexigibilidade> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as DispensaInexigibilidade);
  }

  buscarPorCodigo(codigo: number): Promise<DispensaInexigibilidade> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as DispensaInexigibilidade;
        
        this.converterStringsParaDatas([entidade]);

        return entidade;
      });
  }

  alterarStatus(codigo: number, status): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/altera-status`, status)
      .toPromise()
      .then(() => null);
  }

  avaliarProposta(codigo: number, avaliacao): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/avalia-proposta`, avaliacao)
      .toPromise()
      .then(() => null);
  }

  retornaTipoProposta(tipo){
    if(tipo == 'Parceria'){
      return 0
    }else if(tipo == 'Convenio'){
      return 1
    }else if(tipo == 'Contrato_gestao'){
      return 2
    }else if(tipo == 'Patrocinio'){
      return 3
    } 
  }

  private converterStringsParaDatas(objetos: DispensaInexigibilidade[]) {
    for(const dispensaInexigibilidade of objetos){
      if (dispensaInexigibilidade.dataPublicacao) {
        dispensaInexigibilidade.dataPublicacao = moment(dispensaInexigibilidade.dataPublicacao,
          'YYYY-MM-DD').toDate();
      }
    }

  }

}
