import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from 'primeng/components/common/api';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { Page } from '../../../core/pagination/pagination.model';
import { AuthService } from '../../../seguranca/auth.service';
import { ComissaoAcompanhamentoFiltro, ComissaoAcompanhamentoService } from '../comissaoAcompanhamento.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-comissao-acompanhamento-pesquisa',
  templateUrl: './comissao-acompanhamento-pesquisa.component.html',
})
export class ComissaoAcompanhamentoPesquisaComponent implements OnInit {
  totalRegistros = 0;
  filtro = new ComissaoAcompanhamentoFiltro();
  comissoes = [];
  loading: boolean;

  constructor(
    private comissaoAcompanhamentoService: ComissaoAcompanhamentoService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    AdminLTE.init();

    this.title.setTitle('Gerenciar Comissões');
    this.pesquisar();
  }
  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.filtro.itensPorPagina = event.size
    this.filtro.pagina = event.page
    this.comissaoAcompanhamentoService.pesquisar(this.filtro).then(res => {
      this.page = res
      this.comissoes = res.content;
    });
  }

  pesquisar(pagina = 0) {
    this.filtro.pagina = pagina;
    this.filtro.entidadeConcedente = this.auth.jwtPayload.concedente;

    this.comissaoAcompanhamentoService
      .pesquisar(this.filtro)
      .then(resultado => {
        this.loading = true
        this.comissoes = resultado.content;
        this.page = resultado;
      });
  }

  confirmarExclusao(comissao: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(comissao);
      },
    });
  }

  excluir(comissao: any) {
    this.comissaoAcompanhamentoService
      .excluir(comissao.id)
      .then(() => {
        this.pesquisar();

        this.toasty.success('Comissão de acompanhamento excluída com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  comissaoSelecionada(comissao){
    sessionStorage.setItem('comissaoAcompCompPesquisa', JSON.stringify(comissao))
  }
}
