import { environment } from './../../../environments/environment';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ToastyService } from 'ng2-toasty';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

@Component({
  selector: 'app-upload-service',
  templateUrl: './upload-service.component.html',
  styleUrls: ['./upload-service.component.css']
})
export class UploadServiceComponent implements OnInit {

  static setaUpload = new EventEmitter();
  static uploadRealizado = new EventEmitter();

  options: UploaderOptions;
  formData: FormData;

  uploadView
  files: UploadFile[];

  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  constructor(private toasty: ToastyService) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    sessionStorage.removeItem('upload');
  }

  elementUpload
  changeFile(event) {
    this.elementUpload = event
  }

  onUploadOutput(output: UploadOutput): void {

    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' }
      // };
      // this.uploadInput.emit(event);
    }

    else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added

      if(output.file.name.slice(-4) != ".pdf" && output.file.name.slice(-4) != ".mp4"){
        return this.toasty.error("Só é possivel enviar arquivos do tipo PDF");
      }

      if (this.files.length == 0) {
        this.files.push(output.file);
        sessionStorage.setItem('upload', JSON.stringify(this.files[0]));
      } else {
        this.toasty.error("Só é possivel enviar um arquivo");
      }

    }

    else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    }

    else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
      this.elementUpload.target.value = ''
    }

    else if (output.type === 'dragOver') {
      this.dragOver = true;
    }

    else if (output.type === 'dragOut') {
      this.dragOver = false;
    }

    else if (output.type === 'drop') {
      this.dragOver = false;
    }

    else if (output.type === 'done') {
      sessionStorage.setItem('upload', JSON.stringify(this.files[0]));
      this.elementUpload.target.value = ''
      this.files = []
      this.toasty.success("Upload realizado!");
      UploadServiceComponent.uploadRealizado.emit()

      this.uploadView = true
      setTimeout(() => {
        this.uploadView = false
      }, 5000);
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      // url: 'http://ngx-uploader.com/upload',
      url: environment.uploadFileUrl,
      method: 'POST',
      data: { foo: 'bar' }
    };

    this.uploadInput.emit(event);

    UploadServiceComponent.setaUpload.emit()
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.elementUpload.target.value = ''
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.files = [];
    this.elementUpload.target.value = ''
    this.uploadInput.emit({ type: 'removeAll' });
  }
}
