import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Entidade, EntidadeConcedenteHasUsuario } from '../../core/model';
import { UsuarioService } from '../../usuario/usuario.service';
import { ConcedenteService } from '../../core/services/concedente/concedente.service';
import { PessaoUtil } from '../../core/pessoa.util';
import { EntidadeConcedenteHasUsuarioService } from '../../core/services/entidade-concedente-has-usuario/entidadeConcedenteHasUsuario.service';
import { GetAdress } from '../../core/services/utils/get-address.service';
import { ConcedenteFiltro } from '../../core/services/concedente/concedente.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-concedente-cadastro',
  templateUrl: './concedente-cadastro.component.html',
})
export class ConcedenteCadastroComponent implements OnInit {
  chefes = [];
  entidade = new Entidade();
  entidadeConcedenteHasUsuario = new EntidadeConcedenteHasUsuario();
  pessoaUtil = new PessaoUtil();

  concedenteFiltro = new ConcedenteFiltro();

  tipos = [{ label: 'Sim', value: 1 }, { label: 'Não', value: 0 }];


  tipoEntidade = [
    { label: 'Unidade Administrtiva', value: 'UA' },
    { label: 'Concedente', value: 'Concedente' }
  ];



  constructor(
    private entidadeConcedenteHasUsuarioService: EntidadeConcedenteHasUsuarioService,
    private usuarioService: UsuarioService,
    private concedenteService: ConcedenteService,
    private getAdress: GetAdress,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private location: Location
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Entidade Concedente');

    this.carregarChefesConcedente();

    const codigoEntidade = sessionStorage.getItem('editEntidade');
    if (codigoEntidade) {
      this.carregarEntidade(codigoEntidade);
    }
  }


  goBack() {
    this.location.back();
  }
  // =====  VIEWS STEPS
  etapa1;
  etapa2;
  etapa3;
  etapa4;
  etapa5;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
    this.etapa5 = false;
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
    this.etapa5 = false;
  }
  openEtapa5() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = true;
  }

  get editando() {
    return Boolean(this.entidade.id);
  }

  carregarEntidade(codigo) {
    this.concedenteService
      .buscarPorCodigo(codigo)
      .then(entidade => {
        this.entidade = entidade;
        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  salvar() {
    if (this.editando) {
      this.atualizarEntidade();
    } else {
      this.adicionarEntidade();
    }
  }

  adicionarEntidade() {
    this.concedenteService
      .adicionar(this.entidade)
      .then(entidadeAdicionada => {
        this.toasty.success('Entidade concedente adicionada com sucesso!');

        // vincula o chefe a entidade concedente
        this.entidadeConcedenteHasUsuario.entidadeConcedente.id =
          entidadeAdicionada.id;
        this.entidadeConcedenteHasUsuario.usuario.id =
          entidadeAdicionada.chefe.id;
        this.entidadeConcedenteHasUsuarioService.adicionar(
          this.entidadeConcedenteHasUsuario,
        );

        this.router.navigate(["/cadastros/concedente/visualizar"]);
        sessionStorage.setItem('viewEntidade', String(entidadeAdicionada.id))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarEntidade() {
    this.concedenteService
      .atualizar(this.entidade)
      .then(entidade => {
        this.entidade = entidade;
        this.toasty.success('Entidade concedente alterada com sucesso!');
        this.router.navigate(["/cadastros/concedente/visualizar"]);
        sessionStorage.setItem('viewEntidade', String(entidade.id))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarTituloEdicao() {
    this.title.setTitle(`Edição da entidade: ${this.entidade.nome}`);
  }

  carregarChefesConcedente() {
    return this.usuarioService
      .listarChefeConcedente()
      .then(chefes => {
        this.chefes = chefes.map(c => ({ label: c.nome, value: c.id }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  getEnderecoCep(event) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return;
          }
          this.entidade.endereco.cep = data.cep;
          this.entidade.endereco.endereco = data.logradouro;
          this.entidade.endereco.complemento = data.complemento;
          this.entidade.endereco.bairro = data.bairro;
          this.entidade.endereco.cidade = data.localidade;
          this.entidade.endereco.estado = data.uf;
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }


  imageChangedEvent: any = '';
  croppedImage: any = '';
  choosedImg
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.choosedImg = true;
    this.entidade.logotipo = event.base64
    // //console.log(event);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


  verificarCnpj() {

    if (this.entidade.cnpj != null && this.entidade.cnpj.length > 10) {

      if (this.pessoaUtil.validarCNPJ(this.entidade.cnpj) != false) {


          this.concedenteFiltro.cnpj = this.entidade.cnpj

          this.concedenteService.pesquisar(this.concedenteFiltro).then(resultado => {

            if (resultado.totalElements != 0) {
              this.toasty.error("CNPJ já cadastrado!")
              this.entidade.cnpj = null
            }
          });

      } else {
        this.entidade.cnpj = null
        this.errorHandler.handle("CNPJ inválido!");
      }
    } else {
      this.toasty.warning("Por favor preencha o CNPJ !")
    }
  }



}
