import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { AlterCronogramaFisico } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class AlterCronogramaFisicoFiltro {
  usuarioAvaliou;
	usuarioSubmeteu;
	versaoPlano;
	cronogramaFisicoExecucaoObjeto;
	pthpId;
	tipoAlteracao;
	statusAlteracao;

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class AlterCronogramaFisicoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/alter-cronograma-fisico`;
  }

  pesquisar(filtro: AlterCronogramaFisicoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.usuarioAvaliou) {
      params.set('usuarioAvaliou', filtro.usuarioAvaliou);
    }
    if (filtro.usuarioSubmeteu) {
      params.set('usuarioSubmeteu', filtro.usuarioSubmeteu);
    }
    if (filtro.versaoPlano) {
      params.set('versaoPlano', filtro.versaoPlano);
    }
    if (filtro.cronogramaFisicoExecucaoObjeto) {
      params.set('cronogramaFisicoExecucaoObjeto', filtro.cronogramaFisicoExecucaoObjeto);
    }
    if (filtro.tipoAlteracao) {
      params.set('tipoAlteracao', filtro.tipoAlteracao);
    }
    if (filtro.statusAlteracao) {
      params.set('statusAlteracao', filtro.statusAlteracao);
    }
    if (filtro.pthpId) {
      params.set('pthpId', filtro.pthpId);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: AlterCronogramaFisico): Promise<AlterCronogramaFisico> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  addCronogramaAddAlter(entidade: AlterCronogramaFisico): Promise<AlterCronogramaFisico> {
    return this.http.post(`${this.url}/add-cronograma-add-alter`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: AlterCronogramaFisico): Promise<AlterCronogramaFisico> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AlterCronogramaFisico);
  }

  avaliar(entidade: AlterCronogramaFisico): Promise<AlterCronogramaFisico> {

    return this.http.put(`${this.url}/avaliar`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AlterCronogramaFisico);
  }

  alterarAvaliacao(entidade: AlterCronogramaFisico): Promise<AlterCronogramaFisico> {

    return this.http.put(`${this.url}/alterar-avaliacao`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AlterCronogramaFisico);
  }

  atualizaAlterAtualCrono(entidade: AlterCronogramaFisico): Promise<AlterCronogramaFisico> {

    return this.http.put(`${this.url}/atualiza-cronograma-atualiza-alteracao/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AlterCronogramaFisico);
  }

  buscarPorCodigo(codigo: number): Promise<AlterCronogramaFisico> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as AlterCronogramaFisico;
        return entidade;
      });
  }

}
