import { Pipe, PipeTransform, ElementRef } from "@angular/core";

@Pipe({
  name: "resizeTextArea"
})
export class ResizeTextAreaPipe implements PipeTransform {

  transform(value:HTMLInputElement): any {
    
    // //console.log(value);

    // var teste: HTMLInputElement = value

    // //console.log(teste)

    // let tpl = document.querySelector('#viewTodoTop');
    // //console.log(tpl)

    // setTimeout(
    //   () => document.getElementById(value).scrollIntoView({behavior: "smooth"}) ,10
    // );

    return null;
  }



  resizeTextarea() {
    var textarea = document.querySelector('textarea');

    textarea.addEventListener('DOMContentLoaded', autosize);

    function autosize() {
      var el = this;
      setTimeout(function () {
        el.style.cssText = 'height:auto; padding:0';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      }, 0);
    }
  }
}
