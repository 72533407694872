import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inpugnar',
  templateUrl: './inpugnar.component.html',
})
export class InpugnarComponent implements OnInit {


  observacao = `
          <p style="text-align: center;"><b>MODELO&nbsp;</b></p><p style="text-align: center;"><br></p><p style="text-align: justify;"><b>INFORMAÇÕES DO RECURSO
          </b></p><p style="text-align: justify;"><br></p><p style="text-align: justify;"><b>Introdução</b> (no primeiro parágrafo, o representante da entidade deve fazer
          referência ao conteúdo da notificação em que foi comunicado do resultado do
          julgamento.)
          </p><p style="text-align: justify;"><br></p><p style="text-align: justify;"><b>Relatório</b> (nos parágrafos seguintes, o representante deve apresentar as razões
          que justifiquem a não aceitação da penalidade imposta, apresentando fatos novos
          que possam reverter a decisão imposta pela comissão de seleção.
          </p><p style="text-align: justify;"><br></p><p style="text-align: justify;"><b>Conclusão</b> (no último parágrafo, depois de apresentar o recurso, o representante
          conclui seu recurso pedindo o quê acha que tem direito, como mudança na
          avaliação, impugnação do processo, etc.
          </p><p style="text-align: justify;"><br></p><p style="text-align: justify;"><b>DADOS FINAIS
          </b></p><p style="text-align: justify;"><b>Data</b> (colocar localidade e data)
          </p><p style="text-align: justify;"><b>Assinatura</b> (Nome do autuado com seu nº de CRC ou preposto e assinatura)
          </p><p style="text-align: justify;"><br></p><p style="text-align: justify;"><br></p><p style="text-align: justify;"><b>OBSERVAÇÕES:
          </b></p><p style="text-align: justify;"><ul><li>O profissional sempre que tiver dificuldades em se defender de notificação
          ou processo tem o direito de solicitar ajuda junto ao órgão concedente.</li><li>Defesa é o procedimento no qual o autuado ou preposto apresenta as
          alegações que tiver a seu favor, juntando cópia de documentos e indicando
          outros elementos de prova pertinentes.&nbsp;</li><li>Recurso é de iniciativa exclusiva do autuado ou do seu preposto, no qual o
          recorrente deve expor os fundamentos do pedido de reexame, solicitando a
          reconsideração da decisão de primeira instância.<br></li></ul></p>
  `;

  constructor() { }
  
  ngOnInit() {
  }

}
