import { AnexoPropostaFiltro } from './../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { AnexoProposta, DispensaInexigibilidade, PlanoTrabalho } from '../../../core/model';
import { ConcedenteService } from '../../../core/services/concedente/concedente.service';
import { AnexoPropostaService } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { DispensaInexigibilidadeService } from "../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service";
import { ProponenteFiltro, ProponenteService } from '../../../core/services/proponente/proponente.service';
import { AuthService } from "../../../seguranca/auth.service";
import { ConcedenteFiltro } from './../../../core/services/concedente/concedente.service';

@Component({
  selector: "app-parcerias-editar",
  templateUrl: "./parcerias-editar.component.html"
})
export class ParceriasEditarComponent implements OnInit {

  entidadesConcedente: any;
  dispensaInexigibilidade = new DispensaInexigibilidade();
  planoTrabalho = new PlanoTrabalho();
  planosDaDispensa = []
  proponenteFiltro = new ProponenteFiltro()
  listaOscs
  anexoProposta = new AnexoProposta()

  etapa1: boolean;
  etapa2: boolean;
  etapa3: boolean;
  etapa4: boolean;
  etapa5: boolean;

  tipoUpload
  dataUploadArray = []
  anexosSalvos = []

  displayEscondeInput
  openModalViewUpload

  displayAnexo: boolean;
  urlDocumento
  viewDownload
  nomeAnexo
  nomeConcedente: any;

  anexoFiltro = new AnexoPropostaFiltro()

  constructor(
    private entidadeProponenteService: ProponenteService,
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private dispensaInexigibilidadeService: DispensaInexigibilidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private anexoPropostaService: AnexoPropostaService,
  ) { }

  ngOnInit() {
    this.carregarEntidadesConcedente();
    this.dispensaInexigibilidade.tipoProposta = "0"

    this.title.setTitle("Editar proposta");

    //Inicializando obj para richtexteditor
    this.dispensaInexigibilidade.descricao = ""

    const idProposta = sessionStorage.getItem("proposta");

    this.carregarProposta(idProposta)
    this.carregarAnexos(idProposta)
  }

  // CARREGAR PROPOSTA
  carregarProposta(idProposta) {
    this.dispensaInexigibilidadeService.buscarPorCodigo(idProposta).then(disp => {
      this.dispensaInexigibilidade = disp
      this.planoTrabalho = disp.planoTrabalho[0]
    }).catch(erro => this.errorHandler.handle(erro));
  }

  // CARREGAR ANEXOS
  carregarAnexos(idProposta) {
    this.anexoFiltro.dispensaInexigibilidade = idProposta
    this.anexoPropostaService.pesquisar(this.anexoFiltro).then(anexos => {
      this.anexosSalvos = anexos.content
    }).catch(erro => this.errorHandler.handle(erro));
  }

  // ==== SE ADMINISTRADOR ====//
  verificaSeAdm() {
    if (this.auth.jwtPayload.perfil == "Administrador") {
      this.carregarEntidadesConcedente();
    } else {
      this.dispensaInexigibilidade.entidadeConcedente.id = this.auth.jwtPayload.concedente
    }
  }

  carregarEntidadesConcedente() {
    let filtro = new ConcedenteFiltro()
    filtro.tipoConcedente = '1' //concedente
    this.concedenteService.pesquisar(filtro)
      .then(retorno => {
        this.entidadesConcedente = retorno.content.map(c => ({ label: c.nome, value: c.id }))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  entidadeSelecionada() {
    let concedente = this.entidadesConcedente.filter(x => {
      return x.value === this.dispensaInexigibilidade.entidadeConcedente.id
    });
    this.nomeConcedente = concedente[0].label;
  }


  oscSelecionada(idOsc) {
    this.buscarProponente(idOsc)
  }

  //====== RETORNA RESUMO ETAPA V
  retornaTipoProposta() {
    let nome
    if (this.dispensaInexigibilidade.tipoProposta == "0") {
      nome = "Parceria"
      return nome
    }
  }

  retornaNomeEntidade() {
    let nome
    this.listaOscs.forEach(entidade => {
      if (entidade.value == this.planoTrabalho.entidadeProponente.id) {
        nome = entidade.label
      }
    });
    return nome
  }


  // === ADICIONA PROPOSTA TIPO PARCERIA ===//
  salvar() {

    //PLANO DE TRABALHO 
    this.planoTrabalho.usuario.id = this.auth.jwtPayload.id
    this.planoTrabalho.chamamentoPublico = null
    this.planoTrabalho.dispensaInexigibilidade = null
    this.planoTrabalho.editalCredenciamento = null
    this.planoTrabalho.eixoPrograma = null
    this.planoTrabalho.emendaParlamentar = null
    this.planoTrabalho.celebracao = null
    this.planoTrabalho.editalCredenciamento = null
    this.planoTrabalho.eixoPrograma = null
    this.planoTrabalho.emendaParlamentar = null
    this.planoTrabalho.entidadeProponente.id = this.auth.jwtPayload.proponente

    this.planosDaDispensa[0] = this.planoTrabalho

    //INICIANDO VARIAVEIS DISPENSAINEGIBILIDADE
    this.dispensaInexigibilidade.usuario.id = this.auth.jwtPayload.id
    this.dispensaInexigibilidade.tipoProposta = "0" //Proposta por parceria
    this.dispensaInexigibilidade.dispensa = false
    this.dispensaInexigibilidade.inexigibilidade = false
    this.dispensaInexigibilidade.dataPublicacao = null
    this.dispensaInexigibilidade.dataAnaliseProp = null
    this.dispensaInexigibilidade.dataCadastroProp = null
    this.dispensaInexigibilidade.usuarioAvaliouProposta = null
    this.dispensaInexigibilidade.usuarioEnvioProposta = null
    this.dispensaInexigibilidade.statusAvaliacao = null
    this.dispensaInexigibilidade.statusProposta = "0"
    this.dispensaInexigibilidade.logAvaliacao = null
    this.dispensaInexigibilidade.origemProposta = null
    this.dispensaInexigibilidade.anexoJustificativa = null
    this.dispensaInexigibilidade.anexoJustificativaHashFile = null
    this.dispensaInexigibilidade.planoTrabalho = this.planosDaDispensa

    this.dispensaInexigibilidadeService.adicionar(this.dispensaInexigibilidade)
      .then(objAdd => {
        this.salvarPropostaAnexo(objAdd)
        this.toasty.success("Proposta adicionada com sucesso!");
        this.router.navigate(['proposta/parcerias/listar']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  buscarProponente(id) {
    this.entidadeProponenteService.buscarPorCodigo(id).then(proponent => {
      this.planoTrabalho.entidadeProponente = proponent

    })
  }

  //======== UPLOAD 
  openModalUpload(tipo) {
    this.openModalViewUpload = true
    this.tipoUpload = tipo
  }

  adicionarArquivo() {
    let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
    sessionStorage.removeItem('upload');
    if (!fileItem || !fileItem.responseStatus)
      return this.toasty.error("É necessário efetivar o upload");

    if (this.tipoUpload == "parceria") {
      this.dataUploadArray.push(fileItem.response.data);
    }

    this.openModalViewUpload = false
  }

  removeFileProposta(item, tipo) {
    if (tipo == "parceria") {
      var index = this.dataUploadArray.indexOf(item);
      if (index > -1) {
        this.dataUploadArray.splice(index, 1);
      }
    }
  }

  async salvarPropostaAnexo(proposta) {
    this.dataUploadArray.forEach(element => {
      this.anexoProposta.proposta = element.originalname;
      this.anexoProposta.hashFile = element.filename;
      this.anexoProposta.dispensaInexigibilidade.id = proposta.id
      this.anexoProposta.descricaoAnexo = "Parceria"

      this.anexoPropostaService
        .adicionar(this.anexoProposta)
        .then(() => {
          //this.toasty.success(element.originalname + " " + "cadastrado com sucesso!");
          this.dataUploadArray = [];
        })
        .catch(erro => this.errorHandler.handle(erro));
    });
  }

  // ===== VIEW ANEXO 
  openModalAnexo(anexo) {
    this.displayAnexo = true
    this.nomeAnexo = anexo.originalname
    this.urlDocumento = environment.downloadFileUrl + "/" + anexo.filename;
    anexo.filename.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }

  //==== ETAPAS =====//
  openEtapa1() {
    this.etapa1 = true
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
  }
  openEtapa2() {
    this.etapa1 = false
    this.etapa2 = true
    this.etapa3 = false
    this.etapa4 = false
  }
  openEtapa3() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = true
    this.etapa4 = false
  }
  openEtapa4() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = true
  }
  fecharTodasEtapas() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
  }

}
