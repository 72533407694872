import { Component, OnInit } from '@angular/core';

import { DescricaoRealidade, AlterDescricaoRealidade } from '../../../core/model';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AlterDescricaoRealidadeFiltro, AlterDescricaoRealidadeService } from '../../../core/services/alteracao-plano/alterDescricaoRealidade.service';
import { AuthService } from '../../../seguranca/auth.service';
import { PlanoTrabalhoHasProponenteService } from '../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';


declare let AdminLTE: any;

@Component({
  selector: 'app-avaliar-descricao-realidade',
  templateUrl: './avaliar-descricao-realidade.component.html',
})
export class AvaliarDescricaoRealidadeComponent implements OnInit {


  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  descricaoRealidade = new DescricaoRealidade();
  descricaoRealidadeAlterada = new DescricaoRealidade();
  atualizar: any;

  loading
  alterDescricaoRealidade = new AlterDescricaoRealidade();

  constructor(
    private pthpService: PlanoTrabalhoHasProponenteService,
    private auth: AuthService,
    private alterDescricaoRealidadeService: AlterDescricaoRealidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService
  ) { }

  async ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.descricaoRealidade = this.planoTrabalhoHasProponente.descricaoRealidade[0];
    await this.pthpBuscar()
    this.procuraAlteracaoDescricao()
    
  }

  pthpBuscar(){
    this.pthpService.buscarPorCodigo(this.planoTrabalhoHasProponente.id)
      .then(retorno => {
        this.planoTrabalhoHasProponente = retorno
        this.descricaoRealidade = this.planoTrabalhoHasProponente.descricaoRealidade[0];
        sessionStorage.setItem('pthpModuloAjuste', JSON.stringify(retorno))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // ==== PROCURAR DESCRICAO REALIDADE ==== //
  procuraAlteracaoDescricao() {
    let filtro = new AlterDescricaoRealidadeFiltro
    filtro.versaoPlano = this.versaoPlano.id

    this.alterDescricaoRealidadeService.pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado[0] != null) {
          this.alterDescricaoRealidade = retorno.dado[0]
          this.verificaAlteracao()
        } else {
          this.atualizar = 1
          this.loading = true
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaAlteracao() {
    if (this.alterDescricaoRealidade.statusAlteracao == 'Submetido') {
      this.descricaoRealidadeAlterada.descricaoRealidade = this.alterDescricaoRealidade.descricaoRealidadeDepois
      this.atualizar = 0
    }else{
      this.atualizar = 2
    }
    this.loading = true
  }

  //AVALIAR
  finalizarAvaliacao() {
    if(this.alterDescricaoRealidade.statusAlteracao != 'Submetido'){
      this.loading = false

    this.alterDescricaoRealidade.usuarioAvaliou = this.auth.jwtPayload.id
    this.alterDescricaoRealidade.dataAvaliacao = new Date().getTime()
    this.alterDescricaoRealidade.descricaoRealidadeAntes = this.descricaoRealidade.descricaoRealidade

    this.alterDescricaoRealidadeService.avaliar(this.alterDescricaoRealidade)
      .then(retorno => {
        this.pthpBuscar()
        this.alterDescricaoRealidade = retorno
        this.verificaAprovada()
        this.atualizar = 2
        this.toasty.success("Avaliação realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
    }else{
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
    }
    
  }
  verificaAprovada(){
    if(this.alterDescricaoRealidade.statusAlteracao == 'Aprovado'){
      this.descricaoRealidade.descricaoRealidade = this.alterDescricaoRealidade.descricaoRealidadeDepois
    }else if(this.alterDescricaoRealidade.statusAlteracao == 'Reprovado'){
      this.descricaoRealidade.descricaoRealidade = this.alterDescricaoRealidade.descricaoRealidadeAntes
    }
    this.loading = true
  }

  //ALTERAR AV
  alterarAvaliacao(){
    this.loading = false

    this.alterDescricaoRealidadeService.alterarAvaliacao(this.alterDescricaoRealidade)
    .then(retorno => {
      this.pthpBuscar()
      this.alterDescricaoRealidade = retorno
      this.verificaAlteracao()
      this.toasty.success("Modifique sua avaliação!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  //UTILS
  aprovado() {
    this.alterDescricaoRealidade.statusAlteracao = 0 //aprovado
  }
  reprovado() {
    this.alterDescricaoRealidade.statusAlteracao = 1 //reprovado
  }

}
