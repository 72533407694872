import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination',
  template: `
  

    <div class="box-footer clearfix col-sm-12" *ngIf="page && page.totalPages == 0">
        <div class="no-margin pull-right"><b class="text-muted">Sem paginação</b></div>
    </div>

    <div class="box-footer clearfix col-sm-1 pull-right" *ngIf="page && page.totalPages > 0">
        <select (ngModelChange)="changePage()" [(ngModel)]="size" class="form-control customized">
            <option value="5">5</option>
            <option value="10" selected>10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>

    <div class="box-footer clearfix col-sm-11" *ngIf="page && page.totalPages > 0">
        <ul class="pagination pagination-sm no-margin pull-right">
            <li class="page-item" [ngClass]="page.first ? 'disabled' : '' ">
                <a  class="page-link" *ngIf="page.first" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                <a  class="page-link" style="cursor: pointer" *ngIf="!page.first" (click)="changePage(page.number-1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>

            <li class="page-item" *ngFor="let pageAux of page.pages" [ngClass]="(pageAux == page.number) ? 'active' : ''"  [ngClass]="i == page.number ? 'active' : '' ">
                <a class="page-link" style="cursor: pointer" (click)="changePage(pageAux)">{{pageAux+1}}</a>
            </li>

            <li class="page-item" [ngClass]="page.last ? 'disabled' : '' ">
                <a  class="page-link" *ngIf="page.last" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                <a  class="page-link" style="cursor: pointer" *ngIf="!page.last" (click)="changePage(page.number+1)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </div>
  
  `,
  styleUrls:['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
    size: number = 20;
    page;
    @Input("page") public set value(page : any){
        if(!page) return;
        this.page = page;
        this.setPagetion();
    }
    @Output() public  paginationEvent : EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {}

    changePage(page?){
        setTimeout(()=>{
            this.paginationEvent.emit({page: page? page : 0, size: this.size} );
        });
    }

    setPagetion(){
        let pages = new Array<number>();
        let inc =  (this.page.number - 2) <= 0 ? (4 - this.page.number) : 2;
        let dec =  (this.page.number + 2) >= this.page.totalPages ? (5 - (this.page.totalPages - this.page.number)) : 2;
        let inicio = (this.page.number - dec) <= 0 ? 0 : (this.page.number - dec);
        let fim = (this.page.number + inc) < this.page.totalPages ? (this.page.number + inc) : (this.page.totalPages - 1);
        for(let i = inicio; i<= fim; i++){
            pages.push(i);
        }
        this.page.pages = pages;
    }
}