import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../seguranca/auth.guard";
import { ComissaoAcompanhamentoCadastroComponent } from './comissao-acompanhamento/comissao-acompanhamento-cadastro/comissao-acompanhamento-cadastro.component';
import { ComissaoAcompanhamentoEditarComponent } from './comissao-acompanhamento/comissao-acompanhamento-editar/comissao-acompanhamento-editar.component';
import { ComissaoAcompanhamentoPesquisaComponent } from './comissao-acompanhamento/comissao-acompanhamento-pesquisa/comissao-acompanhamento-pesquisa.component';
import { ComissaoSelecaoCadastroComponent } from './comissao-selecao/comissao-selecao-cadastro/comissao-selecao-cadastro.component';
import { ComissaoSelecaoPesquisaComponent } from './comissao-selecao/comissao-selecao-pesquisa/comissao-selecao-pesquisa.component';
import { ComissaoSelecaoVincularComponent } from './comissao-selecao/comissao-selecao-vincular/comissao-selecao-vincular.component';
import { ConcedenteCadastroComponent } from './concedente-cadastro-editar/concedente-cadastro.component';
import { ConcedenteGerenciarComponent } from "./concedente-gerenciar/concedente-gerenciar.component";
import { ConcedenteListarComponent } from './concedente-listar/concedente-listar.component';
import { ConcedenteViewComponent } from './concedente-view/concedente-view.component';
import { NovaEntidadeHasUsuarioComponent } from './nova-entidade-has-usuario/nova-entidade-has-usuario.component';
import { SetorPesquisaComponent } from "../setor/setor-pesquisa/setor-pesquisa.component";
import { SetorCadastroComponent } from "../setor/setor-cadastro/setor-cadastro.component";
import { SetorVincularComponent } from "../setor/setor-vincular/setor-vincular.component";

const routers: Routes = [
  { path: '', component: ConcedenteGerenciarComponent, canActivate: [AuthGuard],
    children: [
          { path: 'visualizar', component: ConcedenteViewComponent, canActivate: [AuthGuard] },
          { path: 'listar/:tipo', component: ConcedenteListarComponent, canActivate: [AuthGuard] },

          { path: 'novo', component: NovaEntidadeHasUsuarioComponent, canActivate: [AuthGuard], data: {roles: ['ROLE_CADASTRAR_CONCEDENTE']} },

          { path: 'concedente-editar', component: ConcedenteCadastroComponent, canActivate: [AuthGuard] },
          { path: 'comissao-selecao', component: ComissaoSelecaoPesquisaComponent, canActivate: [AuthGuard] },
          { path: 'comissao-selecao/nova', component: ComissaoSelecaoCadastroComponent, canActivate: [AuthGuard] },
          { path: 'comissao-selecao/vincular', component: ComissaoSelecaoVincularComponent, canActivate: [AuthGuard] },
          { path: 'comissao-acompanhamento', component: ComissaoAcompanhamentoPesquisaComponent, canActivate: [AuthGuard] },
          { path: 'comissao-acompanhamento/nova', component: ComissaoAcompanhamentoCadastroComponent, canActivate: [AuthGuard] },
          { path: 'comissao-acompanhamento/editar', component: ComissaoAcompanhamentoEditarComponent, canActivate: [AuthGuard] },

          { path: 'setor', component: SetorPesquisaComponent, canActivate: [AuthGuard] },
          { path: 'setor/novo', component: SetorCadastroComponent, canActivate: [AuthGuard] },
          { path: 'setor/vincular', component: SetorVincularComponent, canActivate: [AuthGuard] },
  ]},
  
];

@NgModule({
  imports: [RouterModule.forChild(routers)],
  exports: [RouterModule]
})
export class ConcedenteRoutingModule {}
