import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxMaskModule } from 'ngx-mask';
import { ConcedenteModule } from '../concedente/concedente.module';
import { OscService } from '../core/services/proponente/osc.service';
import { OscModule } from '../proponente/osc.module';
import { CelebracaoService } from './../core/services/celebracao/celebracao.service';
import { ConcedenteService } from './../core/services/concedente/concedente.service';
import { NotificacaoService } from './../core/services/notificacao/notificacao.service';
import { NotificacaoHasUsuarioService } from './../core/services/notificacao/notificacaoHasUsuario.service';
import { UsuarioModule } from './../usuario/usuario.module';
import { UsuarioService } from './../usuario/usuario.service';
import { HomePageComponent } from "./home/home-page.component";
import { EntidadeViewComponent } from './info/entidade-view/entidade-view.component';
import { SharedsModule } from '../shareds/shareds.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskModule,

    OscModule,
    ConcedenteModule,
    UsuarioModule,
    SharedsModule
  ],
  declarations: [
    HomePageComponent,
    EntidadeViewComponent,
  ],
  providers:[
    NotificacaoService,
    NotificacaoHasUsuarioService,
    UsuarioService,
    OscService,
    CelebracaoService,
    ConcedenteService
  ]
})
export class HomeModule {}
