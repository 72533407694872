import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { AtendidosProjeto } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class AtendidosProjetoFiltro {
  nome;
  cpf
  idade;
	quilombola;
	indigena;
	cadUnico;
	planoTrabalhoHasProponente;

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class AtendidosProjetoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/atendidos-projeto`;
  }

  pesquisar(filtro: AtendidosProjetoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set('nome', filtro.nome);
    }
    if (filtro.cpf) {
      params.set('cpf', filtro.cpf);
    }
    if (filtro.idade) {
      params.set('idade', filtro.idade);
    }
    if (filtro.quilombola) {
      params.set('quilombola', filtro.quilombola);
    }
    if (filtro.indigena) {
      params.set('indigena', filtro.indigena);
    }
    if (filtro.cadUnico) {
      params.set('cadUnico', filtro.cadUnico);
    }
    if (filtro.planoTrabalhoHasProponente) {
      params.set('planoTrabalhoHasProponente', filtro.planoTrabalhoHasProponente);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: AtendidosProjeto): Promise<AtendidosProjeto> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: AtendidosProjeto): Promise<AtendidosProjeto> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AtendidosProjeto);
  }

  buscarPorCodigo(codigo: number): Promise<AtendidosProjeto> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as AtendidosProjeto;
        return entidade;
      });
  }

}
