import { PessaoUtil } from '../../core/pessoa.util';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { EntidadeProponenteHasUsuario, Osc } from '../../core/model';
import { UsuarioService } from '../../usuario/usuario.service';
import { ProponenteService, ProponenteFiltro } from '../../core/services/proponente/proponente.service';
import { EntidadeProponenteHasUsuarioService } from '../../core/services/entidade-proponente-has-usuario/entidadeProponenteHasUsuario.service';
import { GetAdress } from '../../core/services/utils/get-address.service';
import { Location } from '@angular/common';

declare let AdminLTE: any;

@Component({
  selector: 'app-osc-cadastro',
  templateUrl: './osc-cadastro.component.html',
})
export class OscCadastroComponent implements OnInit {
  entidadeProponenteUsuario = new EntidadeProponenteHasUsuario();
  chefes = [];
  osc = new Osc();

  pessoaUtil = new PessaoUtil();

  filtroOsc = new ProponenteFiltro();
  cnpjTemp: any

  constructor(
    private entidadeProponenteHasUsuario: EntidadeProponenteHasUsuarioService,
    private usuarioService: UsuarioService,
    private ProponenteService: ProponenteService,
    private getAdress: GetAdress,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private location: Location
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
    
    this.title.setTitle('Gestão de entidade');

    this.carregarChefesOsc();
    
    const codigoOsc = sessionStorage.getItem('editEntidade')
    if (codigoOsc) {
      this.carregarOsc(codigoOsc);
    }
  }


  goBack() {
    this.location.back();
  }



  etapa1;
  etapa2;
  etapa3;
  etapa4;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
  }

  get editando() {
    return Boolean(this.osc.id);
  }

  carregarOsc(codigo) {
    this.ProponenteService
      .buscarPorCodigo(codigo)
      .then(osc => {
        this.osc = osc;
        this.cnpjTemp = osc.cnpj
        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  salvar() {
    if (this.editando) {
      this.atualizarOsc();
    } else {
      this.adicionarOsc();
    }
  }

  adicionarOsc() {
    this.ProponenteService
      .adicionar(this.osc)
      .then(async oscAdicionada => {
        this.toasty.success('OSC adicionada com sucesso!');

        //Dps de add a entidade, vai vincular o chefe a entidade
        this.entidadeProponenteUsuario.entidadeProponente.id = oscAdicionada.id;
        this.entidadeProponenteUsuario.usuario.id = oscAdicionada.chefe.id;
        await this.entidadeProponenteHasUsuario.adicionar(
          this.entidadeProponenteUsuario,
        );

        sessionStorage.setItem('viewEntidade', String(oscAdicionada.id))
        this.router.navigate(["/osc/visualizar"]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarOsc() {
    this.ProponenteService
      .atualizar(this.osc)
      .then(osc => {
        this.osc = osc;
        this.toasty.success('Osc alterada com sucesso!');

        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  novo(form: FormControl) {
    form.reset();

    //Necessário para que "new entidade" seja executado depois de "form.reset"
    setTimeout(
      function () {
        this.osc = new Osc();
      }.bind(this),
      1,
    );

    this.router.navigate(['/osc/nova']);
  }

  atualizarTituloEdicao() {
    this.title.setTitle(`Edição da OSC: ${this.osc.nome}`);
  }

  carregarChefesOsc() {
    return this.usuarioService
      .listarChefeOsc()
      .then(chefes => {
        this.chefes = chefes.map(c => ({ label: c.nome, value: c.id }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  getEnderecoCep(event) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return;
          }
          this.osc.endereco.cep = data.cep;
          this.osc.endereco.endereco = data.logradouro;
          this.osc.endereco.complemento = data.complemento;
          this.osc.endereco.bairro = data.bairro;
          this.osc.endereco.cidade = data.localidade;
          this.osc.endereco.estado = data.uf;
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }

  verificarCnpj() {

    if (this.osc.cnpj != null && this.osc.cnpj.length > 10) {

      if (this.pessoaUtil.validarCNPJ(this.osc.cnpj) != false) {

        if (this.cnpjTemp != this.osc.cnpj) {

          this.filtroOsc.cnpj = this.osc.cnpj

          this.ProponenteService.pesquisar(this.filtroOsc).then(resultado => {

            if (resultado.totalElements != 0) {
              this.toasty.error("CNPJ já cadastrado!")
              this.osc.cnpj = null
            }
          });
        }

      } else {
        this.osc.cnpj = null
        this.errorHandler.handle("CNPJ inválido!");
      }
    } else {
      this.toasty.warning("Por favor preencha o CNPJ !")
    }
  }


  verificarEmail() {
    this.osc.email = this.osc.email.toLowerCase();
  }



}
