import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { RoteiroParecer, ResponderRoteiro, RespostaQuestaoParecer, QuestaoParecer, SubmeterAnaliseControleInternoDTO, Celebracao } from "../../core/model";
import { ResponderRoteiroService } from "../../core/services/modelos-sistema/responderRoteiro.service";
import { RespostaQuestaoParecerService, RespostaQuestaoParecerFiltro } from "../../core/services/modelos-sistema/respostaQuestaoParecer.service";
import { AuthService } from "../../seguranca/auth.service";
import { ToastyService } from "ng2-toasty";
import { GenerateParamPipe } from "../../core/my-pipes/generate-param.pipe";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { CelebracaoService } from "../../core/services/celebracao/celebracao.service";
import { PlanoTrabalhoHasProponenteService } from "../../core/services/plano-trabalho/planoTrabalhoHasProponente.service";


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-roteiro-responder",
  templateUrl: "./roteiro-responder.component.html"
})
export class RoteiroResponderComponent implements OnInit {

  roteiro = new RoteiroParecer
  responderRoteiro: ResponderRoteiro;
  finalizarRoteiro: boolean;
  roteiroJaSubmetido: boolean;
  respostas = []
  recomendacaoAnalista: string

  loading
  introducao
  mostraModalIntro
  celebracao = new Celebracao();
  mostraModalUpload: boolean;
  questaoSelecionadaUpload: any;

  constructor(
    private title: Title,
    private responderRoteiroService: ResponderRoteiroService,
    private respostaQuestaoParecerService: RespostaQuestaoParecerService,
    private errorHandler: ErrorHandlerService,
    private auth: AuthService,
    private toasty: ToastyService,
    private router: Router,
    private location: Location,
    private celebracaoService: CelebracaoService,
    private pthpService: PlanoTrabalhoHasProponenteService

  ) { }

  ngOnInit() {
    AdminLTE.init();

    this.title.setTitle('Responder Roteiro');

    this.responderRoteiro = JSON.parse(sessionStorage.getItem('emitirParecer'))

    if (this.responderRoteiro.celebracao != null) {
      this.celebracao = JSON.parse(sessionStorage.getItem("celebracao"));
      if (this.celebracao.id != this.responderRoteiro.celebracao) {
        this.carregaCelebracao()
      }
    }

    if (this.responderRoteiro != null) {
      this.roteiro = this.responderRoteiro.roteiroParecer
      this.buscarRespostas()
      if (this.responderRoteiro.status == 'Submetido') {
        this.roteiroJaSubmetido = true
      }
    }
  }
  carregaCelebracao() {
    this.celebracaoService.buscarPorCodigo(this.responderRoteiro.celebracao)
      .then(celebracao => {
        this.celebracao = celebracao
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  goBack() {
    this.location.back();
  }

  verIntroducao(intro) {
    this.introducao = intro
    this.mostraModalIntro = true
  }

  buscarRespostas() {
    let filtro = new RespostaQuestaoParecerFiltro
    filtro.responderRoteiro = this.responderRoteiro.id
    this.respostaQuestaoParecerService.pesquisar(filtro)
      .then(async retorno => {
        if (retorno.dado.length != 0) {
          this.respostas = retorno.dado
          await this.setaRespostasNasQuestoes()
          await this.verificaSeTodasForamRespondidas()

          this.loading = true
        } else {

          this.loading = true
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  setaRespostasNasQuestoes() {
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta
          }
        });
      });
    });
  }

  verificaSeTodasForamRespondidas() {
    let faltaResponder = false
    // let questoesReprovadas = []
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta
            if (resposta.respostaEnum == 'Nao') {
            }
          }
        });
        if (questao.resposta == null) {
          faltaResponder = true
        }
      });
    });
    if (!faltaResponder) {
      this.finalizarRoteiro = true
    }
  }




  setarParecer(event, questao) {
    if (event.target.checked) {
      // //console.log("this checked ", questao.id);

      (<HTMLInputElement>document.getElementById(questao.id + "-checkBox")).checked = true

      event.target.checked = true
      document.getElementById(questao.id + "-parecerNaoFavoravel").classList.add("hide")
      document.getElementById(questao.id + "-parecerNaoFavoravel").classList.remove("inline")
      document.getElementById(questao.id + "-parecerFavoravel").classList.add("inline")
      document.getElementById(questao.id + "-parecerFavoravel").classList.remove("hide")
    } else {
      // //console.log("this not checked", questao.id);

      event.target.checked = false
      document.getElementById(questao.id + "-parecerFavoravel").classList.add("hide")
      document.getElementById(questao.id + "-parecerFavoravel").classList.remove("inline")
      document.getElementById(questao.id + "-parecerNaoFavoravel").classList.add("inline")
      document.getElementById(questao.id + "-parecerNaoFavoravel").classList.remove("hide")
    }
  }


  alterarParecer(event, questao) {
    if (event.target.checked) {
      questao.resposta.respostaEnum = 'Sim'
    } else {
      questao.resposta.respostaEnum = 'Nao'
    }
  }

  verificaParecerQuestao(questao) {
    if (typeof (questao.resposta) != 'undefined') {
      if (typeof (questao.resposta.respostaEnum) != 'undefined') {
        return questao.resposta.respostaEnum == 'Sim' ? true : false
      }
    }
    return false
  }

  verificaEscolhaQuestao(questao) {
    questao.escolha.forEach(escolha => {
      if (escolha.id == questao.resposta.escolha.id) {
        (<HTMLInputElement>document.getElementById(escolha.id + "-escolha")).checked = true
      } else {
        (<HTMLInputElement>document.getElementById(escolha.id + "-escolha")).checked = false
      }
    });
  }

  // ==== RESPONDER ====== //
  responderQuestao(questao, flagAtualizar = false) {
    if (questao.tipo == 'Texto' || questao.tipo == 'Sim_nao') {
      this.responderQuestaoTextoSimNao(questao, flagAtualizar)
    } else if (questao.tipo == 'Multipla_escolha') {
      this.responderQuestaoMultiplaEscolha(questao, flagAtualizar)
    } else if (questao.tipo == 'Anexo' || questao.tipo == 'CPF' || questao.tipo == 'CNPJ' || questao.tipo == 'Monetario' || questao.tipo == 'Numerico' || questao.tipo == 'Data') {
      this.responderQuestaoCpfCnpjMonetarioNumericoData(questao, flagAtualizar)
    }
  }

  responderQuestaoTextoSimNao(questao, flagAtualizar) {
    let resposta = new RespostaQuestaoParecer
    resposta.data = new Date().getTime()

    resposta.anexo = null
    resposta.hashFile = null
    resposta.escolha = null

    let questaoParecer = new QuestaoParecer
    questaoParecer.id = questao.id
    resposta.questaoParecer = questaoParecer

    let responderRoteiro = new ResponderRoteiro
    responderRoteiro.id = this.responderRoteiro.id
    resposta.responderRoteiro = responderRoteiro

    resposta.usuario.id = this.auth.jwtPayload.id

    if (questao.tipo == 'Texto') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-texto")).value
    } else {
      resposta.observacao = (<HTMLInputElement>document.getElementById(questao.id + "-observacao")).value
    }


    if (questao.parecer == true) {

      if (typeof (questao.resposta) != 'undefined') {

        if (questao.resposta.respostaEnum == 'Sim') {
          resposta.respostaEnum = 0 //Sim
        } else if (questao.resposta.respostaEnum == 'Nao') {
          resposta.respostaEnum = 1 //Não
        } else {
          resposta.respostaEnum = 1 //Não
        }

      } else {

        var y = (<HTMLInputElement>document.getElementById(questao.id + "-checkBox")).checked
        if (y == true) {
          resposta.respostaEnum = 0 //Sim
        } else {
          resposta.respostaEnum = 1 //Não
        }

      }

    } else {
      //Não mostra parecer
      resposta.respostaEnum = null
    }

    if (flagAtualizar) {
      resposta.id = questao.resposta.id
      this.respostaQuestaoParecerService.atualizar(resposta)
        .then(() => {
          this.buscarRespostas()
          this.toasty.success("Resposta alterada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.respostaQuestaoParecerService.adicionar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.respostas[this.respostas.length] = retorno
          this.buscarRespostas()
          this.toasty.success("Resposta submetida com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }


  responderQuestaoMultiplaEscolha(questao, flagAtualizar) {
    let resposta = new RespostaQuestaoParecer
    resposta.data = new Date().getTime()

    resposta.anexo = null
    resposta.hashFile = null


    questao.escolha.forEach(escolha => {
      var k = (<HTMLInputElement>document.getElementById(escolha.id + "-escolha")).checked
      if (k == true) {
        resposta.escolha = escolha
      }
    });

    let questaoParecer = new QuestaoParecer
    questaoParecer.id = questao.id
    resposta.questaoParecer = questaoParecer

    let responderRoteiro = new ResponderRoteiro
    responderRoteiro.id = this.responderRoteiro.id
    resposta.responderRoteiro = responderRoteiro

    resposta.usuario.id = this.auth.jwtPayload.id

    resposta.observacao = (<HTMLInputElement>document.getElementById(questao.id + "-observacao")).value

    if (questao.parecer == true) {
      //Mostra parecer  
      var y = (<HTMLInputElement>document.getElementById(questao.id + "-favoravel")).checked
      var z = (<HTMLInputElement>document.getElementById(questao.id + "-desfavoravel")).checked

      if (y == true) {
        resposta.respostaEnum = 0 //Sim
      } else if (z == true) {
        resposta.respostaEnum = 1 //Não
      } else { resposta.respostaEnum = null }

    } else {
      //Não mostra parecer
      resposta.respostaEnum = null
    }

    if (flagAtualizar) {
      resposta.id = questao.resposta.id
      this.respostaQuestaoParecerService.atualizar(resposta)
        .then(() => {
          this.buscarRespostas()
          this.toasty.success("Resposta alterada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.respostaQuestaoParecerService.adicionar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.respostas[this.respostas.length] = retorno
          this.buscarRespostas()
          this.toasty.success("Resposta submetida com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    }



  }


  responderQuestaoCpfCnpjMonetarioNumericoData(questao, flagAtualizar) {
    let resposta = new RespostaQuestaoParecer
    resposta.data = new Date().getTime()

    resposta.anexo = null
    resposta.hashFile = null
    resposta.escolha = null

    let questaoParecer = new QuestaoParecer
    questaoParecer.id = questao.id
    resposta.questaoParecer = questaoParecer

    let responderRoteiro = new ResponderRoteiro
    responderRoteiro.id = this.responderRoteiro.id
    resposta.responderRoteiro = responderRoteiro

    resposta.usuario.id = this.auth.jwtPayload.id

    if (questao.tipo == 'CPF' || questao.tipo == 'CNPJ') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-cpf-cnpj")).value
    } else if (questao.tipo == 'Monetario') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-monetario")).value
    } else if (questao.tipo == 'Numerico') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-numerico")).value
    } else if (questao.tipo == 'Data') {
      resposta.responderData = (<HTMLInputElement>document.getElementById(questao.id + "-data")).value
    }if (questao.tipo == 'Anexo') {
      resposta.anexo = (<HTMLInputElement>document.getElementById(questao.id + "-anexo")).value
      resposta.hashFile = (<HTMLInputElement>document.getElementById(questao.id + "-hash")).value
    }

    resposta.observacao = (<HTMLInputElement>document.getElementById(questao.id + "-observacao")).value

    if (questao.parecer == true) {



      var y = (<HTMLInputElement>document.getElementById(questao.id + "-checkBox")).checked
      if (y == true) {
        resposta.respostaEnum = 0 //Sim
      } else {
        resposta.respostaEnum = 1 //Não
      }

    } else {
      //Não mostra parecer
      resposta.respostaEnum = 1 //Não
    }

    if (flagAtualizar) {
      resposta.id = questao.resposta.id
      this.respostaQuestaoParecerService.atualizar(resposta)
        .then(() => {
          this.buscarRespostas()
          this.toasty.success("Resposta alterada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.respostaQuestaoParecerService.adicionar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.respostas[this.respostas.length] = retorno
          this.buscarRespostas()
          this.toasty.success("Resposta submetida com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  // === FINALIZAR/ALTERAR === //
  async finalizarQuestionario() {

    let sACIDTO = new SubmeterAnaliseControleInternoDTO()
    sACIDTO.codigo = this.responderRoteiro.id
    sACIDTO.hash = new GenerateParamPipe().generateHash()
    sACIDTO.idUsuario = this.auth.jwtPayload.id
    sACIDTO.recomendacaoAnalista = this.responderRoteiro.recomendacaoAnalise ? this.responderRoteiro.recomendacaoAnalise : "Nenhuma recomendação informada"
    sACIDTO.status = await this.processarAnalise(this.roteiro)

    this.responderRoteiroService.submeterControleInterno(sACIDTO)
      .then(async () => {
        this.responderRoteiro.status = 'Submetido'
        this.roteiroJaSubmetido = true
        await this.verificaCelebracao()
        this.toasty.success("Roteiro Subumetido com Sucesso!");
        this.goBack()
        //this.enviarParaVisualizarAnalise()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaCelebracao() {
    if (this.celebracao.status == 'Celebracao') {
      this.pthpService.condicaoAlterarPlano(this.celebracao.pthpId, false)
        .then(() => { })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  enviarParaVisualizarAnalise() {
    this.responderRoteiroService.buscarPorCodigo(this.responderRoteiro.id)
      .then(respRot => {
        sessionStorage.setItem('respondeRoteiroControle', JSON.stringify(respRot))
        this.router.navigate(['/controle-interno/gestao/analise-concluida/submetido']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  alterarQuestionario() {
    let hash = new GenerateParamPipe().generateHash()
    this.responderRoteiroService.mudarStatus(this.responderRoteiro.id, 0, hash)
      .then(() => {
        this.responderRoteiro.status = 'Em_elaboracao'
        this.roteiroJaSubmetido = false
        this.toasty.success("Roteiro Em Elaboração!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }





  processarAnalise(roteiro) {

    let secaoCountIndice = 0
    let questaoCountIndice = 0
    let observacoes = []
    let qtdQuestoes = 0
    let favoravel = 0
    let naoFavoravel = 0
    let qtdRespostas = 0
    let qtdParecer = 0
    let qtdObsFavoravel = 0
    let qtdObsNaoFavoravel = 0
    let reprovado = 0
    let aprovado = 0

    roteiro.secao.forEach(secao => {

      secaoCountIndice++
      questaoCountIndice = 0

      secao.questaoParecer.forEach(questao => {
        questaoCountIndice++
        qtdQuestoes++
        let value = secaoCountIndice + "." + questaoCountIndice

        // verifica as questoes que foram respondidas
        if (questao.resposta != null) {
          //Incrementa a quantidade de questões que contém respostas
          qtdRespostas++

          if (questao.resposta.observacao != null && questao.resposta.observacao != "") {
            observacoes.push(value)
            //Para obter a quantidade de questões é necessário utilizar a função length ou fazer um for para buscar as observações
          }

          if (questao.resposta.respostaEnum) {
            qtdParecer++
            // verifica as questoes favoraveis e que nao tiveram obs
            if (questao.resposta.respostaEnum === 'Sim') {
              favoravel++
            }

            if (questao.resposta.respostaEnum === 'Nao') {

              if (questao.continuar === 'Nao') {
                reprovado++
              } else {
                aprovado++
              }
            }
            naoFavoravel++
          }
        }

      });

    });

    //Verifico os quantitativo para definir os status
    if (qtdRespostas === favoravel) {
      if (observacoes.length === 0) {
        return 0 //Aprovado
      } else {
        return 1 // Ressalva
      }
    } else {
      if (observacoes.length === 1) {
        return 2 //Reprovado
      } else {
        return 2 //Reprovado
      }
    }
  }

  //ULPLOAD
  openModalUpload(questao){
    //questao.anexo = questao.enunciado
    this.mostraModalUpload = true
    this.questaoSelecionadaUpload = questao
    
  }

  salvarUpload() {
    let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
    sessionStorage.removeItem('upload');
    if (!fileItem || !fileItem.responseStatus)
      return this.toasty.error("É necessário efetivar o upload")

    let name = fileItem.response.data.originalname
    let hash = fileItem.response.data.filename
    
    this.questaoSelecionadaUpload.anexo = name
    this.questaoSelecionadaUpload.hash = hash

    this.mostraModalUpload = false

  }


}
