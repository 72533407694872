import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { DetalhamentoFinanceiroHasEtapaCronogramaFisico } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class DetalhamentoFinanceiroHasEtapaCronogramaFisicoFiltro {
  especificacao
  natureza
  detalhamentoFinanceiro
  etapaCronogramaFisico
  pagina = 0;
  itensPorPagina = 2000;
  planoTrabalhoHasProponente;
  categoria;
  tipo;
  planoTrabalho;
  entidadeProponente;
}

@Injectable()
export class DetalhamentoFinanceiroHasEtapaCronogramaFisicoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/detalhamento-financeiro-has-etapa-cronograma-fisico`;
  }

  pesquisar(filtro: DetalhamentoFinanceiroHasEtapaCronogramaFisicoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.especificacao) {
      params.set('especificacao', filtro.especificacao);
    }
    if (filtro.natureza) {
      params.set('natureza', filtro.natureza);
    }
    if (filtro.detalhamentoFinanceiro) {
      params.set('detalhamentoFinanceiro', filtro.detalhamentoFinanceiro);
    }
    if (filtro.etapaCronogramaFisico) {
      params.set('etapaCronogramaFisico', filtro.etapaCronogramaFisico);
    }

    if (filtro.categoria) {
      params.set("categoria", filtro.categoria);
    }

    if (filtro.planoTrabalho) {
      params.set("planoTrabalho", filtro.planoTrabalho);
    }

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }

    if (filtro.tipo) {
      params.set("tipo", filtro.tipo);
    
    }
    if (filtro.planoTrabalhoHasProponente) {
      params.set("planoTrabalhoHasProponente", filtro.planoTrabalhoHasProponente);
    }

    return this.http.get(`${this.url}/dto`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: DetalhamentoFinanceiroHasEtapaCronogramaFisico): Promise<DetalhamentoFinanceiroHasEtapaCronogramaFisico> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: DetalhamentoFinanceiroHasEtapaCronogramaFisico): Promise<DetalhamentoFinanceiroHasEtapaCronogramaFisico> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as DetalhamentoFinanceiroHasEtapaCronogramaFisico);
  }

  buscarPorCodigo(codigo: number): Promise<DetalhamentoFinanceiroHasEtapaCronogramaFisico> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as DetalhamentoFinanceiroHasEtapaCronogramaFisico;
        return entidade;
      });
  }

}
