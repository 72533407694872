import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Celebracao, ResponderRoteiro, Financeiro } from "../../core/model";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService } from "primeng/primeng";
import { ResponderRoteiroService, ResponderRoteiroFiltro } from "../../core/services/modelos-sistema/responderRoteiro.service";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { AuthService } from "../../seguranca/auth.service";

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-analise-execucao",
  templateUrl: "./analise-execucao.component.html"
})
export class AnaliseExecucaoComponent implements OnInit {

  tipo: any;
  rr = new ResponderRoteiro()
  tipoRota: any;
  financeiro = new Financeiro();
  mostraEmitirAnalise: boolean;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private confirmation: ConfirmationService,
    private reponderRoteiroService: ResponderRoteiroService,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    public auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.financeiro = JSON.parse(sessionStorage.getItem("financeiroParaAnalise"));

    const tipo = this.route.snapshot.params['tipo'];
    this.tipoRota = tipo
    this.verificaTipoParecer(tipo)

    if(sessionStorage.getItem("mostraRotaListar") == '1'){
      this.mostraEmitirAnalise = true
      sessionStorage.setItem("mostraRotaListar", '0');
    }
  }

  verificaTipoParecer(tipo) {
    if (tipo != null) {
      if (tipo == "relatorio-visita-loco") {
        this.tipo = "Relatório de visita In loco"
        sessionStorage.setItem('tipoParecer', '3')
      } else if (tipo == "parecer-prestacao-contas") {
        this.tipo = "Parecer técnico da prestação de contas"
        sessionStorage.setItem('tipoParecer', '4')
      } else if (tipo == "relatorio-monitoramento-avaliacao") {
        this.tipo = "Relatório técnico de monitoramento e avaliação"
        sessionStorage.setItem('tipoParecer', '5')
      } else if (tipo == "analise-financeira") {
        this.tipo = "Análise financeira"
        sessionStorage.setItem('tipoParecer', '6')
      } else if (tipo == "outras-pendencias") {
        this.tipo = "Outras pendências"
        sessionStorage.setItem('tipoParecer', '7')
      }
      
    }

    this.title.setTitle('Parecer ' + this.tipo);
  }

  verificaExiteParecer() {
    let filtro = new ResponderRoteiroFiltro();
    filtro.financeiro = this.financeiro.id
    filtro.filtroTipoCelebracao = sessionStorage.getItem("tipoParecer")

    this.reponderRoteiroService.pesquisar(filtro)
      .then(resultado => {
        if (resultado.content.length == 0) this.openConfirmacaoNovoParecer()
        else this.openEmitirParecer(resultado.content)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  openEmitirParecer(listRR) {
    listRR.forEach(responderRoteiro => {
      if (responderRoteiro.situacao == 'ATIVO') {
        if (responderRoteiro.status == 'Em_elaboracao') {
          sessionStorage.setItem('emitirParecer', JSON.stringify(responderRoteiro))
          this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoRota}/emitir`]);
          this.mostraEmitirAnalise = true
        } else if (responderRoteiro.status == 'Submetido') {
          this.confirmaAlterarParecer(responderRoteiro)
        }

      }
    });
  }

  confirmaAlterarParecer(rr) {
    this.confirmation.confirm({
      message: 'Ao clicar em "sim", a análise atual ficará como obsoleta e será criada uma nova análise idêntica para que possa ser alterada. Deseja continuar?',
      accept: () => {
        this.alterarParecer(rr);
      },
    });
  }
  alterarParecer(rr) {
    this.reponderRoteiroService.editarResponderRoteiro(rr.id)
      .then(async resultado => {
        sessionStorage.setItem('emitirParecer', JSON.stringify(resultado))
        this.toasty.success("Altere o seu parecer!");
        await this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoRota}`]);
        await this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoRota}/emitir`]);
        this.mostraEmitirAnalise = true
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  openConfirmacaoNovoParecer() {
    this.confirmation.confirm({
      message: 'Você deseja iniciar um(a) ' + this.tipo + '?',
      accept: () => {
        this.addNovoParecer();
      },
    });
  }

  async addNovoParecer() {

    await this.setaResponderRoteiro()

    this.reponderRoteiroService.adicionarParecer(this.rr)
      .then(async responder => {
        sessionStorage.setItem('emitirParecer', JSON.stringify(responder))
        this.toasty.success("Parecer adicionado com sucesso!");
        await this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoRota}`]);
        await this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoRota}/emitir`]);
        this.mostraEmitirAnalise = true

      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  setaResponderRoteiro() {
    this.rr = new ResponderRoteiro()
    this.rr.planoTrabalho = null
    this.rr.entidadeProponente = null

    if (this.tipo == "Relatório de visita In loco") {
      this.rr.filtroTipoCelebracao = 3
    } else if (this.tipo == "Parecer técnico da prestação de contas") {
      this.rr.filtroTipoCelebracao = 4
    } else if (this.tipo == "Relatório técnico de monitoramento e avaliação") {
      this.rr.filtroTipoCelebracao = 5
    } else if (this.tipo == "Análise financeira") {
      this.rr.filtroTipoCelebracao = 6
    } else if (this.tipo == "Outras pendências") {
      this.rr.filtroTipoCelebracao = 7
    }

    this.rr.financeiro = this.financeiro.id
    this.rr.usuario.id = this.auth.jwtPayload.id
    this.rr.entidadeConcedente.id = this.financeiro.celebracao.et1EntidadeConcedente.id
    this.rr.status = 0 //Em_elaboração
    this.rr.situacao = 0 //Ativo

  }

  mostraRouter(){
    this.mostraEmitirAnalise = true
  }



}
