import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../../../environments/environment';
import { ErrorHandlerService } from './../../../core/error-handler.service';
import { ExtratoFiltro, ExtratoService } from './../../../core/services/moduloFinanceiro/extrato.service';

@Component({
  selector: 'cotacao-preco',
  templateUrl: './cotacao-preco.component.html',
})
export class CotacaoPrecoComponent implements OnInit, OnChanges {

  pthpId: any;

  extratoFiltro = new ExtratoFiltro()

  periodoInicial
  periodoFinal

  listaDespesasComuns = [];
  listaDespesasPessoal = [];

  @Input() periodoInicialInput;
  @Input() periodoFinalInput;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private extratoService: ExtratoService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {

    this.pthpId = JSON.parse(sessionStorage.getItem("planoTrabalhoHasProponenteId"));

  }

  ngOnChanges(changes: any): void {
    if (changes.periodoInicialInput) {
      this.periodoInicial = moment(changes.periodoInicialInput.currentValue).format("YYYY-MM-DD")
    }
    if (changes.periodoFinalInput) {
      this.periodoFinal = moment(changes.periodoFinalInput.currentValue).format("YYYY-MM-DD")
    }
    setTimeout(
      function () { this.gerarComprovantes(); }.bind(this), 10
    );
  }

  //GERAR RELAÇÃO
  gerarComprovantes() {

    this.extratoFiltro.planoTrabalhoHasProponenteId = this.pthpId
    this.extratoFiltro.itensPorPagina = 10000 //TODO: retorno máximo de registros

    this.extratoFiltro.dataCadastroInicial = this.periodoInicial
    this.extratoFiltro.dataCadastroFinal = this.periodoFinal

    this.extratoService.pesquisarDtoRelatorioDespesaReceitaInfoCompleta(this.extratoFiltro)
      .then(resultado => {
        this.listaDespesasComuns = [];
        this.listaDespesasPessoal = [];
        
        resultado.content.forEach(element => {
          if(element.despesaId){
            this.listaDespesasComuns.push(element);
          }
          if(element.pagamentoPessoalId){
            this.listaDespesasPessoal.push(element);
          }
        });
      }).catch(erro => this.errorHandler.handle(erro));
  }

  displayOpenModalView
  urlDocumento
  viewDownload
  openViewPDF(item){
    this.displayOpenModalView = true;
    this.urlDocumento = environment.downloadFileUrl + "/" + item;
    item.includes(".pdf") ? this.viewDownload=true : this.viewDownload=false
  }

}
