import { ConcedenteService } from '../core/services/concedente/concedente.service';
import { PendenciaRoutingModule } from './pendencia.routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedsModule } from '../shareds/shareds.module';
import { PendenciaGerenciarComponent } from './pendencia-gerenciar/pendencia-gerenciar.component';
import { PendenciaListarComponent } from './pendencia-listar/pendencia-listar.component';
import { PendenciaCadastrarComponent } from './pendencia-cadastrar/pendencia-cadastar.component';
import { ProponenteService } from '../core/services/proponente/proponente.service';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedsModule,
    PendenciaRoutingModule,
    

  ],
  declarations: [//COMPONENTS
    PendenciaGerenciarComponent,
    PendenciaListarComponent,
    PendenciaCadastrarComponent,
    
  ],
  exports: [],

  providers:[//SERVICES
    ConcedenteService,
    ProponenteService
    

  ],
  
})
export class PendenciaModule {}
