import { URLSearchParams } from '@angular/http';
import { Injectable, EventEmitter } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { ResponderRoteiro } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class ResponderRoteiroFiltro {
  tituloAnalise;
  processoFisico;
  status;
  statusControleInterno;
  roteiroParecer;
  planoTrabalho;
  entidadeConcedente;
  entidadeProponente;
  usuario;
  situacao;
  submoduloRoteiro;
  hash

  celebracao;
  filtroTipoCelebracao;

  financeiro

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class ResponderRoteiroService {

  static setaResponder = new EventEmitter<void>();

  static atualizaVisualizacao = new EventEmitter<void>();

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/responder-roteiro`;
  }

  acaoAtualizaVizualizacao(){
    ResponderRoteiroService.atualizaVisualizacao.emit()
  }

  pesquisar(filtro: ResponderRoteiroFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tituloAnalise) {
      params.set('tituloAnalise', filtro.tituloAnalise);
    }
    if (filtro.processoFisico) {
      params.set('processoFisico', filtro.processoFisico);
    }
    if (filtro.status) {
      params.set('status', filtro.status);
    }
    if (filtro.statusControleInterno) {
      params.set('statusControleInterno', filtro.statusControleInterno);
    }
    if (filtro.roteiroParecer) {
      params.set('roteiroParecer', filtro.roteiroParecer);
    }
    if (filtro.planoTrabalho) {
      params.set('planoTrabalho', filtro.planoTrabalho);
    }
    if (filtro.entidadeConcedente) {
      params.set('entidadeConcedente', filtro.entidadeConcedente);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.usuario) {
      params.set('usuario', filtro.usuario);
    }
    if (filtro.situacao) {
      params.set('situacao', filtro.situacao);
    }
    if (filtro.submoduloRoteiro) {
      params.set('submoduloRoteiro', filtro.submoduloRoteiro);
    }
    if (filtro.hash) {
      params.set('hash', filtro.hash);
    }
    if (filtro.celebracao) {
      params.set('celebracao', filtro.celebracao);
    }
    if (filtro.filtroTipoCelebracao) {
      params.set('filtroTipoCelebracao', filtro.filtroTipoCelebracao);
    }
    if (filtro.financeiro) {
      params.set('financeiro', filtro.financeiro);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        // const responseJson = response.json();
        // const dado = responseJson.content;

        // const resultado = {
        //   dado,
        //   total: responseJson.totalElements
        // };
        // return resultado;
        return response.json();

      })
  }

  valorTotalAnalisado(filtro: ResponderRoteiroFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tituloAnalise) {
      params.set('tituloAnalise', filtro.tituloAnalise);
    }
    if (filtro.processoFisico) {
      params.set('processoFisico', filtro.processoFisico);
    }
    if (filtro.status) {
      params.set('status', filtro.status);
    }
    if (filtro.statusControleInterno) {
      params.set('statusControleInterno', filtro.statusControleInterno);
    }
    if (filtro.roteiroParecer) {
      params.set('roteiroParecer', filtro.roteiroParecer);
    }
    if (filtro.planoTrabalho) {
      params.set('planoTrabalho', filtro.planoTrabalho);
    }
    if (filtro.entidadeConcedente) {
      params.set('entidadeConcedente', filtro.entidadeConcedente);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.usuario) {
      params.set('usuario', filtro.usuario);
    }
    if (filtro.situacao) {
      params.set('situacao', filtro.situacao);
    }
    if (filtro.submoduloRoteiro) {
      params.set('submoduloRoteiro', filtro.submoduloRoteiro);
    }

    return this.http.get(`${this.url}/valores-analisados`, { search: params })
      .toPromise()
      .then(response => {
        // const responseJson = response.json();
        // const dado = responseJson.content;

        // const resultado = {
        //   dado,
        //   total: responseJson.totalElements
        // };
        // return resultado;
        return response.json();

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: ResponderRoteiro): Promise<ResponderRoteiro> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => {
        ResponderRoteiroService.setaResponder.emit()
        return response.json()
      });
  }

  adicionarParecer(entidade: ResponderRoteiro): Promise<ResponderRoteiro> {
    return this.http.post(`${this.url}/parecer`, JSON.stringify(entidade))
      .toPromise()
      .then(response => {
        ResponderRoteiroService.setaResponder.emit()
        return response.json()
      });
  }

  atualizar(entidade: ResponderRoteiro): Promise<ResponderRoteiro> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as ResponderRoteiro);
  }

  buscarPorCodigo(codigo: number): Promise<ResponderRoteiro> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as ResponderRoteiro;
        return entidade;
      });
  }

  mudarStatus(codigo: number, status, hash): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/${hash}/status`, status)
      .toPromise()
      .then(() => null);
  }

  submeterControleInterno(submeterAnaliseControleInternoDTO): Promise<void> {
    return this.http.put(`${this.url}/submeter-controle-interno`, submeterAnaliseControleInternoDTO)
      .toPromise()
      .then(() => {
        ResponderRoteiroService.setaResponder.emit()
      });
  }

  editarResponderRoteiro(id: number): Promise<ResponderRoteiro> {
    return this.http.put(`${this.url}/desativar/${id}`, status)
      .toPromise()
      .then(response => {
        ResponderRoteiroService.setaResponder.emit()
        return response.json() as ResponderRoteiro
      });
  }
  retornaStatus(status) {
    let i
    if (status == 'Em_elaboracao') i = 'Em elaboração'
    else if (status == 'Submetido') i = 'Submetido'
    else i = 'Desconhecido'
    return i
  }

}
