import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare let AdminLTE: any;

@Component({
  selector: 'app-ajuste-plano-avaliar',
  templateUrl: './ajuste-plano-avaliar.component.html',
})
export class AjustePlanoAvaliarComponent implements OnInit {
  
  planoTrabalhoEscolhido: any;
  celebracao: any;
  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  etapa1: boolean;
  etapa2: boolean;
  etapa3: boolean;
  etapa4: boolean;
  etapa5: boolean;
  etapa6: boolean;
  etapa7: boolean;
  etapa8: boolean;
  etapa9: boolean;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    setTimeout(
      function() {
        document.getElementById("topoAlterar").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

  }

  // ==== ABRIR ETAPAS ==== //
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa01").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

    let rota = sessionStorage.getItem("rotaModuloAjuste")
    this.router.navigate([rota+'/avaliar/descricao-realidade']);
  }
  openEtapa2() {
   
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa02").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

    let rota = sessionStorage.getItem("rotaModuloAjuste")
    this.router.navigate([rota+'/avaliar/sintese-proposta']);
  }
  openEtapa3() {
    
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa03").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

    let rota = sessionStorage.getItem("rotaModuloAjuste")
    this.router.navigate([rota+'/avaliar/capacidade-instalada']);
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa04").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

    let rota = sessionStorage.getItem("rotaModuloAjuste")
    this.router.navigate([rota+'/avaliar/monitoramento']);
  }
  openEtapa5() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = true;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa05").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

    let rota = sessionStorage.getItem("rotaModuloAjuste")
    this.router.navigate([rota+'/avaliar/cronograma-fisico']);
  }
  openEtapa6() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = true;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa06").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );

    let rota = sessionStorage.getItem("rotaModuloAjuste")
    this.router.navigate([rota+'/avaliar/detalhamento-financeiro']);

  }
  openEtapa7() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = true;
    this.etapa8 = false;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa07").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );
  }
  openEtapa8() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = true;
    this.etapa9 = false;

    setTimeout(
      function() {
        document.getElementById("etapa08").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );
  }
  openEtapa9() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = true;

    setTimeout(
      function() {
        document.getElementById("etapa09").scrollIntoView({
          behavior: "smooth"
        });
      }.bind(this),
      10
    );
  }
  fechaTodasEtapas() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
    this.etapa8 = false;
    this.etapa9 = false;
  }






}
