import { Injectable } from "@angular/core";
import { URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";
import * as moment from "moment";
import "rxjs/add/operator/toPromise";
import { environment } from "../../../../environments/environment";
import { Osc, Usuario } from "../../model";
import { AuthService } from "../../../seguranca/auth.service";

export class ProponenteFiltro {
  nome: string;
  cnpj: string;
  statusHabilitacao: string;
  hashHabilitacao: string;
  tipoProponente: string;
  habilitacao: string;
  cidade:string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ProponenteService {
  url: string;
  usuarioChefe = new Usuario();

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/entidade-proponente`;
  }

  pesquisar(filtro: ProponenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }
    if (filtro.statusHabilitacao) {
      params.set("statusHabilitacao", filtro.statusHabilitacao);
    }
    if (filtro.hashHabilitacao) {
      params.set("hashHabilitacao", filtro.hashHabilitacao);
    }
    if (filtro.cnpj) {
      params.set("cnpj", filtro.cnpj);
    }
    if (filtro.tipoProponente) {
      params.set("tipoProponente", filtro.tipoProponente);
    }
    if (filtro.habilitacao) {
      params.set("habilitacao", filtro.habilitacao);
    }
    if (filtro.cidade) {
      params.set("cidade", filtro.cidade);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        return responseJson;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();

    if (this.auth.jwtPayload.perfil == "Chefe de Proponente") {
      params.set("chefe", this.auth.jwtPayload.id);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(osc: Osc): Promise<Osc> {
    return this.http
      .post(this.url, JSON.stringify(osc))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(osc: Osc): Promise<Osc> {
    return this.http
      .put(`${this.url}/${osc.id}`, JSON.stringify(osc))
      .toPromise()
      .then(response => response.json() as Osc);
  }

  buscarPorCodigo(codigo: number): Promise<Osc> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const osc = response.json() as Osc;

        this.converterStringsParaDatas([osc]);

        return osc;
      });
  }

  private converterStringsParaDatas(oscs: Osc[]) {
    for (const osc of oscs) {
      if (osc.dataCadastro) {
        osc.dataCadastro = moment(osc.dataCadastro, "YYYY-MM-DD").toDate();
      }
    }
  }

  mudarStatus(codigo: number, status): Promise<void> {
    return this.http
      .put(`${this.url}/${codigo}/status`, status)
      .toPromise()
      .then(() => null);
  }

  mudarHash(codigo: number, hash: string, habilitacao: number): Promise<void> {
    return this.http
      .put(`${this.url}/hash/${habilitacao}/${hash}`, codigo)
      .toPromise()
      .then(() => null);
  }

  retornaStatusHabilitacao(status){
    if(status == 'Habilitada_plenamente'){
      status = 'Habilitada plenamente'
    }else if(status == 'Habilitada_parcialmente'){
      status = 'Habilitada parcialmente'
    }else{
      status = 'Não habilitada'
    }
    return status
  }
  

}
