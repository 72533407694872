import { Component, OnInit } from '@angular/core';

import { SinteseProposta, AlterSinteseProposta } from '../../../core/model';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AlterSintesePropostaFiltro, AlterSintesePropostaService } from '../../../core/services/alteracao-plano/alterSinteseProposta.service';
import { AuthService } from '../../../seguranca/auth.service';
import { PlanoTrabalhoHasProponenteService } from '../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-avaliar-sintese-proposta',
  templateUrl: './avaliar-sintese-proposta.component.html',
})
export class AvaliarSintesePropostaComponent implements OnInit {

  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  sinteseProposta = new SinteseProposta();
  sintesePropostaAlterada = new SinteseProposta();
  atualizar: any;

  loading
  alterSinteseProposta = new AlterSinteseProposta()

  constructor(
    private pthpService: PlanoTrabalhoHasProponenteService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private alterSintesePropostaService: AlterSintesePropostaService,
    private auth: AuthService
  ) { }

  async ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.sinteseProposta = this.planoTrabalhoHasProponente.sinteseProposta[0];
    await this.pthpBuscar()
    this.procuraAlteracaoSintese()

  }

  pthpBuscar() {
    this.pthpService.buscarPorCodigo(this.planoTrabalhoHasProponente.id)
      .then(retorno => {
        this.planoTrabalhoHasProponente = retorno
        this.sinteseProposta = this.planoTrabalhoHasProponente.sinteseProposta[0];
        sessionStorage.setItem('pthpModuloAjuste', JSON.stringify(retorno))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // ==== PROCURAR SINTESE PROPOSTA ==== //
  procuraAlteracaoSintese() {
    let filtro = new AlterSintesePropostaFiltro
    filtro.versaoPlano = this.versaoPlano.id

    this.alterSintesePropostaService.pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado[0] != null) {
          this.alterSinteseProposta = retorno.dado[0]
          this.verificaAlteracao()
        }else{
          this.atualizar = 1
          this.loading = true
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  verificaAlteracao() {
    if (this.alterSinteseProposta.statusAlteracao == 'Submetido') {
      this.sintesePropostaAlterada.tituloProposta = this.alterSinteseProposta.tituloPropostaDepois
      this.sintesePropostaAlterada.identificacaoObjeto = this.alterSinteseProposta.identificacaoObjetoDepois
      this.sintesePropostaAlterada.objetivoGeralProposta = this.alterSinteseProposta.objetivoGeralPropostaDepois
      this.sintesePropostaAlterada.objetivoEspecificoProposta = this.alterSinteseProposta.objetivoEspecificoPropostaDepois
      this.sintesePropostaAlterada.justificativaProposta = this.alterSinteseProposta.justificativaPropostaDepois
      this.sintesePropostaAlterada.abrangenciaProposta = this.alterSinteseProposta.abrangenciaPropostaDepois
      this.sintesePropostaAlterada.publicoBeneficiario = this.alterSinteseProposta.publicoBeneficiarioDepois
      this.sintesePropostaAlterada.perfilPublicoBeneficiario = this.alterSinteseProposta.perfilPublicoBeneficiarioDepois
      this.sintesePropostaAlterada.metaAtendimento = this.alterSinteseProposta.metaAtendimentoDepois
      this.sintesePropostaAlterada.dataIniExecucao = this.alterSinteseProposta.dataIniExecucaoDepois
      this.sintesePropostaAlterada.dataFimExecucao = this.alterSinteseProposta.dataFimExecucaoDepois
      this.sintesePropostaAlterada.metodologiaAbordagemProposta = this.alterSinteseProposta.metodologiaAbordagemPropostaDepois
      this.atualizar = 0
    } else {
      this.atualizar = 2
    }
    this.loading = true
  }

  //AVALIAR
  finalizarAvaliacao(){
    if(this.alterSinteseProposta.statusAlteracao != 'Submetido'){
      this.loading = false
      
      this.alterSinteseProposta.usuarioAvaliou = this.auth.jwtPayload.id
      this.alterSinteseProposta.dataAvaliacao = new Date().getTime()

      this.alterSinteseProposta.tituloPropostaAntes = this.sinteseProposta.tituloProposta
      this.alterSinteseProposta.identificacaoObjetoAntes = this.sinteseProposta.identificacaoObjeto
      this.alterSinteseProposta.objetivoGeralPropostaAntes = this.sinteseProposta.objetivoGeralProposta
      this.alterSinteseProposta.objetivoEspecificoPropostaAntes = this.sinteseProposta.objetivoEspecificoProposta
      this.alterSinteseProposta.justificativaPropostaAntes = this.sinteseProposta.justificativaProposta
      this.alterSinteseProposta.abrangenciaPropostaAntes = this.sinteseProposta.abrangenciaProposta
      this.alterSinteseProposta.publicoBeneficiarioAntes = this.sinteseProposta.publicoBeneficiario
      this.alterSinteseProposta.perfilPublicoBeneficiarioAntes = this.sinteseProposta.perfilPublicoBeneficiario
      this.alterSinteseProposta.metaAtendimentoAntes = this.sinteseProposta.metaAtendimento
      this.alterSinteseProposta.dataIniExecucaoAntes = this.sinteseProposta.dataIniExecucao
      this.alterSinteseProposta.dataFimExecucaoAntes = this.sinteseProposta.dataFimExecucao
      this.alterSinteseProposta.metodologiaAbordagemPropostaAntes = this.sinteseProposta.metodologiaAbordagemProposta

      this.alterSintesePropostaService.avaliar(this.alterSinteseProposta)
      .then(retorno => {
        this.pthpBuscar()
        this.alterSinteseProposta = retorno
        this.verificaAprovada()
        this.atualizar = 2
        this.toasty.success("Avaliação realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
    
    }else{
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
    }
  }
  verificaAprovada(){
    if(this.alterSinteseProposta.statusAlteracao == 'Aprovado'){
      this.sinteseProposta.tituloProposta = this.alterSinteseProposta.tituloPropostaDepois
      this.sinteseProposta.identificacaoObjeto = this.alterSinteseProposta.identificacaoObjetoDepois
      this.sinteseProposta.objetivoGeralProposta = this.alterSinteseProposta.objetivoGeralPropostaDepois
      this.sinteseProposta.objetivoEspecificoProposta = this.alterSinteseProposta.objetivoEspecificoPropostaDepois
      this.sinteseProposta.justificativaProposta = this.alterSinteseProposta.justificativaPropostaDepois
      this.sinteseProposta.abrangenciaProposta = this.alterSinteseProposta.abrangenciaPropostaDepois
      this.sinteseProposta.publicoBeneficiario = this.alterSinteseProposta.publicoBeneficiarioDepois
      this.sinteseProposta.perfilPublicoBeneficiario = this.alterSinteseProposta.perfilPublicoBeneficiarioDepois
      this.sinteseProposta.metaAtendimento = this.alterSinteseProposta.metaAtendimentoDepois
      this.sinteseProposta.dataIniExecucao = this.alterSinteseProposta.dataIniExecucaoDepois
      this.sinteseProposta.dataFimExecucao = this.alterSinteseProposta.dataFimExecucaoDepois
      this.sinteseProposta.metodologiaAbordagemProposta = this.alterSinteseProposta.metodologiaAbordagemPropostaDepois
    }else if(this.alterSinteseProposta.statusAlteracao == 'Reprovado'){
      this.sinteseProposta.tituloProposta = this.alterSinteseProposta.tituloPropostaAntes
      this.sinteseProposta.identificacaoObjeto = this.alterSinteseProposta.identificacaoObjetoAntes
      this.sinteseProposta.objetivoGeralProposta = this.alterSinteseProposta.objetivoGeralPropostaAntes
      this.sinteseProposta.objetivoEspecificoProposta = this.alterSinteseProposta.objetivoEspecificoPropostaAntes
      this.sinteseProposta.justificativaProposta = this.alterSinteseProposta.justificativaPropostaAntes
      this.sinteseProposta.abrangenciaProposta = this.alterSinteseProposta.abrangenciaPropostaAntes
      this.sinteseProposta.publicoBeneficiario = this.alterSinteseProposta.publicoBeneficiarioAntes
      this.sinteseProposta.perfilPublicoBeneficiario = this.alterSinteseProposta.perfilPublicoBeneficiarioAntes
      this.sinteseProposta.metaAtendimento = this.alterSinteseProposta.metaAtendimentoAntes
      this.sinteseProposta.dataIniExecucao = this.alterSinteseProposta.dataIniExecucaoAntes
      this.sinteseProposta.dataFimExecucao = this.alterSinteseProposta.dataFimExecucaoAntes
      this.sinteseProposta.metodologiaAbordagemProposta = this.alterSinteseProposta.metodologiaAbordagemPropostaAntes
    }
    this.loading = true
  }

  //ALTERAR AV
  alterarAvaliacao(){
    this.loading = false

    this.alterSintesePropostaService.alterarAvaliacao(this.alterSinteseProposta)
    .then(retorno => {
      this.pthpBuscar()
      this.alterSinteseProposta = retorno
      this.verificaAlteracao()
      this.toasty.success("Modifique sua avaliação!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  //UTILS
  aprovado() {
    this.alterSinteseProposta.statusAlteracao = 0 //aprovado
  }
  reprovado() {
    this.alterSinteseProposta.statusAlteracao = 1 //reprovado
  }

}
