import { Injectable } from "@angular/core";
import { Http, URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";
import "rxjs/add/operator/toPromise";
import { environment } from "../../environments/environment";
import { Usuario } from "../core/model";

export class UsuarioFiltro {
  nome: string;
  entidadeConcedente: string;
  entidadeProponente: string;
  cpf: string;
  email: string;
  perfil: string;
  nomePerfil;
  idOsHasCurso: any;

  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class UsuarioService {
  url: string;
  vari: any;

  constructor(private http: AuthHttp, private httpPub: Http) {
    this.url = `${environment.apiUrl}/usuarios`;
  }

  pesquisar(filtro: UsuarioFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }
    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }
    if (filtro.entidadeConcedente) {
      params.set("entidadeConcedente", filtro.entidadeConcedente);
    }
    if (filtro.cpf) {
      params.set("cpf", filtro.cpf);
    }
    if (filtro.email) {
      params.set("email", filtro.email);
    }
    if (filtro.perfil) {
      params.set("perfil", filtro.perfil);
    }
    if (filtro.nomePerfil) {
      params.set("nomePerfil", filtro.nomePerfil);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then((response) => {
        const responseJson = response.json();
        return responseJson;
      });
  }

  pesquisaMatriculaPresencial(filtro: UsuarioFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.cpf) {
      params.set("cpf", filtro.cpf);
    }
    if (filtro.idOsHasCurso) {
      params.set("idOsHasCurso", filtro.idOsHasCurso);
    }

    return this.httpPub
      .get(`${this.url}/pesquisa-matricula-presencial`, { search: params })
      .toPromise()
      .then((response) => {
        return response.json();
      });
  }

  listarTodosConcedente(idConcedente): Promise<any> {
    const params = new URLSearchParams();
    params.set("entidadeConcedente", idConcedente);
    params.set("page", "0");
    params.set("size", "50");

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then((response) => response.json().content);
  }

  listarTodosProponente(idProponente): Promise<any> {
    const params = new URLSearchParams();
    params.set("entidadeProponente", idProponente);
    params.set("page", "0");
    params.set("size", "50");

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then((response) => response.json().content);
  }

  listarChefeConcedente(): Promise<any> {
    const params = new URLSearchParams();
    params.set("perfil", "3");

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then((response) => response.json().content);
  }

  listarChefeOsc(): Promise<any> {
    const params = new URLSearchParams();
    params.set("perfil", "4");

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then((response) => response.json().content);
  }

  listarTodos(): Promise<any> {
    return this.http
      .get(`${this.url}/todos`)
      .toPromise()
      .then((response) => response.json());
  }

  countUsuario(): Promise<any> {
    return this.http
      .get(`${this.url}/count`)
      .toPromise()
      .then((response) => response.json());
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  mudarStatus(codigo: number, ativo: boolean): Promise<void> {
    return this.http
      .put(`${this.url}/${codigo}/ativo`, ativo)
      .toPromise()
      .then(() => null);
  }

  adicionar(usuario: Usuario): Promise<Usuario> {
    return this.http
      .post(this.url, JSON.stringify(usuario))
      .toPromise()
      .then((response) => response.json());
  }

  atualizar(pessoa: Usuario): Promise<any> {
    return this.http
      .put(`${this.url}/${pessoa.id}`, JSON.stringify(pessoa))
      .toPromise()
      .then((response) => true);
  }

  resetarSenha(pessoa: Usuario): Promise<Usuario> {
    return this.http
      .put(`${this.url}/resetar-senha/${pessoa.id}`, JSON.stringify(pessoa))
      .toPromise()
      .then((response) => response.json() as Usuario);
  }

  atualizarSenha(pessoa: Usuario): Promise<any> {
    return this.http
      .put(`${this.url}/atualizar-senha/${pessoa.id}`, JSON.stringify(pessoa))
      .toPromise()
      .then((response) => response.status);
  }

  buscarPorCodigo(codigo: number): Promise<Usuario> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then((response) => response.json() as Usuario);
  }

  atualizarUsuarioConcedente(
    pessoa: Usuario,
    idUsuario,
    entidade
  ): Promise<Usuario> {
    return this.http
      .put(
        `${this.url}/atualizar-concedente/${idUsuario}/${entidade}`,
        JSON.stringify(pessoa)
      )
      .toPromise()
      .then((response) => response.json() as Usuario);
  }
  atualizarUsuarioProponente(
    pessoa: Usuario,
    idUsuario,
    entidade
  ): Promise<Usuario> {
    return this.http
      .put(
        `${this.url}/atualizar-proponente/${idUsuario}/${entidade}`,
        JSON.stringify(pessoa)
      )
      .toPromise()
      .then((response) => response.json() as Usuario);
  }
}
