import { Injectable } from "@angular/core";
import { URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";
import "rxjs/add/operator/toPromise";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../seguranca/auth.service";
import { PerfilHasPerfil } from "../../model";

export class PerfilHasPerfilFiltro {
  id: string;
  perfilPrincipal:string;
  perfilPermitido:string;
  pagina = 0;
  itensPorPagina = 2000;
}

@Injectable()
export class PerfilHasPerfilService {
  _Url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this._Url = `${environment.apiUrl}/perfil-has-perfil`;
  }

  pesquisar(filtro: PerfilHasPerfilFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.id) {
      params.set("id", filtro.id);
    }
    if (filtro.perfilPrincipal) {
      params.set("perfilPrincipal", filtro.perfilPrincipal);
    }
    if (filtro.perfilPermitido) {
      params.set("perfilPermitido", filtro.perfilPermitido);
    }

    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dados = responseJson.content;

        const resultado = {
          dados,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();
    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this._Url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: PerfilHasPerfil): Promise<PerfilHasPerfil> {
    return this.http
      .post(this._Url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: PerfilHasPerfil): Promise<PerfilHasPerfil> {
    return this.http
      .put(`${this._Url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as PerfilHasPerfil);
  }

  buscarPorCodigo(codigo: number): Promise<PerfilHasPerfil> {
    return this.http
      .get(`${this._Url}/${codigo}`)
      .toPromise()
      .then(response => {
        const retorno = response.json() as PerfilHasPerfil;
        return retorno;
      });
  }
}
