import { CronogramaFinanceiroCadastroComponent } from './cronograma-financeiro-cadastro/cronograma-financeiro-cadastro.component';
import { CronogramaFinanceiroPesquisaComponent } from './cronograma-financeiro-pesquisa/cronograma-financeiro-pesquisa.component';

import { SharedsModule } from "../shareds/shareds.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { providers } from 'ng2-toasty';
import { CronogramaFinanceiroService } from './cronograma-financeiro.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    
    SharedsModule,
  ],
  declarations: [CronogramaFinanceiroCadastroComponent, CronogramaFinanceiroPesquisaComponent],
  providers:[
    CronogramaFinanceiroService
  ]
})
export class CronogramaFinanceiroModule { }
