import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GototopPipe } from './gototop.pipe';
import { GenerateParamPipe } from './generate-param.pipe';
import { SafePipe } from './safePipe.pipe';
import { ConvertGuidelinesPipe } from './convert-guidelines.pipe';
import { ResizeTextAreaPipe } from './resizeTextArea.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ SafePipe, GototopPipe, GenerateParamPipe, ConvertGuidelinesPipe, ResizeTextAreaPipe ],
  exports: [ SafePipe, GototopPipe, GenerateParamPipe, ConvertGuidelinesPipe, ResizeTextAreaPipe  ]
})
export class MyPipesModule { }
