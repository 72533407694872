import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { ConcedenteService } from '../core/services/concedente/concedente.service';
import { GetAdress } from '../core/services/utils/get-address.service';
import { SharedsModule } from '../shareds/shareds.module';
import { UsuarioService } from '../usuario/usuario.service';
import { PaginationModule } from './../core/pagination/pagination.module';
import { ComissaoAcompanhamentoModule } from './comissao-acompanhamento/comissaoAcompanhamento.module';
import { ComissaoSelecaoModule } from './comissao-selecao/comissaoSelecao.module';
import { ConcedenteCadastroComponent } from './concedente-cadastro-editar/concedente-cadastro.component';
import { ConcedenteGerenciarComponent } from './concedente-gerenciar/concedente-gerenciar.component';
import { ConcedenteListarComponent } from './concedente-listar/concedente-listar.component';
import { ConcedenteViewComponent } from './concedente-view/concedente-view.component';
import { ConcedenteRoutingModule } from './concedente.routing.module';
import { NovaEntidadeHasUsuarioComponent } from './nova-entidade-has-usuario/nova-entidade-has-usuario.component';
import { SetorModule } from '../setor/setor.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    NgxMaskModule,
    PaginationModule,

    ImageCropperModule,

    SharedsModule,
    ConcedenteRoutingModule,
    ComissaoSelecaoModule,
    ComissaoAcompanhamentoModule,
    SetorModule
  ],
  declarations: [
    ConcedenteGerenciarComponent,
    ConcedenteCadastroComponent,
    ConcedenteListarComponent,
    ConcedenteViewComponent,
    NovaEntidadeHasUsuarioComponent
  ],
  providers: [
    UsuarioService,
    GetAdress,
    ConcedenteService
  ],
})
export class ConcedenteModule {}
