import { Location } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { ToastyService } from "ng2-toasty";
import { ConfirmationService } from "primeng/components/common/api";
import { Subscription } from "rxjs/Subscription";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { ComissaoSelecao, ComissaoSelecaoHasUsuarioConcedente, Usuario } from "../../../core/model";
import { UsuarioFiltro, UsuarioService } from "../../../usuario/usuario.service";
import { ComissaoSelecaoService } from "../comissaoSelecao.service";
import { ComissaoSelecaoHasUsuarioConcedenteFiltro, ComissaoSelecaoHasUsuarioConcedenteService } from "../comissaoSelecaoHasUsuarioConcedente.service";
import { AuthService } from './../../../seguranca/auth.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-comissao-selecao-vincular",
  templateUrl: "./comissao-selecao-vincular.component.html"
})
export class ComissaoSelecaoVincularComponent implements OnInit {
  usuariosLista = [];
  comissaoSelecao = new ComissaoSelecao();
  entidades = [];
  existeEfetivo: boolean;

  etapa1;
  etapa2;
  etapa3;

  cshsc = new ComissaoSelecaoHasUsuarioConcedente();

  viewQualificacao;
  qualificacao

  MANY_ITEMS = "MANY_ITEMS";
  many1 = [];
  many2 = [];
  many3 = [];
  many4 = [];

  subs = new Subscription();

  //pesquisar pessoas
  totalRegistros = 0;
  filtroUsuario = new UsuarioFiltro();
  filtroUsuarioCpf = new UsuarioFiltro();
  usuarioCpf = new Usuario()
  pessoas = [];

  pt: any;
  listUsuariosVinculados = [];

  constructor(
    private comissaoSelecaoHasUsuarioConcedenteService: ComissaoSelecaoHasUsuarioConcedenteService,
    private comissaoSelecaoService: ComissaoSelecaoService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private usuarioService: UsuarioService,
    private confirmation: ConfirmationService,
    private dragulaService: DragulaService,
    public auth: AuthService,
    private location: Location
  ) {
    this.dragAndDrop();
  }

  async ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Gerenciar comissao de seleção");

    this.comissaoSelecao = JSON.parse(sessionStorage.getItem('comissaoCompPesquisa'))

    await this.carregarUsuariosConcedentes();
    this.carregarJaVinculados();
  }

  goBack() {
    this.location.back();
  }

  carregarUsuariosConcedentes() {
    return this.usuarioService
      .listarTodosConcedente(this.comissaoSelecao.entidadeConcedente.id)
      .then(usuarios => {
        this.usuariosLista = usuarios;
        // //console.log(this.usuariosLista)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  /******JÁ VINCULADOS */
  carregarJaVinculados() {
    let filtro = new ComissaoSelecaoHasUsuarioConcedenteFiltro();
    filtro.comissaoSelecao = this.comissaoSelecao.id;
    this.comissaoSelecaoHasUsuarioConcedenteService
      .pesquisar(filtro)
      .then(resultado => {
        this.listUsuariosVinculados = resultado.retorno;
        this.verfificaVinculadosENaoVinculados();
        this.verificaExisteEfetivo(resultado.retorno);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verfificaVinculadosENaoVinculados() {

    this.many1 = this.usuariosLista
    this.many2 = []
    this.many3 = []
    this.listUsuariosVinculados.forEach(vinculado => {

      this.usuariosLista.forEach(usuario => {
        if (usuario.id == vinculado.usuario.id) {
          let i = this.many1.indexOf(usuario)
          this.many1.splice(i, 1)

        }
      });

      if (vinculado.funcao == "Chefe") {
        this.many2.push(vinculado.usuario);
      }
      if (vinculado.funcao == "DemaisMembros") {
        this.many3.push(vinculado.usuario);
      }

    });

  }
  verificaExisteEfetivo(listaVinculados) {
    this.existeEfetivo = false;
    listaVinculados.forEach(item => {
      if (item.usuario.efetivo == true) {
        this.existeEfetivo = true;
      }
    });
  }

  /*****VINCULAR USUÁRIOS */
  vincularUsuario() {
    this.viewQualificacao = false
    this.cshsc.qualificacao = this.qualificacao

    if (this.cshsc.qualificacao == null || this.cshsc.qualificacao == "") {
      this.removeUsuarioArray()
      return this.toasty.warning("Informe a qualificacao do usuário!");
    }
    if (this.cshsc.funcao == null) {
      return this.toasty.warning("Informe a função do usuário!");
    }

    //verifica se o usuário já foi vinculado
    let filtro = new ComissaoSelecaoHasUsuarioConcedenteFiltro();
    filtro.comissaoSelecao = this.comissaoSelecao.id;
    filtro.usuario = this.cshsc.usuario.id;
    this.comissaoSelecaoHasUsuarioConcedenteService
      .pesquisar(filtro)
      .then(resultado => {
        if (resultado.retorno.length != 0) {
          this.toasty.error("Usuário já vinculado!");
        } else {
          this.adicionaUsuarioComissao();
        }
      })
      .catch(erro => this.errorHandler.handle(erro));

  }

  removeUsuarioArray() {
    let i
    if (this.cshsc.funcao == 3) {
      this.many3.forEach(item => {
        if (item.id == this.cshsc.usuario.id) {
          i = this.many3.indexOf(item)
        }
      });
      this.many3.splice(i, 1)
    } else if (this.cshsc.funcao == 2) {
      this.many2.forEach(item => {
        if (item.id == this.cshsc.usuario.id) {
          i = this.many2.indexOf(item)
        }
      });
      this.many2.splice(i, 1)
    }
  }

  adicionaUsuarioComissao() {
    this.cshsc.comissaoSelecao = this.comissaoSelecao;
    this.comissaoSelecaoHasUsuarioConcedenteService
      .adicionar(this.cshsc)
      .then(retorno => {
        this.cshsc = new ComissaoSelecaoHasUsuarioConcedente();
        this.toasty.success("Usuário vinculado com sucesso!");
        this.carregarJaVinculados();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarUsuarioComissao(entidade: ComissaoSelecaoHasUsuarioConcedente) {

    if (entidade.qualificacao == null || entidade.qualificacao == "") {
      return this.toasty.warning("Informe a qualificacao do usuário!");
    }

    //entidade.comissaoSelecao = this.comissaoSelecao;
    this.comissaoSelecaoHasUsuarioConcedenteService
      .atualizar(entidade)
      .then(retorno => {
        this.cshsc = new ComissaoSelecaoHasUsuarioConcedente();
        this.toasty.success("Usuário alterado com sucesso!");
        this.carregarJaVinculados();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  confirmaExclusao(entidade: ComissaoSelecaoHasUsuarioConcedente) {
    this.confirmation.confirm({
      message: `
        <br>Tem certeza que deseja desvincular o usuário:<br>
        <b>${entidade.usuario.nome}</b><br> 
        da comissão: <br><b>${entidade.comissaoSelecao.nome}</b>
      `,
      accept: () => {
        this.excluirVinculo(entidade);
      },
      reject: () => {
        this.carregarJaVinculados();
      }
    });
  }

  excluirVinculo(entidade) {
    this.comissaoSelecaoHasUsuarioConcedenteService
      .excluir(entidade.id)
      .then(async () => {
        this.toasty.success("Usuário desvinculado com sucesso!");
        await this.carregarUsuariosConcedentes();
        this.carregarJaVinculados();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  dragAndDrop() {
    this.subs.add(
      this.dragulaService
        .dropModel(this.MANY_ITEMS)
        .subscribe(({ el, target, source, sourceModel, targetModel, item }) => {
          this.qualificacao = null

          this.cshsc.usuario.id = item.id
          if (target.id == "many2")
            this.cshsc.funcao = 2
          if (target.id == "many3")
            this.cshsc.funcao = 3

          if (source.id == "many2" || source.id == "many3") {
            this.listUsuariosVinculados.forEach(element => {
              if (element.usuario.id == item.id) {

                if (target.id == "many2") {
                  element.funcao = 2
                  return this.atualizarUsuarioComissao(element)
                }
                if (target.id == "many3") {
                  element.funcao = 3
                  return this.atualizarUsuarioComissao(element)
                }

              }
            });
          }

          // === Verifica se está removendo dos membros da comissao
          if (target.id == "many1") {
            this.listUsuariosVinculados.forEach(element => {
              if (element.usuario.id == item.id) {
                return this.confirmaExclusao(element)
              }
            });
          } else {
            // === Verifica se o usuario tem qualificacao
            if (item.qualificacao == null || item.qualificacao == "") {

              if (source.id == "many2")
                return this.viewQualificacao = false;
              if (source.id == "many3")
                return this.viewQualificacao = false;

              this.viewQualificacao = true;
            } else {
              this.vincularUsuario();
            }
          }


        })
    );

  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  //=== Usuário CPF ===//
  pesquisarUsuarioCpf() {
    if (this.filtroUsuarioCpf.cpf) {
      let i = this.filtroUsuarioCpf.cpf.toString().length
      if (i != 11) {
        this.toasty.info("CPF deve conter 11 caracteres!")
      } else {

        this.usuarioService.pesquisar(this.filtroUsuarioCpf)
          .then(usuario => {
            if (usuario.content.length == 0) {
              this.toasty.info("Usuário não encontrado!")
            }
            this.many4 = usuario.content
          })
          .catch(erro => this.errorHandler.handle(erro));
      }
    } else {
      this.toasty.info("Preencha o campo de pesquisa!")
    }
  }

  /*****ATUALIZAR COMISSÃO */
  atualizarComissao() {
    this.comissaoSelecaoService
      .atualizar(this.comissaoSelecao)
      .then(comissaoSelecao => {
        this.comissaoSelecao = comissaoSelecao;
        this.toasty.success("Comissão de seleção alterada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
  }

  verificaFuncao(funcao) {
    if (funcao == "Tecnico") {
      funcao = "Analista Técnico";
    } else if (funcao == "Juridico") {
      funcao = "Analista Jurídico";
    } else if (funcao == "Chefe") {
      funcao = "Chefe da Comissão";
    } else if (funcao == "DemaisMembros") {
      funcao = "Demais membros";
    }
    return funcao;
  }

  finalizar() {
    this.toasty.success("Cadastro finalizado com sucesso!");
    this.router.navigate(["/cadastros/concedente/comissao-selecao"]);
  }

}
