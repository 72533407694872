import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { TutPerfilHasTopico } from '../../model/tutoriais.model';

export class TutPerfilHasTopicoFiltro {
    perfil;
    tutTopico;

    pagina = 0;
    itensPorPagina = 20;
}

@Injectable()
export class TutPerfilHasTopicoService {

    url: string;

    constructor(private http: AuthHttp,
        public auth: AuthService,) {
        this.url = `${environment.apiUrl}/tut-perfil-has-topico`;
    }

    pesquisar(filtro: TutPerfilHasTopicoFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set('page', filtro.pagina.toString());
        params.set('size', filtro.itensPorPagina.toString());

        if (filtro.perfil) {
            params.set('perfil', filtro.perfil);
        }

        if (filtro.tutTopico) {
            params.set('tutTopico', filtro.tutTopico);
        }

        return this.http.get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => {
                return response.json();
            })
    }

    listarTodos(): Promise<any> {
        return this.http.get(this.url)
            .toPromise()
            .then(response => response.json().content);
    }

    excluir(codigo: number): Promise<void> {
        return this.http.delete(`${this.url}/${codigo}`)
            .toPromise()
            .then(() => null);
    }

    adicionar(entidade: TutPerfilHasTopico): Promise<TutPerfilHasTopico> {
        return this.http.post(this.url, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    atualizar(entidade: TutPerfilHasTopico): Promise<TutPerfilHasTopico> {

        return this.http.put(`${this.url}/${entidade.id}`,
            JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json() as TutPerfilHasTopico);
    }

    buscarPorCodigo(codigo: number): Promise<TutPerfilHasTopico> {
        return this.http.get(`${this.url}/${codigo}`)
            .toPromise()
            .then(response => {
                const entidade = response.json() as TutPerfilHasTopico;
                return entidade;
            });
    }

}
