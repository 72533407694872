import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { HttpEvent } from "@angular/common/http";
import { environment } from "../../environments/environment";

// import SignaturePad from 'signature_pad';

type SignatureType = "draw" | "font" | "import";

declare var AdminLTE: any;

@Component({
  selector: "app-assinador-documentos",
  templateUrl: "./assinador-documentos.component.html",
})
export class AssinadorDocumentosComponent implements OnInit {
  hashDocument: string;
  urlDocumentSignatureApplication: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private toasty: ToastyService
  ) {
    activatedRoute.queryParams.subscribe((params) => {
      this.hashDocument = params["hash_file"] || undefined;
    });
  }
  
  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    if (this.hashDocument) {
      this.urlDocumentSignatureApplication = `${environment.documentSignatureApplicationURL}/?hash_file=${this.hashDocument}`
    } else {
      this.urlDocumentSignatureApplication = environment.documentSignatureApplicationURL
    }
  }

}
