import { Injectable } from "@angular/core";
import { Headers, Http, URLSearchParams } from "@angular/http";
import { ValidacaoCertidaoAreaPublica } from '../../model';
import { environment } from './../../../../environments/environment';

@Injectable()
export class ValidacaoDocumentos {
  constructor(private http: Http) {}

  getCertidaoHabilitacao(hash: any): Promise<ValidacaoCertidaoAreaPublica> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(`${environment.apiUrl}/validacao-certidao`, hash, { headers })
      .toPromise()
      .then(response => {
        return response.json();
      });
  }

  getResponderRoteiro(hash: any): Promise<any> {
    const params = new URLSearchParams();
    params.set('hash', hash);

    return this.http.get(`${environment.apiUrl}/responder-roteiro`, { search: params})
    .toPromise()
    .then(response => {
      return response.json();
    })

  }

}
