import { environment } from "../../../environments/environment";

import { ComissaoSelecao } from "../../core/model";

import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";
import { AuthService } from "../../seguranca/auth.service";

export class ComissaoSelecaoFiltro {
  nome: string;
  entidadeConcedente: string;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class ComissaoSelecaoService {
  comissaoSelecaoUrl: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.comissaoSelecaoUrl = `${environment.apiUrl}/comissao-selecao`;
  }

  pesquisar(filtro: ComissaoSelecaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }
    if (filtro.entidadeConcedente) {
      params.set("entidadeConcedente", filtro.entidadeConcedente);
    }

    return this.http
      .get(`${this.comissaoSelecaoUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        /*const comissoes = responseJson.content;

        const resultado = {
          comissoes,
          total: responseJson.totalElements
        };*/
        return responseJson;
      });
  }

  listarTodos(): Promise<any> {
    return this.http
      .get(this.comissaoSelecaoUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  listarComissaoDaEntidade(id: any): Promise<any> {
    const params = new URLSearchParams();
    params.set("entidadeConcedente", id);

    return this.http
      .get(`${this.comissaoSelecaoUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.comissaoSelecaoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(comissaoSelecao: ComissaoSelecao): Promise<ComissaoSelecao> {
    return this.http
      .post(this.comissaoSelecaoUrl, JSON.stringify(comissaoSelecao))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(comissaoSelecao: ComissaoSelecao): Promise<ComissaoSelecao> {
    return this.http
      .put(
        `${this.comissaoSelecaoUrl}/${comissaoSelecao.id}`,
        JSON.stringify(comissaoSelecao)
      )
      .toPromise()
      .then(response => response.json() as ComissaoSelecao);
  }

  buscarPorCodigo(id: number): Promise<ComissaoSelecao> {
    return this.http
      .get(`${this.comissaoSelecaoUrl}/${id}`)
      .toPromise()
      .then(response => {
        const comissaoSelecao = response.json() as ComissaoSelecao;

        return comissaoSelecao;
      });
  }
}
