import { GenerateParamPipe } from '../../../core/my-pipes/generate-param.pipe';
import { AuthService } from '../../../seguranca/auth.service';
import { environment } from '../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty/src/toasty.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { Page } from '../../../core/pagination/pagination.model';
import { AtendidosProjetoService } from '../../../core/services/atendidos/atendidosProjeto.service';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { AtendidosProjeto, CelebracaoHasAtendidosProjeto, Usuario } from '../../../core/model';
import { CelebracaoHasAtendidosProjetoFiltro, CelebracaoHasAtendidosProjetoService } from '../../../core/services/atendidos/celebracaoHasAtendidosProjeto.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-beneficiarios-listar',
  templateUrl: './beneficiarios-listar.component.html'
})
export class BeneficiariosListarComponent implements OnInit {

  beneficiarioFiltro = new CelebracaoHasAtendidosProjetoFiltro();
  beneficiario = new AtendidosProjeto();
  beneficiarios = []
  loading = true
  modalBeneficiario
  situacaoSelecionada
  modalAgenda: boolean
  beneficiarioAgenda = new AtendidosProjeto()

  status = [
    { label: 'Por status', value: '' },
    { label: 'Ativo', value: '1' },
    { label: 'Inativo', value: '0' },
    { label: 'Todos', value: '' }
  ];

  constructor(
    private beneficiariosService: CelebracaoHasAtendidosProjetoService,
    private atendidoService: AtendidosProjetoService,
    private confirmation: ConfirmationService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private title: Title,
    private router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.title.setTitle('Listar');
    this.pesquisar();
    
  }

  page: Page;
  changePage(event) {
    this.loading = false
    this.beneficiarioFiltro.itensPorPagina = event.size
    this.beneficiarioFiltro.pagina = event.page
    this.beneficiariosService.pesquisar(this.beneficiarioFiltro).then(res => {
      this.page = res
      this.beneficiarios = res.content;
      this.loading = true
    });
  }

  pesquisar() {
    // this.loading = false
    this.beneficiariosService.pesquisar(this.beneficiarioFiltro).then(resultado => {
      this.beneficiarios = resultado.content;
      this.page = resultado
      this.loading = true
    })
  }

  //========= MODAL AGENDA =====
 openModalAngenda(idBeneficiario){
    this.modalAgenda = true 
    this.buscarBeneficiario(idBeneficiario)   
  }

  buscarBeneficiario(idBeneficiario){
    this.atendidoService.buscarPorCodigo(idBeneficiario).then(beneficiario => {
      this.beneficiarioAgenda = beneficiario
    })
  }
  //=========\. MODAL AGENDA =====

  openModalDadosBeneficiario(idBeneficiario) {
    this.atendidoService.buscarPorCodigo(idBeneficiario).then(beneficiario => {
      this.beneficiario = beneficiario
    })
    this.modalBeneficiario = true
  }


  confirmarExclusao(areaDeAtuacao: any) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluir(areaDeAtuacao);
      }
    });
  }

  excluir(entidade: any) {
    this.beneficiariosService
      .excluir(entidade.id)
      .then(() => {
        this.pesquisar();
        this.toasty.success("Recurso excluído com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaEscolaridade(escolaridade) {
    if (escolaridade == "Fundamental_incompleto ") {
      return 'Ensino Fundamental Incompleto'
    } else if (escolaridade == "Fundamental_completo") {
      return 'Ensino Fundamental Completo'
    } else if (escolaridade == "Medio_incompleto") {
      return 'Ensino Médio Incompleto'
    } else if (escolaridade == "Medio_completo") {
      return 'Ensino Médio Completo'
    } else if (escolaridade == "Superior_incompleto") {
      return 'Ensino Superior Incompleto'
    } else if (escolaridade == "Superior_completo") {
      return 'Ensino Superior Completo'
    } else if (escolaridade == "Pos_incompleta") {
      return 'Pós Incompleta'
    } else if (escolaridade == "Pos_graduacao") {
      return 'Pós Graduado(a)'
    } else if (escolaridade == "Mestrado") {
      return 'Mestrado'
    } else if (escolaridade == "Doutorado") {
      return 'Doutorado'
    }
  }


  


  // ===== ROTINA DE UPLOAD AND DOWNLOAD ===== //
  urlDocumento
  viewDownload
  displayOpenModalView
  displayOpenModalDocumento
  displayOpenModalEditar
  infoItem
  flagAlterar

  openModalView(item) {
    this.infoItem = item;
    this.displayOpenModalView = true;
    this.flagAlterar = true;

    this.urlDocumento = environment.downloadFileUrl + "/" + item.hashFile;
    item.hashFile.includes(".pdf") ? this.viewDownload=true : this.viewDownload=false
  }

  download(){
    window.location.href = this.urlDocumento;
  }

  openModalUpload(item) {
    this.flagAlterar = false;
    this.infoItem = item;
    this.displayOpenModalDocumento = true;
  }

  openModalEditar(item) {
    this.flagAlterar = true;
    this.displayOpenModalEditar = true;
    this.infoItem = item;
    this.urlDocumento = environment.downloadFileUrl + "/" + item.hashFile;
    item.hashFile.includes(".pdf") ? this.viewDownload=true : this.viewDownload=false
  }

  adicionarArquivo(item: CelebracaoHasAtendidosProjeto) {
    let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
    sessionStorage.removeItem('upload');
    if (!fileItem || !fileItem.responseStatus)
      return this.toasty.error("É necessário efetivar o upload");
    
    item.arquivo = fileItem.response.data.originalname;
    item.hashFile = fileItem.response.data.filename;
    item.dataCadastroArquivo = new Date().getTime()

    let user = new Usuario();
    user.id = this.auth.jwtPayload.id;
    item.usuarioCadastroArquivo = user;

    this.beneficiariosService
      .adicionar(item)
      .then(() => {
        this.toasty.success(
          " Arquivo: " + fileItem.response.data.originalname + " enviado com sucesso!"
        );
        this.displayOpenModalDocumento = false;
        this.pesquisar()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  alterarArquivo(item: CelebracaoHasAtendidosProjeto) {
    let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
    sessionStorage.removeItem('upload');
    if (!fileItem || !fileItem.responseStatus)
      return this.toasty.error("É necessário efetivar o upload");

    item.arquivo = fileItem.response.data.originalname;
    item.hashFile = fileItem.response.data.filename;
    item.dataCadastroArquivo = new Date().getTime()

    let user = new Usuario();
    user.id = this.auth.jwtPayload.id;
    item.usuarioCadastroArquivo = user;

    this.beneficiariosService
      .atualizar(item)
      .then(() => {
        this.toasty.success(
          " Arquivo: " + fileItem.response.data.originalname + " alterado com sucesso!"
        );
        this.displayOpenModalEditar = false;
        this.displayOpenModalDocumento = false;
        this.pesquisar();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  alterarSatus(event, item) {
    let flag: boolean;
    if (event.target.checked) {
      flag = true;
    } else {
      flag = false;
    }
    item.status = flag;
    this.beneficiariosService
      .atualizar(item)
      .then(() => {
        this.toasty.success("Permissão alterada com sucesso!");
        this.pesquisar()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  gotoBeneficiario(param) {
    this.router.navigate(["/execucao/visualizar/beneficiarios/alterar", new GenerateParamPipe().generateHash()]);
    sessionStorage.setItem('beneficiario', JSON.stringify(param))
  }


}