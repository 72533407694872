import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxCaptchaModule } from "ngx-captcha";
import { NgxMaskModule } from "ngx-mask";
import { MyPipesModule } from '../core/my-pipes/my-pipes.module';
import { HabilitacaoService } from '../core/services/habilitacao/habilitacao.service';
import { RespostaQuestaoRequisitoHabilitacaoService } from '../core/services/habilitacao/resposta-questao-requisito-habilitacao.service';
import { ValidacaoDocumentos } from '../core/services/utils/validacao-certidao.service';
import { ProponenteService } from '../core/services/proponente/proponente.service';
import { UsuarioService } from '../usuario/usuario.service';
import { ResponderRoteiroService } from './../core/services/modelos-sistema/responderRoteiro.service';
import { RelatorioService } from './../core/services/relatorio/relatorio.service';
import { ConsultaCepimService } from './../core/services/utils/consulta-cepim.service';
import { GetAdress } from './../core/services/utils/get-address.service';
import { CertidaoComponent } from "./certidao/certidao.component";
import { ControleComponent } from './controle/controle.component';
import { NovaEntidadeComponent } from "./nova-entidade/nova-entidade.component";
import { OutrasOpcoesRoutingModule } from "./outras-opcoes.routing.module";
import { OutrasOpcoesComponent } from "./outras-opcoes/outras-opcoes.component";
import { ValidacaoComponent } from './validacao/validacao.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskModule,
    NgxCaptchaModule,
    HttpClientModule,
    MyPipesModule,

    OutrasOpcoesRoutingModule,
  ],
  declarations: [
    OutrasOpcoesComponent,
    NovaEntidadeComponent,
    ValidacaoComponent,
    CertidaoComponent,
    ControleComponent
  ],
  providers: [
    GetAdress,
    RelatorioService,
    ConsultaCepimService,
    HabilitacaoService,
    RespostaQuestaoRequisitoHabilitacaoService,
    ValidacaoDocumentos,
    UsuarioService,
    ProponenteService,
    ResponderRoteiroService,
  ]
})
export class OutrasOpcoesModule { }
