import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Http, RequestOptions } from "@angular/http";
import { RouterModule } from "@angular/router";
import { AuthConfig, AuthHttp } from "angular2-jwt";
import { NgxCaptchaModule } from "ngx-captcha";
import { AuthGuard } from "./auth.guard";
import { AuthService } from "./auth.service";
import { FilterHttp } from "./filter-http";
import { LoginFormComponent } from "./login-form/login-form.component";
import { LogoutService } from "./logout.service";

export function authHttpServiceFactory(
  auth: AuthService,
  http: Http,
  options: RequestOptions
) {
  const config = new AuthConfig({
    globalHeaders: [{ "Content-Type": "application/json" }]
  });

  return new FilterHttp(auth, config, http, options);
}

@NgModule({
  imports: [
    CommonModule, 
    FormsModule, 
    RouterModule, 
    NgxCaptchaModule
  ],
  declarations: [
    LoginFormComponent
  ],
  providers: [
    {
      provide: AuthHttp,
      useFactory: authHttpServiceFactory,
      deps: [AuthService, Http, RequestOptions]
    },
    AuthGuard,
    LogoutService
  ]
})
export class SegurancaModule {}
