import { environment } from "../../../environments/environment";

import { ComissaoAcompanhamento  } from "../../core/model";

import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";
import { AuthService } from "../../seguranca/auth.service";



export class ComissaoAcompanhamentoFiltro {
  nome: string;
  entidadeConcedente: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class ComissaoAcompanhamentoService {
  url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/comissao-acompanhamento-gestao`;
  }

  pesquisar(filtro: ComissaoAcompanhamentoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }
    if (filtro.entidadeConcedente) {
      params.set("entidadeConcedente", filtro.entidadeConcedente);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        return responseJson;
      });
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  listarComissaoDaEntidade(id: any): Promise<any> {
    const params = new URLSearchParams();
    params.set("entidadeConcedente", id);

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(id: number): Promise<void> {
    return this.http.delete(`${this.url}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: ComissaoAcompanhamento): Promise<ComissaoAcompanhamento> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: ComissaoAcompanhamento): Promise<ComissaoAcompanhamento> {
    return this.http.put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as ComissaoAcompanhamento);
  }

  buscarPorCodigo(id: number): Promise<ComissaoAcompanhamento> {
    return this.http.get(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        const retorno = response.json() as ComissaoAcompanhamento;
        return retorno;
      });
  }
}
