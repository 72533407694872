import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty/src/toasty.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { DispensaInexigibilidadeFiltro, DispensaInexigibilidadeService } from '../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service';
import { Page } from '../../../core/pagination/pagination.model';
import { AnexoPropostaFiltro } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-patrocinio-listar',
  templateUrl: './patrocinio-listar.component.html'
})
export class PatrocinioListarComponent implements OnInit {

 

  listaStatus = [
    { label: "Em elaboração", value: "0" },
    { label: "Aguardando análise", value: "1" },
    { label: "Aprovado", value: "2" },
    { label: "Reprovado", value: "3" },

        
  ];

 
  dispensaInegibilidadeFiltro = new DispensaInexigibilidadeFiltro()
  listaPatrocinios
  anexosFiltro = new AnexoPropostaFiltro();
  constructor(
   
    private confirmation: ConfirmationService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private title: Title,
    private dispensaInegibilidadeService: DispensaInexigibilidadeService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.title.setTitle('Listar');
   
  }
// === CONFIGURACOES DE PAGINACAO
page : Page;
changePage(event){
this.dispensaInegibilidadeFiltro.itensPorPagina = event.size
this.dispensaInegibilidadeFiltro.pagina = event.page
}

  pesquisarPropostaNome(){
    this.dispensaInegibilidadeFiltro.tipoProposta = '3'
      this.dispensaInegibilidadeService.pesquisar(this.dispensaInegibilidadeFiltro).then(resultado=>{
      this.page = resultado
      this.listaPatrocinios = resultado.content
    })
  } 
  

  exibirStatus(status){
    if(status == "Em_elaboracao"){
      status = "Em elaboração"
    }else if (status == "Aguardando_analise"){
      status = "Aguardando análise"
    }else if(status == "Aprovado"){
      status = "Aprovado"
    }else if(status == "Reprovado"){
      status = "Reprovado"
    }

    return(status)
  }

  confirmarExclusao(proposta: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(proposta);
      }
    });
  }
  
  excluir(proposta){
    this.dispensaInegibilidadeService.excluir(proposta.id).then(resp => {
      this.toasty.success("Proposta Excluida!")
      this.pesquisarPropostaNome()
    }) .catch(erro => this.errorHandler.handle(erro));
  }
  cor
  solicitarAnalise(){
    this.toasty.info("Solicitação realizada, aguarde a reposta!")
    
  }

  gotoEditar(param) {
    this.router.navigate(["/proposta/patrocinio/editar"]);
    sessionStorage.setItem('proposta', param)
  }

  gotoAvaliacao(param) {
    this.router.navigate(["/proposta/patrocinio/avaliacao"]);
    sessionStorage.setItem('proposta', param)
  }
  


}
