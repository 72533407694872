import { TipoAvaliacaoPlanoService, TipoAvaliacaoPlanoFiltro } from '../../core/services/avaliacao-plano/tipoAvaliacaoPlano.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AuthService } from '../../seguranca/auth.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-pendencia-listar',
  templateUrl: './pendencia-listar.component.html'
})
export class PendenciaListarComponent implements OnInit {
  
  constructor(
    private title: Title,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    public auth: AuthService,
    
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Lista de Pendencias');
   
  }


  
}
