import { EditalCredenciamentoService } from './../../../core/services/edital-credenciamento/EditalCredenciamento.service';
import { EixoProgramaService } from './../../../core/services/edital-credenciamento/EixoPrograma.service';
import { AnexoProposta, Usuario, AnexosEvidencias } from '../../../core/model';
import { ProponenteFiltro } from '../../../core/services/proponente/proponente.service';
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { Subject } from "rxjs";
import { ConcedenteFiltro, ConcedenteService } from '../../../core/services/concedente/concedente.service';
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { DispensaInexigibilidade, PlanoTrabalho } from "../../../core/model";
import { DispensaInexigibilidadeService } from "../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service";
import { ProponenteService } from "../../../core/services/proponente/proponente.service";
import { AuthService } from "../../../seguranca/auth.service";
import { EixoProgramaHasProponenteCredenciadoService } from "../../../core/services/eixo-programa-has-proponente-credenciado/eixo-programa-has-proponente-credenciado.service";
import { AnexoPropostaService, AnexoPropostaFiltro } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { environment } from '../../../../environments/environment';
import { elementAt } from 'rxjs/operator/elementAt';
import { ConfirmationService } from 'primeng/primeng';
import { PlanoTrabalhoService } from '../../../core/services/plano-trabalho/planoTrabalho.service';


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-contrato-editar",
  templateUrl: "./contrato-editar.component.html"
})

export class ContratoEditarComponent implements OnInit {
  
  entidadesConcedente: any;
  dispensaInexigibilidade = new DispensaInexigibilidade();
  planoTrabalho = new PlanoTrabalho();
  planosDaDispensa = []
  proponenteFiltro = new ProponenteFiltro()
  listaOscs
  anexoProposta = new AnexoProposta()
  concedenteFiltro = new ConcedenteFiltro()

  etapa1: boolean;
  etapa2: boolean;
  etapa3: boolean;
  etapa4: boolean;
  etapa5: boolean;
  

  
  tipoUpload
  dataUploadArray = []
  anexosSalvos = []
  
  displayEscondeInput
  openModalViewUpload 

  displayAnexo: boolean;
  urlDocumento
  viewDownload
  nomeAnexo
  anexoFiltro = new AnexoPropostaFiltro

  constructor(
    private eixoProgramaHasProponenteCredenciadoService: EixoProgramaHasProponenteCredenciadoService,
    private eixoProgramaService: EixoProgramaService,
    private entidadeProponenteService: ProponenteService,
    private editalCredenciamentoService: EditalCredenciamentoService,
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private dispensaInexigibilidadeService: DispensaInexigibilidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title, 
    private anexoPropostaService: AnexoPropostaService,
    private confirmation: ConfirmationService,
    private planoTrabalhoService: PlanoTrabalhoService,
    
  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();
   
    
  
    this.title.setTitle("Editar Proposta");
    const idProposta =  sessionStorage.getItem("proposta");
 
   

    this.carregarProposta(idProposta)
    this.carregarAnexos(idProposta)

  }
  //§§§§§§§§§§§§§ CARREGAR PROPOSTA
    carregarProposta(idProposta){
      this.dispensaInexigibilidadeService.buscarPorCodigo(idProposta).then(disp=>{
        this.dispensaInexigibilidade = disp
        this.planoTrabalho = disp.planoTrabalho[0]
       
      }).catch(erro => this.errorHandler.handle(erro));
     
    }
   
  //&&&&&&&&&&&&&& CARREGAR ENTIDADE PROPONENTE
   carregarOscs(){
    this.proponenteFiltro.tipoProponente = "0" //OSCs
  this.entidadeProponenteService.pesquisar(this.proponenteFiltro).then(res =>{
    this.listaOscs = res.content.map(c=>({label: c.nome, value: c.id}))
    
  }).catch(erro => this.errorHandler.handle(erro));
    
  }
  //$$$$$$$$$$$$ CARREGAR ENTIDADE CONCEDENTE
  carregarEntidadesConcedente() {
    this.concedenteFiltro.tipoConcedente = "1"
    this.concedenteService.pesquisar(this.concedenteFiltro)
      .then(entidades => {
        this.entidadesConcedente = entidades.content.map(c=>({label:c.nome, value:c.id}))
        
       
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  entidadeSelecionada(idEntidade) {
    this.dispensaInexigibilidade.entidadeConcedente.id = idEntidade
  }
  
  
  //############# CARREGAR ANEXOS
  carregarAnexos(idProposta){
   
    this.anexoFiltro.dispensaInexigibilidade = idProposta 
    this.anexoPropostaService.pesquisar(this.anexoFiltro).then(anexos=>{
      this.anexosSalvos = anexos.content
      
     
    }) .catch(erro => this.errorHandler.handle(erro));  

    
  }
  
   //======== UPLOAD
 
 openModalUpload(tipo){
  this.openModalViewUpload = true
  this.tipoUpload = tipo

}

adicionarArquivo(){
  let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
  sessionStorage.removeItem('upload');
  if(!fileItem || !fileItem.responseStatus)
  return this.toasty.error("É necessário efetivar o upload");
 
  if (this.tipoUpload == "contrato") {
    this.dataUploadArray.push(fileItem.response.data);
  }


  this.openModalViewUpload=false
}

removeFileProposta(item, tipo) {
  if(tipo == "existente"){

  this.excluirAnexos(item.id)

     
        var index = this.anexosSalvos.indexOf(item);
        if (index > -1) {
          this.anexosSalvos.splice(index, 1);
        }
      
    
  } else{
    var index = this.dataUploadArray.indexOf(item);
    if (index > -1) {
      this.dataUploadArray.splice(index, 1);
    }
  }
}

confimarExclusao(arquivo,tipo) {
  this.confirmation.confirm({
    message: 'Tem certeza que deseja excluir?',
    accept: () => {
      
      this.removeFileProposta(arquivo,tipo);
    }
  });
}

excluirAnexos(idAnexo){
  this.anexoPropostaService.excluir(idAnexo).then(anexo=>{
    this.toasty.success("Anexo Excluido!")
  }) .catch(erro => this.errorHandler.handle(erro));
}

async salvarPropostaAnexo(proposta) {

  this.dataUploadArray.forEach(element => {
    this.anexoProposta.proposta = element.originalname; 
    this.anexoProposta.hashFile = element.filename;
    this.anexoProposta.dispensaInexigibilidade.id = proposta.id 
    this.anexoProposta.descricaoAnexo = "Contrato"
    

    this.anexoPropostaService
      .adicionar(this.anexoProposta)
      .then(() => {
        this.toasty.success( element.originalname +" "+ "cadastrado com sucesso!");
        this.dataUploadArray = [];
      })
      .catch(erro => this.errorHandler.handle(erro));
  });
}
//========== ./UPLOAD

// ################# VIEW ANEXO 
openModalAnexo(anexo, tipo){
  this.displayAnexo = true
  if(tipo == "novo"){
  
  this.nomeAnexo = anexo.originalname
  this.urlDocumento = environment.downloadFileUrl + "/" + anexo.filename;
  anexo.filename.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }else{
  
  this.nomeAnexo = anexo.proposta
  this.urlDocumento = environment.downloadFileUrl + "/" + anexo.hashFile;
  anexo.filename.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }
}
//######################./ VIEW ANEXO

 
  
  //====== RETORNA RESUMO ETAPA V
  retornaTipoProposta(){
  let nome
    if(this.dispensaInexigibilidade.tipoProposta == "2"){
      nome = "Contrato"
      return nome
    }
  }

  retornaNomeEntidade(){
    let nome
    this.listaOscs.forEach(entidade => {
      if(entidade.value == this.planoTrabalho.entidadeProponente.id){
        nome = entidade.label
      }
    });
    return nome
  }
 
// === ADICIONA PROPOSTA TIPO PARCERIA ===//
  
 
  salvar() {  
  
    this.dispensaInexigibilidade.planoTrabalho = null
    let i = new DispensaInexigibilidade
    i.id = this.dispensaInexigibilidade.id
    this.planoTrabalho.dispensaInexigibilidade = i
    this.planoTrabalhoService.atualizar(this.planoTrabalho)
      .then(() => {
        this.dispensaInexigibilidadeService.atualizar(this.dispensaInexigibilidade)
          .then(objAdd => {      
        this.salvarPropostaAnexo(objAdd);
        this.toasty.success("Proposta tipo contrato atualizada com sucesso!");        
        this.router.navigate(['proposta/contrato/listar']);

    })  
    .catch(erro => this.errorHandler.handle(erro));
    })
  }
// ==== BUSCAR PROPONENTE
  buscarProponente(id){
  this.entidadeProponenteService.buscarPorCodigo(id).then( proponent=>{
    this.planoTrabalho.entidadeProponente = proponent
   
  })
  }  
// === BUSCAR CONCEDENTE
  buscarConcedente(id){
    this.concedenteService.buscarPorCodigo(id).then( concedente=>{
      this.dispensaInexigibilidade.entidadeConcedente = concedente     
    })
  }
  //==== ETAPAS =====//
  openEtapa1() {
    this.carregarEntidadesConcedente();
    this.etapa1 = true
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa2() {
    this.etapa1 = false
    this.etapa2 = true
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa3() {
   
    this.dispensaInexigibilidade.tipoProposta = "2"
    this.carregarOscs();

    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = true
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa4() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = true
    this.etapa5 = false
  }
  openEtapa5() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = true
  }
  fecharTodasEtapas() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }



}
