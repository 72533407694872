import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../seguranca/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html'
})
export class LeftSideComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
  }

  gotoHabilitacao(param) {
    this.router.navigate(["/habilitacao/visualizar"]);
    sessionStorage.setItem('tipoHabilitacao', param)
  }

}
