import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { Financeiro } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class FinanceiroFiltro {
  usuario
  celebracao
  tipo
  categoria

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class FinanceiroService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/financeiro`;
  }

  pesquisar(filtro: FinanceiroFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.usuario) {
      params.set('usuario', filtro.usuario);
    }
    if (filtro.celebracao) {
      params.set('celebracao', filtro.celebracao);
    }
    if (filtro.tipo) {
      params.set('tipo', filtro.tipo);
    }
    if (filtro.categoria) {
      params.set('categoria', filtro.categoria);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Financeiro): Promise<Financeiro> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: Financeiro): Promise<Financeiro> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Financeiro);
  }

  buscarPorCodigo(codigo: number): Promise<Financeiro> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as Financeiro;
        return entidade;
      });
  }

  //ENCARGOS
  adicionarEncargo(entidade: Financeiro): Promise<Financeiro> {
    return this.http.post(`${this.url}/adiciona-encargo`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizaEncargo(entidade: Financeiro): Promise<Financeiro> {

    return this.http.put(`${this.url}/atualiza-encargo/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Financeiro);
  }

  excluirEncargo(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/excluir-encargo/${codigo}`)
      .toPromise()
      .then(() => null);
  }

}
