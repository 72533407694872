import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Celebracao, PlanoTrabalhoHasProponente } from '../../core/model';
import { PlanoTrabalhoHasProponenteService } from '../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';
import { AuthService } from '../../seguranca/auth.service';
import { ToastyService } from "ng2-toasty";

declare let AdminLTE: any;

@Component({
  selector: "app-visualizar-execucao",
  templateUrl: "./visualizar-execucao.component.html"
})
export class VisualizarExecucaoComponent implements OnInit {

  showDefaultMessage
  celebracao = new Celebracao();

  codigoPlanoTrabalhoHasProponente: number;
  codigoCelebracao: number

  pthp = new PlanoTrabalhoHasProponente();

  constructor(
    private planoTrabalhoHasProponenteService: PlanoTrabalhoHasProponenteService,
    public auth: AuthService,
    private toasty: ToastyService,
    private router: Router,
  ) { }

  ngOnInit() {
    AdminLTE.init();

    this.celebracao = JSON.parse(sessionStorage.getItem("modExecucaoCelebracao"))
    this.pthp = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"))

    // AlteracoesExecucaoService.exOfficioAdd.subscribe(
    //   () => (this.celebracao = JSON.parse(sessionStorage.getItem("modExecucaoCelebracao")))
    // )

    //TODO: Verficiar o tipo da celebracao para setar o plano
    if (this.celebracao.et1TipoDemanda === 'Parceria') {
      if (this.celebracao.et1ChamamentoPublico) {

        sessionStorage.setItem('planoDeTrabalhoId', this.celebracao.et1ChamamentoPublico.planoTrabalho[0].id)
        this.codigoCelebracao = this.celebracao.id
        this.codigoPlanoTrabalhoHasProponente = this.celebracao.et1ChamamentoPublico.planoTrabalho[0].id
        this.pesquisarCalculosExecucao(this.codigoPlanoTrabalhoHasProponente, this.codigoCelebracao)

      } else if (this.celebracao.et1DispensaInexigibilidade) {

        sessionStorage.setItem('planoDeTrabalhoId', this.celebracao.pthpId)
        this.codigoCelebracao = this.celebracao.id
        this.codigoPlanoTrabalhoHasProponente = this.celebracao.pthpId
        this.pesquisarCalculosExecucao(this.codigoPlanoTrabalhoHasProponente, this.codigoCelebracao)

      } else if (this.celebracao.et1EmendaParlamentar) {

        sessionStorage.setItem('planoDeTrabalhoId', this.celebracao.pthpId)
        this.codigoCelebracao = this.celebracao.id
        this.codigoPlanoTrabalhoHasProponente = this.celebracao.pthpId
        this.pesquisarCalculosExecucao(this.codigoPlanoTrabalhoHasProponente, this.codigoCelebracao)

      }
    } else if (this.celebracao.et1TipoDemanda === 'Convenio') {

      sessionStorage.setItem('planoDeTrabalhoId', this.celebracao.pthpId)
      this.codigoCelebracao = this.celebracao.id
      this.codigoPlanoTrabalhoHasProponente = this.celebracao.pthpId
      this.pesquisarCalculosExecucao(this.codigoPlanoTrabalhoHasProponente, this.codigoCelebracao)

    } else {
      this.router.navigate(["/execucao"]);
    }

  }


  async pesquisarCalculosExecucao(codigoPlanoTrabalhoHasProponente: number, codigoCelebracao: number) {
    await this.planoTrabalhoHasProponenteService.calculosExecucao(codigoPlanoTrabalhoHasProponente, codigoCelebracao)
      .then(async resultado => {

        await this.formatarCalculoExecucao(resultado)

      });
  }



  valorTotalProjeto = 0
  repasses = 0
  aplicacaoFinanceira = 0
  contasApresentadas = 0
  percentualContasAtual = 0
  prestacaoContasParcial = 0
  prestacaoContasTotal = 0
  async formatarCalculoExecucao(obj) {

    this.valorTotalProjeto = this.pthp.planoTrabalho.et2Valor

    obj.financeiroList.forEach(element => {
      if (element.tipo === "Repasse_ob") {
        this.repasses = this.repasses + element.obValorReceb
      }
      if (element.tipo === "Aplicacao_financeira") {
        this.aplicacaoFinanceira = this.aplicacaoFinanceira + element.afValRend
      }

      if (element.tipo === "INSS" || element.tipo === "FGTS" || element.tipo === "PIS" || element.tipo === "IR") {
        this.contasApresentadas = this.contasApresentadas + element.devSaldoValor
      }
    });

    obj.pessoaProjetoList.forEach(element => {
      element.pagamentoPessoal.forEach(pp => {
        this.contasApresentadas = this.contasApresentadas + pp.valor
      });
    });

    obj.etapaDespesaList.forEach(element => {
      element.despesa.forEach(despesa => {
        this.contasApresentadas = this.contasApresentadas + despesa.valor
      });
    });


    this.percentualContasAtual = (this.contasApresentadas / this.repasses) * 100
    this.prestacaoContasParcial = this.repasses * 0.7
    this.prestacaoContasTotal = this.repasses - this.contasApresentadas

  }


}