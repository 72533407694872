import {
  OscFiltro,
  OscService,
} from "./../../core/services/proponente/osc.service";
import { SetorEntidade } from "./../../core/model/setorEntidade.model";
import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import {
  ConcedenteFiltro,
  ConcedenteService,
} from "../../core/services/concedente/concedente.service";
import { AuthService } from "../../seguranca/auth.service";
import { SetorService } from "../setor.service";

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-setor-cadastro",
  templateUrl: "./setor-cadastro.component.html",
})
export class SetorCadastroComponent implements OnInit {
  setorEntidade = new SetorEntidade();
  entidades = [];
  entidade;
  listaEntidades: any[] = [];
  filtroConcedente = new ConcedenteFiltro();
  filtroNomeEntidade: any;
  filtroProponente = new OscFiltro();
  filtroCnpjEntidade: any;
  listaEntidadesTitulo: any;
  tipoEntidade: any = "";

  constructor(
    public auth: AuthService,
    private setorService: SetorService,
    private entidadeConcedenteService: ConcedenteService,
    private oscService: OscService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private location: Location
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.verificaSetor();

    this.title.setTitle("Novo Setor/Departamento");
  }

  goBack() {
    this.location.back();
  }

  verificaSetor() {
    if (this.auth.jwtPayload.concedente) {
      this.tipoEntidade = "Concedente";
      this.setorEntidade.entidadeConcedente.id =
        this.auth.jwtPayload.concedente;
      this.setorEntidade.entidadeProponente = null;
      this.carregarEntidadesConcedentePreDefnida(
        this.auth.jwtPayload.concedente
      );
    } else if (this.auth.jwtPayload.proponente) {
      this.tipoEntidade = "Proponente";
      this.setorEntidade.entidadeProponente.id =
        this.auth.jwtPayload.proponente;
      this.setorEntidade.entidadeConcedente = null;
      this.carregarEntidadesProponentePreDefnida(
        this.auth.jwtPayload.proponente
      );
    } else {
      this.carregarEntidadesConcedente();
    }
  }

  salvar() {
    this.setorEntidade.dataCadastro = new Date();
    this.setorEntidade.usuarioCadastro.id = this.auth.jwtPayload.id;
    this.setorEntidade.habilitado = true;
    this.setorService
      .adicionar(this.setorEntidade)
      .then(() => {
        this.toasty.success("Setor/Departamento adicionado com sucesso!");
        this.router.navigate(["/cadastros/concedente/setor"]);
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  carregarEntidadesConcedente() {
    return this.entidadeConcedenteService
      .listarTodos()
      .then((entidades) => {
        this.entidades = entidades.map((c) => ({ label: c.nome, value: c.id }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  carregarEntidadesConcedentePreDefnida(codigo: number) {
    return this.entidadeConcedenteService
      .buscarPorCodigo(codigo)
      .then((entidade) => {
        this.entidade = entidade;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  carregarEntidadesProponentePreDefnida(codigo: number) {
    return this.oscService
      .buscarPorCodigo(codigo)
      .then((entidade) => {
        this.entidade = entidade;
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  getListaConcedentes(pagina = 0) {
    this.listaEntidadesTitulo = "Concedentes";

    this.filtroConcedente.cnpj = this.filtroCnpjEntidade;
    this.filtroConcedente.nome = this.filtroNomeEntidade;

    this.filtroConcedente.pagina = pagina;
    this.entidadeConcedenteService
      .pesquisar(this.filtroConcedente)
      .then((resultado) => {
        this.listaEntidades = resultado.content;
      });
  }
  getListaProponentes(pagina = 0) {
    this.listaEntidadesTitulo = "Proponentes";

    this.filtroProponente.cnpj = this.filtroCnpjEntidade;
    this.filtroProponente.nome = this.filtroNomeEntidade;
    this.filtroProponente.pagina = pagina;
    this.oscService.pesquisar(this.filtroProponente).then((resultado) => {
      this.listaEntidades = resultado.content;
    });
  }
  atualizarEntitadeSelecionada(item: any) {
    this.entidade = item;
    if (this.tipoEntidade == "Concedente") {
      this.setorEntidade.entidadeConcedente = item;
    } else {
      this.setorEntidade.entidadeProponente = item;
    }
    this.toasty.success("Entidade atualizada com sucesso!");
  }
}
