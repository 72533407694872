import { environment } from './../../../environments/environment';
import { RelatorioService } from './../../core/services/relatorio/relatorio.service';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  RoteiroParecerService,
  RoteiroParecerFiltro
} from "../../core/services/modelos-sistema/roteiroParecer.service";
import { ErrorHandlerService } from "../../core/error-handler.service";
import {
  RoteiroParecer,
  ModelResponderRoteiro,
  ResponderRoteiro
} from "../../core/model";
import {
  ResponderRoteiroService,
  ResponderRoteiroFiltro
} from "../../core/services/modelos-sistema/responderRoteiro.service";
import {
  RespostaQuestaoParecerService,
  RespostaQuestaoParecerFiltro
} from "../../core/services/modelos-sistema/respostaQuestaoParecer.service";
import { AuthService } from "../../seguranca/auth.service";
import { ToastyService } from "ng2-toasty";
import { ActivatedRoute } from '@angular/router';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

import * as moment from 'moment';
moment.locale('pt');

export class ObsModel{
  questao: string
  embasamentoLegal: string
  informacaoesExtras: string
  recomendacao: string
  tipoImpropriedade: string
}

@Component({
  selector: "app-roteiro-imprimir",
  templateUrl: "./roteiro-imprimir.component.html"
})
export class RoteiroImprimirComponent implements OnInit {
  
  loading
  questoesRespondidas
  paramImprimir
  situacao
  obsArray
  conclusao
  dataPdf = ''
  respostas = [];
  roteiro = new RoteiroParecer();
  modelResponderRoteiro = new ModelResponderRoteiro();
  modelParceriaCelebracao
  responderRoteiro: ResponderRoteiro;

  constructor(
    private title: Title,
    private roteiroParecerService: RoteiroParecerService,
    private responderRoteiroService: ResponderRoteiroService,
    private respostaQuestaoParecerService: RespostaQuestaoParecerService,
    private relatorioService: RelatorioService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private toasty: ToastyService
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Imprimir Roteiro");

    this.paramImprimir = this.route.snapshot.paramMap.get("param-print");

    this.modelResponderRoteiro = JSON.parse(sessionStorage.getItem("respondeRoteiro"));
    this.modelParceriaCelebracao = JSON.parse(sessionStorage.getItem("modParceriaCelebracao"));
    if (this.modelResponderRoteiro != null && this.modelParceriaCelebracao != null) {
      this.pesquisaRoteiro();
    }
  }

  pesquisaRoteiro() {
    let filtro = new RoteiroParecerFiltro();
    filtro.moduloParecer = this.modelResponderRoteiro.modulo;
    filtro.submodulRoteiro = this.modelResponderRoteiro.submodulo;
    filtro.tipoRoteiro = this.modelResponderRoteiro.tipoRoteiro;
    filtro.status = "Ativo";

    this.roteiroParecerService
      .pesquisar(filtro)
      .then(retorno => {
        this.roteiro = retorno.dado[0];
        this.verificaResponderRoteiro();

        // //console.log(this.roteiro);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaResponderRoteiro() {
    let filtro = new ResponderRoteiroFiltro();
    filtro.roteiroParecer = this.roteiro.id;
    switch (this.modelResponderRoteiro.campoProcesso) {
      case "planoTrabalho": {
        filtro.planoTrabalho = this.modelResponderRoteiro.idProcesso;
        break;
      }
    }
    this.responderRoteiroService
      .pesquisar(filtro)
      .then(retorno => {
        if (retorno.content.length != 0) {
          //Buscar respostas
          this.responderRoteiro = retorno.content[0];
          this.buscarRespostas();
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  buscarRespostas() {
    let filtro = new RespostaQuestaoParecerFiltro();
    filtro.responderRoteiro = this.responderRoteiro.id;
    
    this.respostaQuestaoParecerService
      .pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado.length != 0) {
          this.respostas = retorno.dado;
          
          // === PROMISE PARA EFETIVAR A IMPRESSAO COM OS DADOS ALOCADOS
          this.setaRespostasNasQuestoes().then(() => {

            this.processarAnalise()

            this.verificaSeTodasForamRespondidas().then(() => {
              if(this.questoesRespondidas){
                this.imprimirRelatorio()
              }else{
                this.loading=true
              }
            });;
          });
        }else{
          this.loading=true
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  imprimirRelatorio(){

    let content = this.modificandoContent(this.roteiro)
    let hash = this.paramImprimir
    let dataImpressao = moment().format('LL')
    let dataRespondidoExtenso = moment(this.roteiro.ultimaAtualizacao).format('LL')
    let textoConclusao = this.conclusao
    let situacao = this.situacao
    let obsArray = this.obsArray
    let entidade = this.modelParceriaCelebracao.et1EntidadeConcedente.nome
    let projeto = this.modelParceriaCelebracao.et1Titulo
    let linkValidacao =  "http://teste.com/teste123"

    let data= {
      content: content,
      hash: hash,
      dataRespondidoExtenso: dataRespondidoExtenso,
      dataImpressao: dataImpressao,
      linkValidacao: linkValidacao,
      textoConclusao: textoConclusao,
      situacao: situacao,
      projeto: projeto,
      entidade: entidade,
      obsArray:obsArray
    }

    // //console.log(data)

    return this.relatorioService
      .relatorioRoteiro(data)
      .then(retorno => {
        //this.dataPdf = retorno.data
        //this.dataPdf = URL.createObjectURL(retorno.data)
        if(retorno.report && retorno.hash === this.paramImprimir){
          this.dataPdf = environment.downloadFileUrl + "/report.pdf";
          this.loading = true
        }else{
          setTimeout(function(){
            this.imprimirRelatorio()
          },3000);
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  modificandoContent(roteiro){
    let secaoCount = 0
    let questaoCount = 0
    roteiro.secao.forEach(secao => {
      secaoCount++
      questaoCount = 0
      secao.secao = secaoCount+". "+secao.secao
      secao.questaoParecer.forEach(questao => {
        questaoCount++
        questao.enunciado = secaoCount+"."+questaoCount+". "+questao.enunciado
      })
    })
    return roteiro
  }
  
  processarAnalise(){
    
    // //console.log(this.roteiro)

    let secaoCountIndice = 0
    let questaoCountIndice = 0
    let observacoes = []
    let qtdQuestoes = 0
    let favoravel = 0
    let naoFavoravel = 0
    let qtdRespostas = 0
    let qtdParecer = 0
    let qtdObsFavoravel = 0
    let qtdObsNaoFavoravel = 0
    let reprovado= 0

    this.roteiro.secao.forEach(secao => {
      secaoCountIndice++
      questaoCountIndice = 0

      secao.questaoParecer.forEach(questao => {
        questaoCountIndice++
        qtdQuestoes++
        let value = secaoCountIndice+"."+questaoCountIndice
       
        
        // verifica as questoes que foram respondidas
        if (questao.resposta != null) {
          qtdRespostas++

          if (questao.resposta.observacao != null && questao.resposta.observacao != "") {
            observacoes.push(value)
          }

          if (questao.resposta.respostaEnum) {
            qtdParecer++
            // verifica as questoes favoraveis e que nao tiveram obs
            if (questao.resposta.respostaEnum === 'Sim') {
              if (questao.resposta.observacao == null || questao.resposta.observacao === "") {
                favoravel++
              } else {
                qtdObsFavoravel++
              }
            }
            if (questao.resposta.respostaEnum === 'Nao') {
              if (questao.resposta.observacao == null || questao.resposta.observacao === "") {
                naoFavoravel++
              } else {
                qtdObsNaoFavoravel++
              }
              if(questao.continuar === 'Nao'){
                this.obsArray = []

                let obsModel = new ObsModel()
                obsModel.questao = value
                obsModel.embasamentoLegal = questao.embasamentoLegal
                obsModel.informacaoesExtras = questao.informacaoesExtras
                obsModel.recomendacao = questao.recomendacao
                obsModel.tipoImpropriedade = questao.tipoImpropriedade
                this.obsArray.push(obsModel)

                reprovado++
              }
            }
          }
        }
        
      });

    });

    // //console.log("QtdQuestoes: "+ qtdQuestoes + " - qtdRespostas: "+ qtdRespostas )
    // //console.log("QtdQuestoes do tipo parecer: "+ qtdParecer )
    // //console.log("Favoravel e sem OBS: "+ favoravel )

    if(qtdParecer === favoravel){
      this.situacao = "Aprovado"
      this.conclusao = 
        "Após o exame dos itens que compõem a análise do "
        +this.roteiro.tipoRoteiro.tipo+
        ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento às demais etapas subsequentes."
        ;
    }

    if(qtdParecer == (qtdObsFavoravel + favoravel)){
      this.situacao = "Aprovado com recomendações"

      if(observacoes.length === 1){
        this.conclusao = 
        "Após o exame dos itens que compõem a análise do "
        +this.roteiro.tipoRoteiro.tipo+
        ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento "+
        "às demais etapas subsequentes, desde que, a OBSERVAÇÃO da questão: "
        +observacoes.toString()+
        " seja realizado o devido ajuste."
      }else{
        this.conclusao = 
        "Após o exame dos itens que compõem a análise do "
        +this.roteiro.tipoRoteiro.tipo+
        ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento "+
        "às demais etapas subsequentes, desde que, as OBSERVAÇÕES das questões: "
        +observacoes.toString()+
        " sejam realizados os devidos ajustes."
      }
    }

    if(reprovado > 0){
      this.situacao = "Reprovado"

      if(qtdObsNaoFavoravel === 1){
        this.conclusao =
        "Após o exame dos itens que compõem a análise do "
        +this.roteiro.tipoRoteiro.tipo+
        ", entendo que o mesmo NÃO ESTÁ DE ACORDO com a legislação vigente e INAPTO. Para que seja dado prosseguimento,"+
        " solicito que seja verificado a observação da questão: "  +observacoes.toString()+ " e seja realizado o devido ajuste. "+
        "Foi constatada restrição apontada na questão: "+observacoes.toString()+ ", verificando-se ocorrência de "+
        "IRREGULARIDADE e IMPROPRIEDADE que configura prejuízo ao erário. Sendo assim, recomenda-se a providência definida na questão abaixo: "
      
      }else{

        this.conclusao =
        "Após o exame dos itens que compõem a análise do "
        +this.roteiro.tipoRoteiro.tipo+
        ", entendo que o mesmo NÃO ESTÁ DE ACORDO com a legislação vigente e INAPTO. Para que seja dado prosseguimento,"+
        " solicito que seja verificado a observação das questões: "  +observacoes.toString()+ " e sejam realizados os devidos ajustes. "+
        "Foram constatadas restrições apontadas nas questões: "+observacoes.toString()+ ", verificando-se ocorrências de "+
        "IRREGULARIDADES e IMPROPRIEDADES que configuram prejuízos ao erário. Sendo assim, recomenda-se a providência definida nas questões abaixo: "
      }
    }
  }

  async setaRespostasNasQuestoes(): Promise<any> {
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            resposta.questaoParecer.secao = null
            resposta.responderRoteiro = null
            questao.resposta = resposta;
          }
        });
      });
    });
  }

  async verificaSeTodasForamRespondidas(): Promise<any> {
    this.questoesRespondidas = true;
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta;
          }
        });
        if (questao.resposta == null) {
          this.questoesRespondidas = false;
        }
      });
    });
  }
}
