import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { NotificacaoHasUsuario, NotificacaoHasUsuarioDTO } from '../../model';

export class NotificacaoHasUsuarioFiltro {
  notificacao
  usuario
  titulo
  statusNotificacao
  favorito
  prioridade
  lixeira
  pagina = 0;
  itensPorPagina = 5;
}

@Injectable()
export class NotificacaoHasUsuarioService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/notificacao-has-usuario`;
  }

  pesquisar(filtro: NotificacaoHasUsuarioFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.notificacao) {
      params.set('notificacao', filtro.notificacao);
    }
    if (filtro.usuario) {
      params.set('usuario', filtro.usuario);
    }

    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }
    if (filtro.statusNotificacao) {
      params.set('statusNotificacao', filtro.statusNotificacao);
    }
    if (filtro.favorito) {
      params.set('favorito', filtro.favorito);
    }
    if (filtro.prioridade) {
      params.set('prioridade', filtro.prioridade);
    }

    params.set('lixeira', filtro.lixeira);

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: NotificacaoHasUsuario): Promise<NotificacaoHasUsuario> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }



  adicionarDTO(entidade: NotificacaoHasUsuarioDTO): Promise<any> {
    return this.http.post(`${this.url}/salvar-notificacoes`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }


  atualizar(entidade: NotificacaoHasUsuario): Promise<NotificacaoHasUsuario> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as NotificacaoHasUsuario);
  }

  buscarPorCodigo(codigo: number): Promise<NotificacaoHasUsuario> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as NotificacaoHasUsuario;
        return entidade;
      });
  }

}
