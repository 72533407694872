import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { Subject } from 'rxjs';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { PlanoTrabalhoHasProponente, Usuario, VersaoPlano } from '../../core/model';
import { VersaoPlanoService } from '../../core/services/alteracao-plano/versaoPlano.service';
import { AuthService } from '../../seguranca/auth.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-ajuste-plano-novo',
  templateUrl: './ajuste-plano-novo.component.html',
})
export class AjustePlanoNovoComponent implements OnInit {
  public showPreview: boolean = false;
  public update$: Subject<any> = new Subject();
  public options1: any = {
    autogrow: true,
    removeformatPasted: true,
    semantic: false,
    btns: [
      
      ["undo", "redo"], // Only supported in Blink browsers
      ["formatting"],
      ["strong", "em", "del"],
      ["superscript", "subscript"],
      ["link"],
      ["insertImage"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
      ["unorderedList", "orderedList"],
      ["horizontalRule"],
      ["removeformat"] 
    ]
  };

  justContIni = `
            <ol>
              <li>
                <b>Descreva aqui o por que de realizar o ajuste</b> (Sugere informar a data de criação, diretrizes
                da OSC);</li>
              <li>
                <b>Informar</b> quais projetos, programas ou campanhas a instituição participou.</li>
              <li>
                <b>Descrever</b> de forma sucinta aa justificativa do ajuste.</li>
              <li>
                <b>Estruturar a informação</b> as informações pertinentes aos projetos aprovados em convênios anteriores objetivando
                a consolidação dos indicadores de avaliação dos investimentos realizados pelo Governo do Estado através
                dos Órgãos convenentes.</li>
              <li>
                <b>Elencar</b> quais os bens que a entidade disponibiliza (sede própria, carro, equipamentos, máquinas,
                terrenos).
              </li>
            </ol>
  `;

  planoTrabalhoHasProponente: any;
  versaoPlano = new VersaoPlano
  openModalViewUpload: boolean;
  dataUpload: any;
  urlDown: string;
  

  constructor(
    private versaoPlanoService: VersaoPlanoService,
    private errorHandler: ErrorHandlerService, 
    private auth: AuthService,
    private toasty: ToastyService,
    private router: Router
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
  }



  solicitarMudanca(){
    this.update$.next();
    if(this.versaoPlano.justificativa == ''){
      this.toasty.warning("Informe a justificativa do ajuste!")
    }else{
      if(this.dataUpload){
        this.versaoPlano.anexo = this.dataUpload.originalname
        this.versaoPlano.hashFile = this.dataUpload.filename
        this.versaoPlano.tamanho = this.dataUpload.size
      }

      let usrSolic = new Usuario
      usrSolic.id = this.auth.jwtPayload.id

      let pthp = new PlanoTrabalhoHasProponente
      pthp.id = this.planoTrabalhoHasProponente.id
      
      this.versaoPlano.planoTrabalhoHasProponente = pthp
      this.versaoPlano.dataSolicitacao = new Date()
      this.versaoPlano.usuarioSolicitante = usrSolic
      this.versaoPlano.status = 0 

      let rota = sessionStorage.getItem("rotaModuloAjuste")
      
      this.versaoPlanoService.adicionar(this.versaoPlano)
      .then(retorno => {
        VersaoPlanoService.eventoVersaoPlano.emit(true);
        sessionStorage.setItem('versaoPlano', JSON.stringify(retorno))
        this.toasty.success("Ajuste solicitado com sucesso!")
        //this.router.navigate([rota+'/alterar']);
        this.router.navigate(['/projetos/parceria-gerenciar/execucao/alterar/plano-trabalho/listar']);

      })
      .catch(erro => this.errorHandler.handle(erro));
    }
  }

  
  //========== Operacoes de Upload 
  adicionarArquivo() {
    let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
    sessionStorage.removeItem('upload');
    if (!fileItem || !fileItem.responseStatus)
      return this.toasty.error("É necessário efetivar o upload");


    this.dataUpload = fileItem.response.data;
    this.openModalViewUpload = false;
  }

  removeFileProposta() {
    this.dataUpload = null;
  }

  openModalUpload() {
    this.openModalViewUpload = true
  }



}
