import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AuthService } from '../../../seguranca/auth.service';
import { AlterDetalhamentoFinanceiroFiltro, AlterDetalhamentoFinanceiroService } from '../../../core/services/alteracao-plano/alterDetalhamentoFinanceiro.service';
import { AlterDetalhamentoHasEtapaFiltro, AlterDetalhamentoHasEtapaService } from '../../../core/services/alteracao-plano/alterDetalhamentoHasEtapa.service';
import { CategoriaFiltro, CategoriaService } from '../../../core/services/fornecedor/categoria.service';
import { DetalhamentoFinanceiro, AlterDetalhamentoFinanceiro, AlterDetalhamentoHasEtapa, DetalhamentoFinanceiroHasEtapaCronogramaFisico } from '../../../core/model';
import { FormControl } from '@angular/forms';
import { ConfirmationService } from 'primeng/primeng';
import { DetalhamentoFinanceiroHasEtapaCronogramaFisicoService } from '../../../core/services/plano-trabalho/detalhamentoFinanceiroHasEtapaCronogramaFisico.service';
import { CronogramaFisicoExecucaoObjetoService } from '../../../core/services/plano-trabalho/cronogramaFisicoExecucaoObjeto.service';
import { PlanoTrabalhoHasProponenteService } from '../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-alterar-detalhamento-financeiro',
  templateUrl: './alterar-detalhamento-financeiro.component.html',
})
export class AlterarDetalhamentoFinanceiroComponent implements OnInit {

  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  loading

  listDetalhamentoFinanceiro = [];
  listaAlteracoesDetalhamentoFin = [];
  listaAlteracoesDetalhamentoHas = [];

  idDespesaIterada = 0;

  listaCategorias: any;
  listaCategoriasVer: any;
  listaCategoriasEditar: any;
  pessoaCeletistaSelecionada: boolean;

  modalAdicionarDetalhamentoFinanceiro: boolean;
  detalhamentoFinanceiroAdd = new DetalhamentoFinanceiro()

  tipoDespesa = [
    { label: "Material de consumo", value: "0" },
    { label: "Pessoa Celetista", value: "1" },
    { label: "Pessoa Horista", value: "2" },
    { label: "Diária", value: "3" },
    { label: "Premiações", value: "4" },
    { label: "Passagens", value: "5" },
    { label: "Consultorias", value: "6" },
    { label: "Serviços de Terceiros Pessoa Jurídica", value: "7" },
    { label: "Equipamento e Material Permanente", value: "8" },
    // { label: "Despesas e Encargos de Projetos Anteriores", value: "9" },
    { label: "Benefícios", value: "10" },
  ];
  categoriaReduzida: boolean;
  todasCategorias: any;

  detalhamentoFinanceiroCodigo: any;
  detalhamentoFinanceiroEspecificacao: any;
  detalhamentoFinanceiroTotal: any;
  detalhamentoFinanceiroTipo: any;
  detalhamentoFinanceiroCategoria: any;
  pessoaSelecionada: boolean;
  todasCategoriasEditar: any;
  detalhamentoFinanceiroEditar = new DetalhamentoFinanceiro();
  modalEditarDetalhamentoFinanceiro: boolean;
  editandoDetalhamentoFinanceiro: boolean;

  etapaEditarNgModel: any;
  metaEditarNgModel: any;
  listaEtapaCronogramaFisicoEditar: any[];
  idDetalhamentoFinanceiro: any;
  detalhamentoNomeEtapa: any;
  detalhamentoEspecificacao: any;
  detalhamentoUn: any;
  detalhamentoQuantidade: any;
  detalhamentoValorUnitario: any;
  detalhamentoValorTotal: any;
  detalhamentoMaterialPermanente: any;
  detalhamentoEditar = new DetalhamentoFinanceiroHasEtapaCronogramaFisico()
  mostraAtrEquipMatPerm: boolean;
  modalEditarDetalhamento: boolean;
  editandoDetalhamento: boolean;
  listaMetaCronogramaFisico: any;
  listaMetas: any;
  detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd = new DetalhamentoFinanceiroHasEtapaCronogramaFisico()
  modalAdicionarDetalhamentoFinanceiroHasEtapaCronogramaFisico: boolean;
  listaEtapaCronogramaFisico: any;

  metaNgModel: any;
  inicioProjeto: any;
  fimProjeto: any;

  constructor(
    private planoTrabalhoHasProponenteService: PlanoTrabalhoHasProponenteService,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private categoriaService: CategoriaService,
    private alterDetalhamentoFinanceiroService: AlterDetalhamentoFinanceiroService,
    private alterDetalhamentoHasEtapaService: AlterDetalhamentoHasEtapaService,
    private confirmation: ConfirmationService,
    private detalhamentoHasEtapaService: DetalhamentoFinanceiroHasEtapaCronogramaFisicoService,
    private cfeoService: CronogramaFisicoExecucaoObjetoService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.listDetalhamentoFinanceiro = this.planoTrabalhoHasProponente.detalhamentoFinanceiro;

    this.pthpBuscar()
    this.carregaMetasEtapas()
    this.verificaIniFimProjeto()

  }

  //CARREGAR
  pthpBuscar() {
    this.planoTrabalhoHasProponenteService.buscarPorCodigo(this.planoTrabalhoHasProponente.id)
      .then(async retorno => {
        this.planoTrabalhoHasProponente = retorno
        this.listDetalhamentoFinanceiro = this.planoTrabalhoHasProponente.detalhamentoFinanceiro;
        await this.carregaCategorias()
        await this.procuraAlteracoesDetalhamentoFin()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregaCategorias() {
    let i = new CategoriaFiltro();
    i.itensPorPagina = 1000;
    this.categoriaService.pesquisar(i)
      .then(retorno => {
        this.listaCategorias = retorno.dado.map(c => ({
          label: c.categoria,
          value: c.id
        }));
        this.listaCategoriasVer = retorno.dado.map(c => ({
          label: c.categoria,
          value: c.id
        }));
        this.listaCategoriasEditar = retorno.dado.map(c => ({
          label: c.categoria,
          value: c.id
        }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  //TODO: verificar carregamento de metas e etapas
  carregaMetasEtapas() {
    this.cfeoService.pesquisarAlteracoes(this.planoTrabalhoHasProponente.id)
      .then(retorno => {
        this.listaMetas = retorno
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  procuraAlteracoesDetalhamentoFin() {
    //Procura por alterações ativas
    let filtro = new AlterDetalhamentoFinanceiroFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.statusAlteracao = "2" //Submetido    
    this.alterDetalhamentoFinanceiroService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesDetalhamentoFin = retorno.dado
        this.procuraAlteracoesDetalhamentoHas()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  procuraAlteracoesDetalhamentoHas() {
    //Procura por alterações ativas
    let filtro = new AlterDetalhamentoHasEtapaFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.statusAlteracao = "2" //Submetido    
    this.alterDetalhamentoHasEtapaService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesDetalhamentoHas = retorno.dado
        this.verificaAlteracoesDespesa()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  verificaAlteracoesDespesa() {
    this.listaAlteracoesDetalhamentoFin.forEach(detalFinAlter => {
      this.listDetalhamentoFinanceiro.forEach(detalFinPlano => {

        if (detalFinAlter.detalhamentoFinanceiro == detalFinPlano.id) {
          var index = this.listDetalhamentoFinanceiro.indexOf(detalFinPlano)

          if (detalFinAlter.tipoAlteracao == "Alterado") {

            this.listDetalhamentoFinanceiro[index].codigo = detalFinAlter.codigoDepois
            this.listDetalhamentoFinanceiro[index].especificacao = detalFinAlter.especificacaoDepois
            this.listDetalhamentoFinanceiro[index].total = detalFinAlter.totalDepois
            this.listDetalhamentoFinanceiro[index].tipoAlteracao = "Alterado"

          } else if (detalFinAlter.tipoAlteracao == "Excluido") {
            this.listDetalhamentoFinanceiro.splice(index, 1)
          }
        }
      });

    });

    this.verificaAlteracoesDetalhamento()

  }
  verificaAlteracoesDetalhamento() {
    this.listDetalhamentoFinanceiro.forEach(desp => {
      desp.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
        this.listaAlteracoesDetalhamentoHas.forEach(alteracaoDetalhamento => {

          if (alteracaoDetalhamento.detalhamentoHasEtapa == detalhamento.id) {
            var index = this.listDetalhamentoFinanceiro.indexOf(desp)
            var i = this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)

            if (alteracaoDetalhamento.tipoAlteracao == "Alterado") {

              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].especificacao = alteracaoDetalhamento.especificacaoDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].un = alteracaoDetalhamento.unDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].funcao = alteracaoDetalhamento.funcaoDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mes = alteracaoDetalhamento.mesDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mesAnoContratacao = alteracaoDetalhamento.mesAnoContratacaoDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mesAnoDispensa = alteracaoDetalhamento.mesAnoDispensaDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].quantidade = alteracaoDetalhamento.quantidadeDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].salario = alteracaoDetalhamento.salarioDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].inss = alteracaoDetalhamento.inssDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].terceiro = alteracaoDetalhamento.terceiroDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].fgts = alteracaoDetalhamento.fgtsDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].pis = alteracaoDetalhamento.pisDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].umDozeDeFerias = alteracaoDetalhamento.umDozeDeFeriasDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].umTresDeFerias = alteracaoDetalhamento.umTresDeFeriasDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].umDozeDeTrezeSalario = alteracaoDetalhamento.umDozeDeTrezeSalarioDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].multaRecisoria = alteracaoDetalhamento.multaRecisoriaDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].encargoMensal = alteracaoDetalhamento.encargoMensalDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].valorUnitario = alteracaoDetalhamento.valorUnitarioDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].totalUnitario = alteracaoDetalhamento.totalUnitarioDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].valorTotal = alteracaoDetalhamento.valorTotalDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].nomeEtapa = alteracaoDetalhamento.nomeEtapaDepois
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mes = alteracaoDetalhamento.mesDepois

              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].tipoAlteracao = "Alterado"

            } else if (alteracaoDetalhamento.tipoAlteracao == "Excluido") {
              this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.splice(i, 1)
              this.verificaAlteracoesDetalhamento()
            }
          }
        });
      });

    });

    this.loading = true
  }

  //ALTERAR DESPESA
  openModalEditarDetalhamentoFinanceiro(detalhamento) {
    this.detalhamentoFinanceiroCodigo = detalhamento.codigo;
    this.detalhamentoFinanceiroEspecificacao = detalhamento.especificacao;
    this.detalhamentoFinanceiroTotal = detalhamento.total;
    this.detalhamentoFinanceiroTipo = detalhamento.tipo;
    this.detalhamentoFinanceiroCategoria = detalhamento.categoria;

    if (detalhamento.tipo == "Padrao" || detalhamento.tipo == 0) {
      detalhamento.tipo = 0;
      this.pessoaSelecionada = false;
      //reduzir categorias
      this.todasCategoriasEditar = this.listaCategoriasEditar;
      let u = 0;
      let i = [];
      this.pessoaSelecionada = false;
      this.listaCategoriasEditar.forEach(categoria => {
        if (categoria.value != 43 && categoria.value != 44) {
          i[u] = categoria;
          u++;
        }
      });
      this.listaCategoriasEditar = i;
    } else if (
      detalhamento.tipo == "Pessoa_celetista" ||
      detalhamento.tipo == 1
    ) {
      if (this.todasCategoriasEditar) {
        this.listaCategoriasEditar = this.todasCategoriasEditar;
      }
      detalhamento.tipo = 1;
      this.pessoaSelecionada = true;
    } else if (
      detalhamento.tipo == "Pessoa_horista" ||
      detalhamento.tipo == 2
    ) {
      if (this.todasCategoriasEditar) {
        this.listaCategoriasEditar = this.todasCategoriasEditar;
      }
      detalhamento.tipo = 2;
      this.pessoaSelecionada = true;
    }

    this.detalhamentoFinanceiroEditar = detalhamento;
    this.modalEditarDetalhamentoFinanceiro = true;
    this.editandoDetalhamentoFinanceiro = true;
  }
  closeModalEditarDetalhamentoFinanceiro() {
    if (this.editandoDetalhamentoFinanceiro) {
      this.detalhamentoFinanceiroEditar.codigo = this.detalhamentoFinanceiroCodigo;
      this.detalhamentoFinanceiroEditar.especificacao = this.detalhamentoFinanceiroEspecificacao;
      this.detalhamentoFinanceiroEditar.total = this.detalhamentoFinanceiroTotal;
      this.detalhamentoFinanceiroEditar.tipo = this.detalhamentoFinanceiroTipo;
      this.detalhamentoFinanceiroEditar.categoria = this.detalhamentoFinanceiroCategoria;
    }
  }
  alterarDetalhamentoFinanceiro() {
    let adf = new AlterDetalhamentoFinanceiro();
    adf.versaoPlano = this.versaoPlano.id
    adf.usuarioSubmeteu = this.auth.jwtPayload.id
    adf.pthpId = this.planoTrabalhoHasProponente.id
    adf.data = new Date().getTime()
    adf.statusAlteracao = "2" //submetido
    adf.tipoAlteracao = "0" //Alterado

    adf.detalhamentoFinanceiro = this.detalhamentoFinanceiroEditar.id
    adf.codigoDepois = this.detalhamentoFinanceiroEditar.codigo
    adf.especificacaoDepois = this.detalhamentoFinanceiroEditar.especificacao
    adf.totalDepois = this.detalhamentoFinanceiroEditar.total
    adf.tipo = this.detalhamentoFinanceiroEditar.tipo
    adf.categoria = this.detalhamentoFinanceiroEditar.categoria.id

    if (this.detalhamentoFinanceiroEditar.tipoAlteracao == "Alterado") {
      //atualizar
      this.listaAlteracoesDetalhamentoFin.forEach(altEtapa => {
        if (altEtapa.detalhamentoFinanceiro == this.detalhamentoFinanceiroEditar.id) {
          adf.codigoAntes = altEtapa.codigoAntes
          adf.especificacaoAntes = altEtapa.especificacaoAntes
          adf.totalAntes = altEtapa.totalAntes
          adf.id = altEtapa.id
        }
      });
      this.atualizaAlterDetalhamentoFinanceiro(adf)

    } else if (this.detalhamentoFinanceiroEditar.tipoAlteracao == "Adicionado") {
      this.listaAlteracoesDetalhamentoFin.forEach(altEtapa => {
        if (altEtapa.detalhamentoFinanceiro == this.detalhamentoFinanceiroEditar.id) {
          adf.codigoAntes = altEtapa.codigoAntes
          adf.especificacaoAntes = altEtapa.especificacaoAntes
          adf.totalAntes = altEtapa.totalAntes
          adf.id = altEtapa.id
        }
      });

      adf.tipoAlteracao = "1" //Adicionado
      this.atualizaAlterDetalhamentoAtualizaDetalhamento(adf)

    } else {
      //adicionar
      adf.codigoAntes = this.detalhamentoFinanceiroCodigo
      adf.especificacaoAntes = this.detalhamentoFinanceiroEspecificacao
      adf.totalAntes = this.detalhamentoFinanceiroTotal
      this.adicionaAlterDetalhamentoFinanceiro(adf)
    }

  }
  setaDespesa(AlterDetal) {
    this.listDetalhamentoFinanceiro.forEach(detal => {
      if (detal.id == AlterDetal.detalhamentoFinanceiro) {
        var index = this.listDetalhamentoFinanceiro.indexOf(detal)

        this.listDetalhamentoFinanceiro[index].codigo = AlterDetal.codigoDepois
        this.listDetalhamentoFinanceiro[index].especificacao = AlterDetal.especificacaoDepois
        this.listDetalhamentoFinanceiro[index].total = AlterDetal.totalDepois
        this.listDetalhamentoFinanceiro[index].tipoAlteracao = "Alterado"
      }
    });
    this.editandoDetalhamentoFinanceiro = false;
    this.modalEditarDetalhamentoFinanceiro = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }

  //ADICIONAR DESPESA
  openModalAddDetalhamentoFinanceiro(form: FormControl) {
    this.pessoaCeletistaSelecionada = false;
    form.reset();
    this.modalAdicionarDetalhamentoFinanceiro = true;
  }
  adicionarDetalhamentoFinanceiro() {
    let adf = new AlterDetalhamentoFinanceiro();
    adf.versaoPlano = this.versaoPlano.id
    adf.usuarioSubmeteu = this.auth.jwtPayload.id
    adf.pthpId = this.planoTrabalhoHasProponente.id
    adf.data = new Date().getTime()
    adf.statusAlteracao = "2" //submetido
    adf.tipoAlteracao = "1" //Adicionado

    adf.tipo = this.detalhamentoFinanceiroAdd.tipo
    adf.categoria = this.detalhamentoFinanceiroAdd.categoria.id
    adf.especificacaoDepois = this.detalhamentoFinanceiroAdd.especificacao
    adf.totalDepois = 0

    this.alterDetalhamentoFinanceiroService.addDetalhamentoFinAddAlter(adf)
      .then(retorno => {
        this.idDespesaIterada = 0;
        let detalhamentoFin = this.setaAtributosdetalhamentoFin(retorno)

        let i = this.listDetalhamentoFinanceiro.length;

        this.listDetalhamentoFinanceiro[i] = detalhamentoFin;
        this.modalAdicionarDetalhamentoFinanceiro = false;
        this.toasty.success("Despesa salva com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  setaAtributosdetalhamentoFin(obj: AlterDetalhamentoFinanceiro) {
    let detal = new DetalhamentoFinanceiro()

    detal.id = obj.detalhamentoFinanceiro
    detal.codigo = obj.codigoDepois
    detal.especificacao = obj.especificacaoDepois
    detal.total = obj.totalDepois
    detal.tipoAlteracao = obj.tipoAlteracao
    detal.categoria.id = obj.categoria
    detal.tipo = obj.tipo
    detal.detalhamentoFinanceiroHasEtapaCronogramaFisico = []

    let i = this.listaAlteracoesDetalhamentoFin.length
    this.listaAlteracoesDetalhamentoFin[i] = obj

    return detal;
  }

  //EXCLUIR DESPESA
  confirmarExclusaoDetalhamentoFinanceiro(despesa) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluirDespesa(despesa);
      }
    });
  }
  excluirDespesa(despesa) {
    let adf = new AlterDetalhamentoFinanceiro();
    adf.versaoPlano = this.versaoPlano.id
    adf.usuarioSubmeteu = this.auth.jwtPayload.id
    adf.detalhamentoFinanceiro = despesa.id
    adf.pthpId = this.planoTrabalhoHasProponente.id
    adf.data = new Date().getTime()
    adf.statusAlteracao = "2" //submetido
    adf.tipoAlteracao = "2" //Excluido

    if (despesa.tipoAlteracao == "Alterado") {
      //é de uma versao
      this.listaAlteracoesDetalhamentoFin.forEach(alterDetal => {
        if (alterDetal.detalhamentoFinanceiro == despesa.id) {
          adf.codigoAntes = alterDetal.codigoAntes
          adf.especificacaoAntes = alterDetal.especificacaoAntes
          adf.totalAntes = alterDetal.totalAntes
          adf.id = alterDetal.id
        }
      });
      this.atualizaAlterDetalhamentoFinanceiro(adf)
    } else if (despesa.tipoAlteracao == "Adicionado") {
      this.listaAlteracoesDetalhamentoFin.forEach(alterDetal => {
        if (alterDetal.detalhamentoFinanceiro == despesa.id) {
          adf.codigoAntes = alterDetal.codigoAntes
          adf.especificacaoAntes = alterDetal.especificacaoAntes
          adf.totalAntes = alterDetal.totalAntes
          adf.id = alterDetal.id
        }
      });
      this.delAlterDelDespesaDeldetalhamento(adf)
    } else {
      //é original do plano      
      adf.codigoAntes = despesa.codigo
      adf.especificacaoAntes = despesa.especificacao
      adf.totalAntes = despesa.total
      this.adicionaAlterDetalhamentoFinanceiro(adf)

    }
  }
  delAlterDelDespesaDeldetalhamento(adf) {
    this.alterDetalhamentoFinanceiroService.excluir(adf.id)
      .then(() => {
        this.removeDespesaArrayPrincipal(adf)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeDespesaArrayPrincipal(alterDetal) {
    let index
    this.listDetalhamentoFinanceiro.forEach(detal => {
      if (detal.id == alterDetal.detalhamentoFinanceiro) {
        index = this.listDetalhamentoFinanceiro.indexOf(detal)
      }
    });
    this.listDetalhamentoFinanceiro.splice(index, 1)
    this.toasty.success("Despesa excluída com sucesso!");
  }

  //UTILS DESPESA 
  adicionaAlterDetalhamentoFinanceiro(adf) {
    this.alterDetalhamentoFinanceiroService.adicionar(adf)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeDespesaArrayPrincipal(retorno)
        } else {
          var index = this.listaAlteracoesDetalhamentoFin.length
          this.listaAlteracoesDetalhamentoFin[index] = retorno

          this.setaDespesa(retorno)

        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterDetalhamentoFinanceiro(adf) {
    this.alterDetalhamentoFinanceiroService.atualizar(adf)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeDespesaArrayPrincipal(retorno)
        } else {
          this.editandoDetalhamentoFinanceiro = false;
          this.modalEditarDetalhamentoFinanceiro = false;
          this.toasty.success("Alteração realizada com sucesso!");
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterDetalhamentoAtualizaDetalhamento(adf) {
    this.alterDetalhamentoFinanceiroService.atualizaAlterAtualDetalhamento(adf)
      .then(() => {
        this.editandoDetalhamentoFinanceiro = false;
        this.modalEditarDetalhamentoFinanceiro = false;
        this.toasty.success("Alteração realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //=== ADD DETALHAMENTO ===//
  openModalAddDetalhamento(despesa: DetalhamentoFinanceiro, form: FormControl, formCeletista: FormControl, formHorista: FormControl) {
    if (despesa.tipo == "Pessoa_celetista") {
      //this.carregaParametrosContabeis();

      //let i = new DetalhamentoFinanceiro();
      //i.id = despesa.id;
      //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAddCeletista.detalhamentoFinanceiro = i;
      //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAddCeletista.etapaCronogramaFisico = null;
      //formCeletista.reset();

      //this.calculoCeletista = false;
      //this.modalAdicionarDetalhamentoFinanceiroHasEtapaCronogramaFisicoCeletista = true;
    } else if (despesa.tipo == "Pessoa_horista") {
      //this.listaMetaCronogramaFisico = this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.map(
      //  c => ({ label: c.meta, value: c.id })
      //);

      //let i = new DetalhamentoFinanceiro();
      //i.id = despesa.id;
      //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAddHorista.detalhamentoFinanceiro = i;

      //formHorista.reset();
      //this.modalAdicionarDetalhamentoFinanceiroHasEtapaCronogramaFisicoHorista = true;
    } else {
      this.listaMetaCronogramaFisico = this.listaMetas.map(
        c => ({ label: c.meta, value: c.id })
      );

      let i = new DetalhamentoFinanceiro();
      i.id = despesa.id;
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.detalhamentoFinanceiro = i;
      form.reset();

      if (despesa.tipo == "Padrao") {
        this.mostraAtrEquipMatPerm = true;
      } else {
        this.mostraAtrEquipMatPerm = false;
        this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.equipamentoMaterialPermanente = null;
      }

      this.modalAdicionarDetalhamentoFinanceiroHasEtapaCronogramaFisico = true;
    }
  }
  //Padrão
  async adicionarDetalhamentoPadrao() {
    let adhe = new AlterDetalhamentoHasEtapa()
    adhe.versaoPlano = this.versaoPlano.id
    adhe.usuarioSubmeteu = this.auth.jwtPayload.id
    adhe.pthpId = this.planoTrabalhoHasProponente.id
    adhe.data = new Date().getTime()
    adhe.statusAlteracao = "2" //submetido
    adhe.tipoAlteracao = "1" //Adicionado

    adhe.detalhamentoFinanceiro = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.detalhamentoFinanceiro.id
    adhe.etapaCronogramaFisico = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.etapaCronogramaFisico.id
    adhe.nomeEtapaDepois = await this.retornaNomeEtapa(adhe.etapaCronogramaFisico)

    adhe.especificacaoDepois = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.especificacao
    adhe.unDepois = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.un
    adhe.quantidadeDepois = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.quantidade
    adhe.valorUnitarioDepois = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.valorUnitario
    adhe.valorTotalDepois = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.valorTotal

    this.alterDetalhamentoHasEtapaService.addDetalhamentoHasAddAlter(adhe)
      .then(retorno => {
        let detalhamento = this.setaAtributosdetalhamento(retorno)
        this.listDetalhamentoFinanceiro.forEach(despesa => {
          if (despesa.id == retorno.detalhamentoFinanceiro) {
            let i = despesa.detalhamentoFinanceiroHasEtapaCronogramaFisico.length
            let index = this.listDetalhamentoFinanceiro.indexOf(despesa)
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i] = detalhamento
          }
        })

        this.modalAdicionarDetalhamentoFinanceiroHasEtapaCronogramaFisico = false
        this.toasty.success("Detalhamento salvo com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  async retornaNomeEtapa(idEtapa) {
    let i
    await this.listaEtapaCronogramaFisico.forEach(etapa => {
      if (etapa.value == idEtapa)
        i = etapa.label
    });
    return i
  }
  setaAtributosdetalhamento(obj: AlterDetalhamentoHasEtapa) {
    let detalhamento = new DetalhamentoFinanceiroHasEtapaCronogramaFisico()

    detalhamento.etapaCronogramaFisico.id = obj.etapaCronogramaFisico
    detalhamento.detalhamentoFinanceiro.id = obj.detalhamentoFinanceiro
    detalhamento.especificacao = obj.especificacaoDepois
    detalhamento.un = obj.unDepois
    detalhamento.quantidade = obj.quantidadeDepois
    detalhamento.valorUnitario = obj.valorUnitarioDepois
    detalhamento.valorTotal = obj.valorTotalDepois
    detalhamento.nomeEtapa = obj.nomeEtapaDepois

    let i = this.listaAlteracoesDetalhamentoHas.length
    this.listaAlteracoesDetalhamentoHas[i] = obj

    return detalhamento
  }

  //EXCLUIR DETALHAMENTO
  confirmarExclusaoDetalhamento(detalhamento, despesa) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluirDetalhamento(detalhamento, despesa);
      }
    });
  }
  async excluirDetalhamento(detalhamento, despesa) {
    let adhe = new AlterDetalhamentoHasEtapa()
    adhe.versaoPlano = this.versaoPlano.id
    adhe.usuarioSubmeteu = this.auth.jwtPayload.id
    adhe.pthpId = this.planoTrabalhoHasProponente.id
    adhe.data = new Date().getTime()
    adhe.statusAlteracao = "2" //submetido
    adhe.tipoAlteracao = "2" //Excluido

    adhe.detalhamentoHasEtapa = detalhamento.id
    adhe.detalhamentoFinanceiro = despesa.id

    if (detalhamento.tipoAlteracao == "Alterado") {
      //é de uma versao
      await this.listaAlteracoesDetalhamentoHas.forEach(alterDetalhamento => {
        if (alterDetalhamento.detalhamentoHasEtapa == detalhamento.id) {
          adhe.id = alterDetalhamento.id
        }
      });
      //console.log(adhe)
      this.atualizaAlterDetalhamentoHasEtapa(adhe)
    } else if (detalhamento.tipoAlteracao == "Adicionado") {
      //é de um adicionado na alteração
      await this.listaAlteracoesDetalhamentoHas.forEach(alterDespesa => {
        if (alterDespesa.detalhamentoHasEtapa == detalhamento.id) {
          adhe.id = alterDespesa.id
        }
      });
      this.delAlterDelDetalhamentoHas(adhe)
    } else {
      //é original do plano      
      this.adicionaAlterDetalhamentoHasEtapa(adhe)

    }

  }
  delAlterDelDetalhamentoHas(adhe) {
    this.alterDetalhamentoHasEtapaService.excluir(adhe.id)
      .then(() => {
        this.removeDetalhamentoArrayPrincipal(adhe)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeDetalhamentoArrayPrincipal(alterDetalhamento: AlterDetalhamentoHasEtapa) {

    this.listDetalhamentoFinanceiro.forEach(despesa => {
      if (despesa.id == alterDetalhamento.detalhamentoFinanceiro) {
        var index = this.listDetalhamentoFinanceiro.indexOf(despesa)

        this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
          if (detalhamento.id == alterDetalhamento.detalhamentoHasEtapa) {

            var i = this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.splice(i, 1)
            this.toasty.success("Detalhamento excluído com sucesso!");
          }
        });
      }
    });

  }

  //EDITAR DETALHAMENTO
  openModalEditarDetalhamentoPadrao(detalhamento, idDespesa) {
    this.etapaEditarNgModel = null;
    this.metaEditarNgModel = null;
    this.listaEtapaCronogramaFisicoEditar = [];

    this.idDetalhamentoFinanceiro = idDespesa;

    this.detalhamentoNomeEtapa = detalhamento.nomeEtapa;
    this.detalhamentoEspecificacao = detalhamento.especificacao;
    this.detalhamentoUn = detalhamento.un;
    this.detalhamentoQuantidade = detalhamento.quantidade;
    this.detalhamentoValorUnitario = detalhamento.valorUnitario;
    this.detalhamentoValorTotal = detalhamento.valorTotal;
    this.detalhamentoMaterialPermanente = detalhamento.equipamentoMaterialPermanente;

    this.detalhamentoEditar = detalhamento;

    this.detalhamentoHasEtapaService.buscarPorCodigo(this.detalhamentoEditar.id)
      .then(retorno => {
        this.setaMetaEtapa(retorno);
        if (retorno.detalhamentoFinanceiro.tipo == "Padrao") {
          this.verificaEquipamentoMaterialPermanente();
          this.mostraAtrEquipMatPerm = true;
        } else {
          this.mostraAtrEquipMatPerm = false;
          this.detalhamentoEditar.equipamentoMaterialPermanente = null;
        }
        this.modalEditarDetalhamento = true;
        this.editandoDetalhamento = true;
      })
      .catch(erro => this.errorHandler.handle(erro));


  }
  closeModalEditarDetalhamentoPadrao(flag = false) {
    this.etapaEditarNgModel = null;
    this.metaEditarNgModel = null;
    this.listaEtapaCronogramaFisicoEditar = [];

    if (this.editandoDetalhamento) {
      if (flag == false) {
        this.detalhamentoEditar.nomeEtapa = this.detalhamentoNomeEtapa;
        this.detalhamentoEditar.especificacao = this.detalhamentoEspecificacao;
        this.detalhamentoEditar.un = this.detalhamentoUn;
        this.detalhamentoEditar.quantidade = this.detalhamentoQuantidade;
        this.detalhamentoEditar.valorUnitario = this.detalhamentoValorUnitario;
        this.detalhamentoEditar.valorTotal = this.detalhamentoValorTotal;
        this.detalhamentoEditar.equipamentoMaterialPermanente = this.detalhamentoMaterialPermanente;
      } else {
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.nomeEtapa = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoNomeEtapa;
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.especificacao = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEspecificacao;
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.un = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoUn;
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.quantidade = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoQuantidade;
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.valorUnitario = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoValorUnitario;
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.valorTotal = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoValorTotal;
        //this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEditarHorista.equipamentoMaterialPermanente = this.detalhamentoFinanceiroHasEtapaCronogramaFisicoEquipamentoMaterialPermanente;
      }
    }
  }
  alterarDetalhamentoPadrao(form: FormControl, flag = false) {
    let adhe = new AlterDetalhamentoHasEtapa();
    adhe.versaoPlano = this.versaoPlano.id
    adhe.usuarioSubmeteu = this.auth.jwtPayload.id
    adhe.pthpId = this.planoTrabalhoHasProponente.id
    adhe.data = new Date().getTime()
    adhe.statusAlteracao = "2" //submetido
    adhe.tipoAlteracao = "0" //Alterado

    adhe.detalhamentoFinanceiro = this.idDetalhamentoFinanceiro
    adhe.detalhamentoHasEtapa = this.detalhamentoEditar.id

    adhe.especificacaoDepois = this.detalhamentoEditar.especificacao
    adhe.unDepois = this.detalhamentoEditar.un
    adhe.funcaoDepois = this.detalhamentoEditar.funcao
    adhe.mesDepois = this.detalhamentoEditar.mes
    adhe.mesAnoContratacaoDepois = this.detalhamentoEditar.mesAnoContratacao
    adhe.mesAnoDispensaDepois = this.detalhamentoEditar.mesAnoDispensa
    adhe.quantidadeDepois = this.detalhamentoEditar.quantidade
    adhe.salarioDepois = this.detalhamentoEditar.salario
    adhe.inssDepois = this.detalhamentoEditar.inss
    adhe.terceiroDepois = this.detalhamentoEditar.terceiro
    adhe.fgtsDepois = this.detalhamentoEditar.fgts
    adhe.pisDepois = this.detalhamentoEditar.pis
    adhe.umDozeDeFeriasDepois = this.detalhamentoEditar.umDozeDeFerias
    adhe.umTresDeFeriasDepois = this.detalhamentoEditar.umTresDeFerias
    adhe.umDozeDeTrezeSalarioDepois = this.detalhamentoEditar.umDozeDeTrezeSalario
    adhe.multaRecisoriaDepois = this.detalhamentoEditar.multaRecisoria
    adhe.encargoMensalDepois = this.detalhamentoEditar.encargoMensal
    adhe.valorUnitarioDepois = this.detalhamentoEditar.valorUnitario
    adhe.totalUnitarioDepois = this.detalhamentoEditar.totalUnitario
    adhe.valorTotalDepois = this.detalhamentoEditar.valorTotal
    adhe.nomeEtapaDepois = this.detalhamentoEditar.nomeEtapa
    adhe.mesDepois = this.detalhamentoEditar.mes

    if (this.detalhamentoEditar.tipoAlteracao == "Alterado") {
      //atualizar
      this.listaAlteracoesDetalhamentoHas.forEach(alterDetalhamento => {
        if (alterDetalhamento.detalhamentoHasEtapa == this.detalhamentoEditar.id) {
          adhe.id = alterDetalhamento.id
        }
      });

      this.atualizaAlterDetalhamentoHasEtapa(adhe)

    } else if (this.detalhamentoEditar.tipoAlteracao == "Adicionado") {
      //this.listaAlteracoesEtapa.forEach(alterEtapa => {
      //  if (alterEtapa.etapaCronogramaFisico == this.etapaCronogramaFisicoEditar.id) {
      //    aec.id = alterEtapa.id
      //  }
      //});

      //aec.tipoAlteracao = "1" //Adicionado
      //this.atualizaAlterEtapaCrononamaAtualizaCron(aec)

    } else {
      //adicionar
      this.adicionaAlterDetalhamentoHasEtapa(adhe)
    }
  }

  //UTILS DETALHAMENTO 
  adicionaAlterDetalhamentoHasEtapa(adhe) {
    this.alterDetalhamentoHasEtapaService.adicionar(adhe)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {

          this.removeDetalhamentoArrayPrincipal(retorno)
        } else {
          var i = this.listaAlteracoesDetalhamentoHas.length
          this.listaAlteracoesDetalhamentoHas[i] = adhe
          this.setaDetalhamento(retorno)
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterDetalhamentoHasEtapa(adhe) {
    this.alterDetalhamentoHasEtapaService.atualizar(adhe)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeDetalhamentoArrayPrincipal(retorno)
        } else {
          this.editandoDetalhamento = false;
          this.modalEditarDetalhamento = false;
          this.toasty.success("Alteração realizada com sucesso!");
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  setaDetalhamento(adhe: AlterDetalhamentoHasEtapa) {
    this.listDetalhamentoFinanceiro.forEach(despesa => {
      if (despesa.id == adhe.detalhamentoFinanceiro) {
        var index = this.listDetalhamentoFinanceiro.indexOf(despesa)

        despesa.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
          if (detalhamento.id == adhe.detalhamentoHasEtapa) {
            var i = this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)

            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].especificacao = adhe.especificacaoDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].un = adhe.unDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].funcao = adhe.funcaoDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mes = adhe.mesDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mesAnoContratacao = adhe.mesAnoContratacaoDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mesAnoDispensa = adhe.mesAnoDispensaDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].quantidade = adhe.quantidadeDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].salario = adhe.salarioDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].inss = adhe.inssDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].terceiro = adhe.terceiroDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].fgts = adhe.fgtsDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].pis = adhe.pisDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].umDozeDeFerias = adhe.umDozeDeFeriasDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].umTresDeFerias = adhe.umTresDeFeriasDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].umDozeDeTrezeSalario = adhe.umDozeDeTrezeSalarioDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].multaRecisoria = adhe.multaRecisoriaDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].encargoMensal = adhe.encargoMensalDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].valorUnitario = adhe.valorUnitarioDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].totalUnitario = adhe.totalUnitarioDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].nomeEtapa = adhe.nomeEtapaDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].mes = adhe.mesDepois
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico[i].tipoAlteracao = "Alterado"


          }
        });

      }
    });
    this.editandoDetalhamento = false;
    this.modalEditarDetalhamento = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }

  //UTILS
  retornaDespesaIterada() {
    if (this.idDespesaIterada >= this.listDetalhamentoFinanceiro.length) {
      this.idDespesaIterada = 0;
    }
    this.idDespesaIterada = this.idDespesaIterada + 1;
    return this.idDespesaIterada;
  }
  retornaTipo(tipo) {
    let r
    if (tipo == "Padrao") {
      r = "Material de consumo"
    } else if (tipo == "Pessoa_celetista") {
      r = "Pessoa Celetista"
    } else if (tipo == "Pessoa_horista") {
      r = "Pessoa Horista"
    } else if (tipo == "Diaria") {
      r = "Diária"
    } else if (tipo == "Premiacoes") {
      r = "Premiações"
    } else if (tipo == "Passagens") {
      r = "Passagens"
    } else if (tipo == "Consultorias") {
      r = "Consultorias"
    } else if (tipo == "Serv_ter_pessoa_jurd") {
      r = "Serviços de Terceiros Pessoa Jurídica"
    } else if (tipo == "Equip_mat_perm") {
      r = "Equipamento e Material Permanente"
    } else if (tipo == "Despesas_encar_proj_ant") {
      r = "Despesas e Encargos de Projetos Anteriores"
    }
    return r
  }
  verificaCategoria(idCategoria) {
    let retorno;
    this.listaCategoriasVer.forEach(categoria => {
      if (categoria.value == idCategoria) {
        retorno = categoria.label;
      }
    });
    return retorno;
  }
  calculaDespesa(df: DetalhamentoFinanceiro) {
    let i = 0;
    df.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(especificacao => {
      i = i + especificacao.valorTotal;
    });
    return i;
  }
  soma20Inss(qtd, valorUn) {
    return (valorUn / 100) * 20 * qtd;
  }
  tipoDespesaSelecionada(tipo) {
    if (tipo == 1) {
      this.categoriaReduzida = false;
      if (this.todasCategorias) {
        this.listaCategorias = this.todasCategorias;
      }
      this.detalhamentoFinanceiroAdd.categoria.id = 43;
      this.pessoaCeletistaSelecionada = true;
    } else if (tipo == 2) {
      this.categoriaReduzida = false;
      if (this.todasCategorias) {
        this.listaCategorias = this.todasCategorias;
      }
      this.pessoaCeletistaSelecionada = true;
      this.detalhamentoFinanceiroAdd.categoria.id = 44;
    } else {
      this.todasCategorias = this.listaCategorias;
      let u = 0;
      let i = [];
      this.pessoaCeletistaSelecionada = false;
      this.listaCategorias.forEach(categoria => {
        if (categoria.value != 43 && categoria.value != 44) {
          i[u] = categoria;
          u++;
        }
      });
      this.listaCategorias = i;
    }
  }
  setaMetaEtapa(detalhamento: DetalhamentoFinanceiroHasEtapaCronogramaFisico) {
    this.listaMetaCronogramaFisico = this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.map(
      c => ({ label: c.meta, value: c.id })
    );

    if (detalhamento.etapaCronogramaFisico != null) {
      this.etapaEditarNgModel = detalhamento.etapaCronogramaFisico.id;
      this.metaEditarNgModel =
        detalhamento.etapaCronogramaFisico.cronogramaFisicoExecucaoObjeto.id;

      this.metaSelecionadaEditar(this.metaEditarNgModel);
    }
  }
  metaSelecionadaEditar(idMeta) {
    this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.forEach(
      meta => {
        if (meta.id == idMeta) {
          this.listaEtapaCronogramaFisicoEditar = meta.etapaCronogramaFisico.map(
            c => ({ label: c.especificacao, value: c.id })
          );
        }
      }
    );
  }
  verificaEquipamentoMaterialPermanente() {
    if (this.detalhamentoEditar.equipamentoMaterialPermanente == true) {
      this.detalhamentoEditar.equipamentoMaterialPermanente = "true";
    } else if (this.detalhamentoEditar.equipamentoMaterialPermanente == false) {
      this.detalhamentoEditar.equipamentoMaterialPermanente = "false";
    }
  }
  somarValorTotal() {
    let i;
    i =
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.quantidade *
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.valorUnitario;
    if (i != 0) {
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.valorTotal = i;
    }

    let p;
    p =
      this.detalhamentoEditar.quantidade *
      this.detalhamentoEditar.valorUnitario;
    if (p != 0) {
      this.detalhamentoEditar.valorTotal = p;
    }
  }
  metaSelecionada(idMeta) {
    this.listaMetas.forEach(
      meta => {
        if (meta.id == idMeta) {
          this.listaEtapaCronogramaFisico = meta.etapaCronogramaFisico.map(
            c => ({ label: c.especificacao, value: c.id })
          );
        }
      }
    );
  }

  adicionarDetalhamentoFinanceiroHasEtapaCronogramaFisico(value) {
    return value;
  }

  etapaSelecionada(value) {
    return value;
  }

  verificaIniFimProjeto() {
    let i = this.planoTrabalhoHasProponenteService.retornaIniFimPlano(this.planoTrabalhoHasProponente)
    this.inicioProjeto = i.ini
    this.fimProjeto = i.fim
  }

}
