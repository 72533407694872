import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-pendencia-gerenciar',
  templateUrl: './pendencia-gerenciar.component.html',
})

export class PendenciaGerenciarComponent implements OnInit {
 
 
  constructor(private title: Title) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Pendência');
  }
}
