import { ConcedenteFiltro, ConcedenteService } from '../../core/services/concedente/concedente.service';
import { Component } from "@angular/core";
import { Headers, Http, URLSearchParams } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { environment } from "../../../environments/environment";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Cidadao, Entidade, ConcedenteUsuarioNovoDTO } from "../../core/model";
import { PessaoUtil } from "../../core/pessoa.util";
import { GetAdress } from '../../core/services/utils/get-address.service';

@Component({
  selector: "app-nova-entidade",
  templateUrl: "./nova-entidade-has-usuario.component.html"
})
export class NovaEntidadeHasUsuarioComponent {

  entidade = new Entidade();
  cidadao = new Cidadao();
  pessoaUtil = new PessaoUtil();
  filtroConcedente = new ConcedenteFiltro();

  repetirSenha: any;

  tipoEntidade = [
    { label: 'Unidade Administrtiva', value: 'UA' },
    { label: 'Concedente', value: 'Concedente' }
  ];

  constructor(
    private http: Http,
    private getAdress: GetAdress,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private concedenteService: ConcedenteService,
  ) {
    this.title.setTitle("Nova entidade");
  }

  etapa1;
  etapa2;
  etapa3;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
  }

  salvar() {
    if (!this.cidadao.senha && this.cidadao.senha != this.repetirSenha) {
      this.toasty.error("As senhas não conferem.");
      return null;
    }

    if (!this.entidade.tipoConcedente) {
      this.toasty.warning("O tipo da entidade tem que ser definido.");
      return null;
    }

    let concedenteUsuarioNovoDTO = new ConcedenteUsuarioNovoDTO()
    concedenteUsuarioNovoDTO.entidadeDto = this.entidade
    concedenteUsuarioNovoDTO.usuarioDto = this.cidadao
    this.concedenteService.adicionarNovo(concedenteUsuarioNovoDTO)
    .then(response => {
      this.toasty.success('Entidade concedente alterada com sucesso!');
      sessionStorage.setItem('viewEntidade', String(response.entidadeDto.id))
      this.router.navigate(["/cadastros/concedente/visualizar"]);
    })
    .catch(erro => this.errorHandler.handle(erro));
  }


  getEnderecoCep(event, tipo) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return
          }
          if (tipo === 'entidade') {
            this.entidade.endereco.cep = data.cep
            this.entidade.endereco.endereco = data.logradouro
            this.entidade.endereco.complemento = data.complemento
            this.entidade.endereco.bairro = data.bairro
            this.entidade.endereco.cidade = data.localidade
            this.entidade.endereco.estado = data.uf
          }
          if (tipo === 'cidadao') {
            this.cidadao.endereco.cep = data.cep
            this.cidadao.endereco.endereco = data.logradouro
            this.cidadao.endereco.complemento = data.complemento
            this.cidadao.endereco.bairro = data.bairro
            this.cidadao.endereco.cidade = data.localidade
            this.cidadao.endereco.estado = data.uf
          }
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }


  // ========== VALIDACAO CPF, EMAIL e CNPJ
  verificarCpf(): Promise<any> {
    if (this.pessoaUtil.vercpf(this.cidadao.cpf) != false) {
      if (this.cidadao.cpf != null && this.cidadao.cpf.length > 10) {

        let url = `${environment.apiUrl}/usuarios/pesquisa-publica`

        const params = new URLSearchParams();
        if (this.cidadao.cpf) {
          params.set("cpf", this.cidadao.cpf);
        }

        return this.http
          .get(url, { search: params })
          .toPromise()
          .then(response => {
            if (response.status == 200) {
              this.toasty.error("CPF já cadastrado")
              this.cidadao.cpf = null
            }
          });
      } else {
        this.toasty.warning("Por Favor preencha o CPF !")
      }

    } else {
      this.toasty.info("CPF INVALIDO")
      this.cidadao.cpf = null
    }

  }

  verificarEmail(): Promise<any> {
    if (this.cidadao.email != null && this.cidadao.email.length > 8) {
      this.cidadao.email = this.cidadao.email.toLowerCase();

      let url = `${environment.apiUrl}/usuarios/pesquisa-publica`
      const params = new URLSearchParams();
      if (this.cidadao.email) {
        params.set("email", this.cidadao.email);
      }
      return this.http
        .get(url, { search: params })
        .toPromise()
        .then(response => {

          if (response.status == 200) {
            this.toasty.error("Email já cadastrado")
            this.cidadao.email = null
          }
        });

    } else {
      this.toasty.warning("Por favor preencha o email !")
    }

  }

  verificarCnpj() {
    if (this.entidade.cnpj != null && this.entidade.cnpj.length > 10) {

      if (this.pessoaUtil.validarCNPJ(this.entidade.cnpj) != false) {
        this.filtroConcedente.cnpj = this.entidade.cnpj

        this.concedenteService.pesquisar(this.filtroConcedente).then(resultado => {

          if (resultado.totalElements != 0) {
            this.toasty.error("CNPJ já cadastrado!")
            this.entidade.cnpj = null
          }
        });

      } else {
        this.entidade.cnpj = null
        this.errorHandler.handle("CNPJ inválido!");
      }
    } else {
      this.toasty.warning("Por favor preencha o CNPJ !")
    }
  }

  verificarEmailEntidade() {
    this.entidade.email = this.entidade.email.toLowerCase();
  }

}