import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ToastyService } from "ng2-toasty";
import { ConfirmationService } from "primeng/components/common/api";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Page } from "../../core/pagination/pagination.model";
import { AuthService } from "../../seguranca/auth.service";
import { SetorFiltro, SetorService } from "../setor.service";
import { SetorEntidade } from "../../core/model/setorEntidade.model";

declare let AdminLTE: any;

@Component({
  selector: "app-setor-pesquisa",
  templateUrl: "./setor-pesquisa.component.html",
})
export class SetorPesquisaComponent implements OnInit {
  totalRegistros = 0;
  filtro = new SetorFiltro();
  setores = [];
  loading: boolean;

  constructor(
    private setorEntidadeService: SetorService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService
  ) {}

  ngOnInit() {
    AdminLTE.init();

    this.title.setTitle("Setores");

    if (this.auth.jwtPayload.perfil == "Administrador") {
      this.filtro.entidadeConcedente = null;
      this.filtro.entidadeProponente = null;
    } else {
      if (this.auth.jwtPayload.proponente) {
        this.filtro.entidadeProponente = this.auth.jwtPayload.proponente;
      } else {
        this.filtro.entidadeConcedente = this.auth.jwtPayload.concedente;
      }
    }
    this.pesquisar();
  }

  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.filtro.itensPorPagina = event.size;
    this.filtro.pagina = event.page;
    this.setorEntidadeService.pesquisar(this.filtro).then((res) => {
      this.page = res;
      this.setores = res.content;
    });
  }

  pesquisar(pagina = 0) {
    this.filtro.pagina = pagina;
    this.setorEntidadeService.pesquisar(this.filtro).then((resultado) => {
      this.loading = true;
      this.setores = resultado.content;
      this.page = resultado;
    });
  }

  confirmarExclusao(setorEntidade: any) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluir(setorEntidade);
      },
    });
  }

  confirmarDesabilitarSetor(setorEntidade: SetorEntidade) {
    this.confirmation.confirm({
      message: `Tem certeza que deseja ${
        setorEntidade.habilitado ? "desabilitar" : "habilitar"
      } setor <b>${setorEntidade.nome}</b>?`,
      accept: () => {
        setorEntidade.habilitado = !setorEntidade.habilitado;
        this.atualizarSetor(setorEntidade);
      },
      reject: () => {
        this.pesquisar();
      },
    });
  }

  atualizarSetor(setorEntidade: any) {
    this.setorEntidadeService
      .atualizar(setorEntidade)
      .then(() => {
        this.pesquisar();
        this.toasty.success("Setor atualizado com sucesso!");
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  excluir(setorEntidade: any) {
    this.setorEntidadeService
      .excluir(setorEntidade.id)
      .then(() => {
        this.pesquisar();
        this.toasty.success("Setor excluído com sucesso!");
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  setorSelecionada(setor: any) {
    sessionStorage.setItem("setorCompPesquisa", JSON.stringify(setor));
  }
}
