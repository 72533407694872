import { Component, OnInit } from '@angular/core';

import { MonitoramentoAvaliacao, AlterMonitoramentoAvaliacao } from '../../../core/model';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AlterMonitoramentoAvaliacaoFiltro, AlterMonitoramentoAvaliacaoService } from '../../../core/services/alteracao-plano/alterMonitoramentoAvaliacao.service';
import { AuthService } from '../../../seguranca/auth.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-alterar-monitoramento',
  templateUrl: './alterar-monitoramento.component.html',
})
export class AlterarMonitoramentoComponent implements OnInit {

  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  monitoramentoAvaliacao = new MonitoramentoAvaliacao();
  atualizar: boolean;

  loading
  alterMonitoramentoAvaliacao = new AlterMonitoramentoAvaliacao()

  constructor(
    private alterMonitoramentoAvaliacaoService: AlterMonitoramentoAvaliacaoService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.monitoramentoAvaliacao = this.planoTrabalhoHasProponente.monitoramentoAvaliacao[0];
    this.procuraMonitoramentoVersao()

  }

  // ==== PROCURAR ALTERAÇÃO MONITORAMENTO ==== //
  procuraMonitoramentoVersao() {
    let filtro = new AlterMonitoramentoAvaliacaoFiltro
    filtro.monitoramentoAvaliacao = this.monitoramentoAvaliacao.id
    filtro.statusAlteracao = "2" //Submetido  

    this.alterMonitoramentoAvaliacaoService.pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado[0] != null) {
          this.alterMonitoramentoAvaliacao = retorno.dado[0]
          this.monitoramentoAvaliacao.sustentabilidadeProposta = this.alterMonitoramentoAvaliacao.sustentabilidadePropostaDepois
          this.monitoramentoAvaliacao.tecnicaMonitoramento = this.alterMonitoramentoAvaliacao.tecnicaMonitoramentoDepois
          this.atualizar = true
        }
        this.loading = true
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  // ==== MODIFICAR MONITORAMENTO AVALIAÇÃO ==== //
  modificaMonitoramentoAvaliacao() {

    if (this.atualizar) {
      this.alterMonitoramentoAvaliacao.data = new Date().getTime()
      this.alterMonitoramentoAvaliacao.usuarioSubmeteu = this.auth.jwtPayload.id
      this.alterMonitoramentoAvaliacao.sustentabilidadePropostaDepois = this.monitoramentoAvaliacao.sustentabilidadeProposta
      this.alterMonitoramentoAvaliacao.tecnicaMonitoramentoDepois = this.monitoramentoAvaliacao.tecnicaMonitoramento

      this.atualizaAlterMonitoramentoAvaliacao()
    } else {
      let ama = new AlterMonitoramentoAvaliacao()
      ama.versaoPlano = this.versaoPlano.id
      ama.usuarioSubmeteu = this.auth.jwtPayload.id
      ama.pthpId = this.planoTrabalhoHasProponente.id    
      ama.data = new Date().getTime()
      ama.statusAlteracao = "2" //submetido
      ama.tipoAlteracao = "0" //Alterado

      ama.monitoramentoAvaliacao = this.monitoramentoAvaliacao.id
      ama.tecnicaMonitoramentoDepois = this.monitoramentoAvaliacao.tecnicaMonitoramento
      ama.sustentabilidadePropostaDepois = this.monitoramentoAvaliacao.sustentabilidadeProposta

      this.addAlterMonitoramentoAvaliacao(ama)
    }
  }

  addAlterMonitoramentoAvaliacao(ama) {
    this.alterMonitoramentoAvaliacaoService.adicionar(ama)
      .then(retorno => {
        this.atualizar = true
        this.alterMonitoramentoAvaliacao = retorno
        this.toasty.success("Ajuste realizado com Sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterMonitoramentoAvaliacao() {
    this.alterMonitoramentoAvaliacaoService.atualizar(this.alterMonitoramentoAvaliacao)
      .then(() => {
        this.toasty.success("Ajuste realizado com Sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


}
