import { MandatoInformacoesComponent } from './mandato/mandato-informacoes/mandato-informacoes.component';
import { MandatoVisualizarComponent } from './mandato/mandato-visualizar/mandato-visualizar.component';
import { MandatoListarComponent } from './mandato/mandato-listar/mandato-listar.component';
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "../seguranca/auth.guard";
import { ConviteOSCComponent } from "./convite-osc/convite-osc.component";
import { EmitirCertificadoComponent } from "./emitir-certificado/emitir-certificado.component";
import { OscCadastroComponent } from "./osc-cadastrar-editar/osc-cadastro.component";
import { OscGerenciarComponent } from "./osc-gerenciar/osc-gerenciar.component";
import { OscListarComponent } from "./osc-listar/osc-listar.component";
import { OscViewComponent } from "./osc-view/osc-view.component";
// import { OsComponent } from "./os/os.component";
// import { OscipComponent } from "./oscip/oscip.component";
// import { PrefeiturasComponent } from "./prefeituras/prefeituras.component";
import { NovaEntidadeComponent } from "./nova-entidade/nova-entidade.component";
import { MandatoGerenciarComponent } from "./mandato/mandato-gerenciar/mandato-gerenciar.component";
import { MandatoCadastrarComponent } from './mandato/mandato-cadastrar/mandato-cadastrar.component';

const routers: Routes = [
  { path: '', component: OscGerenciarComponent, canActivate: [AuthGuard],
    children: [
          { path: 'visualizar', component: OscViewComponent, canActivate: [AuthGuard] },
          { path: 'listar/:tipo', component: OscListarComponent, canActivate: [AuthGuard] },
          { path: 'convite', component: ConviteOSCComponent, canActivate: [AuthGuard] },
          { path: 'emitir-certificado', component: EmitirCertificadoComponent, canActivate: [AuthGuard] },
          { path: 'novo', component: NovaEntidadeComponent, canActivate: [AuthGuard] },
          { path: 'osc-editar', component: OscCadastroComponent, canActivate: [AuthGuard] },
          // { path: 'os', component: OsComponent, canActivate: [AuthGuard] },
          // { path: 'oscip', component: OscipComponent, canActivate: [AuthGuard] },
          // { path: 'prefeituras', component: PrefeiturasComponent, canActivate: [AuthGuard] },
          { path: 'mandato', component: MandatoGerenciarComponent, canActivate: [AuthGuard], 
          children:[
            { path: 'listar', component: MandatoListarComponent, canActivate: [AuthGuard] },
            { path: 'cadastrar', component: MandatoCadastrarComponent, canActivate: [AuthGuard] },
          ]          
          },
  ]},
  { path: 'mandato-ver', component: MandatoVisualizarComponent, canActivate: [AuthGuard], 
  children:[
        { path: 'informacoes', component: MandatoInformacoesComponent, canActivate: [AuthGuard] },
  ] },
  
];

@NgModule({
  imports: [RouterModule.forChild(routers)],
  exports: [RouterModule]
})
export class OscRoutingModule {}
