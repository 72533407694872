import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxMaskModule } from "ngx-mask";
import { PopoverModule } from "ngx-popover";
import { MyPipesModule } from '../core/my-pipes/my-pipes.module';
import { PaginationModule } from '../core/pagination/pagination.module';
import { AtendidosProjetoService } from '../core/services/atendidos/atendidosProjeto.service';
import { CelebracaoHasAtendidosProjetoService } from '../core/services/atendidos/celebracaoHasAtendidosProjeto.service';
import { CelebracaoService } from "../core/services/celebracao/celebracao.service";
import { AnexosEvidenciasService } from "../core/services/despesa-evidencias/anexosEvidencias.service";
import { DespesaService } from "../core/services/despesa-evidencias/despesa.service";
import { DetalhamentoFisicoHasDespesaService } from "../core/services/despesa-evidencias/detalhamentoFisicoHasDespesa.service";
import { EvidenciasHasMetasService } from "../core/services/despesa-evidencias/evidenciasHasMetas.service";
import { PagamentoPessoalService } from "../core/services/despesa-evidencias/pagamentoPessoal.service";
import { PessoalProjetoService } from "../core/services/despesa-evidencias/pessoalProjeto.service";
import { PropostaService } from "../core/services/despesa-evidencias/proposta.service";
import { AlteracoesExecucaoService } from '../core/services/execucao-has-parceria/alteracoesExecucao.service';
import { AnaliseRepasseService } from '../core/services/financeiro/analise-remessa.service';
import { FinanceiroService } from "../core/services/financeiro/financeiro.service";
import { ContaBancariaService } from '../core/services/moduloFinanceiro/contaBancaria.service';
import { ExtratoService } from '../core/services/moduloFinanceiro/extrato.service';
import { DetalhamentoFinanceiroService } from "../core/services/plano-trabalho/detalhamentoFinanceiro.service";
import { DetalhamentoFinanceiroHasEtapaCronogramaFisicoService } from "../core/services/plano-trabalho/detalhamentoFinanceiroHasEtapaCronogramaFisico.service";
import { EtapaCronogramaFisicoService } from "../core/services/plano-trabalho/etapaCronogramaFisico.service";
import { EtapaCronogramaFisicoComDespesaService } from "../core/services/plano-trabalho/etapaCronogramaFisicoComDespesa.service";
import { PlanoTrabalhoHasProponenteService } from "../core/services/plano-trabalho/planoTrabalhoHasProponente.service";
import { UploadServiceModule } from '../core/upload-service/upload-service.module';
import { HabilitacaoDocModule } from '../habilitacao-doc/habilitacao-doc.module';
import { PlanoTrabalhoAjusteModule } from '../plano-trabalho-ajuste/plano-trabalho-ajuste.module';
import { RoteiroModule } from "../roteiro/roteiro.module";
import { SharedsModule } from "../shareds/shareds.module";
import { ReportsComponent } from './../core/reports/reports.component';
import { DocumentosPDFService } from './../core/services/relatorio/documentos-pdf.service';
import { AnaliseExecucaoListarComponent } from './analise-execucao/analise-execucao-listar/analise-execucao-listar.component';
import { AnaliseExecucaoComponent } from './analise-execucao/analise-execucao.component';
import { AnaliseRepasseRelatorioComponent } from './analise/analise-repasse-relatorio/analise-repasse-relatorio.component';
import { AnaliseRepasseComponent } from './analise/analise-repasse/analise-repasse.component';
import { AnaliseComponent } from "./analise/analise.component";
import { CotacaoPrecoComponent } from './analise/cotacao-preco/cotacao-preco.component';
import { ExtratoBancarioComponent } from './analise/extrato-bancario/extrato-bancario.component';
import { NotasComprovantesComponent } from './analise/notas-comprovantes/notas-comprovantes.component';
import { OrdemBancariaComprovanteTransferenciaComponent } from './analise/ordem-bancaria-comprovante-transferencia/ordem-bancaria-comprovante-transferencia.component';
import { PrestacaoContasComponent } from './analise/prestacao-contas/prestacao-contas.component';
import { ExecucaoFinanceiraComponent } from './analise/receita-despesa/receita-despesa.component';
import { RelacaoPagamentoComponent } from './analise/relacao-pagamento/relacao-pagamento.component';
import { RepassesComponent } from './analise/repasses/repasses.component';
import { BeneficiarioCadastroComponent } from './beneficiarios/beneficiarios-cadastro/beneficiarios-cadastro.component';
import { BeneficiariosGerenciarComponent } from './beneficiarios/beneficiarios-gerenciar/beneficiarios-gerenciar.component';
import { BeneficiariosListarComponent } from './beneficiarios/beneficiarios-listar/beneficiarios-listar.component';
import { ContrapartidaComponent } from "./contrapartida/contrapartida.component";
import { ExportarDocumentosComponent } from './exportar-documentos/exportar-documentos.component';
import { FinalComponent } from './final/final.component';
import { GerenciarComponent } from "./gerenciar/gerenciar.component";
import { InpugnarComponent } from "./inpugnar/inpugnar.component";
import { ListarComponent } from "./listar/listar.component";
import { ParcialComponent } from './parcial/parcial.component';
import { PrestacaoContasRoutingModule } from "./prestacao-contas.routing.module";
import { VisualizarExecucaoComponent } from "./visualizar/visualizar-execucao.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    RoteiroModule,
    SharedsModule,
    MyPipesModule,
    UploadServiceModule,

    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    PopoverModule,
    PaginationModule,
    HabilitacaoDocModule,
    PlanoTrabalhoAjusteModule,
    
    PrestacaoContasRoutingModule,
    
    RichTextEditorAllModule
  ],
  declarations: [
    VisualizarExecucaoComponent,
    ContrapartidaComponent,
    InpugnarComponent,
    AnaliseComponent,
    GerenciarComponent,
    ListarComponent,
    BeneficiariosGerenciarComponent,
    BeneficiarioCadastroComponent,
    BeneficiariosListarComponent,
    AnaliseExecucaoComponent,
    AnaliseExecucaoListarComponent,
    ParcialComponent,
    FinalComponent,
    PrestacaoContasComponent,
    RepassesComponent,
    AnaliseRepasseComponent,
    ReportsComponent,
    ExportarDocumentosComponent,
    RelacaoPagamentoComponent,
    ExecucaoFinanceiraComponent,
    NotasComprovantesComponent,
    OrdemBancariaComprovanteTransferenciaComponent,
    ExtratoBancarioComponent,
    CotacaoPrecoComponent,
    AnaliseRepasseRelatorioComponent
  ],
  providers: [
    PlanoTrabalhoHasProponenteService,
    DetalhamentoFinanceiroService,
    DetalhamentoFinanceiroHasEtapaCronogramaFisicoService,
    DespesaService,
    PropostaService,
    AnexosEvidenciasService,
    EvidenciasHasMetasService,
    DetalhamentoFisicoHasDespesaService,
    EtapaCronogramaFisicoComDespesaService,
    PessoalProjetoService,
    PagamentoPessoalService,
    CelebracaoService,
    EtapaCronogramaFisicoService,
    FinanceiroService,
    AtendidosProjetoService,
    CelebracaoHasAtendidosProjetoService,
    AlteracoesExecucaoService,
    DocumentosPDFService,
    ExtratoService,
    ContaBancariaService,
    AnaliseRepasseService
  ]
})
export class PrestacaoContasModule {}
