import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { ContaBancaria } from '../../model';

export class ContaBancariaFiltro {
  banco
  entidadeProponente
  planoTrabalhoHasProponente
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class ContaBancariaService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService,) {
    this.url = `${environment.apiUrl}/conta-bancaria`;
  }

  pesquisar(filtro: ContaBancariaFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.banco) {
      params.set('banco', filtro.banco);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.planoTrabalhoHasProponente) {
      params.set('planoTrabalhoHasProponente', filtro.planoTrabalhoHasProponente);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: ContaBancaria): Promise<ContaBancaria> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: ContaBancaria): Promise<ContaBancaria> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as ContaBancaria);
  }

  buscarPorCodigo(codigo: number): Promise<ContaBancaria> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as ContaBancaria;
        return entidade;
      });
  }

}
