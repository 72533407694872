import { Despesa } from "../../model";
import { environment } from "../../../../environments/environment";
import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";

export class DespesaFiltro {
  etapaCronogramaFisico: string;
  entidadeProponente: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class DespesaService {
  _Url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this._Url = `${environment.apiUrl}/despesa`;
  }

  pesquisar(filtro: DespesaFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.etapaCronogramaFisico) {
      params.set(
        "etapaCronogramaFisico",
        filtro.etapaCronogramaFisico
      );
    }
    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }

    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dados = responseJson.content;

        const resultado = {
          dados,
          total: responseJson.totalElements
        };
        ////console.log(resultado)
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();
    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this._Url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  excluirDespesa(codigo: number): Promise<void> {
    return this.http
      .delete(`${this._Url}/despesa/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Despesa): Promise<Despesa> {
    return this.http
      .post(this._Url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: Despesa): Promise<Despesa> {
    return this.http
      .put(`${this._Url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Despesa);
  }

  buscarPorCodigo(codigo: number): Promise<Despesa> {
    return this.http
      .get(`${this._Url}/${codigo}`)
      .toPromise()
      .then(response => {
        const retorno = response.json() as Despesa;
        return retorno;
      });
  }

  
  

}
