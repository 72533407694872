import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ComissaoSelecao } from '../../../core/model';
import { ConcedenteService } from '../../../core/services/concedente/concedente.service';
import { AuthService } from '../../../seguranca/auth.service';
import { ComissaoSelecaoService } from '../comissaoSelecao.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-comissao-selecao-cadastro',
  templateUrl: './comissao-selecao-cadastro.component.html',
})
export class ComissaoSelecaoCadastroComponent implements OnInit {
  comissaoSelecao = new ComissaoSelecao();
  entidades = [];
  entidade

  constructor(
    public auth: AuthService,
    private comissaoSelecaoService: ComissaoSelecaoService,
    private entidadeConcedenteService: ConcedenteService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private location: Location
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.verificaComissaoEntidade();

    this.title.setTitle('Nova comissão de seleção');
  }

  goBack() {
    this.location.back();
  }
  
  verificaComissaoEntidade() {
    if (this.auth.jwtPayload.concedente) {
      this.comissaoSelecao.entidadeConcedente.id = this.auth.jwtPayload.concedente;
      this.carregarEntidadesConcedentePreDefnida(this.auth.jwtPayload.concedente)
    } else {
      this.carregarEntidadesConcedente();
    }
  }

  salvar() {
    this.comissaoSelecaoService
      .adicionar(this.comissaoSelecao)
      .then(() => {
        this.toasty.success('Comissão de selecao adicionada com sucesso!');
        this.router.navigate(['/cadastros/concedente/comissao-selecao']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarEntidadesConcedente() {
    return this.entidadeConcedenteService
      .listarTodos()
      .then(entidades => {
        this.entidades = entidades.map(c => ({ label: c.nome, value: c.id }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarEntidadesConcedentePreDefnida(codigo) {
    return this.entidadeConcedenteService.buscarPorCodigo(codigo)
      .then(entidade => {
        this.entidade = entidade;
        ////console.log(this.entidade)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
}
