import { Component } from "@angular/core";

@Component({
  selector: "analise",
  templateUrl: "./analise.component.html"
})
export class AnaliseComponent {

  constructor() { }

}