import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { Notificacao } from '../../model';

export class NotificacaoFiltro {
  titulo
  conteudo
  usuarioRemetente
  pagina = 0;
  itensPorPagina = 5;
}

@Injectable()
export class NotificacaoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/notificacao`;
  }

  pesquisar(filtro: NotificacaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.conteudo) {
      params.set('conteudo', filtro.conteudo);
    }
    if (filtro.usuarioRemetente) {
      params.set('usuarioRemetente', filtro.usuarioRemetente);
    }
    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Notificacao): Promise<Notificacao> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: Notificacao): Promise<Notificacao> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Notificacao);
  }

  buscarPorCodigo(codigo: number): Promise<Notificacao> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as Notificacao;
        return entidade;
      });
  }

}
