import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from "../../seguranca/auth.service";
import { Router } from "@angular/router";

declare let AdminLTE: any;

@Component({
  selector: "app-osc-gerenciar",
  templateUrl: "./osc-gerenciar.component.html",
})
export class OscGerenciarComponent implements OnInit {
  showDefaultMessage;

  isCadastroOption: boolean = true;

  constructor(
    private title: Title,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit() {
    AdminLTE.init();

    this.title.setTitle("Gerenciar Proponentes");

    if (this.router.url === "/cadastros/proponente") {
      this.isCadastroOption = true;
    } else {
      this.isCadastroOption = false;
    }
  }
}
