import { Component, OnInit } from '@angular/core';
import { CapacidadeInstaladaService, CapacidadeInstaladaFiltro } from '../../../core/services/plano-trabalho/capacidadeInstalada.service';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { CapacidadeInstalada, VersaoPlano, InstalacoesFisicas, Equipe, EquipamentosDisponiveis } from '../../../core/model';
import { FormControl } from '@angular/forms';
import { InstalacoesFisicasService, InstalacoesFisicasFiltro } from '../../../core/services/plano-trabalho/instalacoesFisicas.service';
import { ConfirmationService } from 'primeng/primeng';
import { EquipeService, EquipeFiltro } from '../../../core/services/plano-trabalho/equipe.service';
import { EquipamentosDisponiveisService, EquipamentosDisponiveisFiltro } from '../../../core/services/plano-trabalho/equipamentosDisponiveis.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-alterar-capacidade-instalada',
  templateUrl: './alterar-capacidade-instalada.component.html',
})
export class AlterarCapacidadeInstaladaComponent implements OnInit {

  loading

  planoTrabalhoEscolhido: any;
  celebracao: any;
  versaoPlano: any;
  planoTrabalhoHasProponente: any;
  atualizar: boolean;
  capacidadeInstalada: any;
  mostraItensCapacidadeInstalada: boolean;
  mostraObsCapacidadeInstalada: boolean;
  instalacaoComodo: any;
  instalacaoQuantidade: any;
  instalacaoTipoAtividadeDesenv: any;
  instalacoesFisicasEditar = new InstalacoesFisicas();
  modalEditarInstalacoesFisicas: boolean;
  editandoInstalacoesFisicas: boolean;
  instalacoesDaVersao: any;
  instalacoesFisicas: any;
  instalacoesFisicasAdd = new InstalacoesFisicas();
  modalAdicionarInstalacoesFisicas: boolean;
  equipe: any;
  equipamentosDisponiveis: any;
  equipeDaVersao: any;
  equipamentoDaVersao: any;
  equipeAdd = new Equipe();
  modalAdicionarEquipe: boolean;
  editandoEquipe: boolean;
  editandoEquipamentosDisponiveis: boolean;
  modalAdicionarEquipamentosDisponiveis: boolean;
  equipamentosDisponiveisAdd = new EquipamentosDisponiveis();
  equipeNome: any;
  equipeFormacao: any;
  equipeFuncao: any;
  equipeCargaHoraria: any;
  equipeEditar = new Equipe();
  modalEditarEquipe: boolean;
  equipamentoTipoEquipamento: any;
  equipamentoQuantidade: any;
  equipamentosDisponiveisEditar= new EquipamentosDisponiveis()
  modalEditarEquipamentosDisponiveis: boolean;

  constructor(
    private capacidadeInstaladaService: CapacidadeInstaladaService,
    private instalacoesFisicasService: InstalacoesFisicasService,
    private equipeService: EquipeService,
    private equipamentosDisponiveisService: EquipamentosDisponiveisService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private confirmation: ConfirmationService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));

    this.procuraCapacidadeComAlteracaoAtiva()

  }

  //BUSCAR CAPACIDADE
  procuraCapacidadeComAlteracaoAtiva() {
    //Depois, procura por alguma alteração que esteja ativa
    let filtro = new CapacidadeInstaladaFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.ativoInativo = 0 //ativo
    this.capacidadeInstaladaService.pesquisar(filtro)
      .then(retorno => {
        this.seta(retorno.dado[0])
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  seta(capacidadeInstalada) {
    if (capacidadeInstalada == null) {
      //seta o resultado que veio do plano
      if (this.planoTrabalhoHasProponente.capacidadeInstalada[0]) {
        this.capacidadeInstalada = this.planoTrabalhoHasProponente.capacidadeInstalada[0];
      }
    } else {
      this.capacidadeInstalada = capacidadeInstalada
    }
    this.instalacoesFisicas = this.planoTrabalhoHasProponente.capacidadeInstalada[0].instalacoesFisicas;
    this.equipe = this.planoTrabalhoHasProponente.capacidadeInstalada[0].equipe;
    this.equipamentosDisponiveis = this.planoTrabalhoHasProponente.capacidadeInstalada[0].equipamentosDisponiveis;
    this.mostraItensCapacidadeInstalada = true;
    this.verificaTipoEstruturaFisica();

  }
  verificaTipoEstruturaFisica() {
    if (this.capacidadeInstalada.estruturaFisica == "Propria") {
      this.capacidadeInstalada.estruturaFisica = "0";
    } else if (this.capacidadeInstalada.estruturaFisica == "Cedida") {
      this.capacidadeInstalada.estruturaFisica = "1";
    } else if (this.capacidadeInstalada.estruturaFisica == "Alugada") {
      this.capacidadeInstalada.estruturaFisica = "2";
    } else if (this.capacidadeInstalada.estruturaFisica == "Outro") {
      this.capacidadeInstalada.estruturaFisica = "3";
      this.mostraObsCapacidadeInstalada = true;
    }
    this.procuraInstalacaoFisicaVersao()
  }

  //UTILS CAPACIDADE
  capacidadeInstaladaIgualOutro() {
    this.mostraObsCapacidadeInstalada = true;
  }
  capacidadeInstaladaDiferenteOutro() {
    this.mostraObsCapacidadeInstalada = false;
    this.capacidadeInstalada.estruturaFisicaOutro = null;
  }

  // ==== MODIFICAR CAPACIDADE INSTALADA ==== //
  modificaCapacidadeInstalada() {
    let versaoPlano = new VersaoPlano
    versaoPlano.id = this.versaoPlano.id

    this.capacidadeInstalada.versaoPlano = versaoPlano
    this.capacidadeInstalada.tipoAlteracao = 0 //Alteracao
    this.capacidadeInstalada.ativoInativo = 0 //Ativo
    this.capacidadeInstalada.planoTrabalhoHasProponente = null
    this.capacidadeInstalada.pthpId = this.planoTrabalhoHasProponente.id

    if (this.atualizar) {
      let va = new CapacidadeInstalada
      va.id = this.capacidadeInstalada.versaoAnterior.id
      this.capacidadeInstalada.versaoAnterior = va

      this.atualizaCapacidadeInstalada()
    } else {
      let versaoAnterior = new CapacidadeInstalada
      versaoAnterior.id = this.capacidadeInstalada.id
      this.capacidadeInstalada.versaoAnterior = versaoAnterior

      this.capacidadeInstalada.id = null
      this.addCapacidadeInstalada()
    }
  }
  addCapacidadeInstalada() {
    this.capacidadeInstaladaService.adicionar(this.capacidadeInstalada)
      .then(retorno => {
        this.atualizar = true
        this.capacidadeInstalada = retorno
        this.toasty.success("Ajuste realizado com Sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaCapacidadeInstalada() {
    this.capacidadeInstaladaService.atualizar(this.capacidadeInstalada)
      .then(() => {
        this.toasty.success("Ajuste realizado com Sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // === INÍCIO INSTALAÇÕES FÍSICAS === //

  //BUSCAR
  procuraInstalacaoFisicaVersao() {
    let filtro = new InstalacoesFisicasFiltro()
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.ativoInativo = '0' //ATIVAS 
    this.instalacoesFisicasService.pesquisar(filtro)
      .then(retorno => {
        this.instalacoesDaVersao = retorno.dado
        this.verificaInstalacoesVersao()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  verificaInstalacoesVersao() {
    this.instalacoesDaVersao.forEach(instVersao => {
      this.instalacoesFisicas.forEach(instPlano => {
        if (instVersao.versaoAnterior == instPlano.id) {
          var index = this.instalacoesFisicas.indexOf(instPlano)
          if (instVersao.tipoAlteracao == "Alterado") {
            this.instalacoesFisicas[index] = instVersao
          } else if (instVersao.tipoAlteracao == "Excluido") {
            this.instalacoesFisicas.splice(index, 1)
          }
        }
      });

      if (instVersao.tipoAlteracao == "Adicionado") {
        let i = this.instalacoesFisicas.length
        this.instalacoesFisicas[i] = instVersao
      }
    });
    this.procuraEquipeVersao()
  }
  //ALTERAR
  openModalEditarInstalacoesFisicas(instalacao) {
    this.instalacaoComodo = instalacao.comodo;
    this.instalacaoQuantidade = instalacao.quantidade;
    this.instalacaoTipoAtividadeDesenv = instalacao.tipoAtividadeDesenv;

    this.instalacoesFisicasEditar = instalacao;
    this.modalEditarInstalacoesFisicas = true;
    this.editandoInstalacoesFisicas = true;
  }
  closeModalEditarInstalacoesFisicas() {
    if (this.editandoInstalacoesFisicas) {
      this.instalacoesFisicasEditar.comodo = this.instalacaoComodo;
      this.instalacoesFisicasEditar.quantidade = this.instalacaoQuantidade;
      this.instalacoesFisicasEditar.tipoAtividadeDesenv = this.instalacaoTipoAtividadeDesenv;
    }
  }
  alterarInstalacoesFisicas() {
    let instalacoesF = new InstalacoesFisicas()
    instalacoesF.capacidadeInstalada = null
    instalacoesF.versaoPlano = this.versaoPlano.id
    instalacoesF.comodo = this.instalacoesFisicasEditar.comodo
    instalacoesF.quantidade = this.instalacoesFisicasEditar.quantidade
    instalacoesF.tipoAtividadeDesenv = this.instalacoesFisicasEditar.tipoAtividadeDesenv
    instalacoesF.instalacaoVersao = this.instalacoesFisicasEditar.instalacaoVersao
    instalacoesF.ativoInativo = 0 //ATIVO
    instalacoesF.tipoAlteracao = 0 //ALTERANDO
    instalacoesF.pthpId = this.planoTrabalhoHasProponente.id

    if (this.instalacoesFisicasEditar.versaoPlano == this.versaoPlano.id) {
      //Atualizar
      instalacoesF.id = this.instalacoesFisicasEditar.id
      instalacoesF.versaoAnterior = this.instalacoesFisicasEditar.versaoAnterior

      if (this.instalacoesFisicasEditar.tipoAlteracao == 'Adicionado') {
        instalacoesF.tipoAlteracao = 1 //Adicionado
      }
      this.atualizaInstalacoesFisicas(instalacoesF)

    } else {
      //Adicionar
      if (this.instalacoesFisicasEditar.versaoPlano) {
        if (this.instalacoesFisicasEditar.tipoAlteracao == 'Adicionado') {
          instalacoesF.tipoAlteracao = 1 //Adicionado
          instalacoesF.versaoAnterior = null
        } else {
          instalacoesF.versaoAnterior = this.instalacoesFisicasEditar.versaoAnterior
        }
        instalacoesF.instalacaoVersao = this.instalacoesFisicasEditar.id

        this.adicionaSetaInstalacaoFisica(instalacoesF, this.instalacoesFisicasEditar, this.instalacoesFisicasEditar.id)

      } else {
        instalacoesF.versaoAnterior = this.instalacoesFisicasEditar.id
        this.adicionaInstalacoesFisicas(instalacoesF, this.instalacoesFisicasEditar)
      }

    }

  }
  adicionaInstalacoesFisicas(instalacoesF, instAtual) {
    this.instalacoesFisicasService.adicionar(instalacoesF)
      .then(retorno => {
        this.setaInstalacaoAdd(instAtual, retorno)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  adicionaSetaInstalacaoFisica(instalacoesF, instAtual, id) {
    this.instalacoesFisicasService.adicionaSeta(instalacoesF, id)
      .then(retorno => {
        this.setaInstalacaoAdd(instAtual, retorno)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  setaInstalacaoAdd(instAtual, instRetorno) {
    this.instalacoesFisicas.forEach(insta => {
      if (insta.id == instAtual.id) {
        var index = this.instalacoesFisicas.indexOf(instAtual)
        this.instalacoesFisicas[index] = instRetorno
      }
    });
    this.editandoInstalacoesFisicas = false;
    this.modalEditarInstalacoesFisicas = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }
  atualizaInstalacoesFisicas(instalacoesF) {
    this.instalacoesFisicasService.atualizar(instalacoesF)
      .then(() => {
        this.editandoInstalacoesFisicas = false;
        this.modalEditarInstalacoesFisicas = false;
        this.toasty.success("Alteração realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //EXCLUIR
  confirmarExclusaoInstalacoesFisicas(instalacao) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluirInstalacaoFisica(instalacao);
      }
    });
  }
  excluirInstalacaoFisica(instalacao) {
    let instalacoesF = new InstalacoesFisicas()
    instalacoesF.capacidadeInstalada = null
    instalacoesF.versaoPlano = this.versaoPlano.id
    instalacoesF.ativoInativo = 0 //ATIVO
    instalacoesF.tipoAlteracao = 2 //EXCLUIDO
    instalacoesF.pthpId = this.planoTrabalhoHasProponente.id
    instalacoesF.versaoAnterior = instalacao.id

    if (instalacao.versaoPlano == this.versaoPlano.id) {
      instalacao.tipoAlteracao = 2
      this.excluirInstalacaoVersao(instalacao)
    } else {
      if (instalacao.versaoPlano) {
        this.excluirInstalacaoVersaoAnterior(instalacao)
      } else {
        this.excluirInstalacaoPlano(instalacoesF)
      }

    }
  }
  excluirInstalacaoVersao(instalacao) {
    this.instalacoesFisicasService.atualizar(instalacao)
      .then(() => {
        this.removeInstalacaoArrayPrincipal(instalacao)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  excluirInstalacaoPlano(instalacoesF) {
    this.instalacoesFisicasService.adicionar(instalacoesF)
      .then(() => {
        this.removeInstalacaoArrayPrincipal(instalacoesF)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  excluirInstalacaoVersaoAnterior(instalacao) {
    const i = instalacao.id
    instalacao.versaoPlano = this.versaoPlano.id
    instalacao.tipoAlteracao = 2
    instalacao.id = null
    instalacao.instalacaoVersao = i

    this.instalacoesFisicasService.adicionaSeta(instalacao, i)
      .then(() => {
        this.removeInstalacaoArrayPrincipal(instalacao)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeInstalacaoArrayPrincipal(instalacao) {
    var index = this.instalacoesFisicas.indexOf(instalacao)
    this.instalacoesFisicas.splice(index, 1)
    this.toasty.success("Espaço excluído com sucesso!");
  }

  //ADICIONAR
  openModalAddInstalacoesFisicas(form: FormControl) {
    form.reset();
    this.instalacoesFisicasAdd = new InstalacoesFisicas();
    this.modalAdicionarInstalacoesFisicas = true;
    this.editandoInstalacoesFisicas = false;
  }
  adicionarInstalacoesFisicas(form: FormControl) {
    this.instalacoesFisicasAdd.capacidadeInstalada = null;
    this.instalacoesFisicasAdd.versaoPlano = this.versaoPlano.id
    this.instalacoesFisicasAdd.ativoInativo = 0
    this.instalacoesFisicasAdd.tipoAlteracao = 1 //Adicionado
    this.instalacoesFisicasAdd.pthpId = this.planoTrabalhoHasProponente.id

    this.instalacoesFisicasService.adicionar(this.instalacoesFisicasAdd)
      .then(retorno => {
        form.reset();
        let i = this.instalacoesFisicas.length;
        this.instalacoesFisicas[i] = retorno;

        this.modalAdicionarInstalacoesFisicas = false;
        this.toasty.success("Espaço salvo com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // === FIM INSTALAÇÕES FÍSICAS === //

  // === INÍCIO EQUIPE === //

  //BUSCAR
  procuraEquipeVersao() {
    let filtro = new EquipeFiltro()
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.ativoInativo = '0' //ATIVAS 
    this.equipeService.pesquisar(filtro)
      .then(retorno => {
        this.equipeDaVersao = retorno.dado
        this.verificaEquipeVersao()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  verificaEquipeVersao() {
    this.equipeDaVersao.forEach(equiVersao => {
      this.equipe.forEach(equiPlano => {
        if (equiVersao.versaoAnterior == equiPlano.id) {
          var index = this.equipe.indexOf(equiPlano)
          if (equiVersao.tipoAlteracao == "Alterado") {
            this.equipe[index] = equiVersao
          } else if (equiVersao.tipoAlteracao == "Excluido") {
            this.equipe.splice(index, 1)
          }
        }
      });

      if (equiVersao.tipoAlteracao == "Adicionado") {
        let i = this.equipe.length
        this.equipe[i] = equiVersao
      }
    });
    this.procuraEquipamentoVersao()
  }

  //ALTERAR
  openModalEditarEquipe(equipe) {
    this.equipeNome = equipe.nome;
    this.equipeFormacao = equipe.formacao;
    this.equipeFuncao = equipe.funcao;
    this.equipeCargaHoraria = equipe.cargaHoraria

    this.equipeEditar = equipe;
    this.transformaFormacao();
    this.modalEditarEquipe = true;
    this.editandoEquipe = true;
  }
  closeModalEditarEquipe() {
    if (this.editandoEquipe) {
      this.transformaFormacao1();
      this.equipeEditar.nome = this.equipeNome
      this.equipeEditar.formacao = this.equipeFormacao
      this.equipeEditar.funcao = this.equipeFuncao
      this.equipeEditar.cargaHoraria = this.equipeCargaHoraria
    }
  }
  alterarEquipe() {
    let equipeF = new Equipe()
    equipeF.capacidadeInstalada = null
    equipeF.versaoPlano = this.versaoPlano.id
    equipeF.nome = this.equipeEditar.nome
    equipeF.formacao = this.equipeEditar.formacao
    equipeF.funcao = this.equipeEditar.funcao
    equipeF.cargaHoraria = this.equipeEditar.cargaHoraria
    equipeF.equipeVersao = this.equipeEditar.equipeVersao
    equipeF.ativoInativo = 0 //ATIVO
    equipeF.tipoAlteracao = 0 //ALTERANDO
    equipeF.pthpId = this.planoTrabalhoHasProponente.id

    if (this.equipeEditar.versaoPlano == this.versaoPlano.id) {
      //Atualizar
      equipeF.id = this.equipeEditar.id
      equipeF.versaoAnterior = this.equipeEditar.versaoAnterior

      if (this.equipeEditar.tipoAlteracao == 'Adicionado') {
        equipeF.tipoAlteracao = 1 //Adicionado
      }
      this.atualizaEquipe(equipeF)

    } else {
      //Adicionar
      if (this.equipeEditar.versaoPlano) {
        if (this.equipeEditar.tipoAlteracao == 'Adicionado') {
          equipeF.tipoAlteracao = 1 //Adicionado
          equipeF.versaoAnterior = null
        } else {
          equipeF.versaoAnterior = this.equipeEditar.versaoAnterior
        }
        equipeF.equipeVersao = this.equipeEditar.id

        this.adicionaSetaEquipe(equipeF, this.equipeEditar, this.equipeEditar.id)

      } else {
        equipeF.versaoAnterior = this.equipeEditar.id
        this.adicionaEquipe(equipeF, this.equipeEditar)
      }

    }

  }
  adicionaEquipe(equipeF, equipeAtual) {
    this.equipeService.adicionar(equipeF)
      .then(retorno => {
        this.setaEquipeAdd(equipeAtual, retorno)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  adicionaSetaEquipe(equipeF, equipeAtual, id) {
    this.equipeService.adicionaSeta(equipeF, id)
      .then(retorno => {
        this.setaEquipeAdd(equipeAtual, retorno)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  setaEquipeAdd(equipeAtual, equipeRetorno) {
    this.equipe.forEach(equi => {
      if (equi.id == equipeAtual.id) {
        var index = this.equipe.indexOf(equipeAtual)
        this.equipe[index] = equipeRetorno
      }
    });
    this.editandoEquipe = false;
    this.modalEditarEquipe = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }
  atualizaEquipe(equipeF) {
    this.equipeService.atualizar(equipeF)
      .then(() => {
        this.editandoEquipe = false;
        this.modalEditarEquipe = false;
        this.transformaFormacao1()
        this.toasty.success("Alteração realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //EXCLUIR
  confirmarExclusaoEquipe(equipe) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluirEquipe(equipe);
      }
    });
  }
  excluirEquipe(equipe) {
    let equipeF = new Equipe()
    equipeF.capacidadeInstalada = null
    equipeF.versaoPlano = this.versaoPlano.id
    equipeF.ativoInativo = 0 //ATIVO
    equipeF.tipoAlteracao = 2 //EXCLUIDO
    equipeF.pthpId = this.planoTrabalhoHasProponente.id
    equipeF.versaoAnterior = equipe.id

    if (equipe.versaoPlano == this.versaoPlano.id) {
      equipe.tipoAlteracao = 2
      this.excluirEquipeVersao(equipe)
    } else {
      if (equipe.versaoPlano) {
        this.excluirEquipeVersaoAnterior(equipe)
      } else {
        this.excluirEquipePlano(equipeF)
      }

    }
  }
  excluirEquipeVersao(equipe) {
    this.equipeService.atualizar(equipe)
      .then(() => {
        this.removeEquipeArrayPrincipal(equipe)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  excluirEquipePlano(equipeF) {
    this.equipeService.adicionar(equipeF)
      .then(() => {
        this.removeEquipeArrayPrincipal(equipeF)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  excluirEquipeVersaoAnterior(equipe) {
    const i = equipe.id
    equipe.versaoPlano = this.versaoPlano.id
    equipe.tipoAlteracao = 2
    equipe.id = null
    equipe.equipeVersao = i

    this.equipeService.adicionaSeta(equipe, i)
      .then(() => {
        this.removeEquipeArrayPrincipal(equipe)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeEquipeArrayPrincipal(equipe) {
    var index = this.equipe.indexOf(equipe)
    this.equipe.splice(index, 1)
    this.toasty.success("Profissional excluído com sucesso!");
  }

  //ADICIONAR
  openModalAddEquipe(form: FormControl) {
    form.reset();
    this.equipeAdd = new Equipe();
    this.modalAdicionarEquipe = true;
    this.editandoEquipe = false;
  }
  adicionarEquipe(form: FormControl) {
    this.equipeAdd.capacidadeInstalada = null;
    this.equipeAdd.versaoPlano = this.versaoPlano.id
    this.equipeAdd.ativoInativo = 0
    this.equipeAdd.tipoAlteracao = 1 //Adicionado
    this.equipeAdd.pthpId = this.planoTrabalhoHasProponente.id

    this.equipeService.adicionar(this.equipeAdd)
      .then(retorno => {
        form.reset();
        let i = this.equipe.length;
        this.equipe[i] = retorno;

        this.modalAdicionarEquipe = false;
        this.toasty.success("Profissinal salvo com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // === FIM EQUIPE === //

  // === INÍCIO EQUIPAMENTOS DISPONÍVEIS === //

  //BUSCAR
  procuraEquipamentoVersao() {
    let filtro = new EquipamentosDisponiveisFiltro()
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.ativoInativo = '0' //ATIVAS 
    this.equipamentosDisponiveisService.pesquisar(filtro)
      .then(retorno => {
        this.equipamentoDaVersao = retorno.dado
        this.verificaEquipamentoVersao()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  verificaEquipamentoVersao() {
    this.equipamentoDaVersao.forEach(equiVersao => {
      this.equipamentosDisponiveis.forEach(equiPlano => {
        if (equiVersao.versaoAnterior == equiPlano.id) {
          var index = this.equipamentosDisponiveis.indexOf(equiPlano)
          if (equiVersao.tipoAlteracao == "Alterado") {
            this.equipamentosDisponiveis[index] = equiVersao
          } else if (equiVersao.tipoAlteracao == "Excluido") {
            this.equipamentosDisponiveis.splice(index, 1)
          }
        }
      });

      if (equiVersao.tipoAlteracao == "Adicionado") {
        let i = this.equipamentosDisponiveis.length
        this.equipamentosDisponiveis[i] = equiVersao
      }
    });
    this.loading = true
  }

  //ALTERAR
  openModalEditarEquipamentosDisponiveis(equipamento) {
    this.equipamentoTipoEquipamento = equipamento.tipoEquipamento;
    this.equipamentoQuantidade = equipamento.quantidade;
    
    this.equipamentosDisponiveisEditar = equipamento;
    this.modalEditarEquipamentosDisponiveis = true;
    this.editandoEquipamentosDisponiveis = true;
  }
  closeModalEditarEquipamentosDisponiveis() {
    if (this.editandoEquipamentosDisponiveis) {
      this.equipamentosDisponiveisEditar.tipoEquipamento =  this.equipamentoTipoEquipamento
      this.equipamentosDisponiveisEditar.quantidade = this.equipamentoQuantidade
    }
  }
  alterarEquipamentosDisponiveis() {
    let equipamentoD = new EquipamentosDisponiveis()
    equipamentoD.capacidadeInstalada = null
    equipamentoD.versaoPlano = this.versaoPlano.id
    equipamentoD.tipoEquipamento = this.equipamentosDisponiveisEditar.tipoEquipamento
    equipamentoD.quantidade = this.equipamentosDisponiveisEditar.quantidade
    equipamentoD.equipamentoVersao = this.equipamentosDisponiveisEditar.equipamentoVersao
    equipamentoD.ativoInativo = 0 //ATIVO
    equipamentoD.tipoAlteracao = 0 //ALTERANDO
    equipamentoD.pthpId = this.planoTrabalhoHasProponente.id

    if (this.equipamentosDisponiveisEditar.versaoPlano == this.versaoPlano.id) {
      //Atualizar
      equipamentoD.id = this.equipamentosDisponiveisEditar.id
      equipamentoD.versaoAnterior = this.equipamentosDisponiveisEditar.versaoAnterior

      if (this.equipamentosDisponiveisEditar.tipoAlteracao == 'Adicionado') {
        equipamentoD.tipoAlteracao = 1 //Adicionado
      }
      this.atualizaEquipamentosDisponiveis(equipamentoD)

    } else {
      //Adicionar
      if (this.equipamentosDisponiveisEditar.versaoPlano) {
        if (this.equipamentosDisponiveisEditar.tipoAlteracao == 'Adicionado') {
          equipamentoD.tipoAlteracao = 1 //Adicionado
          equipamentoD.versaoAnterior = null
        } else {
          equipamentoD.versaoAnterior = this.equipamentosDisponiveisEditar.versaoAnterior
        }
        equipamentoD.equipamentoVersao = this.equipamentosDisponiveisEditar.id

        this.adicionaSetaEquipamentosDisponiveis(equipamentoD, this.equipamentosDisponiveisEditar, this.equipamentosDisponiveisEditar.id)

      } else {
        equipamentoD.versaoAnterior = this.equipamentosDisponiveisEditar.id
        this.adicionaEquipamentosDisponiveis(equipamentoD, this.equipamentosDisponiveisEditar)
      }

    }

  }
  adicionaEquipamentosDisponiveis(equipamentoD, equiAtual) {
    this.equipamentosDisponiveisService.adicionar(equipamentoD)
      .then(retorno => {
        this.setaEquipamentoAdd(equiAtual, retorno)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  adicionaSetaEquipamentosDisponiveis(equipamentoD, equiAtual, id) {
    this.equipamentosDisponiveisService.adicionaSeta(equipamentoD, id)
      .then(retorno => {
        this.setaEquipamentoAdd(equiAtual, retorno)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  setaEquipamentoAdd(equiAtual, equiRetorno) {
    this.equipamentosDisponiveis.forEach(equi => {
      if (equi.id == equiAtual.id) {
        var index = this.equipamentosDisponiveis.indexOf(equiAtual)
        this.equipamentosDisponiveis[index] = equiRetorno
      }
    });
    this.editandoEquipamentosDisponiveis = false;
    this.modalEditarEquipamentosDisponiveis = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }
  atualizaEquipamentosDisponiveis(equipamentoD) {
    this.equipamentosDisponiveisService.atualizar(equipamentoD)
      .then(() => {
        this.editandoEquipamentosDisponiveis = false;
        this.modalEditarEquipamentosDisponiveis = false;
        this.toasty.success("Alteração realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

   //EXCLUIR
   confirmarExclusaoEquipamentosDisponiveis(equipamento) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluirEquipamento(equipamento);
      }
    });
  }
  excluirEquipamento(equipamento) {
    let equipamentoD = new EquipamentosDisponiveis()
    equipamentoD.capacidadeInstalada = null
    equipamentoD.versaoPlano = this.versaoPlano.id
    equipamentoD.ativoInativo = 0 //ATIVO
    equipamentoD.tipoAlteracao = 2 //EXCLUIDO
    equipamentoD.pthpId = this.planoTrabalhoHasProponente.id
    equipamentoD.versaoAnterior = equipamento.id

    if (equipamento.versaoPlano == this.versaoPlano.id) {
      equipamento.tipoAlteracao = 2
      this.excluirEquipamentoVersao(equipamento)
    } else {
      if (equipamento.versaoPlano) {
        this.excluirEquipamentoVersaoAnterior(equipamento)
      } else {
        this.excluirEquipamentoPlano(equipamentoD)
      }

    }
  }
  excluirEquipamentoVersao(equipamento) {
    this.equipamentosDisponiveisService.atualizar(equipamento)
      .then(() => {
        this.removeEquipamentoArrayPrincipal(equipamento)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  excluirEquipamentoPlano(equipamentoD) {
    this.equipamentosDisponiveisService.adicionar(equipamentoD)
      .then(() => {
        this.removeEquipamentoArrayPrincipal(equipamentoD)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  excluirEquipamentoVersaoAnterior(equipamento) {
    const i = equipamento.id
    equipamento.versaoPlano = this.versaoPlano.id
    equipamento.tipoAlteracao = 2
    equipamento.id = null
    equipamento.equipamentoVersao = i

    this.equipamentosDisponiveisService.adicionaSeta(equipamento, i)
      .then(() => {
        this.removeEquipamentoArrayPrincipal(equipamento)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeEquipamentoArrayPrincipal(equipamento) {
    var index = this.equipamentosDisponiveis.indexOf(equipamento)
    this.equipamentosDisponiveis.splice(index, 1)
    this.toasty.success("Equipamento excluído com sucesso!");
  }

  //ADICIONAR
  openModalAddEquipamentosDisponiveis(form: FormControl) {
    form.reset();
    this.equipamentosDisponiveisAdd = new EquipamentosDisponiveis();
    this.modalAdicionarEquipamentosDisponiveis = true;
    this.editandoEquipamentosDisponiveis = false;
  }
  adicionarEquipamentosDisponiveis(form: FormControl) {
    this.equipamentosDisponiveisAdd.capacidadeInstalada = null;
    this.equipamentosDisponiveisAdd.versaoPlano = this.versaoPlano.id
    this.equipamentosDisponiveisAdd.ativoInativo = 0
    this.equipamentosDisponiveisAdd.tipoAlteracao = 1 //Adicionado
    this.equipamentosDisponiveisAdd.pthpId = this.planoTrabalhoHasProponente.id

    this.equipamentosDisponiveisService.adicionar(this.equipamentosDisponiveisAdd)
      .then(retorno => {
        form.reset();
        let i = this.equipamentosDisponiveis.length;
        this.equipamentosDisponiveis[i] = retorno;

        this.modalAdicionarEquipamentosDisponiveis = false;
        this.toasty.success("Equipamento salvo com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // === FIM EQUIPAMENTOS DISPONÍVEIS === //

  //UTILS
  verificaFormacao(formacao) {
    if (formacao == "Fundamental_incompleto") {
      formacao = "Ensino fundamental incompleto";
    } else if (formacao == "Fundamental_completo") {
      formacao = "Ensino fundamental completo";
    } else if (formacao == "Medio_incompleto") {
      formacao = "Ensino médio incompleto";
    } else if (formacao == "Medio_completo") {
      formacao = "Ensino médio completo";
    } else if (formacao == "Superior_incompleto") {
      formacao = "Superior incompleto";
    } else if (formacao == "Superior_completo") {
      formacao = "Superior completo";
    } else if (formacao == "Pos_incompleta") {
      formacao = "Pós-graduação incompleta";
    } else if (formacao == "Pos_graduacao") {
      formacao = "Pós-graduação";
    }
    return formacao;
  }
  formacao = [
    { label: "Ensino fundamental incompleto", value: "0" },
    { label: "Ensino fundamental completo", value: "1" },
    { label: "Ensino médio incompleto", value: "2" },
    { label: "Ensino médio completo", value: "3" },
    { label: "Superior incompleto", value: "4" },
    { label: "Superior completo", value: "5" },
    { label: "Pós-graduação incompleta", value: "6" },
    { label: "Pós-graduação", value: "7" },
    { label: "Mestrado", value: "8" },
    { label: "Doutorado", value: "9" }
  ];

  transformaFormacao() {
    let e = this.equipeEditar.formacao;

    if (e == "Fundamental_incompleto") {
      e = "0";
    } else if (e == "Fundamental_completo") {
      e = "1";
    } else if (e == "Medio_incompleto") {
      e = "2";
    } else if (e == "Medio_completo") {
      e = "3";
    } else if (e == "Superior_incompleto") {
      e = "4";
    } else if (e == "Superior_completo") {
      e = "5";
    } else if (e == "Pos_incompleta") {
      e = "6";
    } else if (e == "Pos_graduacao") {
      e = "7";
    } else if (e == "Mestrado") {
      e = "8";
    } else if (e == "Doutorado") {
      e = "9";
    }
    this.equipeEditar.formacao = e;
  }
  transformaFormacao1() {
    let e = this.equipeEditar.formacao;
    if (e == "0") {
      e = "Fundamental_incompleto";
    } else if (e == "1") {
      e = "Fundamental_completo";
    } else if (e == "2") {
      e = "Medio_incompleto";
    } else if (e == "3") {
      e = "Medio_completo";
    } else if (e == "4") {
      e = "Superior_incompleto";
    } else if (e == "5") {
      e = "Superior_completo";
    } else if (e == "6") {
      e = "Pos_incompleta";
    } else if (e == "7") {
      e = "Pos_graduacao";
    } else if (e == "8") {
      e = "Mestrado";
    } else if (e == "9") {
      e = "Doutorado";
    }
    this.equipeEditar.formacao = e;
  }

}
