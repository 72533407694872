import { Component, OnInit } from '@angular/core';

import { MonitoramentoAvaliacao, AlterMonitoramentoAvaliacao } from '../../../core/model';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AlterMonitoramentoAvaliacaoFiltro, AlterMonitoramentoAvaliacaoService } from '../../../core/services/alteracao-plano/alterMonitoramentoAvaliacao.service';
import { AuthService } from '../../../seguranca/auth.service';
import { PlanoTrabalhoHasProponenteService } from '../../..//core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-avaliar-monitoramento',
  templateUrl: './avaliar-monitoramento.component.html',
})
export class AvaliarMonitoramentoComponent implements OnInit {

  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  monitoramentoAvaliacao = new MonitoramentoAvaliacao();
  monitoramentoAvaliacaoAlterado = new MonitoramentoAvaliacao();
  atualizar: any;

  loading
  alterMonitoramentoAvaliacao = new AlterMonitoramentoAvaliacao()

  constructor(
    private pthpService: PlanoTrabalhoHasProponenteService,
    private alterMonitoramentoAvaliacaoService: AlterMonitoramentoAvaliacaoService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private auth: AuthService,
  ) { }

  async ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.monitoramentoAvaliacao = this.planoTrabalhoHasProponente.monitoramentoAvaliacao[0];
    await this.pthpBuscar()
    this.procuraMonitoramentoVersao()

  }

  pthpBuscar() {
    this.pthpService.buscarPorCodigo(this.planoTrabalhoHasProponente.id)
      .then(retorno => {
        this.planoTrabalhoHasProponente = retorno
        this.monitoramentoAvaliacao = this.planoTrabalhoHasProponente.monitoramentoAvaliacao[0];
        sessionStorage.setItem('pthpModuloAjuste', JSON.stringify(retorno))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // ==== PROCURAR ALTERAÇÃO MONITORAMENTO ==== //
  procuraMonitoramentoVersao() {
    let filtro = new AlterMonitoramentoAvaliacaoFiltro
    filtro.versaoPlano = this.versaoPlano.id

    this.alterMonitoramentoAvaliacaoService.pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado[0] != null) {
          this.alterMonitoramentoAvaliacao = retorno.dado[0]
          this.verificaAlteracao()

        } else {
          this.atualizar = 1
          this.loading = true
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  verificaAlteracao() {
    if (this.alterMonitoramentoAvaliacao.statusAlteracao == 'Submetido') {
      this.monitoramentoAvaliacaoAlterado.sustentabilidadeProposta = this.alterMonitoramentoAvaliacao.sustentabilidadePropostaDepois
      this.monitoramentoAvaliacaoAlterado.tecnicaMonitoramento = this.alterMonitoramentoAvaliacao.tecnicaMonitoramentoDepois
      this.atualizar = 0
    } else {
      this.atualizar = 2
    }
    this.loading = true
  }

  //AVALIAR
  finalizarAvaliacao() {
    if (this.alterMonitoramentoAvaliacao.statusAlteracao != 'Submetido') {
      this.loading = false

      this.alterMonitoramentoAvaliacao.usuarioAvaliou = this.auth.jwtPayload.id
      this.alterMonitoramentoAvaliacao.dataAvaliacao = new Date().getTime()

      this.alterMonitoramentoAvaliacao.sustentabilidadePropostaAntes = this.monitoramentoAvaliacao.sustentabilidadeProposta
      this.alterMonitoramentoAvaliacao.tecnicaMonitoramentoAntes = this.monitoramentoAvaliacao.tecnicaMonitoramento

      this.alterMonitoramentoAvaliacaoService.avaliar(this.alterMonitoramentoAvaliacao)
        .then(retorno => {
          this.pthpBuscar()
          this.alterMonitoramentoAvaliacao = retorno
          this.verificaAprovada()
          this.atualizar = 2
          this.toasty.success("Avaliação realizada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));

    } else {
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
    }
  }
  verificaAprovada(){
    if(this.alterMonitoramentoAvaliacao.statusAlteracao == 'Aprovado'){
      this.monitoramentoAvaliacao.sustentabilidadeProposta = this.alterMonitoramentoAvaliacao.sustentabilidadePropostaDepois
      this.monitoramentoAvaliacao.tecnicaMonitoramento = this.alterMonitoramentoAvaliacao.tecnicaMonitoramentoDepois
    }else if(this.alterMonitoramentoAvaliacao.statusAlteracao == 'Reprovado'){
      this.monitoramentoAvaliacao.sustentabilidadeProposta = this.alterMonitoramentoAvaliacao.sustentabilidadePropostaAntes
      this.monitoramentoAvaliacao.tecnicaMonitoramento = this.alterMonitoramentoAvaliacao.tecnicaMonitoramentoAntes
    }
    this.loading = true
  }

  //ALTERAR AV
  alterarAvaliacao(){
    this.loading = false

    this.alterMonitoramentoAvaliacaoService.alterarAvaliacao(this.alterMonitoramentoAvaliacao)
    .then(retorno => {
      this.pthpBuscar()
      this.alterMonitoramentoAvaliacao = retorno
      this.verificaAlteracao()
      this.toasty.success("Modifique sua avaliação!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  //UTILS
  aprovado() {
    this.alterMonitoramentoAvaliacao.statusAlteracao = 0 //aprovado
  }
  reprovado() {
    this.alterMonitoramentoAvaliacao.statusAlteracao = 1 //reprovado
  }

}
