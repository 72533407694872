import { URLSearchParams } from '@angular/http';
import { Injectable, EventEmitter } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { AlteracoesExecucao } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class AlteracoesExecucaoFiltro {
  celebracao
  tipo

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class AlteracoesExecucaoService {

  url: string;

  static exOfficioAdd = new EventEmitter<any>();

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/alteracoes-execucao`;
  }

  pesquisar(filtro: AlteracoesExecucaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.celebracao != null) {
      params.set('celebracao', filtro.celebracao);
    }
    if (filtro.tipo != null) {
      params.set('tipo', filtro.tipo);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: AlteracoesExecucao): Promise<AlteracoesExecucao> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: AlteracoesExecucao): Promise<AlteracoesExecucao> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as AlteracoesExecucao);
  }

  cancelarExOfficio(id): Promise<AlteracoesExecucao> {

    return this.http.put(`${this.url}/cancelar-ex-officio`,
      JSON.stringify(id))
      .toPromise()
      .then(response => response.json() as AlteracoesExecucao);
  }

  buscarPorCodigo(codigo: number): Promise<AlteracoesExecucao> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as AlteracoesExecucao;
        return entidade;
      });
  }

}
