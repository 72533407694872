import { Component } from "@angular/core";
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Celebracao } from './../../core/model';
import { GenerateParamPipe } from './../../core/my-pipes/generate-param.pipe';
import { ExtratoFiltro, ExtratoService } from './../../core/services/moduloFinanceiro/extrato.service';
import { DocumentosPDFService } from './../../core/services/relatorio/documentos-pdf.service';

moment.locale('pt');

declare let AdminLTE: any;

@Component({
  selector: "exportar-documentos",
  templateUrl: "./exportar-documentos.component.html"
})
export class ExportarDocumentosComponent {

  periodoInicial
  periodoFinal

  extratoFiltro = new ExtratoFiltro()
  relacaoPagamento = []

  celebracao: Celebracao;

  loading: boolean;
  mostraRelacao: boolean;
  total: any;

  newContent

  urlDocumento
  viewDownload

  constructor(
    private extratoService: ExtratoService,
    private errorHandler: ErrorHandlerService,
    private documentosPDFService: DocumentosPDFService
  ) { }

  ngOnInit() {
    AdminLTE.init();

    this.celebracao = JSON.parse(sessionStorage.getItem("modExecucaoCelebracao"))
  }

  //GERAR RELAÇÃO
  geraRelacaoPagamento() {
    this.extratoFiltro
    this.loading = false
    this.extratoFiltro.itensPorPagina = 500 

    this.extratoService.pesquisarDto(this.extratoFiltro)
      .then(resultado => {
        this.relacaoPagamento = resultado.content
        this.loading = true
        this.mostraRelacao = true
      }).catch(erro => this.errorHandler.handle(erro));
  }


  async generateMerge() {
    this.newContent = []

      for (const element of this.relacaoPagamento) {

        const resultado = {
          idTipoDespesa: '',
          tipoDespesa: '',
          nomeFavorecido: '',
          nomeArquivo: '',
          hashArquivo: '',
          tipoArquivo: '',
        };
  
        resultado.idTipoDespesa = element.tipoDespesaReceita.id
        resultado.tipoDespesa = element.tipoDespesaReceita.descricao
  
        if (element.despesaId) {
          // //console.log(element)
          resultado.nomeFavorecido = element.despesaId.nomeFavorecido
  
          if (element.despesaId.nfAnexoVencedor) {
            resultado.nomeArquivo = element.despesaId.nfAnexoVencedor
            resultado.hashArquivo = element.despesaId.hashFile
            resultado.tipoArquivo = 'Nota fiscal'
            // this.insertElementInArray(element.tipoDespesaReceita.id, element.tipoDespesaReceita.descricao, element.despesaId.hashFile)
            this.newContent.push(resultado);
          }
  
          if (element.despesaId.comprovanteExtratoBancario) {
            resultado.nomeArquivo = element.despesaId.comprovanteExtratoBancario
            resultado.hashArquivo = element.despesaId.hashFileComprovanteExtrato
            resultado.tipoArquivo = 'Comprovante extrato bancário'
            // this.insertElementInArray(element.tipoDespesaReceita.id, element.tipoDespesaReceita.descricao, element.despesaId.hashFileComprovanteExtrato)
            this.newContent.push(resultado);
          }
  
          if (element.despesaId.comprovanteOrdemBancaria) {
            resultado.nomeArquivo = element.despesaId.comprovanteOrdemBancaria
            resultado.hashArquivo = element.despesaId.hashFileOrdemBancaria
            resultado.tipoArquivo = 'Comprovante ordem bancária'
            // this.insertElementInArray(element.tipoDespesaReceita.id, element.tipoDespesaReceita.descricao, element.despesaId.hashFileOrdemBancaria)
            this.newContent.push(resultado);
          }
  
        }
  
        if (element.pagamentoPessoalId) {
          // //console.log(element)
          resultado.nomeFavorecido = element.pagamentoPessoalId.pessoalProjeto.nome
  
          if (element.pagamentoPessoalId.comprovanteTransferencia) {
            resultado.nomeArquivo = element.pagamentoPessoalId.comprovanteTransferencia
            resultado.hashArquivo = element.pagamentoPessoalId.hashComprovanteTransferencia
            resultado.tipoArquivo = 'Comprovante transferência'
            // this.insertElementInArray(element.tipoDespesaReceita.id, element.tipoDespesaReceita.descricao, element.pagamentoPessoalId.hashComprovanteTransferencia)
            this.newContent.push(resultado);
          }
          if (element.pagamentoPessoalId.comprovanteRecibo) {
            resultado.nomeArquivo = element.pagamentoPessoalId.comprovanteRecibo
            resultado.hashArquivo = element.pagamentoPessoalId.hashComprovanteRecibo
            resultado.tipoArquivo = 'Comprovante recibo'
            // this.insertElementInArray(element.tipoDespesaReceita.id, element.tipoDespesaReceita.descricao, element.pagamentoPessoalId.hashComprovanteRecibo)
            this.newContent.push(resultado);
          }
        }

        
      }

    // //console.log(this.newContent);
    this.imprimirMerger(this.newContent);
  }


  imprimirMerger(content) {

    let data = {
      content: content,
      projeto: this.celebracao.et1Titulo,
      hash: new GenerateParamPipe().generateHash(),
      dataImpressao: moment().format("L"),
      // filesMerge: this.arquivosCategoriaPrint
    }

    return this.documentosPDFService
      .relatorioDeDocumentosMerge(data)
      .then(() => {

        this.viewDownload = true;
        this.urlDocumento = environment.downloadFileUrl + "/merges/file-ouput.pdf";
        // window.location.href = this.dataPdf

        // if(retorno.report && retorno.hash === this.paramImprimir){
        //   this.dataPdf = environment.downloadFileUrl + "/report.pdf";
        //   this.loading = true
        // }else{
        //   setTimeout(function(){
        //     this.imprimirCertidao()
        //   },3000);
        // }
      })
      .catch(erro => this.errorHandler.handle(erro));



  }


}

