import { environment } from "../../../../environments/environment";

import { PagamentoPessoal } from "../../model";

import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";

export class PagamentoPessoalFiltro {
  pessoalProjeto: string;
  dfhecfId;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class PagamentoPessoalService {
  url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/pagamento-pessoal`;
  }

  pesquisar(filtro: PagamentoPessoalFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.pessoalProjeto) {
      params.set("pessoalProjeto", filtro.pessoalProjeto);
    }
    if (filtro.dfhecfId) {
      params.set("dfhecfId", filtro.dfhecfId);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dados = responseJson.content;

        const resultado = {
          dados,
          total: responseJson.totalElements
        };
        ////console.log(resultado)
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    const params = new URLSearchParams();
    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: PagamentoPessoal): Promise<PagamentoPessoal> {
    return this.http
      .post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: PagamentoPessoal): Promise<PagamentoPessoal> {
    return this.http
      .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as PagamentoPessoal);
  }

  buscarPorCodigo(codigo: number): Promise<PagamentoPessoal> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const retorno = response.json() as PagamentoPessoal;
        return retorno;
      });
  }

  cadastroRecebimento(entidade: PagamentoPessoal): Promise<PagamentoPessoal> {
    return this.http
      .post(`${this.url}/cadastro-recebimento`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }
  excluirRecebimento(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/excluir-recebimento/${codigo}`)
      .toPromise()
      .then(() => null);
  }
}
