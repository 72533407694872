import { SetorPesquisaComponent } from './setor-pesquisa/setor-pesquisa.component';
import { SetorVincularComponent } from './setor-vincular/setor-vincular.component';

import { SharedsModule } from '../shareds/shareds.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';
import { PaginationModule } from '../core/pagination/pagination.module';
import { SetorCadastroComponent } from './setor-cadastro/setor-cadastro.component';
import { SetorService } from './setor.service';
import { UsuarioHasSetorEntidadeService } from './usuarioHasSetorEntidade.service';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedsModule,
    PaginationModule,
    NgxMaskModule,

    DragulaModule.forRoot()
  ],
  declarations: [
   SetorCadastroComponent,
   SetorPesquisaComponent,
   SetorVincularComponent,
    
  ],
  exports: [],
  providers:[
    SetorService,
    UsuarioHasSetorEntidadeService,
    
  ]
})
export class SetorModule { }
