import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-beneficiarios-gerenciar",
  templateUrl: "./beneficiarios-gerenciar.component.html"
})
export class BeneficiariosGerenciarComponent implements OnInit {

  constructor(
    private title: Title,
  ) { }

  ngOnInit() {
    this.title.setTitle("Beneficiários/Atendidos");
  }

}