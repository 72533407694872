import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "convertGuidelines"
})
export class ConvertGuidelinesPipe implements PipeTransform {
 
  transform(value): any {

    var split = value.split("***");
    var concat
    if(split.length > 1){
      concat = split[0] + " " + "<a href="+split[1]+" target='_blank'>CLIQUE AQUI</a>"
    } else {
      concat = value
    }
    return concat;
  }

}
