import { Component, OnInit } from "@angular/core";

declare let AdminLTE: any;

@Component({
  selector: "app-mandato-visualizar",
  templateUrl: "./mandato-visualizar.component.html"
})
export class MandatoVisualizarComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
    AdminLTE.init();

  }
}
