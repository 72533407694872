import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserXhr, HttpModule } from "@angular/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragulaModule } from "ng2-dragula";
import { ToastyModule } from "ng2-toasty";
import { NgxMaskModule } from "ngx-mask";
import { NgProgressBrowserXhr, NgProgressModule } from "ngx-progressbar";
import { ConfirmDialogModule } from "primeng/components/confirmdialog/confirmdialog";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { UploadServiceModule } from "./core/upload-service/upload-service.module";
import { CronogramaFinanceiroModule } from "./cronograma-financeiro/cronograma-financeiro.module";
import { EmendaParlamentarModule } from "./emenda-parlamentar/emenda-parlamentar.module";
import { HomeModule } from "./home/home.module";
import { MapaModule } from "./mapa/mapa.module";
import { NoticiasModule } from "./noticias/noticias.module";
import { OutrasOpcoesModule } from "./outras-opcoes/outras-opcoes.module";
import { PendenciaModule } from "./pendencia/pendencia.module";
import { PrestacaoContasModule } from "./prestacao-contas/prestacao-contas.module";
import { PropostaModule } from "./proposta/proposta.module";
import { RepassesModule } from "./repasses/repasses.module";
import { SegurancaModule } from "./seguranca/seguranca.module";
import { TutoriaisModule } from "./tutoriais/tutoriais.module";
import { WrapperModule } from "./wrapper/wrapper.module";
import { AssinadorDocumentosModule } from "./assinador-documentos/assinador-documentos.module";

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    FormsModule,
    CoreModule,

    ConfirmDialogModule,

    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    ToastyModule.forRoot(),

    NgProgressModule,

    AppRoutingModule,
    WrapperModule,
    HomeModule,
    UploadServiceModule,
    SegurancaModule,

    CronogramaFinanceiroModule,
    EmendaParlamentarModule,
    NoticiasModule,
    RepassesModule,
    PrestacaoContasModule,
    MapaModule,
    OutrasOpcoesModule,
    PendenciaModule,
    PropostaModule,
    TutoriaisModule,

    AssinadorDocumentosModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: BrowserXhr, useClass: NgProgressBrowserXhr },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
