import { CronogramaFinanceiroService } from '../cronograma-financeiro.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { CronogramaFinanceiro } from '../../core/model';

import { ToastyService } from 'ng2-toasty';

import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-cronograma-financeiro-cadastro',
  templateUrl: './cronograma-financeiro-cadastro.component.html'
})
export class CronogramaFinanceiroCadastroComponent implements OnInit {

  cronogramaFinanceiro = new CronogramaFinanceiro();

  tipos = [
    { label: 'Sim', value: 1 },
    { label: 'Não', value: 0 }
  ];

  constructor(
    private cronogramaFinanceiroService: CronogramaFinanceiroService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title

  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    const codigo = this.route.snapshot.params['codigo'];

    this.title.setTitle('Novo Cronograma');

    if (codigo) {
      this.carregarPorCodigo(codigo);
    }
  }

  get editando() {
    return Boolean(this.cronogramaFinanceiro.id)
  }

  carregarPorCodigo(codigo: number) {
    this.cronogramaFinanceiroService.buscarPorCodigo(codigo)
      .then(entidade => {
        this.cronogramaFinanceiro = entidade;
        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  salvar(form: FormControl) {
    if (this.editando) {
      // //console.log("atualizar")
      this.atualizar(form);
    } else {
      // //console.log('adicionar')
      this.adicionar(form);
    }
  }

  adicionar(form: FormControl) {
    this.cronogramaFinanceiroService.adicionar(this.cronogramaFinanceiro)
      .then(enditadeAdcionada => {
        this.toasty.success('Cronograma financeiro adicionado com sucesso!');

        this.router.navigate(['/cronograma-financeiro', enditadeAdcionada.id]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizar(form: FormControl) {
    this.cronogramaFinanceiroService.atualizar(this.cronogramaFinanceiro)
      .then(entidade => {
        this.cronogramaFinanceiro = entidade;
        this.toasty.success('Cronograma financeiro alterado com sucesso!');

        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  novo(form: FormControl) {
    form.reset();

    //Necessário para que "new Lancamento" seja executado depois de "form.reset"
    setTimeout(function () {
      this.cronogramaFinanceiro = new CronogramaFinanceiro();
    }.bind(this), 1);

    this.router.navigate(['/cronograma-financeiro/novo']);
  }

  atualizarTituloEdicao() {
    this.title.setTitle(`Edição do Cronograma Financeiro`);
  }

}