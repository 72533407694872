import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { QuestaoRequisitoProponente, Usuario } from '../../../core/model';
import { AuthService } from '../../../seguranca/auth.service';

export class RequisitoProponenteFiltro {
  status: string;
  anexo: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class RequisitoProponenteService {

  questaoRequisitoProponenteUrl: string;
  usuarioAdmin = new Usuario();

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.questaoRequisitoProponenteUrl = `${environment.apiUrl}/questao-requisito-proponente`;
  }

  pesquisar(filtro: RequisitoProponenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.status) {
      params.set('status', filtro.status);
    }
    if (filtro.anexo) {
      params.set('anexo', filtro.anexo);
    }

    return this.http.get(`${this.questaoRequisitoProponenteUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        // //console.log(resultado)
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.questaoRequisitoProponenteUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.questaoRequisitoProponenteUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: QuestaoRequisitoProponente): Promise<QuestaoRequisitoProponente> {
    return this.http.post(this.questaoRequisitoProponenteUrl, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: QuestaoRequisitoProponente): Promise<QuestaoRequisitoProponente> {
    return this.http.put(`${this.questaoRequisitoProponenteUrl}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as QuestaoRequisitoProponente);
  }

  buscarPorCodigo(codigo: number): Promise<QuestaoRequisitoProponente> {
    return this.http.get(`${this.questaoRequisitoProponenteUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as QuestaoRequisitoProponente;
        return entidade;
      });
  }

  mudarStatus(codigo: number, status: number): Promise<void> {
    return this.http.put(`${this.questaoRequisitoProponenteUrl}/${codigo}/status`, status)
      .toPromise().then(() => null);
  }

  vincularProponenteCredenciamento(eixo): Promise<any>{
    let codigo = this.auth.jwtPayload.proponente
    
    return this.http.post(`${this.questaoRequisitoProponenteUrl}/${codigo}`, JSON.stringify(eixo))
    .toPromise()
    .then(response => response.json());
  }



  // questaoRespondida(idProponente: any, idQuestao): Promise<any> {
  //   const params = new URLSearchParams();
  //   params.set('questaoRequisito', idQuestao);
  //   params.set('entidadeProponente', idProponente);
  //   return this.http.get(`${this.questaoRequisitoProponenteUrl}`, { search: params })
  //     .toPromise()
  //     .then(response => response.json().content);
  // }

  
}
