import { DispensaInexigibilidadeService } from './../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service';

import { PropostaDispensaInegibilidadeListarComponent } from './proposta-dispensa-inegibilidade/proposta-dispensa-inegibilidade-listar/proposta-dispensa-inegibilidade-listar.component';
import { PropostaDispensaInegibilidadeCadastroComponent } from './proposta-dispensa-inegibilidade/proposta-dispensa-inegibilidade-cadastro/proposta-dispensa-inegibilidade-cadastro.component';
import { PropostaDispensaInegibilidadeGerenciarComponent } from './proposta-dispensa-inegibilidade/proposta-dispensa-inegibilidade-gerenciar/proposta-dispensa-inegibilidade-gerenciar.component';

import { PatrocinioListarComponent } from './patrocinio/patrocinio-listar/patrocinio-listar.component';
import { PatrocinioGerenciarComponent } from './patrocinio/patrocinio-gerenciar/patrocinio-gerenciar.component';
import { ContratoListarComponent } from './contrato/contrato-listar/contrato-listar.component';
import { ContratoCadastroComponent } from './contrato/contrato-cadastro/contrato-cadastro.component';
import { ContratoGerenciarComponent } from './contrato/contrato-gerenciar/contrato-gerenciar.component';
import { ConveniosListarComponent } from './convenios/convenios-listar/convenios-listar.component';
import { ConveniosCadastroComponent } from './convenios/convenios-cadastro/convenios-cadastro.component';
import { ConveniosGerenciarComponent } from './convenios/convenios-gerenciar/convenios-gerenciar.component';
import { ParceriasListarComponent } from './parcerias/parcerias-listar/parcerias-listar.component';
import { PropostaRoutingModule } from './proposta.routing.module';
import { PropostaGerenciarComponent } from './proposta-gerenciar/proposta-gerenciar.component';
import { ParceriasCadastroComponent } from './parcerias/parcerias-cadastro/parcerias-cadastro.component';
import { ParceriasGerenciarComponent } from './parcerias/parcerias-gerenciar/parcerias-gerenciar.component';
import { ParceriaGerenciarComponent } from './../celebracao/parceria/parceria-gerenciar/parceria-gerenciar.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { PaginationModule } from "../core/pagination/pagination.module";
import { PlanoTrabalhoService } from "../core/services/plano-trabalho/planoTrabalho.service";
import { ProponenteService } from "../core/services/proponente/proponente.service";
import { EditalCredenciamentoService } from "../core/services/edital-credenciamento/EditalCredenciamento.service";
import { EixoProgramaService } from "../core/services/edital-credenciamento/EixoPrograma.service";
import { SharedsModule } from "../shareds/shareds.module";
import { PropostaPatrocinioCadastroComponent } from './patrocinio/proposta-patrocinio-cadastro/proposta-patrocinio-cadastro.component';
import { UploadServiceModule } from '../core/upload-service/upload-service.module';
import { ConcedenteService } from '../core/services/concedente/concedente.service';
import { HttpClientModule } from '@angular/common/http';
import { MyPipesModule } from '../core/my-pipes/my-pipes.module';
import { ParceriasEditarComponent } from './parcerias/parcerias-editar/parcerias-editar.component';
import { ParceriasAvaliacaoComponent } from './parcerias/parcerias-avaliacao/parcerias-avaliacao.component';

import { ConveniosAvaliacaoComponent } from './convenios/convenios-avaliacao/covenios-avaliacao.component';
import { ConveniosEditarComponent } from './convenios/convenios-editar/convenios-editar.component';
import { ContratoAvaliacaoComponent } from './contrato/contrato-avaliacao/contrato-avaliacao.component';
import { ContratoEditarComponent } from './contrato/contrato-editar/contrato-editar.component';
import { PatrocinioEditarComponent } from './patrocinio/patrocinio-editar/patrocinio-editar.component';
import { PatrocinioAvaliacaoComponent } from './patrocinio/patrocinio-avaliacao/patrocinio-avaliacao.component';
import { AnexoPropostaService } from '../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { ModalidadePropostaService } from '../core/services/dispensaInexigibilidade/modalidade-proposta.service';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PaginationModule,
    SharedsModule,
    UploadServiceModule,
    PaginationModule,
    HttpClientModule,
    MyPipesModule,
   

    RichTextEditorAllModule,
    CurrencyMaskModule,
    PropostaRoutingModule
  ],
  declarations: [ 
    PropostaGerenciarComponent,

    ParceriasGerenciarComponent,
    ParceriasCadastroComponent,
    ParceriasListarComponent,
    ParceriasEditarComponent,
    ParceriasAvaliacaoComponent,

    ConveniosGerenciarComponent,
    ConveniosCadastroComponent,
    ConveniosListarComponent,
    ConveniosEditarComponent,
    ConveniosAvaliacaoComponent,

    ContratoGerenciarComponent,
    ContratoCadastroComponent,
    ContratoListarComponent,
    ContratoEditarComponent,
    ContratoAvaliacaoComponent,

    PatrocinioGerenciarComponent,
    PropostaPatrocinioCadastroComponent,
    PatrocinioListarComponent,
    PatrocinioEditarComponent,
    PatrocinioAvaliacaoComponent,

    PropostaDispensaInegibilidadeGerenciarComponent,
    PropostaDispensaInegibilidadeCadastroComponent,
    PropostaDispensaInegibilidadeListarComponent,
   
  ],
  exports: [],
  providers: [
  
    PlanoTrabalhoService,
    EixoProgramaService,
    ProponenteService,
    EditalCredenciamentoService,
    ConcedenteService,
    DispensaInexigibilidadeService,
    AnexoPropostaService,
    ModalidadePropostaService
  ]
})
export class PropostaModule { }
