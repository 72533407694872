import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-parcial",
  templateUrl: "./parcial.component.html"
})
export class ParcialComponent implements OnInit {

  constructor(
    private title: Title,
  ) { }

  ngOnInit() {
    this.title.setTitle("Prestação de contas parcial");
  }

}