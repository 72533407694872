import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ToastyService } from "ng2-toasty";
import { ConfirmationService } from "primeng/components/common/confirmationservice";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { PessaoUtil } from "../../../core/pessoa.util";
import { GetAdress } from '../../../core/services/utils/get-address.service';
import { AuthService } from "../../../seguranca/auth.service";
import { AtendidosProjeto, Celebracao, CelebracaoHasAtendidosProjeto, Usuario } from '../../../core/model';
import { Profissoes } from '../../../core/profissoes';
import { AtendidosProjetoFiltro, AtendidosProjetoService } from '../../../core/services/atendidos/atendidosProjeto.service';
import { CelebracaoHasAtendidosProjetoFiltro, CelebracaoHasAtendidosProjetoService } from '../../../core/services/atendidos/celebracaoHasAtendidosProjeto.service';
import { UsuarioFiltro } from '../../../usuario/usuario.service';

@Component({
  selector: "app-beneficiarios-cadastro",
  templateUrl: "./beneficiarios-cadastro.component.html"
})
export class BeneficiarioCadastroComponent implements OnInit {

  celebracao = new Celebracao();

  beneficiarioAtendido = new AtendidosProjeto();
  filtroBeneficiarioAtendido = new AtendidosProjetoFiltro;
  celebracaoHasAtendidosProjetoFiltro = new CelebracaoHasAtendidosProjetoFiltro();
  idUsuario
  editandoVinculo
  editandoBeneficiario

  filtroUsuario = new UsuarioFiltro();
  pessoaCpf = new PessaoUtil();
  disbledCpf = false

  estados = new PessaoUtil().estados;
  profissoes = new Profissoes().profissoes.map(p => ({ label: p, value: p }));

  escolaridade = [
    { label: 'Selecione', value: '' },
    { label: 'Ensino Fundamental Incompleto', value: 'Fundamental_incompleto' },
    { label: 'Ensino Fundamental Completo', value: 'Fundamental_completo' },
    { label: 'Ensino Medio Incompleto', value: 'Medio_incompleto' },
    { label: 'Ensino Medio Completo', value: 'Medio_completo' },
    { label: 'Superior Incompleto', value: 'Superior_incompleto' },
    { label: 'Superior Completo', value: 'Superior_completo' },
    { label: 'Pós Graduação Incompleta', value: 'Pos_incompleta' },
    { label: 'Pós Graduação Completo', value: 'Pos_graduacao' },
    { label: 'Mestrado', value: 'Mestrado' },
    { label: 'Doutorado', value: 'Doutorado' }
  ];

  sexo = [
    { label: 'Selecione', value: '' },
    { label: 'Masculino', value: 'Masculino' },
    { label: 'Feminino', value: 'Feminino' },
    { label: 'Outro', value: 'Outro' },
  ];

  estadoCivil = [
    { label: 'Selecione', value: '' },
    { label: 'Solteiro', value: 'Solteiro' },
    { label: 'Casado', value: 'Casado' },
    { label: 'Viúvo', value: 'Viuvo' },
    { label: 'Divorciado', value: 'Divorciado' },
    { label: 'Outro', value: 'Outro' },
  ];

  constructor(
    public auth: AuthService,
    private getAdress: GetAdress,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private beneficiarioService: AtendidosProjetoService,
    private celebracaoHasAtendidosProjetoService: CelebracaoHasAtendidosProjetoService,
    private confirmation: ConfirmationService,
  ) { }

  ngOnInit() {
    this.idUsuario = this.auth.jwtPayload.id;

    this.title.setTitle("Beneficiário");

    let param = this.route.snapshot.paramMap.get('codigo');
    if (param) {
      //console.log(param);
      let beneficiario = JSON.parse(sessionStorage.getItem("beneficiario"))
      sessionStorage.removeItem("beneficiario")
      this.alterandoBeneficiario(beneficiario)

    } else {
      this.celebracao = JSON.parse(sessionStorage.getItem("modExecucaoCelebracao"))
      this.openEtapa1();
    }
  }


  // =====  VIEWS STEPS
  etapa1;
  etapa2;
  etapa3;
  etapa4;
  etapa5;
  demaisEtapasShow;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
    this.etapa5 = false;
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
    this.etapa5 = false;
  }
  openEtapa5() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = true;
  }

  confereCpfBeneficiario() {
    if (this.pessoaCpf.vercpf(this.beneficiarioAtendido.cpf) != false) {
      if (this.beneficiarioAtendido.cpf != null && this.beneficiarioAtendido.cpf.length > 10) {

        this.celebracaoHasAtendidosProjetoFiltro.celebracao = this.celebracao.id
        this.celebracaoHasAtendidosProjetoFiltro.atendidosProjeto.cpf = this.beneficiarioAtendido.cpf
        this.celebracaoHasAtendidosProjetoService.pesquisar(this.celebracaoHasAtendidosProjetoFiltro).then(retorno => {

          if (retorno.totalElements == 0) {
            this.filtroBeneficiarioAtendido.cpf = this.beneficiarioAtendido.cpf
            this.beneficiarioService.pesquisar(this.filtroBeneficiarioAtendido).then(retorno => {
              if (retorno.totalElements == 0) {
                this.demaisEtapasShow = true
                this.openEtapa2();
              } else {
                this.confirmacaoCadastroExistente(retorno.content[0]);
              }
            })
          } else {
            this.toasty.warning("Este CPF já está vinculado ao projeto!")
          }
        })

      } else {
        this.toasty.warning("Por Favor preencha o CPF!")
      }
    } else {
      this.toasty.info("CPF INVÁLIDO")
      this.beneficiarioAtendido.cpf = null
    }
  }


  confirmacaoCadastroExistente(atendidosProjeto: AtendidosProjeto) {
    this.confirmation.confirm({
      message: "Já existe um beneficiário cadastrado com esse cpf, deseja vincular ao projeto?",
      accept: () => {
        this.beneficiarioAtendido = atendidosProjeto
        this.editandoVinculo = true;
        this.demaisEtapasShow = true;
        this.openEtapa2();
      }
    });
  }

  alterandoBeneficiario(beneficiario) {
    this.beneficiarioAtendido = beneficiario
    this.demaisEtapasShow = true;
    this.editandoBeneficiario = true;
    this.openEtapa2();
  }


  confereCelular(event) {
    if (event.target.checked)
      this.beneficiarioAtendido.celularWhats = true
  }


  salvar() {
    if(this.editandoBeneficiario)
      return this.atualizarBeneficiario()
    if(this.editandoVinculo)
      return this.atualizarBeneficiarioVinculo();

    return this.salvarBeneficiario();
  }


  salvarBeneficiario() {
    this.beneficiarioAtendido.usuarioCadastrou.id = this.idUsuario
    this.beneficiarioAtendido.ultimaAtualizacao = moment(this.beneficiarioAtendido.ultimaAtualizacao).format("YYYY-MM-DD hh:mm:ss")
    this.beneficiarioAtendido.dataNascimento = moment(this.beneficiarioAtendido.dataNascimento).format("YYYY-MM-DD")
    this.beneficiarioService.adicionar(this.beneficiarioAtendido).then(resultado => {
      this.toasty.success(resultado.nome + " adicionado com sucesso!")
      this.salvarCelebracaoHasAtendidosProjeto(resultado)
    }).catch(erro => this.errorHandler.handle(erro));
  }


  atualizarBeneficiario() {
    this.beneficiarioService.atualizar(this.beneficiarioAtendido)
      .then(resultado => {
        this.toasty.success(resultado.nome + " atualizado com sucesso!")
        this.router.navigate(['/execucao/visualizar/beneficiarios/listar'])
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarBeneficiarioVinculo() {
    this.beneficiarioService.atualizar(this.beneficiarioAtendido)
      .then(resultado => {
        this.toasty.success(resultado.nome + " atualizado com sucesso!")
        this.salvarCelebracaoHasAtendidosProjeto(resultado);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  salvarCelebracaoHasAtendidosProjeto(atendidosProjeto: AtendidosProjeto) {
    let chap = new CelebracaoHasAtendidosProjeto();
    chap.celebracao = this.celebracao.id
    chap.atendidosProjeto = atendidosProjeto
    chap.status = 1
    chap.linkArquivo = "https://drive.google.com/file/d/1PkQtKMcywLpkCIXqDwoPuIMQK_vbahkn/view";
    chap.dataCadastroVinculo = new Date().getTime()
    let user = new Usuario();
    user.id = this.idUsuario;
    chap.usuarioCadastroVinculo = user;
    chap.usuarioCadastroArquivo = null;

    this.celebracaoHasAtendidosProjetoService.adicionar(chap).then(obj => {
      this.toasty.success("Beneficiário vinculado no projeto.")
      this.router.navigate(['/execucao/visualizar/beneficiarios/listar'])
    }).catch(erro => this.errorHandler.handle(erro));
  }



  getEnderecoCep(event) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return;
          }
          this.beneficiarioAtendido.cep = data.cep;
          this.beneficiarioAtendido.endereco = data.logradouro;
          this.beneficiarioAtendido.bairro = data.bairro;
          this.beneficiarioAtendido.cidade = data.localidade;
          this.beneficiarioAtendido.estado = data.uf;
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

}