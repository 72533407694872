import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "generateParam"
})
export class GenerateParamPipe implements PipeTransform {
  transform(value): any {
    let attr = this.generateId(20);
    ////console.log(attr);
    return attr;
  }

  // generateId :: Integer -> String
  generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join("");
  }

  generateHash() {
    var arr = new Uint8Array((10 || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join("");
  }
  // dec2hex :: Integer -> String
  dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
  }

}
