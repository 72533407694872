import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { Celebracao, PlanoTrabalhoHasProponente } from "../../../core/model";
import { PlanoTrabalhoHasProponenteService } from "../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service";
import { AuthService } from "../../../seguranca/auth.service";

@Component({
  selector: "prestacao-contas",
  templateUrl: "./prestacao-contas.component.html"
})
export class PrestacaoContasComponent implements OnInit, OnChanges {

  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
  celebracao = new Celebracao();
  repasse
  periodoInicial
  periodoFinal

  @Input() periodoInicialInput;
  @Input() periodoFinalInput;
  @Output() onChange:EventEmitter<any> = new EventEmitter();

  constructor(
    private planoTrabalhoHasProponenteService: PlanoTrabalhoHasProponenteService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    public auth: AuthService,
    private location: Location
  ) { }

  ngOnInit() {
    this.repasse = JSON.parse(sessionStorage.getItem("repasse"));
    this.celebracao = JSON.parse(sessionStorage.getItem("modExecucaoCelebracao"));
  }

  ngOnChanges(changes:any):void {
    if(changes.periodoInicialInput){
      this.periodoInicial = moment(changes.periodoInicialInput.currentValue).format("YYYY-MM-DD")
    }
    if(changes.periodoFinalInput){
      this.periodoFinal = moment(changes.periodoFinalInput.currentValue).format("YYYY-MM-DD")
    }
    setTimeout(
      function () { this.getPlanoTrabalhoHasProponente(); }.bind(this), 10
    );
  }

  goBack() {
    this.location.back();
  }

  gotoAnaliseContasReport(param) {
    sessionStorage.setItem('data-report', JSON.stringify(param))
    this.router.navigate(["/prestacao-contas/visualizar/parcial/analise-contas/report"]);
  }


  getPlanoTrabalhoHasProponente() {
    this.planoTrabalhoHasProponenteService.buscarPorCodigo(this.celebracao.pthpId)
      .then(async retorno => {
        this.planoTrabalhoHasProponente = retorno
        await this.gerarCronogramaDesembolso();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  listaCronogramaDesembolso = [];
  qtdMesProjeto
  async gerarCronogramaDesembolso() {
    this.listaCronogramaDesembolso = [];

    let iniProjeto;
    let fimProjeto;

    // For para descobrir os periodos
    await this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.forEach(meta => {
      meta.etapaCronogramaFisico.forEach(etapa => {
        //Descobrir data inicial do projeto
        if (iniProjeto) {
          if (iniProjeto > etapa.dataIni) {
            iniProjeto = etapa.dataIni;
          }
        } else {
          iniProjeto = etapa.dataIni;
        }

        //Descobrir data final do projeto
        if (fimProjeto) {
          if (fimProjeto < etapa.dataFim) {
            fimProjeto = etapa.dataFim;
          }
        } else {
          fimProjeto = etapa.dataFim;
        }
      });
    });


    // SE HOUVER PARAMETRO DE PERIODO CADASTRADO
    if (this.periodoInicial) {
      iniProjeto = moment(this.periodoInicial).format("YYYY-MM-DD")
    } else {
      this.periodoInicial = iniProjeto
    }
    if (this.periodoFinal) {
      fimProjeto = moment(this.periodoFinal).format("YYYY-MM-DD")
    } else {
      this.periodoFinal = fimProjeto
    }

    this.qtdMesProjeto = moment(fimProjeto).diff(moment(iniProjeto), "months") + 1;

    let MES_ANO = iniProjeto

    for (let i = 0; i < this.qtdMesProjeto; i++) {

      let resultado = {
        mes: MES_ANO,
        metas: []
      };

      let flagMeta: boolean

      await this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.forEach(meta => {

        flagMeta = false

        let etapasNew = []
        meta.etapaCronogramaFisico.forEach(etapa => {

          let mesAno = moment(MES_ANO).format("MM-YYYY");
          let inicioEtapa = moment(etapa.dataIni).format("MM-YYYY");
          let fimEtapa = moment(etapa.dataFim).format("MM-YYYY");

          //Verifica se a etapa esta no periodo
          if (moment(mesAno, "MM-YYYY").toDate() >= moment(inicioEtapa, "MM-YYYY").toDate() && moment(mesAno, "MM-YYYY").toDate() <= moment(fimEtapa, "MM-YYYY").toDate()) {

            // Informacao nao necessaria para o front
            etapa.detalhamentoFinanceiroHasEtapaCronogramaFisico = null

            let evidenciaNew = []
            etapa.evidenciasHasMeta.forEach(evidencia => {
              if (moment(evidencia.dataOcorrencia).isSameOrAfter(iniProjeto) && moment(evidencia.dataOcorrencia).isSameOrBefore(fimProjeto)) {

                let m_a = moment(MES_ANO); let e_d = moment(evidencia.dataOcorrencia);
                if (m_a.month() === e_d.month() && m_a.year() === e_d.year()) {
                  evidenciaNew.push(evidencia)
                }
              }
            });

            //Depois de add Evidencias, add a etapa nova
            etapasNew.push(this.addEssaChibataDaEvidenciaNaEtapa(etapa, evidenciaNew))

            flagMeta = true
          }

        });

        if (flagMeta) {
          let metaNew = this.addEssaChibataDaEtapaNaMeta(meta.meta, etapasNew)
          resultado.metas.push(metaNew);
        }

      });

      this.listaCronogramaDesembolso.push(resultado)

      MES_ANO = moment(MES_ANO).add(1, "months");
      MES_ANO = moment(MES_ANO, "YYYY-MM-DD").toDate();
    }

  }

  addEssaChibataDaEtapaNaMeta(meta, etapasNew) {
    let metaNew = { descricao: meta, etapas: [] };
    Array.prototype.push.apply(metaNew.etapas, etapasNew);
    return metaNew
  }

  addEssaChibataDaEvidenciaNaEtapa(etapa, evidenciaNew) {
    let etapaNew = {
      dataFim: etapa.dataFim,
      dataIni: etapa.dataIni,
      descricao: etapa.descricao,
      especificacao: etapa.especificacao,
      evidenciasHasMeta: []
    };
    Array.prototype.push.apply(etapaNew.evidenciasHasMeta, evidenciaNew);
    return etapaNew
  }

}