import { GenerateParamPipe } from './../../core/my-pipes/generate-param.pipe';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RoteiroParecerService, RoteiroParecerFiltro } from "../../core/services/modelos-sistema/roteiroParecer.service";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { RoteiroParecer, ModelResponderRoteiro, ResponderRoteiro, PlanoTrabalho, RespostaQuestaoParecer, QuestaoParecer } from "../../core/model";
import { ResponderRoteiroService, ResponderRoteiroFiltro } from "../../core/services/modelos-sistema/responderRoteiro.service";
import { RespostaQuestaoParecerService, RespostaQuestaoParecerFiltro } from "../../core/services/modelos-sistema/respostaQuestaoParecer.service";
import { AuthService } from "../../seguranca/auth.service";
import { ToastyService } from "ng2-toasty";


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-roteiro-multiplo",
  templateUrl: "./roteiro-multiplo.component.html"
})
export class RoteiroMultiploComponent implements OnInit {

  roteiro = new RoteiroParecer
  modelResponderRoteiro = new ModelResponderRoteiro
  responderRoteiro: ResponderRoteiro;
  finalizarRoteiro: boolean;
  roteiroJaSubmetido: boolean;
  respostas = []

  constructor(
    private title: Title,
    private roteiroParecerService: RoteiroParecerService,
    private responderRoteiroService: ResponderRoteiroService,
    private respostaQuestaoParecerService: RespostaQuestaoParecerService,
    private errorHandler: ErrorHandlerService,
    private auth: AuthService,
    private toasty: ToastyService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    //this.title.setTitle('Responder Roteiro');

    this.modelResponderRoteiro = JSON.parse(sessionStorage.getItem('respondeRoteiro'))
    if (this.modelResponderRoteiro != null) {
      this.pesquisaRoteiro()
    }
    
  }

  pesquisaRoteiro() {
    let filtro = new RoteiroParecerFiltro
    filtro.moduloParecer = this.modelResponderRoteiro.modulo
    filtro.submodulRoteiro = this.modelResponderRoteiro.submodulo
    filtro.tipoRoteiro = this.modelResponderRoteiro.tipoRoteiro
    filtro.status = 'Ativo'

    this.roteiroParecerService.pesquisar(filtro)
      .then(retorno => {
        this.roteiro = retorno.dado[0]
        this.verificaResponderRoteiro()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaResponderRoteiro() {
    let filtro = new ResponderRoteiroFiltro
    filtro.roteiroParecer = this.roteiro.id

    switch (this.modelResponderRoteiro.campoProcesso) {
      case 'planoTrabalho': {
        filtro.planoTrabalho = this.modelResponderRoteiro.idProcesso
        break;
      }
    }

    this.responderRoteiroService.pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado.length == 0) {
          //Cadastrar
          this.inserirResponderRoteiro()
        } else {
          //Buscar respostas
          this.responderRoteiro = retorno.dado[0]
          this.buscarRespostas()
          if (this.responderRoteiro.status == 'Submetido') {
            this.roteiroJaSubmetido = true
          }
        }
      })
      .catch(erro => this.errorHandler.handle(erro));

  }

  inserirResponderRoteiro() {
    let responder = new ResponderRoteiro
    responder.roteiroParecer.id = this.roteiro.id
    responder.status = 0

    switch (this.modelResponderRoteiro.campoProcesso) {
      case 'planoTrabalho': {
        let plano = new PlanoTrabalho
        plano.id = this.modelResponderRoteiro.idProcesso
        responder.planoTrabalho = plano
        break;
      }
    }
    responder.usuario = null

    this.responderRoteiroService.adicionar(responder)
      .then(retorno => {
        this.responderRoteiro = retorno
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  buscarRespostas() {
    let filtro = new RespostaQuestaoParecerFiltro
    filtro.responderRoteiro = this.responderRoteiro.id
    this.respostaQuestaoParecerService.pesquisar(filtro)
      .then(retorno => {
        if (retorno.dado.length != 0) {
          this.respostas = retorno.dado
          this.setaRespostasNasQuestoes()
          this.verificaSeTodasForamRespondidas()
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  setaRespostasNasQuestoes() {
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta
          }
        });
      });
    });
  }
  verificaSeTodasForamRespondidas() {
    let faltaResponder = false
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta
          }
        });
        if (questao.resposta == null) {
          faltaResponder = true
        }
      });
    });
    if (!faltaResponder) {
      this.finalizarRoteiro = true
    }
  }

  verificaParecerQuestao(questao) {
    let i = questao.id
    if (questao.resposta.respostaEnum == 'Nao') {
      (<HTMLInputElement>document.getElementById(i + "-desfavoravel")).checked = true
    } else if (questao.resposta.respostaEnum == 'Sim') {
      (<HTMLInputElement>document.getElementById(i + "-favoravel")).checked = true
    }
  }

  verificaEscolhaQuestao(questao) {
    questao.escolha.forEach(escolha => {
      if (escolha.id == questao.resposta.escolha.id) {
        (<HTMLInputElement>document.getElementById(escolha.id + "-escolha")).checked = true
      } else {
        (<HTMLInputElement>document.getElementById(escolha.id + "-escolha")).checked = false
      }
    });
  }

  // ==== RESPONDER ====== //
  responderQuestao(questao, flagAtualizar = false) {
    if (questao.tipo == 'Texto' || questao.tipo == 'Sim_nao') {
      this.responderQuestaoTextoSimNao(questao, flagAtualizar)
    } else if (questao.tipo == 'Multipla_escolha') {
      this.responderQuestaoMultiplaEscolha(questao, flagAtualizar)
    } else if (questao.tipo == 'CPF' || questao.tipo == 'CNPJ' || questao.tipo == 'Monetario' || questao.tipo == 'Numerico' || questao.tipo == 'Data') {
      this.responderQuestaoCpfCnpjMonetarioNumericoData(questao, flagAtualizar)
    }

  }
  responderQuestaoTextoSimNao(questao, flagAtualizar) {
    let resposta = new RespostaQuestaoParecer
    resposta.data = new Date().getTime()

    resposta.anexo = null
    resposta.hashFile = null
    resposta.escolha = null

    let questaoParecer = new QuestaoParecer
    questaoParecer.id = questao.id
    resposta.questaoParecer = questaoParecer

    let responderRoteiro = new ResponderRoteiro
    responderRoteiro.id = this.responderRoteiro.id
    resposta.responderRoteiro = responderRoteiro

    resposta.usuario.id = this.auth.jwtPayload.id

    if (questao.tipo == 'Texto') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-texto")).value
    } else {
      resposta.observacao = (<HTMLInputElement>document.getElementById(questao.id + "-observacao")).value
    }


    if (questao.parecer == true) {
      //Mostra parecer  
      var y = (<HTMLInputElement>document.getElementById(questao.id + "-favoravel")).checked
      var z = (<HTMLInputElement>document.getElementById(questao.id + "-desfavoravel")).checked

      if (y == true) {
        resposta.respostaEnum = 0 //Sim
      } else if (z == true) {
        resposta.respostaEnum = 1 //Não
      } else { resposta.respostaEnum = null }

    } else {
      //Não mostra parecer
      resposta.respostaEnum = null
    }

    if (flagAtualizar) {
      resposta.id = questao.resposta.id
      this.respostaQuestaoParecerService.atualizar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.toasty.success("Resposta alterada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.respostaQuestaoParecerService.adicionar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.respostas[this.respostas.length] = retorno
          this.verificaSeTodasForamRespondidas()
          this.toasty.success("Resposta submetida com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }
  responderQuestaoMultiplaEscolha(questao, flagAtualizar) {
    let resposta = new RespostaQuestaoParecer
    resposta.data = new Date().getTime()

    resposta.anexo = null
    resposta.hashFile = null


    questao.escolha.forEach(escolha => {
      var k = (<HTMLInputElement>document.getElementById(escolha.id + "-escolha")).checked
      if (k == true) {
        resposta.escolha = escolha
      }
    });

    let questaoParecer = new QuestaoParecer
    questaoParecer.id = questao.id
    resposta.questaoParecer = questaoParecer

    let responderRoteiro = new ResponderRoteiro
    responderRoteiro.id = this.responderRoteiro.id
    resposta.responderRoteiro = responderRoteiro

    resposta.usuario.id = this.auth.jwtPayload.id

    resposta.observacao = (<HTMLInputElement>document.getElementById(questao.id + "-observacao")).value

    if (questao.parecer == true) {
      //Mostra parecer  
      var y = (<HTMLInputElement>document.getElementById(questao.id + "-favoravel")).checked
      var z = (<HTMLInputElement>document.getElementById(questao.id + "-desfavoravel")).checked

      if (y == true) {
        resposta.respostaEnum = 0 //Sim
      } else if (z == true) {
        resposta.respostaEnum = 1 //Não
      } else { resposta.respostaEnum = null }

    } else {
      //Não mostra parecer
      resposta.respostaEnum = null
    }

    if (flagAtualizar) {
      resposta.id = questao.resposta.id
      this.respostaQuestaoParecerService.atualizar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.toasty.success("Resposta alterada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.respostaQuestaoParecerService.adicionar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.respostas[this.respostas.length] = retorno
          this.verificaSeTodasForamRespondidas()
          this.toasty.success("Resposta submetida com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    }



  }

  responderQuestaoCpfCnpjMonetarioNumericoData(questao, flagAtualizar) {
    let resposta = new RespostaQuestaoParecer
    resposta.data = new Date().getTime()

    resposta.anexo = null
    resposta.hashFile = null
    resposta.escolha = null

    let questaoParecer = new QuestaoParecer
    questaoParecer.id = questao.id
    resposta.questaoParecer = questaoParecer

    let responderRoteiro = new ResponderRoteiro
    responderRoteiro.id = this.responderRoteiro.id
    resposta.responderRoteiro = responderRoteiro

    resposta.usuario.id = this.auth.jwtPayload.id

    if (questao.tipo == 'CPF' || questao.tipo == 'CNPJ') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-cpf-cnpj")).value
    } else if (questao.tipo == 'Monetario') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-monetario")).value
    } else if (questao.tipo == 'Numerico') {
      resposta.resposta = (<HTMLInputElement>document.getElementById(questao.id + "-numerico")).value
    } else if (questao.tipo == 'Data') {
      resposta.responderData = (<HTMLInputElement>document.getElementById(questao.id + "-data")).value
    }

    resposta.observacao = (<HTMLInputElement>document.getElementById(questao.id + "-observacao")).value

    if (questao.parecer == true) {
      //Mostra parecer  
      var y = (<HTMLInputElement>document.getElementById(questao.id + "-favoravel")).checked
      var z = (<HTMLInputElement>document.getElementById(questao.id + "-desfavoravel")).checked

      if (y == true) {
        resposta.respostaEnum = 0 //Sim
      } else if (z == true) {
        resposta.respostaEnum = 1 //Não
      } else { resposta.respostaEnum = null }

    } else {
      //Não mostra parecer
      resposta.respostaEnum = null
    }

    if (flagAtualizar) {
      resposta.id = questao.resposta.id
      this.respostaQuestaoParecerService.atualizar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.toasty.success("Resposta alterada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.respostaQuestaoParecerService.adicionar(resposta)
        .then(retorno => {
          questao.resposta = retorno
          this.respostas[this.respostas.length] = retorno
          this.verificaSeTodasForamRespondidas()
          this.toasty.success("Resposta submetida com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  // === FINALIZAR/ALTERAR === //
  finalizarQuestionario() {
    let hash = new GenerateParamPipe().generateHash()

    this.responderRoteiroService.mudarStatus(this.responderRoteiro.id, 1, hash)
      .then(() => {
        this.responderRoteiro.status = 'Submetido'
        this.roteiroJaSubmetido = true
        this.toasty.success("Roteiro Subumetido com Sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  alterarQuestionario() {
    let hash = new GenerateParamPipe().generateHash()

    this.responderRoteiroService.mudarStatus(this.responderRoteiro.id, 0, hash)
      .then(() => {
        this.responderRoteiro.status = 'Em_elaboracao'
        this.roteiroJaSubmetido = false
        this.toasty.success("Roteiro Em Elaboração!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


}
