import { AuthService } from '../../seguranca/auth.service';
import { EmendaParlamentarService } from '../emenda-parlamentar.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { EmendaParlamentar } from '../../core/model';

import { ToastyService } from 'ng2-toasty';

import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProponenteService } from '../../core/services/proponente/proponente.service';
import { Pt_Br } from '../../core/date-pt_br';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-emenda-parlamentar-cadastro',
  templateUrl: './emenda-parlamentar-cadastro.component.html',
  styleUrls: ['./emenda-parlamentar-cadastro.component.css']
})
export class EmendaParlamentarCadastroComponent implements OnInit {

  emendaParlamentar = new EmendaParlamentar();
  emendaParlamentarDTO = new EmendaParlamentar();
  entidadesConcedente = []
  entidadesProponente = []

  selectedValues: any;
  mostraSelecaoOsc: boolean
  count:any;

  tipos = [
    { label: 'Sim', value: 1 },
    { label: 'Não', value: 0 }
  ];

  pt = new Pt_Br().pt_br;

  constructor(
    public auth: AuthService,
    private entidadeProponenteService: ProponenteService,
    private emandaParlamentarService: EmendaParlamentarService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title

  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    this.carregarEntidadesProponentes() 

    const codigo = this.route.snapshot.params['codigo'];

    this.title.setTitle('Nova Emenda');

    if (codigo) {
      this.carregarPorCodigo(codigo);
    }
  }

  get editando() {
    return Boolean(this.emendaParlamentar.id)
  }

  carregarEntidadesProponentes(){
    return this.entidadeProponenteService.listarTodos()
    .then(comissoes => {
      this.entidadesProponente = comissoes.map(c => ({ label: c.nome, value: c.id }));
    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  carregarPorCodigo(codigo: number) {
    this.emandaParlamentarService.buscarPorCodigo(codigo)
      .then(entidade => {
        
        if(entidade.entidadeProponente != null){
          this.selectedValues = ["SIM"]
          this.emendaParlamentar.tipo = 0
          this.mostraSelecaoOsc = true
          this.emendaParlamentarDTO.entidadeProponente = entidade.entidadeProponente
        }
        
        this.emendaParlamentar = entidade;
        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  salvar(form: FormControl) {
    if(this.emendaParlamentar.tipo == 0 && this.emendaParlamentar.entidadeProponente.id == null){
      this.toasty.warning('Selecione uma OSC ou desmarque a opção de vincular!');
    }else{
      if (this.editando) {
        if(this.emendaParlamentar.tipo == 1){
          this.emendaParlamentar.entidadeProponente = null
        }
        this.atualizar(form);
      } else {
        this.adicionar(form);
      }
    }
  }

  adicionar(form: FormControl) {
    this.emendaParlamentar.entidadeConcedente.id = this.auth.jwtPayload.concedente

    this.emandaParlamentarService.adicionar(this.emendaParlamentar)
      .then(enditadeAdcionada => {
        this.toasty.success('Emenda adicionada com sucesso!');

        this.router.navigate(['/emenda-parlamentar', enditadeAdcionada.id]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizar(form: FormControl) {
    this.emandaParlamentarService.atualizar(this.emendaParlamentar)
      .then(entidade => {
        this.emendaParlamentar = entidade;
        this.toasty.success('Emenda alterada com sucesso!');

        this.atualizarTituloEdicao();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  novo(form: FormControl) {
    form.reset();

    //Necessário para que "new Lancamento" seja executado depois de "form.reset"
    setTimeout(function () {
      this.emenda = new EmendaParlamentar();
    }.bind(this), 1);

    this.router.navigate(['/emenda-parlamentar/novo']);
  }

  atualizarTituloEdicao() {
    this.title.setTitle(`Edição da emenda: ${this.emendaParlamentar.titulo}`);
  }

  vinculaOsc(){
    if(this.selectedValues.length){
      this.mostraSelecaoOsc = true
      this.emendaParlamentar.tipo = 0
      this.emendaParlamentar.entidadeProponente = this.emendaParlamentarDTO.entidadeProponente
    }else{
      this.mostraSelecaoOsc = false
      this.emendaParlamentar.tipo = 1
      this.emendaParlamentar.entidadeProponente.id = null
    }
  }

  setaOsc(){
    this.emendaParlamentar.entidadeProponente = this.emendaParlamentarDTO.entidadeProponente
  }

}