import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty/src/toasty.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { DispensaInexigibilidadeService, DispensaInexigibilidadeFiltro } from '../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service';
import { Page } from '../../../core/pagination/pagination.model';
import { AnexoPropostaFiltro } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parcerias-listar',
  templateUrl: './parcerias-listar.component.html'
})
export class ParceriasListarComponent implements OnInit {

  listaStatus = [
    { label: "Em elaboração", value: "0" },
    { label: "Aguardando análise", value: "1" },
    { label: "Aprovado", value: "2" },
    { label: "Reprovado", value: "3" },
  ];

  anexosFiltro = new AnexoPropostaFiltro()

  dispensaInegibilidadeFiltro = new DispensaInexigibilidadeFiltro()
  listaParcerias = []

  constructor(
    private confirmation: ConfirmationService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private title: Title,
    private dispensaInegibilidadeService: DispensaInexigibilidadeService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.title.setTitle('Listar Propostas');
    this.buscarPropostas()
    this.dispensaInegibilidadeFiltro.statusProposta = ""
  }

  buscarPropostas() {
    this.dispensaInegibilidadeFiltro.tipoProposta = '0'

    this.dispensaInegibilidadeService.pesquisar(this.dispensaInegibilidadeFiltro)
      .then(resultado => {
        this.page = resultado
        this.listaParcerias = resultado.content
      }).catch(erro => this.errorHandler.handle(erro));
  }

  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.dispensaInegibilidadeFiltro.itensPorPagina = event.size
    this.dispensaInegibilidadeFiltro.pagina = event.page
  }

  //EDITAR
  gotoEditar(param) {
    this.router.navigate(["/proposta/parcerias/editar"]);
    sessionStorage.setItem('proposta', param)
  }

  submeterProposta(proposta) {
    this.dispensaInegibilidadeService.alterarStatus(proposta.id, 1)
      .then(() => {
        this.toasty.info("Proposta submetida com sucesso!")
        this.buscarPropostas()
      }).catch(erro => this.errorHandler.handle(erro));
  }

  confirmarExclusao(proposta: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(proposta);
      }
    });
  }

  excluir(proposta) {
    this.dispensaInegibilidadeService.excluir(proposta.id).then(resp => {
      this.toasty.success("Proposta Excluida!")
      this.buscarPropostas()
    }).catch(erro => this.errorHandler.handle(erro));
  }

  gotoAvaliacao(param) {
    this.router.navigate(["/proposta/parcerias/avaliacao"]);
    sessionStorage.setItem('proposta', JSON.stringify(param))
  }

  //UTILS
  exibirStatus(status) {
    if (status == "Em_elaboracao") {
      status = "Em elaboração"
    } else if (status == "Aguardando_analise") {
      status = "Aguardando análise"
    } else if (status == "Aprovado") {
      status = "Aprovado"
    } else if (status == "Reprovado") {
      status = "Reprovado"
    }
    return (status)
  }

}