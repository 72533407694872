import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { RequisitoHabilitacao } from '../../model';
import { AuthHttp } from 'angular2-jwt';
import { AuthService } from '../../../seguranca/auth.service';
import { environment } from '../../../../environments/environment';
import { URLSearchParams } from '@angular/http';

export class RequisitoHabilitacaoFiltro {
  titulo: string;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class RequisitoHabilitacaoService {
  url: string;
  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/requisito-habilitacao`;
  }
  
  pesquisar(filtro: RequisitoHabilitacaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const itens = responseJson.content;

        const resultado = {
          itens,
          total: responseJson.totalElements
        };
        // //console.log(itens);
        // //console.log('requisitos');
        return resultado;
      });
  }
  buscarPorCodigo(codigo: number): Promise<RequisitoHabilitacao> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as RequisitoHabilitacao;
        return entidade;
      });
  }
  adicionar(entidade: RequisitoHabilitacao): Promise<RequisitoHabilitacao> {
    return this.http
      .post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }
  atualizar(entidade: RequisitoHabilitacao): Promise<RequisitoHabilitacao> {
    return this.http
      .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as RequisitoHabilitacao);
  }
  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }
}
