import { AuthService } from './../../seguranca/auth.service';
import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-usuario-gerenciar",
  templateUrl: "./usuario-gerenciar.component.html"
})
export class UsuarioGerenciarComponent implements OnInit {
  
  constructor(
    public auth: AuthService,
    private title: Title
    ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Gerenciar usuários");
  }
}
