import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { RepassesPesquisaComponent } from './repasses-pesquisa/repasses-pesquisa.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [RepassesPesquisaComponent]
})
export class RepassesModule { }
