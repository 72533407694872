import { EmendaParlamentarCadastroComponent } from './emenda-parlamentar-cadastro/emenda-parlamentar-cadastro.component';
import { EmendaParlamentarPesquisaComponent } from './emenda-parlamentar-pesquisa/emenda-parlamentar-pesquisa.component';

import { SharedsModule } from "../shareds/shareds.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EmendaParlamentarService } from './emenda-parlamentar.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    
    SharedsModule
  ],
  declarations: [EmendaParlamentarCadastroComponent, EmendaParlamentarPesquisaComponent],
  providers:[ EmendaParlamentarService]
})
export class EmendaParlamentarModule { }
