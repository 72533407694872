import { EventEmitter, Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import * as moment from 'moment';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { EditalCredenciamento, Usuario } from '../../model';

export class EditalCredenciamentoFiltro {
  titulo: string;
  statusEdital: string;
  entidadeConcedente: any
  pagina = 0; 
  itensPorPagina = 20;
}

@Injectable()
export class EditalCredenciamentoService {

  static setaEdital = new EventEmitter <EditalCredenciamento>();
  static setaEditalVer = new EventEmitter <EditalCredenciamento>();

  editalCredenciamentoUrl: string;
  usuarioAdmin = new Usuario();

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.editalCredenciamentoUrl = `${environment.apiUrl}/editais-credenciamento`;
  }

  pesquisar(filtro: EditalCredenciamentoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }
    if (filtro.statusEdital) {
      // //console.log(filtro.statusEdital)
      params.set('statusEdital', filtro.statusEdital);
    }
    if (filtro.entidadeConcedente) {
      params.set('entidadeConcedente', filtro.entidadeConcedente);
    }

    return this.http.get(`${this.editalCredenciamentoUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        /*const editais = responseJson.content;

        const resultado = {
          editais,
          total: responseJson.totalElements
        };*/
        return responseJson;
      })
  }

  pesquisarDTO(filtro: EditalCredenciamentoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.titulo) {
      params.set('titulo', filtro.titulo);
    }
    if (filtro.statusEdital) {
      // //console.log(filtro.statusEdital)
      params.set('statusEdital', filtro.statusEdital);
    }
    if (filtro.entidadeConcedente) {
      params.set('entidadeConcedente', filtro.entidadeConcedente);
    }

    return this.http.get(`${this.editalCredenciamentoUrl}/dto`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        /*const editais = responseJson.content;

        const resultado = {
          editais,
          total: responseJson.totalElements
        };*/
        return responseJson;
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.editalCredenciamentoUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  listarEditaisDaEntidade(idEntidade: any): Promise<any> {
    const params = new URLSearchParams();
    params.set('entidadeConcedente', idEntidade);

    return this.http.get(`${this.editalCredenciamentoUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.editalCredenciamentoUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(editalCredenciamento: EditalCredenciamento): Promise<EditalCredenciamento> {
    this.usuarioAdmin.id = this.auth.jwtPayload.id;
    //editalCredenciamento.usuarioAdmin = this.usuarioAdmin;
    editalCredenciamento.statusRegistro = 0; //statusRegistro por padrão: Ativo
    editalCredenciamento.statusEdital = 0; //statusEdital por pardão: Recebendo manifestação de interese 

    return this.http.post(this.editalCredenciamentoUrl, JSON.stringify(editalCredenciamento))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(editalCredenciamento: EditalCredenciamento): Promise<EditalCredenciamento> {
    return this.http.put(`${this.editalCredenciamentoUrl}/${editalCredenciamento.id}`,
      JSON.stringify(editalCredenciamento))
      .toPromise()
      .then(response => {
        this.setaEditalVerEmitter(editalCredenciamento)
        
        const editalAlterado = response.json() as EditalCredenciamento
        this.converterStringsParaDatas([editalAlterado])
        return editalAlterado
      } );
  }

  buscarPorCodigo(codigo: number): Promise<EditalCredenciamento> {
    return this.http.get(`${this.editalCredenciamentoUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const editalCredenciamento = response.json() as EditalCredenciamento;

        this.converterStringsParaDatas([editalCredenciamento]);

        EditalCredenciamentoService.setaEdital.emit(editalCredenciamento)

        let i: any = editalCredenciamento.id
        localStorage.setItem('idEdital', i);

        return editalCredenciamento;
      });
  }

  buscarPorCodigoSemConverterData(codigo: number): Promise<EditalCredenciamento> {
    return this.http.get(`${this.editalCredenciamentoUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const editalCredenciamento = response.json() as EditalCredenciamento;
        EditalCredenciamentoService.setaEdital.emit(editalCredenciamento)
        // let i: any = editalCredenciamento.id
        // localStorage.setItem('idEdital', i);
        return editalCredenciamento;
      });
  }

  buscarPorCodigoSemEmiter(codigo: number, converter = false): Promise<EditalCredenciamento> {
    return this.http.get(`${this.editalCredenciamentoUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const editalCredenciamento = response.json() as EditalCredenciamento;

        let i: any = editalCredenciamento.id
        localStorage.setItem('idEdital', i);

        if(converter == true){
          this.converterStringsParaDatas([editalCredenciamento]);
        }
        return editalCredenciamento;
      });
  }

  setaEditalEmitter(editalCredenciamento){
    EditalCredenciamentoService.setaEdital.emit(editalCredenciamento)
  }
  setaEditalVerEmitter(editalCredenciamento){
    EditalCredenciamentoService.setaEditalVer.emit(editalCredenciamento)
  }

  //Retorna um edital e apenas as questões respondidas de uma determinada OSC
  buscarPorProponente(codigo: number, idProponente): Promise<EditalCredenciamento> {
    return this.http.get(`${this.editalCredenciamentoUrl}/buscar-por-proponente/${codigo}/${idProponente}`)
      .toPromise()
      .then(response => {
        const editalCredenciamento = response.json() as EditalCredenciamento;
        return editalCredenciamento;
      });
  }
  

  private converterStringsParaDatas(editais: EditalCredenciamento[]) {
    for (const editalCredenciamento of editais) {

      if (editalCredenciamento.periodoIniDefinRequisitosCred) {
        editalCredenciamento.periodoIniDefinRequisitosCred = moment(editalCredenciamento.periodoIniDefinRequisitosCred,
          'YYYY-MM-DD').toDate();
      }

      if (editalCredenciamento.periodoFimDefinRequisitosCred) {
        editalCredenciamento.periodoFimDefinRequisitosCred = moment(editalCredenciamento.periodoFimDefinRequisitosCred,
          'YYYY-MM-DD').toDate();
      }

      if (editalCredenciamento.dataPublicacaoEdital) {
        editalCredenciamento.dataPublicacaoEdital = moment(editalCredenciamento.dataPublicacaoEdital,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoIniManifestacaoInteresse) {
        editalCredenciamento.periodoIniManifestacaoInteresse = moment(editalCredenciamento.periodoIniManifestacaoInteresse,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoFimManifestacaoInteresse) {
        editalCredenciamento.periodoFimManifestacaoInteresse = moment(editalCredenciamento.periodoFimManifestacaoInteresse,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoIniAutenticarDoc) {
        editalCredenciamento.periodoIniAutenticarDoc = moment(editalCredenciamento.periodoIniAutenticarDoc,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoFimAutenticarDoc) {
        editalCredenciamento.periodoFimAutenticarDoc = moment(editalCredenciamento.periodoFimAutenticarDoc,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoPublicPreliminar) {
        editalCredenciamento.periodoPublicPreliminar = moment(editalCredenciamento.periodoPublicPreliminar,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoIniRecurso) {
        editalCredenciamento.periodoIniRecurso = moment(editalCredenciamento.periodoIniRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoFimRecurso) {
        editalCredenciamento.periodoFimRecurso = moment(editalCredenciamento.periodoFimRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoIniAnalRecurso) {
        editalCredenciamento.periodoIniAnalRecurso = moment(editalCredenciamento.periodoIniAnalRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.periodoFimAnalRecurso) {
        editalCredenciamento.periodoFimAnalRecurso = moment(editalCredenciamento.periodoFimAnalRecurso,
          'YYYY-MM-DD').toDate();
      }
      if (editalCredenciamento.dataPublicacaoFinal) {
        editalCredenciamento.dataPublicacaoFinal = moment(editalCredenciamento.dataPublicacaoFinal,
          'YYYY-MM-DD').toDate();
      }

    }
  }

}
