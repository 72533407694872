import { Component, OnInit } from '@angular/core';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-gerenciar',
  templateUrl: './gerenciar.component.html'
})
export class GerenciarComponent implements OnInit {

  showDefaultMessage

  constructor() { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    setTimeout(
      function () {
        document.getElementById('viewOn').scrollIntoView({
          behavior: 'smooth'
        });
      }.bind(this),
      10
    );
  }
}