import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { Subject } from "rxjs";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { DispensaInexigibilidade, PlanoTrabalho } from "../../../core/model";
import { ProponenteService } from "../../../core/services/proponente/proponente.service";
import { EditalCredenciamentoService } from "../../../core/services/edital-credenciamento/EditalCredenciamento.service";
import { EixoProgramaService } from "../../../core/services/edital-credenciamento/EixoPrograma.service";
import { AuthService } from "../../../seguranca/auth.service";
import { EixoProgramaHasProponenteCredenciadoService } from "../../../core/services/eixo-programa-has-proponente-credenciado/eixo-programa-has-proponente-credenciado.service";
import { DispensaInexigibilidadeService } from "../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service";
import { ConcedenteService, ConcedenteFiltro } from "../../../core/services/concedente/concedente.service";

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-proposta-dispensa-inegibilidade-cadastro",
  templateUrl: "./proposta-dispensa-inegibilidade-cadastro.component.html"
})
export class PropostaDispensaInegibilidadeCadastroComponent implements OnInit {

  dispensaInexigibilidade = new DispensaInexigibilidade();
  oscs = []
  entidadesConcedente: any;

  oscsJaCarregadas: boolean

  tipos = [{ label: "Sim", value: 1 }, { label: "Não", value: 0 }];

  selecionaTipoDispensa: boolean;
  dispensaMostraJustificativa: boolean;
  dispensaOutro: boolean;
  dispensaCredenciamento: boolean;


  planoTrabalho = new PlanoTrabalho();
  editaisCredenciamento: any;
  eixosEdital: any;
  proponentesCredenciados: any;
  flagDispCredenJaAdd: boolean;

  planosDaDispensa = []
  
  
  etapa1: boolean;
  etapa2: boolean;
  etapa3: boolean;
  etapa4: boolean;
  etapa5: boolean;
  

  constructor(
    private eixoProgramaHasProponenteCredenciadoService: EixoProgramaHasProponenteCredenciadoService,
    private eixoProgramaService: EixoProgramaService,
    private entidadeProponenteService: ProponenteService,
    private editalCredenciamentoService: EditalCredenciamentoService,
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private dispensaInexigibilidadeService: DispensaInexigibilidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title
  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    //this.verificaSeAdm()
    this.title.setTitle("Nova dispensa/inegibilidade");

    //Inicializando obj para richtexteditor
    this.dispensaInexigibilidade.descricao = "Descrição..." 
    this.planoTrabalho.justificativa = "Justificativa..." 
  }

  // ==== SE ADMINISTRADOR ====//
  
  verificaSeAdm() {
    if (this.auth.jwtPayload.perfil == "Administrador") {
      this.carregarEntidadesConcedente();
    } else {
      this.dispensaInexigibilidade.entidadeConcedente.id = this.auth.jwtPayload.concedente
    }
  }
  carregarEntidadesConcedente() {
    this.concedenteService.listarTodos()
      .then(entidades => {
        this.entidadesConcedente = entidades.map(c => ({ label: c.nome, value: c.id }))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }c
  entidadeSelecionada(idEntidade) {
    this.dispensaInexigibilidade.entidadeConcedente.id = idEntidade
  }

  // ==== TIPO PROCESSO ====//  
  tipoProcessoDispensa() {
    this.dispensaInexigibilidade.inexigibilidade = false
    this.dispensaInexigibilidade.dispensa = true

    //this.dispensaCredenciamento = false
    //this.dispensaOutro = false
    
    //AJUSTE TEMPORÁRIO, ELIMINANDO A ALTERNATIVA CREDENCIAMENTO
    this.tipoDispensaOutro()
  }
  tipoDispensaCredenciamento() {
    this.dispensaCredenciamento = true
    this.dispensaOutro = false
  }
  tipoDispensaOutro() {
    this.dispensaCredenciamento = false
    this.dispensaOutro = true

    this.carregaOscs()
  }
  tipoProcessoInexigibilidade() {
    this.carregaOscs()

    this.dispensaInexigibilidade.dispensa = false
    this.dispensaInexigibilidade.inexigibilidade = true

    this.dispensaCredenciamento = false
    this.dispensaOutro = false
  }

  carregaOscs() {
    if (!this.oscsJaCarregadas) {

      let concedenteFiltro = new ConcedenteFiltro()
      concedenteFiltro.itensPorPagina = 1000
      this.entidadeProponenteService.listarTodos()
        .then(entidades => {
          this.oscs = entidades.map(c => ({ label: c.nome, value: c.id }))
          this.oscsJaCarregadas = true
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  // === ADICIONA PROCESSO ===//
  salvar() {
    this.dispensaInexigibilidade.usuario.id = this.auth.jwtPayload.id

    if (this.dispensaCredenciamento) {
      this.dispensaInexigibilidade.dataPublicacao = null
      this.adicionaDispensaCredenciamento()
    }else{
      this.adicionaDispensaInexigibilidade()
    }
  
  }

  adicionaDispensaInexigibilidade() {
    this.planoTrabalho.usuario.id = this.auth.jwtPayload.id
    this.planoTrabalho.chamamentoPublico = null
    this.planoTrabalho.dispensaInexigibilidade = null
    this.planoTrabalho.editalCredenciamento = null
    this.planoTrabalho.eixoPrograma = null
    this.planoTrabalho.emendaParlamentar = null 
    this.planoTrabalho.celebracao = null
    
    this.planosDaDispensa[0] = this.planoTrabalho
  //  this.dispensaInexigibilidade.planoTrabalho = this.planosDaDispensa
     
   this.dispensaInexigibilidadeService.adicionar(this.dispensaInexigibilidade)
      .then(objAdd => {
        this.dispensaInexigibilidade = objAdd

        if (this.dispensaInexigibilidade.dispensa) {
          this.toasty.success("Processo por dispensa adicionado com sucesso!");
        } else {
          this.toasty.success("Processo por inexigibilidade adicionado com sucesso!");
        }
        this.router.navigate(['/dispensa-inexigibilidade/listar']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  adicionaDispensaCredenciamento(){
   // this.dispensaInexigibilidade.planoTrabalho = this.planosDaDispensa
    this.dispensaInexigibilidadeService.adicionar(this.dispensaInexigibilidade)
      .then(objAdd => {
        this.dispensaInexigibilidade = objAdd
        this.router.navigate(['/dispensa-inexigibilidade']);
        this.toasty.success("Processo por dispensa e credenciamento adicionado com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  novo(form: FormControl) {
    form.reset();

    //Necessário para que "new" seja executado depois de "form.reset"
    setTimeout(
      function () {
        this.dispensaInexigibilidade = new DispensaInexigibilidade();
        this.verificaSeAdm()
        this.fecharTodasEtapas()
      }.bind(this),
      1
    );


  }

  //=== CREDENCIAMENTO ===//
  carregaEditalCredenciamento() {
    this.editalCredenciamentoService.listarEditaisDaEntidade(this.dispensaInexigibilidade.entidadeConcedente.id)
      .then(editais => {
        this.editaisCredenciamento = editais.map(c => ({ label: c.titulo, value: c.id }))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  buscarEixosEdital(idEdital) {
    //Seta o nome do edital no obj
    this.editaisCredenciamento.forEach(edital => {
      if(edital.value == idEdital){
        this.planoTrabalho.editalCredenciamento.titulo = edital.label
      }  
    })

    this.eixoProgramaService.listarEixosDoEdital(idEdital)
      .then(eixos => {
        this.eixosEdital = eixos.map(c => ({ label: c.nome, value: c.id }))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  buscarEntidadesCredenciadas(idEixo) {
    //Seta o nome do eixo no ojb
    this.eixosEdital.forEach(eixo => {
      if(eixo.value == idEixo){
        this.planoTrabalho.eixoPrograma.nome = eixo.label
      }  
    })

    this.eixoProgramaHasProponenteCredenciadoService.buscarProponentesEixo(idEixo)
      .then(proponentes => {
        this.proponentesCredenciados = proponentes.map(c => ({ label: c.entidadeProponente.nome, value: c.entidadeProponente.id }))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  oscSelecionada(idOsc){
    //Seta o nome da entidade no Obj
    this.proponentesCredenciados.forEach(entidade => {
      if(entidade.value == idOsc){
        this.planoTrabalho.entidadeProponente.nome = entidade.label
      }
    });
  }

  //=== PLANO DE TRABALHO ===//
  adicionaPlanoTrabalho() {
    this.planoTrabalho.usuario.id = this.auth.jwtPayload.id
    this.planoTrabalho.dispensaInexigibilidade.id = this.dispensaInexigibilidade.id
    this.planoTrabalho.chamamentoPublico = null
    this.planoTrabalho.emendaParlamentar = null
    this.planoTrabalho.celebracao = null
    this.planosDaDispensa[this.planosDaDispensa.length] = this.planoTrabalho
    this.planoTrabalho = new PlanoTrabalho
  }
  excluirPlano(plano){
    var index = this.planosDaDispensa.indexOf(plano)
    this.planosDaDispensa.splice(index, 1);
    this.toasty.success("Removido com sucesso!");
  }

  // === ETAPA 4 ==== //
  retornaTipoProcesso(){
    let retorno
    if(this.dispensaInexigibilidade.inexigibilidade){
      retorno = "Inexigibilidade"
    }else if(this.dispensaOutro){
      retorno = "Dispensa"
    }else if(this.dispensaCredenciamento){
      retorno = "Dispensa na área de Saúde, Educação ou Assistência social "
    }
    return retorno
  }
  retornaNomeEntidade(){
    let nome
    this.oscs.forEach(entidade => {
      if(entidade.value == this.planoTrabalho.entidadeProponente.id){
        nome = entidade.label
      }
    });
    return nome
  }

  //==== ETAPAS =====//
  openEtapa1() {
    this.etapa1 = true
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa2() {
    this.etapa1 = false
    this.etapa2 = true
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa3() {
   if (this.dispensaCredenciamento) {
      this.carregaEditalCredenciamento()
    }
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = true
    this.etapa4 = false
    this.etapa5 = false
  }
  openEtapa4() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = true
    this.etapa5 = false
  }
  openEtapa5() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = true
  }
  fecharTodasEtapas() {
    this.etapa1 = false
    this.etapa2 = false
    this.etapa3 = false
    this.etapa4 = false
    this.etapa5 = false
  }



}
