import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty/src/toasty.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { Page } from '../../../core/pagination/pagination.model';
import { DispensaInexigibilidadeService, DispensaInexigibilidadeFiltro } from '../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service';



@Component({
  selector: 'app-proposta-dispensa-inegibilidade-listar',
  templateUrl: './proposta-dispensa-inegibilidade-listar.component.html'
})
export class PropostaDispensaInegibilidadeListarComponent implements OnInit {

  listaStatus = [
    { label: "Em elaboração", value: "0" },
    { label: "Aguardando análise", value: "1" },
    { label: "Aprovado", value: "2" },
    { label: "Reprovado", value: "3" },
   
        
  ];
  dispensaInegibilidadeFiltro = new DispensaInexigibilidadeFiltro();
  listaDispensaInegibilidade

  constructor(
   
    private confirmation: ConfirmationService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private title: Title,
    private dispensaInegibilidadeService: DispensaInexigibilidadeService
  ) { }

  ngOnInit() {
    this.title.setTitle('Listar');
   
  }

// === CONFIGURACOES DE PAGINACAO
page : Page;
changePage(event){
this.dispensaInegibilidadeFiltro.itensPorPagina = event.size
this.dispensaInegibilidadeFiltro.pagina = event.page
}

  pesquisarPropostaNome(){
    
    this.dispensaInegibilidadeService.pesquisar(this.dispensaInegibilidadeFiltro).then(resultado=>{
      this.page = resultado
      this.listaDispensaInegibilidade = resultado.content
    })
  }


}
