import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { Habilitacao, Osc, RespostaQuestaoRequisitoHabilitacao } from './../core/model';
import { ConsultaCepimService } from './../core/services/utils/consulta-cepim.service';
import { ValidacaoDocumentos } from './../core/services/utils/validacao-certidao.service';
import { environment } from './../../environments/environment';
import { AuthService } from './../seguranca/auth.service';
import { ErrorHandlerService } from './../core/error-handler.service';
import { RelatorioService } from './../core/services/relatorio/relatorio.service';

declare let AdminLTE: any;
declare var jquery: any;
declare var $: any;
moment.locale("pt");

@Component({
  selector: "app-habilitacao-doc",
  templateUrl: "./habilitacao-doc.component.html"
})
export class HabilitacaoDocComponent implements OnInit {

  @Input() hashHabilitacao: any;
  @Input() nomeEntidade: any;
  @Input() cnpjEntidade: any;

  loading;
  habilitacao: Habilitacao;
  respostaQuestao: RespostaQuestaoRequisitoHabilitacao;
  requisitoOptions: any;
  respostasProponente = [];
  dadosCepim = [];
  osc: Osc;

  textoConclusao;
  situacao;
  entidade;
  cnpj;
  gestorProponente;
  dataValidade;
  cepimInfo;
  informacoesDocumentos;
  opcaoVisulizar;

  constructor(
    private relatorioService: RelatorioService,
    private errorHandler: ErrorHandlerService,
    private consultaCepimService: ConsultaCepimService,
    private validacaoDocumentos: ValidacaoDocumentos,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    AdminLTE.init();
    if (this.hashHabilitacao) {
      this.getInformacoesDocumentos(this.hashHabilitacao)
      this.osc = JSON.parse(sessionStorage.getItem("entidadeSelecionada"))
    }
  }

  async getInformacoesDocumentos(hash) {
    await this.validacaoDocumentos.getCertidaoHabilitacao(hash).then(response => {
      this.informacoesDocumentos = response
      this.osc = this.informacoesDocumentos.entidadeDto;

      this.carregarHabilitacao()

    }).catch(erro => {
      this.errorHandler.handle(erro)
    });

  }


  imprimirCertidao() {
    let content = this.requisitoOptions;
    let cepimInfo = this.cepimInfo;
    let hash = this.hashHabilitacao;
    let dataImpressao = moment().format("L");
    let dataValidade = this.dataValidade;
    let textoConclusao = this.textoConclusao;
    let situacao = this.situacao;
    let entidade = this.entidade;
    let cnpj = this.cnpj;
    let gestorProponente = this.gestorProponente;
    let habilitacao =this.habilitacao.nome;
    let linkValidacao = `${environment.validacao}/certidao/${this.hashHabilitacao}`;

    let data = {
      content: content,
      cepimInfo: cepimInfo,
      hash: hash,
      dataValidade: dataValidade,
      dataImpressao: dataImpressao,
      linkValidacao: linkValidacao,
      textoConclusao: textoConclusao,
      situacao: situacao,
      habilitacao: habilitacao,
      entidade: entidade,
      cnpj: cnpj,
      gestorProponente: gestorProponente
    };

    // //console.log(data);

    return this.relatorioService
      .relatorioCertidao(data)
      .then(retorno => {
        this.loading = true;
        this.urlDocumento = environment.downloadFileUrl + "/report.pdf";
        // window.location.href = this.dataPdf

        // if(retorno.report && retorno.hash === this.paramImprimir){
        //   this.dataPdf = environment.downloadFileUrl + "/report.pdf";
        //   this.loading = true
        // }else{
        //   setTimeout(function(){
        //     this.imprimirCertidao()
        //   },3000);
        // }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarHabilitacao() {
    this.habilitacao = this.informacoesDocumentos.habilitacao;
    this.requisitoOptions = this.habilitacao.requisitoHabilitacao;

    this.carregarRespostasProponente();
  }

  carregarRespostasProponente() {
    this.respostasProponente = this.informacoesDocumentos.respostaQuestaoRequisitoHabilitacao;
    this.agruparRespostas(this.requisitoOptions);

    //ESTÁ QUEBRADO... É PRECISO VERIFICAR 
    this.carregarInformacoesParaCertidao(); 
  }

  agruparRespostas(requisitos) {
    if (requisitos === undefined) {
    } else {
      for (let requisito of requisitos) {
        for (let questao of requisito.questaoRequisitoHabilitacao) {
          questao.resposta = this.consultaResposta(questao.id);
        }
      }
    }
  }
  consultaResposta(questaoId) {
    for (let resposta of this.respostasProponente) {
      if (resposta.questaoRequisitoHabilitacao.id === questaoId) {
        return resposta;
      }
    }
    return;
  }

  openCloseCollapsed(item) {
    var x = document
      .getElementById(item + "-collapse-box-body-event")
      .getAttribute("id");
    let elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "collapse-box-body-event"
    );
    let myArray = Array.from(elements);
    myArray.forEach(element => {
      let a = element.getAttribute("id");
      if (x != a) {
        document
          .getElementById(element.getAttribute("id"))
          .classList.add("collapsed-box");
      }
    });

    var z = document
      .getElementById(item + "-collapse-box-body-event-child")
      .getAttribute("id");
    let elementsChild: HTMLCollectionOf<
      Element
    > = document.getElementsByClassName("collapse-box-body-event-child");
    let myArrayChild = Array.from(elementsChild);
    myArrayChild.forEach(elementChild => {
      let a = elementChild.getAttribute("id");
      if (z != a) {
        $(`#${elementChild.getAttribute("id")}`).slideUp(500);
      }
    });

    var y = document
      .getElementById(item + "-collapse-box-body-event-fa")
      .getAttribute("id");
    let elementsFa: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "collapse-box-body-event-fa"
    );
    let myArrayFa = Array.from(elementsFa);
    myArrayFa.forEach(elementFa => {
      let a = elementFa.getAttribute("id");
      if (y != a) {
        document
          .getElementById(elementFa.getAttribute("id"))
          .classList.remove("fa-minus");
        document
          .getElementById(elementFa.getAttribute("id"))
          .classList.add("fa-plus");
      }
    });
  }


  async carregarInformacoesParaCertidao() {
    this.osc = this.respostasProponente[0].entidadeProponente;
    if (this.osc.statusHabilitacao == "Habilitada_parcialmente") {
      this.textoConclusao = "HABILITA PARCIALMENTE";
      this.situacao = "Habilitada parcialmente";
    }
    if (this.osc.statusHabilitacao == "Habilitada_plenamente") {
      this.textoConclusao = "HABILITA PLENAMENTE";
      this.situacao = "Habilitada plenamente";
    }
    this.entidade = this.osc.nome;
    this.cnpj = this.osc.cnpj;
    this.gestorProponente = this.osc.chefe.nome;
    this.hashHabilitacao = this.osc.hashHabilitacao;


    let flagSituacao = moment().format("DD/MM/YYYY")
    let primeiro = 0
    this.dataValidade = moment().format("DD/MM/YYYY")
    this.respostasProponente.forEach(element => {
      if (element.dataValidade) {
        if (primeiro == 0) {
          flagSituacao = element.dataValidade
          primeiro = 1
        }

        // //console.log(flagSituacao)
        // //console.log(element.dataValidade)
        // //console.log("---")
        if (element.dataValidade <= flagSituacao) {
          // //console.log("mudando a data >>>")
          flagSituacao = element.dataValidade

          this.dataValidade = moment(flagSituacao).format("DD/MM/YYYY");
        }

        // FORMATANDO DATA PARA APRESENTAR NO RELATORIO
        element.dataValidade = moment(element.dataValidade).format("DD/MM/YYYY")
      }

    });

    // ==== MAIS UMA VALIDACAO PARA O STATUS BASEADO NAS DATAS DA VALIDADE DOS DOCUMENTOS
    // console.log("Flag situacao: ",moment(flagSituacao).format("YYYY-MM-DD"))
    // console.log("Moment data: ", moment().format("YYYY-MM-DD"))
    // console.log("Situacao: ", this.situacao)
    if (moment(flagSituacao).format("YYYY-MM-DD") < moment().format("YYYYY-MM-DD")) {
      this.textoConclusao = "HABILITA PARCIALMENTE";
      this.situacao = "Habilitada Parcialmente";
    }

    
    this.cepimInfo = "Não foi possível consultar no CEPIM"
    /** 
    await this.consultaCepimService.consultaCepim(this.osc.cnpj).then(retorno => {
      this.dadosCepim = retorno

      let concat = ""
      if (this.dadosCepim.length > 0) {
        this.dadosCepim.forEach(element => {
          concat = concat + " " + element.convenio.codigo
        });
      }
      if (concat) {
        this.cepimInfo = "Foram encontrados pendências no CEPIM no código SIAFI/SICONV: " + concat
        this.textoConclusao = "HABILITA PARCIALMENTE";
        this.situacao = "Habilitada parcialmente";
      } else {
        this.cepimInfo = "Nenhuma pendência encontrada no CEPIM."
      }
    }).catch(erro => this.errorHandler.handle(erro));
    this.imprimirCertidao()
    */
  }

  justificativaTexto
  viewDownload
  displayAvaliarResposta
  urlDocumento
  openModalEditar(resposta: RespostaQuestaoRequisitoHabilitacao) {
    this.displayAvaliarResposta = true;
    this.justificativaTexto = resposta.justificativa
    this.respostaQuestao = resposta;
    this.urlDocumento = environment.downloadFileUrl + "/" + this.respostaQuestao.hashFile;
    this.respostaQuestao.hashFile.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }

  
  download(){
    window.location.href = this.urlDocumento;
  }

  loadingCepim
  dadosCepimNaoEncontrados
  consultarCepim(cnpj: any) {
    this.loadingCepim = true;
    //cnpj teste 17849675000102 sem dados
    //cnpj teste 06888897000118 com dados
    this.consultaCepimService
      .consultaCepim(cnpj)
      .then(retorno => {
        this.dadosCepim = retorno;
        // //console.log(retorno)
        if (this.dadosCepim.length == 0) {
          this.dadosCepimNaoEncontrados = true;
        }
        this.loadingCepim = false;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


}
