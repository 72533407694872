import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() viewModal: boolean;

  @ViewChild('childModal') childModal : boolean = this.viewModal;


  @Input() tituloModal: string;





  constructor() { }

  ngOnInit() {

    if(this.viewModal)
      this.btnOnclick()
  }



  btnOnclick() {
    let modal = document.getElementById("myModal");
    modal.style.display = "block";
  }
  spanOnclick() {
    let modal = document.getElementById("myModal");
    modal.style.display = "none";
  }

  //===quando clicar fora 
  windowOnclick(event) {
    let modal = document.getElementById("myModal");
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }

}
