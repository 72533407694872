import { environment } from "../../../../environments/environment";
import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";
import { Perfil } from "../../model";

export class PerfilFiltro {
  id: string;
  nome: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class PerfilService {
  _Url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this._Url = `${environment.apiUrl}/perfis`;
  }

  pesquisar(filtro: PerfilFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.id) {
      params.set("id", filtro.id);
    }
    if (filtro.nome) {
      params.set("nome", filtro.nome);
    }

    return this.http
      .get(`${this._Url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        // const dados = responseJson.content;
        // const resultado = {
        //   dados,
        //   total: responseJson.totalElements
        // };
        return responseJson;
      });
  }

  listarTodos(): Promise<any> { 
    return this.http
      .get(`${this._Url}/todos`)
      .toPromise()
      .then(response => response.json());
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this._Url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Perfil): Promise<Perfil> {
    return this.http
      .post(this._Url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: Perfil): Promise<Perfil> {
    return this.http
      .put(`${this._Url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Perfil);
  }

  buscarPorCodigo(codigo: number): Promise<Perfil> {
    return this.http
      .get(`${this._Url}/${codigo}`)
      .toPromise()
      .then(response => {
        const retorno = response.json() as Perfil;
        return retorno;
      });
  }
}
