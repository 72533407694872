import { EditalCredenciamentoService } from './../../../core/services/edital-credenciamento/EditalCredenciamento.service';
import { EixoProgramaService } from './../../../core/services/edital-credenciamento/EixoPrograma.service';
import { AnexoProposta, Usuario, AnexosEvidencias } from '../../../core/model';
import { ProponenteFiltro } from '../../../core/services/proponente/proponente.service';
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { Subject } from "rxjs";
import { ConcedenteFiltro, ConcedenteService } from '../../../core/services/concedente/concedente.service';
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { DispensaInexigibilidade, PlanoTrabalho } from "../../../core/model";
import { DispensaInexigibilidadeService } from "../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service";
import { ProponenteService } from "../../../core/services/proponente/proponente.service";
import { AuthService } from "../../../seguranca/auth.service";
import { EixoProgramaHasProponenteCredenciadoService } from "../../../core/services/eixo-programa-has-proponente-credenciado/eixo-programa-has-proponente-credenciado.service";
import { AnexoPropostaService, AnexoPropostaFiltro } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { environment } from '../../../../environments/environment';
import { elementAt } from 'rxjs/operator/elementAt';
import { ConfirmationService } from 'primeng/primeng';
import { PlanoTrabalhoService } from '../../../core/services/plano-trabalho/planoTrabalho.service';


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-patrocinio-avaliacao",
  templateUrl: "./patrocinio-avaliacao.component.html"
})

export class PatrocinioAvaliacaoComponent implements OnInit {
  
  
  dispensaInexigibilidade = new DispensaInexigibilidade();
  planoTrabalho = new PlanoTrabalho();  
  anexosSalvos = []  
  displayAnexo: boolean;
  urlDocumento
  viewDownload
  nomeAnexo
  anexoFiltro = new AnexoPropostaFiltro

  listaAcao = [
    { label: "Açao I", value: "0" },
    { label: "Ação II", value: "1" },
    { label: "Ação III", value: "2" },
    { label: "Ação IV", value: "3" },
   
    
  ];
  listaProduto = [
    { label: "Produto I", value: "0" },
    { label: "Produto II", value: "1" },
    { label: "Produto III", value: "2" },
    { label: "Produto IV", value: "3" },
   
    
  ];

  constructor(
    private eixoProgramaHasProponenteCredenciadoService: EixoProgramaHasProponenteCredenciadoService,
    private eixoProgramaService: EixoProgramaService,
    private entidadeProponenteService: ProponenteService,
    private editalCredenciamentoService: EditalCredenciamentoService,
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private dispensaInexigibilidadeService: DispensaInexigibilidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title, 
    private anexoPropostaService: AnexoPropostaService,
    private confirmation: ConfirmationService,
    private planoTrabalhoService: PlanoTrabalhoService,
    
  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();
   
    
  
    this.title.setTitle("Avaliar Proposta");
    const idProposta =  sessionStorage.getItem("proposta");
 
    this.carregarProposta(idProposta)
    this.carregarAnexos(idProposta)

  }
  //§§§§§§§§§§§§§ CARREGAR PROPOSTA
    carregarProposta(idProposta){
      this.dispensaInexigibilidadeService.buscarPorCodigo(idProposta).then(disp=>{
        this.dispensaInexigibilidade = disp
        this.planoTrabalho = disp.planoTrabalho[0]
       this.carregarPlano(this.planoTrabalho.id)
      }).catch(erro => this.errorHandler.handle(erro));
     
    }

  //############# CARREGAR PLANO DE TRABALHO
     carregarPlano(idPlano){
      this.planoTrabalhoService.buscarPorCodigo(idPlano).then(res =>{
        //console.log(res)
        this.planoTrabalho = res
        
      }).catch(erro => this.errorHandler.handle(erro));
        
     }
  //#############./ CARREGAR PLANO DE TRABALHO
   
  

  
  
  //############# CARREGAR ANEXOS
  carregarAnexos(idProposta){
   
    this.anexoFiltro.dispensaInexigibilidade = idProposta 
    this.anexoPropostaService.pesquisar(this.anexoFiltro).then(anexos=>{
      this.anexosSalvos = anexos.content
      
     
    }) .catch(erro => this.errorHandler.handle(erro));  

    
  }
  //#############./ CARREGAR ANEXOS

// ################# VIEW ANEXO 
openModalAnexo(anexo, tipo){
  this.displayAnexo = true
  if(tipo == "novo"){
  
  this.nomeAnexo = anexo.originalname
  this.urlDocumento = environment.downloadFileUrl + "/" + anexo.filename;
  anexo.filename.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }else{
  
  this.nomeAnexo = anexo.proposta
  this.urlDocumento = environment.downloadFileUrl + "/" + anexo.hashFile;
  anexo.filename.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }
}
//######################./ VIEW ANEXO
 
 
// === ADICIONA PROPOSTA TIPO PARCERIA ===//
  
 
  salvar() { 
    
   
       // this.dispensaInexigibilidadeService.atualizar(this.dispensaInexigibilidade)
         // .then(objAdd => {    
        
        this.toasty.success("Proposta avaliada com sucesso!");        
        this.router.navigate(['proposta/patrocinio/listar']);

   // })
   
  }
  voltarListagem(){
    this.router.navigate(['proposta/patrocinio/listar']);
  }
// ==== BUSCAR PROPONENTE
  buscarProponente(id){
  this.entidadeProponenteService.buscarPorCodigo(id).then( proponent=>{
    this.planoTrabalho.entidadeProponente = proponent
   
  })
  }  
// === BUSCAR CONCEDENTE
  buscarConcedente(id){
    this.concedenteService.buscarPorCodigo(id).then( concedente=>{
      this.dispensaInexigibilidade.entidadeConcedente = concedente     
    })
  }
 



}
