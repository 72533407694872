import { PropostaDispensaInegibilidadeListarComponent } from './proposta-dispensa-inegibilidade/proposta-dispensa-inegibilidade-listar/proposta-dispensa-inegibilidade-listar.component';
import { PropostaDispensaInegibilidadeCadastroComponent } from './proposta-dispensa-inegibilidade/proposta-dispensa-inegibilidade-cadastro/proposta-dispensa-inegibilidade-cadastro.component';
import { PropostaPatrocinioCadastroComponent } from './patrocinio/proposta-patrocinio-cadastro/proposta-patrocinio-cadastro.component';
import { PatrocinioListarComponent } from './patrocinio/patrocinio-listar/patrocinio-listar.component';

import { PatrocinioGerenciarComponent } from './patrocinio/patrocinio-gerenciar/patrocinio-gerenciar.component';
import { ContratoListarComponent } from './contrato/contrato-listar/contrato-listar.component';
import { ContratoCadastroComponent } from './contrato/contrato-cadastro/contrato-cadastro.component';
import { ConveniosListarComponent } from './convenios/convenios-listar/convenios-listar.component';
import { ParceriasListarComponent } from './parcerias/parcerias-listar/parcerias-listar.component';
import { ParceriasCadastroComponent } from './parcerias/parcerias-cadastro/parcerias-cadastro.component';
import { ParceriasGerenciarComponent } from './parcerias/parcerias-gerenciar/parcerias-gerenciar.component';
import { PropostaGerenciarComponent } from './proposta-gerenciar/proposta-gerenciar.component';
import { NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../seguranca/auth.guard';
import { ConveniosGerenciarComponent } from './convenios/convenios-gerenciar/convenios-gerenciar.component';
import { ConveniosCadastroComponent } from './convenios/convenios-cadastro/convenios-cadastro.component';
import { ContratoGerenciarComponent } from './contrato/contrato-gerenciar/contrato-gerenciar.component';
import { PropostaDispensaInegibilidadeGerenciarComponent } from './proposta-dispensa-inegibilidade/proposta-dispensa-inegibilidade-gerenciar/proposta-dispensa-inegibilidade-gerenciar.component';
import { ParceriasEditarComponent } from './parcerias/parcerias-editar/parcerias-editar.component';
import { ParceriasAvaliacaoComponent } from './parcerias/parcerias-avaliacao/parcerias-avaliacao.component';
import { ConveniosAvaliacaoComponent } from './convenios/convenios-avaliacao/covenios-avaliacao.component';
import { ConveniosEditarComponent } from './convenios/convenios-editar/convenios-editar.component';
import { ContratoAvaliacaoComponent } from './contrato/contrato-avaliacao/contrato-avaliacao.component';
import { ContratoEditarComponent } from './contrato/contrato-editar/contrato-editar.component';
import { PatrocinioEditarComponent } from './patrocinio/patrocinio-editar/patrocinio-editar.component';
import { PatrocinioAvaliacaoComponent } from './patrocinio/patrocinio-avaliacao/patrocinio-avaliacao.component';

const routers = [
    { path: '', component: PropostaGerenciarComponent, canActivate: [AuthGuard],
        children: [
            { path: 'parcerias',component: ParceriasGerenciarComponent ,canActivate: [AuthGuard],
            children:[
                { path: 'novo', component: ParceriasCadastroComponent, canActivate: [AuthGuard] }, 
                { path: 'listar', component: ParceriasListarComponent, canActivate: [AuthGuard] },
                { path: 'editar', component: ParceriasEditarComponent, canActivate: [AuthGuard] },
                { path: 'avaliacao', component: ParceriasAvaliacaoComponent, canActivate: [AuthGuard]} 
            ]
            },
            { path: 'convenios',component: ConveniosGerenciarComponent, canActivate: [AuthGuard],
            children:[
                { path: 'novo', component: ConveniosCadastroComponent, canActivate: [AuthGuard] }, 
                { path: 'listar', component: ConveniosListarComponent, canActivate: [AuthGuard] },
                { path: 'editar', component: ConveniosEditarComponent, canActivate: [AuthGuard] },
                { path: 'avaliacao', component: ConveniosAvaliacaoComponent, canActivate: [AuthGuard] }, 
            ]
            },
            { path: 'contrato',component: ContratoGerenciarComponent, canActivate: [AuthGuard],
            children:[
                { path: 'novo', component: ContratoCadastroComponent, canActivate: [AuthGuard] }, 
                { path: 'listar', component: ContratoListarComponent, canActivate: [AuthGuard] },
                { path: 'editar', component: ContratoEditarComponent, canActivate: [AuthGuard] },
                { path: 'avaliacao', component: ContratoAvaliacaoComponent, canActivate: [AuthGuard] }, 
            ]
            },
            { path: 'patrocinio',component: PatrocinioGerenciarComponent, canActivate: [AuthGuard],
            children:[
                { path: 'novo', component: PropostaPatrocinioCadastroComponent, canActivate: [AuthGuard] }, 
                { path: 'listar', component: PatrocinioListarComponent, canActivate: [AuthGuard] },
                { path: 'editar', component: PatrocinioEditarComponent, canActivate: [AuthGuard] },
                { path: 'avaliacao', component: PatrocinioAvaliacaoComponent, canActivate: [AuthGuard] }, 
            ]
            },
            { path: 'dispensaInegibilidade',component: PropostaDispensaInegibilidadeGerenciarComponent, canActivate: [AuthGuard],
            children:[
                { path: 'novo', component: PropostaDispensaInegibilidadeCadastroComponent, canActivate: [AuthGuard] }, 
                { path: 'listar', component: PropostaDispensaInegibilidadeListarComponent, canActivate: [AuthGuard] }, 
            ]
            },
               
            
    ]}
];

@NgModule({
    imports: [RouterModule.forChild(routers)],
    exports: [RouterModule]
})
export class PropostaRoutingModule { }