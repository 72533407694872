import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from "primeng/components/common/api";
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { Page } from '../../../core/pagination/pagination.model';
import { AuthService } from '../../../seguranca/auth.service';
import { ComissaoSelecaoFiltro, ComissaoSelecaoService } from '../comissaoSelecao.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-comissao-selecao-pesquisa',
  templateUrl: './comissao-selecao-pesquisa.component.html'
})
export class ComissaoSelecaoPesquisaComponent implements OnInit {

  totalRegistros = 0;
  filtro = new ComissaoSelecaoFiltro();
  comissoes = [];

  loading: boolean;

  constructor(
    private comissaoSelecaoService: ComissaoSelecaoService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService
  ) { }

  ngOnInit() {
    AdminLTE.init();

    this.title.setTitle('Gerenciar Comissões');
    this.pesquisar()
  }


  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.filtro.itensPorPagina = event.size
    this.filtro.pagina = event.page
    this.comissaoSelecaoService.pesquisar(this.filtro).then(res => {
      this.page = res
      this.comissoes = res.content;
    });
  }

  pesquisar(pagina = 0) {
    this.filtro.pagina = pagina;
    this.filtro.entidadeConcedente = this.auth.jwtPayload.concedente

    this.comissaoSelecaoService.pesquisar(this.filtro)
      .then(resultado => {
        this.loading = true
        this.comissoes = resultado.content;
        this.page = resultado;
      });
  }

  confirmarExclusao(comissaoSelecao: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(comissaoSelecao);
      }
    });
  }

  excluir(comissaoSelecao: any) {
    this.comissaoSelecaoService.excluir(comissaoSelecao.id)
      .then(() => {
        this.pesquisar();
        this.toasty.success('Comissão de seleção excluída com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  comissaoSelecionada(comissao){
    sessionStorage.setItem('comissaoCompPesquisa', JSON.stringify(comissao))
  }

}
