import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { environment } from "../../../environments/environment";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Habilitacao, HabilitacaoHasProponente, Osc, RespostaQuestaoRequisitoHabilitacao } from "../../core/model";
import { RelatorioService } from "../../core/services/relatorio/relatorio.service";
import { ConsultaCepimService } from "../../core/services/utils/consulta-cepim.service";
import { ValidacaoDocumentos } from '../../core/services/utils/validacao-certidao.service';

declare let AdminLTE: any;
moment.locale("pt");

@Component({
  selector: "app-certidao",
  templateUrl: "./certidao.component.html"
})
export class CertidaoComponent implements OnInit {

  osc: Osc;
  habilitacaoHasProponente: HabilitacaoHasProponente;
  habilitacao: Habilitacao;
  respostaQuestao: RespostaQuestaoRequisitoHabilitacao;

  requisitoOptions: any;
  respostasProponente: any;
  dadosCepim = [];

  habilitacaoHasProponenteOptions;
  loading;
  dataPdf;
  textoConclusao;
  situacao;
  entidade;
  cnpj;
  gestorProponente;
  dataValidade;
  hashHabilitacao;
  cepimInfo;

  informacoesDocumentos

  constructor(
    private errorHandler: ErrorHandlerService,
    private consultaCepimService: ConsultaCepimService,
    private relatorioService: RelatorioService,
    private validacaoDocumentos: ValidacaoDocumentos,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    let hash:any
    let param = this.route.snapshot.params["hash"]
    if(param){
      hash = param
    }else{
      hash = sessionStorage.getItem('hashCertidao');
    }

    this.getInformacoesDocumentos(hash);
  }  

  getInformacoesDocumentos(hash){
    this.validacaoDocumentos.getCertidaoHabilitacao(hash).then(response => {
      this.informacoesDocumentos = response
      this.osc = this.informacoesDocumentos.entidadeDto;

      this.carregarHabilitacao()
    })
    .catch(erro => {
      this.router.navigateByUrl('/outras-opcoes/validacao');
      this.errorHandler.handle(erro)
    });
  
  }


  imprimirCertidao() {
    let content = this.requisitoOptions;
    let cepimInfo = this.cepimInfo;
    let hash = this.hashHabilitacao;
    let dataImpressao = moment().format("L");
    let dataValidade = this.dataValidade;
    let textoConclusao = this.textoConclusao;
    let situacao = this.situacao;
    let entidade = this.entidade;
    let cnpj = this.cnpj;
    let gestorProponente = this.gestorProponente;
    let habilitacao = this.habilitacao.nome;
    let linkValidacao = `${environment.validacao}/certidao/${this.hashHabilitacao}`;

    let data = {
      content: content,
      cepimInfo: cepimInfo,
      hash: hash,
      dataValidade: dataValidade,
      dataImpressao: dataImpressao,
      linkValidacao: linkValidacao,
      textoConclusao: textoConclusao,
      situacao: situacao,
      habilitacao: habilitacao,
      entidade: entidade,
      cnpj: cnpj,
      gestorProponente: gestorProponente
    };

    // //console.log(data);

    return this.relatorioService
      .relatorioCertidao(data)
      .then(retorno => {
        this.loading = true;
        this.dataPdf = environment.downloadFileUrl + "/report.pdf";
        window.location.href = this.dataPdf

        // if(retorno.report && retorno.hash === this.paramImprimir){
        //   this.dataPdf = environment.downloadFileUrl + "/report.pdf";
        //   this.loading = true
        // }else{
        //   setTimeout(function(){
        //     this.imprimirCertidao()
        //   },3000);
        // }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarHabilitacao() {
    this.habilitacao = this.informacoesDocumentos.habilitacao;
    this.requisitoOptions = this.habilitacao.requisitoHabilitacao;

    this.carregarRespostasProponente();
  }

  carregarRespostasProponente() {
    this.respostasProponente = this.informacoesDocumentos.respostaQuestaoRequisitoHabilitacao;
    this.agruparRespostas(this.requisitoOptions);

    this.carregarInformacoesParaCertidao();
  }

  async carregarInformacoesParaCertidao() {
    this.osc = this.respostasProponente[0].entidadeProponente;
    if (this.osc.statusHabilitacao == "Habilitada_parcialmente") {
      this.textoConclusao = "HABILITA PARCIALMENTE";
      this.situacao = "Habilitada parcialmente";
    }
    if (this.osc.statusHabilitacao == "Habilitada_plenamente") {
      this.textoConclusao = "HABILITA PLENAMENTE";
      this.situacao = "Habilitada plenamente";
    }
    this.entidade = this.osc.nome;
    this.cnpj = this.osc.cnpj;
    this.gestorProponente = this.osc.chefe.nome;
    this.hashHabilitacao = this.osc.hashHabilitacao;


    let flagSituacao
    let primeiro = 0
    this.dataValidade = moment().format("DD/MM/YYYY")
    this.respostasProponente.forEach(element => {
      if(element.dataValidade){
        
        if(primeiro == 0){
          flagSituacao = element.dataValidade
          primeiro = 1
        }

        if(element.dataValidade <= flagSituacao){
          // //console.log("mudando a data >>>")
          flagSituacao = element.dataValidade

          this.dataValidade = moment(flagSituacao).format("DD/MM/YYYY");
        }

         // FORMATANDO DATA PARA APRESENTAR NO RELATORIO
         element.dataValidade = moment(element.dataValidade).format("DD/MM/YYYY")
      }
      
    });

    // ==== MAIS UMA VALIDACAO PARA O STATUS BASEADO NAS DATAS DA VALIDADE DOS DOCUMENTOS
    // //console.log("Flag situacao: ", moment(flagSituacao).format("DD/MM/YYYY"))
    // //console.log("Moment data: ", moment().format("DD/MM/YYYY"))
    if (moment(flagSituacao) < moment()) {
      this.textoConclusao = "HABILITA PARCIALMENTE";
      this.situacao = "Habilitada Parcialmente";
    }

    this.cepimInfo = "Não foi possível consultar no CEPIM"
    // await this.consultaCepimService.consultaCepim(this.osc.cnpj).then(retorno => {
    //   this.dadosCepim = retorno
      
    //   let concat = ""
    //   if(this.dadosCepim.length > 0){
    //     this.dadosCepim.forEach(element => {
    //       concat = concat + " " + element.convenio.codigo
    //     });
    //   }
    //   if(concat){
    //     this.cepimInfo = "Foram encontrados pendências no CEPIM no código SIAFI/SICONV: "+concat
    //     this.textoConclusao = "HABILITA PARCIALMENTE";
    //     this.situacao = "Habilitada parcialmente";
    //   }else{
    //     this.cepimInfo = "Nenhuma pendência encontrada no CEPIM."
    //   }
    // })
    // .catch(erro => this.errorHandler.handle(erro));

    this.imprimirCertidao()

  }

  consultaResposta(questaoId) {
    for (let resposta of this.respostasProponente) {
      if (resposta.questaoRequisitoHabilitacao.id === questaoId) {
        return resposta;
      }
    }
    return;
  }

  agruparRespostas(requisitos) {
    if (requisitos === undefined) {
      // //console.log("Não há requisitos");
    } else {
      for (let requisito of requisitos) {
        for (let questao of requisito.questaoRequisitoHabilitacao) {
          questao.resposta = this.consultaResposta(questao.id);
        }
      }
    }
  }
}
