import { Router } from '@angular/router';
import { Component, OnInit } from "@angular/core";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { Celebracao } from "../../../core/model";
import { FinanceiroFiltro, FinanceiroService } from "../../../core/services/financeiro/financeiro.service";
import { AuthService } from "../../../seguranca/auth.service";

@Component({
  selector: "repasses",
  templateUrl: "./repasses.component.html"
})
export class RepassesComponent implements OnInit {


  celebracao = new Celebracao();
  listaRepasses = []

  constructor(
    private errorHandler: ErrorHandlerService,
    private financeiroService: FinanceiroService,
    private router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {

    this.celebracao = JSON.parse(sessionStorage.getItem("modExecucaoCelebracao"));
    this.getRepasses()

  }

  getRepasses() {
    let filtro = new FinanceiroFiltro()
    filtro.celebracao = this.celebracao.id
    filtro.tipo = '0' //Repasse_ob

    this.financeiroService.pesquisar(filtro)
      .then(retorno => {
        this.listaRepasses = retorno.dado
        // //console.log(this.listaRepasses)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  gotoAnaliseContas(param) {
    sessionStorage.setItem('repasse', JSON.stringify(param))
    this.router.navigate(["/prestacao-contas/visualizar/parcial/analise-contas/analise-repasse"]);
  }

  gotoExecucaoObjetoFinanceiro(param) {
    sessionStorage.setItem('repasse', JSON.stringify(param))
    this.router.navigate(["/prestacao-contas/visualizar/parcial/analise-contas/execucao-objeto-financeiro"]);
  }

  
}
