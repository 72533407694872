import { environment } from "../../../environments/environment";

import { ComissaoAcompanhamentoHasUsuarioConcedente } from "../../core/model";

import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import "rxjs/add/operator/toPromise";
import { AuthService } from "../../seguranca/auth.service";

export class ComissaoAcompanhamentoHasUsuarioConcedenteFiltro {
  usuario
  comissaoAcompanhamento
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class ComissaoAcompanhamentoHasUsuarioConcedenteService {
  url: string;
 

  constructor(
    private http: AuthHttp, 
    public auth: AuthService
  ) {
    this.url = `${environment.apiUrl}/comissao-acompanhamento-gestao-has-usuario-concedente`;
  }

  pesquisar(filtro: ComissaoAcompanhamentoHasUsuarioConcedenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.usuario) {
      params.set("usuario", filtro.usuario);
    }
    if (filtro.comissaoAcompanhamento) {
      params.set("comissaoAcompanhamentoGestao", filtro.comissaoAcompanhamento);
    }

    return this.http.get(`${this.url}`, { search: params }).toPromise()
      .then(response => {
        const responseJson = response.json();
        const retorno = responseJson.content;

        const resultado = {
          retorno,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(id: number): Promise<void> {
    return this.http.delete(`${this.url}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: ComissaoAcompanhamentoHasUsuarioConcedente): Promise<ComissaoAcompanhamentoHasUsuarioConcedente> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: ComissaoAcompanhamentoHasUsuarioConcedente): Promise<ComissaoAcompanhamentoHasUsuarioConcedente> {
    return this.http.put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as ComissaoAcompanhamentoHasUsuarioConcedente);
  }

  buscarPorCodigo(id: number): Promise<ComissaoAcompanhamentoHasUsuarioConcedente> {
    return this.http.get(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as ComissaoAcompanhamentoHasUsuarioConcedente;
        return entidade;
      });
  }
}
