import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NoticiasCadastroComponent } from './noticias-cadastro/noticias-cadastro.component';
import { NoticiasPesquisaComponent } from './noticias-pesquisa/noticias-pesquisa.component';
import { NoticiasService } from './noticias.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [NoticiasCadastroComponent, NoticiasPesquisaComponent],
  providers:[ NoticiasService,]
})
export class NoticiasModule { }
