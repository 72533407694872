import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EntidadeProponenteHasAreaDeAtuacaoService } from '../../area-de-atuacao/entidadeProponenteHasAreaAtuacao.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Osc } from '../../core/model';
import { ProponenteService } from '../../core/services/proponente/proponente.service';
import { AuthService } from '../../seguranca/auth.service';
import { UsuarioService } from '../../usuario/usuario.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-osc-view',
  templateUrl: './osc-view.component.html',
})
export class OscViewComponent implements OnInit, OnDestroy {

  usuarioVinculados = []
  entidade = new Osc();
  codigoEntidade


  proponentesVinculadas = [];

  constructor(
    private usuarioService: UsuarioService,
    private ProponenteService: ProponenteService,
    private errorHandler: ErrorHandlerService,
    private title: Title,
    public auth: AuthService,
    private entidadeProponenteHasAreaDeAtuacaoService: EntidadeProponenteHasAreaDeAtuacaoService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Informações Proponente');

    if (this.auth.jwtPayload.proponente) this.codigoEntidade = this.auth.jwtPayload.proponente
    if (sessionStorage.getItem('viewEntidade')) this.codigoEntidade = sessionStorage.getItem('viewEntidade')

    if (this.codigoEntidade) {
      this.carregarEntidade(this.codigoEntidade);
      this.carregarPessoasProponente(this.codigoEntidade);
      this.carregarJaVinculadasProponente();
    } else {
      this.codigoEntidade = false
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem("viewEntidade");
  }

  carregarEntidade(codigo: number) {
    this.ProponenteService
      .buscarPorCodigo(codigo)
      .then(entidade => {
        this.entidade = entidade;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarPessoasProponente(proponente) {
    return this.usuarioService
      .listarTodosProponente(proponente)
      .then(retorno => {
        this.usuarioVinculados = retorno
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarJaVinculadasProponente() {
    return this.entidadeProponenteHasAreaDeAtuacaoService
      .buscarJaVinculadas(this.codigoEntidade)
      .then(entidadesC => {
        this.proponentesVinculadas = entidadesC.objeto;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }




}