import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';

import { LazyLoadEvent, ConfirmationService } from "primeng/components/common/api";
import { ToastyService } from 'ng2-toasty';

import { ErrorHandlerService } from '../../core/error-handler.service';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-repasses-pesquisa',
  templateUrl: './repasses-pesquisa.component.html'
})
export class RepassesPesquisaComponent implements OnInit {

  totalRegistros = 0;
  filtro: any;
  dados = [];
  @ViewChild('tabela') grid;

  constructor() { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

  }

}