import { Usuario, NotificacaoHasUsuarioDTO } from './../../core/model';
import { Component } from "@angular/core";
import { Headers, Http, URLSearchParams } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from "../../../environments/environment";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Cidadao, EntidadeUsuarioAreaPublicaDTO, Osc } from "../../core/model";
import { PessaoUtil } from "../../core/pessoa.util";
import { GetAdress } from './../../core/services/utils/get-address.service';

@Component({
  selector: "app-nova-entidade",
  templateUrl: "./nova-entidade.component.html",
  styleUrls: ["./nova-entidade.component.css"]
})
export class NovaEntidadeComponent {
  osc = new Osc();
  cidadao = new Cidadao();
  pessoaUtil = new PessaoUtil();

  repetirSenha: any;
  reCaptcha: any;

  constructor(
    private http: Http,
    private getAdress: GetAdress,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private reCaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.title.setTitle("Nova OSC");

    this.reCaptchaV3Service.execute("6LdCbIoUAAAAAJL-BVvqkCR5fhjEg84kUXNS2MOr", "login", token => {
      this.reCaptcha = token;
      // //console.log("This is your token: ", token);
    });
  }

  etapa1;
  etapa2;
  etapa3;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
  }

  verificaPreenchimentoGestor() {
    if (this.cidadao.nome == '' || this.cidadao.nome == null) {
      this.toasty.warning("Infome o nome do Gestor da Entidade!");
    } else if (this.cidadao.cpf == '' || this.cidadao.cpf == null) {
      this.toasty.warning("Infome o CPF do Gestor da Entidade!");
    } else if (this.cidadao.email == '' || this.cidadao.email == null) {
      this.toasty.warning("Infome o e-mail do Gestor da Entidade!");
    } 
    
    // else if (this.cidadao.endereco.cep == '' || this.cidadao.endereco.cep == null) {
    //   this.toasty.warning("Infome o CEP do Gestor da Entidade!");
    // } else if (this.cidadao.endereco.numero == '' || this.cidadao.endereco.numero == null) {
    //   this.toasty.warning("Infome o número do endereço do Gestor da Entidade!");
    // } 
    
    else {
      this.verificaPreenchimentoEntidade()
    }
  }

  verificaPreenchimentoEntidade() {
    if (this.osc.nome == '' || this.osc.nome == null) {
      this.toasty.warning("Infome o nome da Entidade!");
    } else if (this.osc.cnpj == '' || this.osc.cnpj == null) {
      this.toasty.warning("Infome o CNPJ da Entidade!");
    } else if (this.osc.endereco.cep == '' || this.osc.endereco.cep == null) {
      this.toasty.warning("Infome o CEP da Entidade!");
    } else if (this.osc.endereco.numero == '' || this.osc.endereco.numero == null) {
      this.toasty.warning("Infome o número do endereço da Entidade!");
    } else {
      this.salvar()
    }

  }

  salvar() {
    if (this.cidadao.senha != this.repetirSenha) {
      this.toasty.error("As senhas não conferem.");
      return null;
    }

    this.adicionar()
      .then(() => {
        this.toasty.success("OSC cadastrado com sucesso!");
        this.router.navigate(["/login"]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  adicionar(): Promise<any> {
    if (this.reCaptcha != null) {

      this.osc.statusHabilitacao = 0
      this.osc.tipoProponente = 'OSC'

      let entidadeUsuarioAreaPublicaDTO = new EntidadeUsuarioAreaPublicaDTO()
      entidadeUsuarioAreaPublicaDTO.entidadeDto = this.osc
      entidadeUsuarioAreaPublicaDTO.usuarioDto = this.cidadao

      // //console.log(JSON.stringify(entidadeUsuarioAreaPublicaDTO))

      let url = `${environment.apiUrl}/entidade-proponente/area-publica`
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      return this.http
        .post(url, JSON.stringify(entidadeUsuarioAreaPublicaDTO), { headers })
        .toPromise()
        .then(response => {
          let data = response.json()
          this.enviarMensagem(data.usuarioDto)
        });


    } else {
      this.errorHandler.handle("Erro capctha");
    }
  }

  async enviarMensagem(usuario) {

    let usuariosLista = [];
    usuariosLista.push(usuario)

    var notificacaoHasUsuarioDTO = new NotificacaoHasUsuarioDTO()

    notificacaoHasUsuarioDTO.dataEnvio = new Date().getTime()
    notificacaoHasUsuarioDTO.statusNotificacao = 'Nao_lida'
    notificacaoHasUsuarioDTO.favorito = 0
    notificacaoHasUsuarioDTO.lixeira = 0
    notificacaoHasUsuarioDTO.usuarioLista = usuariosLista
    notificacaoHasUsuarioDTO.notificacao.id = 1

    let url = `${environment.apiUrl}/notificacao-has-usuario/salvar-notificacoes`
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(url, JSON.stringify(notificacaoHasUsuarioDTO), { headers })
      .toPromise()
      .then(response => response.json());
  }


  getEnderecoCep(event, tipo) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return
          }
          if (tipo === 'osc') {
            this.osc.endereco.cep = data.cep
            this.osc.endereco.endereco = data.logradouro
            this.osc.endereco.complemento = data.complemento
            this.osc.endereco.bairro = data.bairro
            this.osc.endereco.cidade = data.localidade
            this.osc.endereco.estado = data.uf
          }
          if (tipo === 'cidadao') {
            this.cidadao.endereco.cep = data.cep
            this.cidadao.endereco.endereco = data.logradouro
            this.cidadao.endereco.complemento = data.complemento
            this.cidadao.endereco.bairro = data.bairro
            this.cidadao.endereco.cidade = data.localidade
            this.cidadao.endereco.estado = data.uf
          }
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }

  // ========== VALIDACAO CPF, EMAIL e CNPJ

  verificarCpf(): Promise<any> {

    if (this.pessoaUtil.vercpf(this.cidadao.cpf) != false) {

      if (this.cidadao.cpf != null && this.cidadao.cpf.length > 10) {

        let url = `${environment.apiUrl}/usuarios/pesquisa-publica`

        if (this.reCaptcha != null) {

          const params = new URLSearchParams();
          if (this.cidadao.cpf) {
            params.set("cpf", this.cidadao.cpf);
          }

          return this.http
            .get(url, { search: params })
            .toPromise()
            .then(response => {
              if (response.status == 200) {
                this.toasty.error("CPF já cadastrado")
                this.cidadao.cpf = null
              }
              // //console.log("Teste: " + response.status)
            });
        } else {
          this.errorHandler.handle("Erro capctha");
        }

      } else {
        this.toasty.warning("Por Favor preencha o CPF !")
      }

    } else {
      this.toasty.info("CPF INVALIDO")
      this.cidadao.cpf = null
    }
  }


  verificarEmail(): Promise<any> {
    this.cidadao.email = this.cidadao.email.toLowerCase();

    if (this.cidadao.email != null && this.cidadao.email.length > 8) {
      let url = `${environment.apiUrl}/usuarios/pesquisa-publica`

      if (this.reCaptcha != null) {
        const params = new URLSearchParams();
        if (this.cidadao.email) {
          params.set("email", this.cidadao.email);
        }
        return this.http
          .get(url, { search: params })
          .toPromise()
          .then(response => {
            if (response.status == 200) {
              this.toasty.error("Email já cadastrado")
              this.cidadao.email = null
            }
          });
      } else {
        this.errorHandler.handle("Erro capctha");
      }
    } else {
      this.toasty.warning("Por favor preencha o email !")
    }

  }

  verificarCnpj() {

    if (this.osc.cnpj != null && this.osc.cnpj.length > 10) {
      if (this.pessoaUtil.validarCNPJ(this.osc.cnpj) != false) {

        // //console.log("teste de cnpj")
        // //console.log(this.osc.cnpj)

        let url = `${environment.apiUrl}/entidade-proponente/pesquisa-publica`

        if (this.reCaptcha != null) {

          const params = new URLSearchParams();
          if (this.osc.cnpj) {
            params.set("cnpj", this.osc.cnpj);
          }
          return this.http
            .get(url, { search: params })
            .toPromise()
            .then(response => {
              if (response.status == 200) {
                this.toasty.error("CNPJ já cadastrado")
                this.osc.cnpj = null
              }
            });
        } else {
          this.errorHandler.handle("Erro capctha");
        }
      } else {
        this.osc.cnpj = null
        this.errorHandler.handle("CNPJ inválido!");
      }

    } else {
      this.toasty.warning("Por favor preencha o CNPJ!")
    }
  }

  verificarEmailOsc() {
    this.osc.email = this.osc.email.toLowerCase();
  }

}