import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { InstalacoesFisicas } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class InstalacoesFisicasFiltro {
  capacidadeInstalada
  versaoPlano
  pthpId
  ativoInativo 

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class InstalacoesFisicasService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/instalacoes-fisicas`;
  }

  pesquisar(filtro: InstalacoesFisicasFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.capacidadeInstalada) {
      params.set('capacidadeInstalada', filtro.capacidadeInstalada);
    }

    if (filtro.versaoPlano) {
      params.set('versaoPlano', filtro.versaoPlano);
    }

    if (filtro.pthpId) {
      params.set('pthpId', filtro.pthpId);
    }

    if (filtro.ativoInativo) {
      params.set('ativoInativo', filtro.ativoInativo);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: InstalacoesFisicas): Promise<InstalacoesFisicas> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  adicionaSeta(entidade: InstalacoesFisicas, id): Promise<InstalacoesFisicas> {
    return this.http.post(`${this.url}/${id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: InstalacoesFisicas): Promise<InstalacoesFisicas> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as InstalacoesFisicas);
  }

  buscarPorCodigo(codigo: number): Promise<InstalacoesFisicas> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as InstalacoesFisicas;
        return entidade;
      });
  }

  alterarAtivoInativo(codigo: number, status): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/ativo-inativo`, status)
      .toPromise()
      .then(() => null);
  }

}
