import { Component } from "@angular/core";
import { Http } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { AuthService } from "../../seguranca/auth.service";

@Component({
  selector: "app-validacao",
  templateUrl: "./validacao.component.html",
  styleUrls: ["./validacao.component.css"]
})
export class ValidacaoComponent {

  constructor(
    private http: Http,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle("Validação");
  }

  hashCertidao
  verificarCertidao(){
    // this.hashCertidao = "d42a11a583"
    sessionStorage.setItem('hashCertidao', this.hashCertidao);
    this.router.navigateByUrl('/outras-opcoes/validacao/certidao');
  }

  hashControleInterno
  verificarControleInterno(){
    sessionStorage.setItem('hashControleInterno', this.hashControleInterno);
    this.router.navigateByUrl('/outras-opcoes/validacao/controle-interno');
  }

}
