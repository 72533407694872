import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { AuthService } from "../../../seguranca/auth.service";
// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-mandato-cadastrar",
  templateUrl: "./mandato-cadastrar.component.html"
})
export class MandatoCadastrarComponent implements OnInit {


  constructor(
  
    public auth: AuthService,
    
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
  
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
    

    this.title.setTitle("Mandato Cadastrar");
    
  }




  // =====  VIEWS STEPS
  etapa1;
  etapa2;
  etapa3;
  etapa4;
  etapa5;
  etapa6;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
    this.etapa5 = false;
    this.etapa6 = false;
  }
  openEtapa5() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = true;
    this.etapa6 = false;
  }
  openEtapa6() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = true;
  }


}
