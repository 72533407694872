import { Component, OnInit } from '@angular/core';

declare let AdminLTE: any;

@Component({
  selector: 'app-mandato-gerenciar',
  templateUrl: './mandato-gerenciar.component.html',
})
export class MandatoGerenciarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AdminLTE.init();
  }

}
