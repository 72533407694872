import { Component } from "@angular/core";
import { Http } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { AuthService } from "../../seguranca/auth.service";

@Component({
  selector: "app-outras-opcoes",
  templateUrl: "./outras-opcoes.component.html",
  styleUrls: ["./outras-opcoes.component.css"]
})
export class OutrasOpcoesComponent {

  constructor(
    private http: Http,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle("Outras opções");
  }

}
