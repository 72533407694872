import { Component, OnInit } from "@angular/core";

declare let AdminLTE: any;

@Component({
  selector: "app-mapa",
  templateUrl: "./mapa.component.html"
})
export class MapaComponent implements OnInit {
  // openModal = false;

  // @ViewChild("search") public searchElement: ElementRef;

  // constructor(
  //   private mapsAPILoader: MapsAPILoader, 
  //   private ngZone: NgZone,
  //   private route: ActivatedRoute
  // ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.autocomplete();
  }

  // // google maps zoom level
  // zoom: number = 12;

  // // initial center position for the map
  // lat: number = -5.082437847937946;
  // lng: number = -42.79498313484771;

  // latitude: number;
  // longitude: number;
  // info: string;


  // initMap() {
  //   var myLatLng = {lat: this.lat, lng: this.lng};
  
  //   var map = new google.maps.Map(document.getElementById('map'), {
  //     zoom: 12,
  //     center: myLatLng
  //   });
  
  //   var marker = new google.maps.Marker({
  //     position: myLatLng,
  //     map: map,
  //     title: 'Hello World!'
  //   });

  //   marker.setMap(map);
  // }

  
  autocomplete() {
    // this.mapsAPILoader.load().then(() => {
    //   let autocomplete = new google.maps.places.Autocomplete(
    //     this.searchElement.nativeElement,
    //     { types: ["address"] }
    //   );

    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //         this.lat = place.geometry.location.lat();
    //         this.lng = place.geometry.location.lng();
    //         this.zoom = 16;

    //         this.markers.push({
    //           lat: place.geometry.location.lat(),
    //           lng: place.geometry.location.lng(),
    //           draggable: true,
    //           info: 'Search here...'
    //         });
    //     });
    //   });
    // });
  }

  // clickedMarker(label: string, index: number) {
  //   // //console.log(`clicked the marker: ${label || index}`);
  // }

  // mapClicked($event: MouseEvent) {
  //   // //console.log("Latitude: " + $event.coords.lat);
  //   // //console.log("Longitude: " + $event.coords.lng);

  //   (this.latitude = $event.coords.lat),
  //     (this.longitude = $event.coords.lng),
  //     (this.openModal = true);
  // }

  // salvarMarcador() {
  //   this.markers.push({
  //     lat: this.latitude,
  //     lng: this.longitude,
  //     draggable: true,
  //     info: this.info
  //   });
  //   this.openModal = false;
  // }

  // markerDragEnd(m: marker, $event: MouseEvent) {
  //   // //console.log("dragEnd", m, $event);
  // }

  // markers: marker[] = [
  //   {
  //     lat: -5.083666485135763,
  //     lng: -42.80360294618242,
  //     //label: 'A',
  //     draggable: true,
  //     info: "001/06-2018"
  //   }
  // ];
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  info?: string;
}
