import { ValidacaoDocumentos } from './../../core/services/utils/validacao-certidao.service';
import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastyService } from "ng2-toasty";
import { environment } from '../../../environments/environment';
import { ErrorHandlerService } from "../../core/error-handler.service";
import { ModelResponderRoteiro, RoteiroParecer } from "../../core/model";
import { ResponderRoteiroService } from "../../core/services/modelos-sistema/responderRoteiro.service";
import { RelatorioService } from '../../core/services/relatorio/relatorio.service';
import { ResponderRoteiroFiltro } from './../../core/services/modelos-sistema/responderRoteiro.service';

declare let AdminLTE: any;
moment.locale('pt');

export class ObsModel {
  questao: string
  embasamentoLegal: string
  informacaoesExtras: string
  recomendacao: string
  tipoImpropriedade: string
}

@Component({
  selector: "app-controle",
  templateUrl: "./controle.component.html"
})
export class ControleComponent implements OnInit {

  loading
  questoesRespondidas
  paramImprimir
  paramHash
  obsArray
  responderRoteiro
  dataPdf
  respostas = [];
  roteiro = new RoteiroParecer();
  modelResponderRoteiro = new ModelResponderRoteiro();

  constructor(
    private responderRoteiroService: ResponderRoteiroService,
    private relatorioService: RelatorioService,
    private errorHandler: ErrorHandlerService,
    private validacaoDocumentos: ValidacaoDocumentos,
    private route: ActivatedRoute,
    private router: Router,
    private toasty: ToastyService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    let hash: any
    let param = this.route.snapshot.params["hash"]
    if (param) {
      hash = param
    } else {
      hash = sessionStorage.getItem('hashControleInterno');
    }

    // //console.log(hash)

    this.buscarResponderRoteiroPorHash(hash)
  }

  // responderRoteiroHash
  buscarResponderRoteiroPorHash(hash) {
    // let filtro = new ResponderRoteiroFiltro()
    // filtro.hash = hash
    this.validacaoDocumentos.getResponderRoteiro(hash).then(async retorno => {

      this.responderRoteiro = retorno.content[0]
      this.roteiro = this.responderRoteiro.roteiroParecer
      this.respostas = this.responderRoteiro.respostaQuestaoParecer;

      await this.verificaSeTodasForamRespondidas()
      await this.processarAnalise()

      this.imprimirRelatorio()

    })
      .catch(erro => {
        this.router.navigateByUrl('/outras-opcoes/validacao');
        this.errorHandler.handle(erro)
      });
  }

  // async alocandoRespostasRoteiro() {
  //   this.respostas = this.responderRoteiro.respostaQuestaoParecer;

  //   await this.processarAnalise()

  //   if (this.questoesRespondidas) {
  //     this.imprimirRelatorio()
  //   } else {
  //     this.loading = true
  //   }
  // }

  imprimirRelatorio() {

    let data = {
      content: this.modificandoContent(this.roteiro),
      hash: this.paramImprimir,
      dataRespondidoExtenso: moment(this.roteiro.ultimaAtualizacao).format('LL'),
      dataImpressao: moment().format('LL'),
      linkValidacao: `${environment.validacao}/controle-interno/${this.responderRoteiro.hash}`,
      situacao: this.situacao,
      textoConclusao: this.conclusao,
      tipoControle: this.roteiro.submoduloRoteiro.submodulo,
      introducao: this.responderRoteiro.introducao,
      numeroProcesso: this.responderRoteiro.processoFisico,
      valor: (new CurrencyPipe('pt-BR')).transform(this.responderRoteiro.valor, 'BRL', 'symbol-narrow', '1.2-2'),
      titulo: this.responderRoteiro.tituloAnalise,
      entidade: this.responderRoteiro.entidadeConcedente.nome,
      usuario: this.responderRoteiro.usuario.nome,
      perfil: this.responderRoteiro.usuario.funcao,
      recomendacaoAnalise: this.responderRoteiro.recomendacaoAnalise,
      obsArray: this.obsArray
    }

    // //console.log(data)

    return this.relatorioService
      .relatorioControle(data)
      .then(retorno => {

        this.loading = true;
        this.dataPdf = environment.downloadFileUrl + "/report.pdf";
        // window.location.href = this.dataPdf

        //this.dataPdf = retorno.data
        //this.dataPdf = URL.createObjectURL(retorno.data)
        // if (retorno.report && retorno.hash === this.paramImprimir) {
        //   this.dataPdf = environment.downloadFileUrl + "/report.pdf";
        //   this.loading = true
        // } else {
        //   setTimeout(function () {
        //     this.imprimirRelatorio()
        //   }, 3000);
        // }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  modificandoContent(roteiro) {
    let secaoCount = 0
    let questaoCount = 0
    roteiro.secao.forEach(secao => {
      secaoCount++
      questaoCount = 0
      secao.secao = secaoCount + ". " + secao.secao
      secao.questaoParecer.forEach(questao => {
        questaoCount++
        questao.enunciado = secaoCount + "." + questaoCount + ". " + questao.enunciado
      })
    })
    return roteiro
  }



  situacao
  conclusao
  processarAnalise() {

    let secaoCountIndice = 0
    let questaoCountIndice = 0
    let observacoes = []
    let qtdQuestoes = 0
    let favoravel = 0
    let naoFavoravel = 0
    let qtdRespostas = 0
    let qtdParecer = 0
    let qtdObsFavoravel = 0
    let qtdObsNaoFavoravel = 0
    let reprovado = 0
    let aprovado = 0

    this.roteiro.secao.forEach(secao => {

      secaoCountIndice++
      questaoCountIndice = 0

      secao.questaoParecer.forEach(questao => {
        questaoCountIndice++
        qtdQuestoes++
        let value = secaoCountIndice + "." + questaoCountIndice


        // verifica as questoes que foram respondidas
        if (questao.resposta != null) {

          //Incrementa a quantidade de questões que contém respostas
          qtdRespostas++

          if (questao.resposta.observacao != null && questao.resposta.observacao != "") {
            // observacoes.push(value)
            //Para obter a quantidade de questões é necessário utilizar a função length ou fazer um for para buscar as observações
          }

          if (questao.resposta.respostaEnum) {
            
            qtdParecer++

            // verifica as questoes favoraveis e que nao tiveram obs
            if (questao.resposta.respostaEnum === 'Sim') {
              favoravel++
            }

            if (questao.resposta.respostaEnum === 'Nao') {

              if (questao.continuar === 'Nao') {

                observacoes.push(value)

                // this.obsArray = []

                // let obsModel = new ObsModel()
                // obsModel.questao = value
                // obsModel.embasamentoLegal = questao.embasamentoLegal
                // obsModel.informacaoesExtras = questao.informacaoesExtras
                // obsModel.recomendacao = questao.recomendacao
                // obsModel.tipoImpropriedade = questao.tipoImpropriedade
                // this.obsArray.push(obsModel)

                reprovado++

              } else {

                aprovado++

              }
            }
            naoFavoravel++
          }
        }

      });

    });

    // //console.log("QtdQuestoes: ", qtdQuestoes, "  qtdRespostas: ", qtdRespostas)
    // //console.log("QtdParecer: ", qtdParecer)
    // //console.log("Favoravel: ", favoravel)
    // //console.log("Não Favoravel: ", naoFavoravel, "OBS não Favoravel: ", qtdObsNaoFavoravel)
    // //console.log("Reprovado: ", reprovado)
    // //console.log("Aprovado: ", aprovado)

    if (qtdRespostas === favoravel) { //Verifico se as questões que estão como favorável são iguais as questões que solicitam parecer

      if (observacoes.length === 0) {
        this.situacao = "Aprovado"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento às demais etapas subsequentes."
          ;

      } else {
        this.situacao = "Aprovado com recomendação"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo está DE ACORDO com a legislação vigente e APTO para que seja dado prosseguimento " +
          "às demais etapas subsequentes, desde que, a OBSERVAÇÃO da questão: "
          + observacoes.toString() +
          " seja  realizado  o  devido ajuste."

      }

    } else {

      if (observacoes.length === 1) {
        this.situacao = "Reprovado com recomendação"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do "
          + this.roteiro.tipoRoteiro.tipo +
          ", entendo que o mesmo NÃO ESTÁ DE ACORDO com a legislação vigente e INAPTO para que seja dado prosseguimento " +
          "às demais etapas subsequentes, desde que, seja corrigido os problemas indendificado nas questões e que as  seguintes observações sejam levadas em condsideração:"
          + observacoes.toString() +
          " seja realizado o devido ajuste."

      } else {
        this.situacao = "Reprovado"
        this.conclusao =
          "Após o exame dos itens que compõem a análise do "
          + this.roteiro.tipoRoteiro.tipo +
          ",entendo que o mesmo NÃO ESTÁ DE ACORDO com a legislação vigente e INAPTO para que seja dado prosseguimento: "
          + observacoes.toString() +
          " sejam realizados os devidos ajustes."
      }
    }


  }

  verificaSeTodasForamRespondidas() {
    this.questoesRespondidas = true;
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta;
          }
        });
        if (questao.resposta == null) {
          this.questoesRespondidas = false;
        }
      });
    });
  }


}
