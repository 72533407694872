
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';
import { ConfirmationService } from 'primeng/primeng';
import { AuthService } from '../seguranca/auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { ModuloNaoDisponivelComponent } from './not-found/modulo-nao-disponivel.component';
import { EixoProgramaHasProponenteCredenciadoService } from './services/eixo-programa-has-proponente-credenciado/eixo-programa-has-proponente-credenciado.service';
import { EntidadeConcedenteHasUsuarioService } from './services/entidade-concedente-has-usuario/entidadeConcedenteHasUsuario.service';
import { EntidadeProponenteHasUsuarioService } from './services/entidade-proponente-has-usuario/entidadeProponenteHasUsuario.service';
import { HabilitacaoService } from './services/habilitacao/habilitacao.service';
import { RequisitoProponenteService } from './services/edital-credenciamento/RequisitoProponente.service';

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    RouterModule,
  ],
  declarations: [
    ModuloNaoDisponivelComponent,
  ],
  exports: [
    ModuloNaoDisponivelComponent,
  ],
  providers: [
    Title,
    AuthService,
    JwtHelper,
    { provide: LOCALE_ID, useValue: 'pt-BR' },

    //Nao possuem module
    ConfirmationService,
    EntidadeConcedenteHasUsuarioService,
    EntidadeProponenteHasUsuarioService,
    ErrorHandlerService,
    RequisitoProponenteService,
    EixoProgramaHasProponenteCredenciadoService,
    HabilitacaoService,
  ]
})
export class CoreModule {}
