
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../seguranca/auth.service";
import { TutPerfilHasTopicoFiltro, TutPerfilHasTopicoService } from '../core/services/tutoriais/tutPerfilHasTopico.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { TutConteudoFiltro, TutConteudoService } from '../core/services/tutoriais/tutConteudo.service';
import { TutTopico } from '../core/model/tutoriais.model';
import { environment } from "../../environments/environment";
import { TutPerfilHasConteudoFiltro, TutPerfilHasConteudoService } from "../core/services/tutoriais/tutPerfilHasConteudoservice";


declare let AdminLTE: any;

@Component({
    selector: "app-tutoriais",
    templateUrl: "./tutoriais.component.html"
})
export class TutoriaisComponent implements OnInit {

    topicos = []
    conteudo = []
    mostraConteudo: boolean;
    topicoSelecionado = new TutTopico
    mostraVideo: boolean;
    caminhoVideo = '';


    constructor(
        public auth: AuthService,
        private tutPerfilHasTopicoService: TutPerfilHasTopicoService,
        private tutPerfilHasConteudoService: TutPerfilHasConteudoService,
        private tutConteudoService: TutConteudoService,
        private errorHandler: ErrorHandlerService,
    ) { }

    ngOnInit() {
        // Update the AdminLTE layouts
        AdminLTE.init();
        this.buscaTopicos()
    }

    buscaTopicos() {
        let filtro = new TutPerfilHasTopicoFiltro
        filtro.perfil = this.auth.jwtPayload.perfil_id
        this.tutPerfilHasTopicoService.pesquisar(filtro)
            .then(res => {
                this.topicos = res.content
            }).catch(erro => this.errorHandler.handle(erro));
    }

    // mostrarConteudoDoTopico(item) {
    //     this.topicoSelecionado = item.tutTopico

    //     let filtro = new TutConteudoFiltro
    //     filtro.tutTopico = item.tutTopico.id

    //     this.tutConteudoService.pesquisar(filtro)
    //         .then(res => {
    //             this.conteudo = res.content
    //             this.mostraConteudo = true
    //         }).catch(erro => this.errorHandler.handle(erro));
    // }

    mostrarConteudoDoTopico(item) {
        this.topicoSelecionado = item.tutTopico
        let filtro = new TutPerfilHasConteudoFiltro
        filtro.tutTopico = item.tutTopico.id
        filtro.perfil = this.auth.jwtPayload.perfil_id

        this.tutPerfilHasConteudoService.pesquisar(filtro)
            .then(res => {
                this.conteudo = res.content.map(x => x.tutConteudo)
                this.mostraConteudo = true
            }).catch(erro => this.errorHandler.handle(erro));
    }

    voltarTopicos() {
        this.mostraConteudo = false
    }

    visualizarVideo(conteudo) {
        this.mostraVideo = true
        this.caminhoVideo = environment.downloadFileUrl + "/" + conteudo.hashAnexo
    }

    closeModalVideo() {
        this.caminhoVideo = null
    }



}