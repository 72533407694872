import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PlanoTrabalhoHasProponente } from '../../core/model';
import { VersaoPlanoFiltro, VersaoPlanoService } from '../../core/services/alteracao-plano/versaoPlano.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';

declare let AdminLTE: any;

@Component({
  selector: 'app-ajuste-plano-menu',
  templateUrl: './ajuste-plano-menu.component.html',
})
export class AjustePlanoMenuComponent implements OnInit {
  
  planoTrabalhoHasProponente =  new PlanoTrabalhoHasProponente;
  podeAdd: boolean; 

  constructor(
    private title: Title,
    private versaoPlanoService: VersaoPlanoService,
    private errorHandler: ErrorHandlerService, 
    private toasty: ToastyService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
    this.title.setTitle('Ajuste no Plano');

    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.verificaPodeCadastrar()

    VersaoPlanoService.eventoVersaoPlano.subscribe(
      () => (this.verificaPodeCadastrar())
    )

  }

  verificaPodeCadastrar(){
    this.podeAdd = false
    let i = false
    let filtro = new VersaoPlanoFiltro
    filtro.planoTrabalhoHasProponente = this.planoTrabalhoHasProponente.id

    this.versaoPlanoService.pesquisar(filtro)
      .then(async retorno => {     
        await retorno.dado.forEach(versao => {
          if(versao.status == 'Aguardando_avaliacao'){
            i = true
          }     
        })

        if(i == true){
          this.podeAdd = false
        }else{
          this.podeAdd = true

        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  setaMsgNaoPode(){
    this.toasty.warning("Aguarde até a que última solicitação seja analisada!");
  }

  


}
