import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";

@Injectable()
export class GetAdress {
  constructor(private http: Http) {}

  getEnderecoCep(cep: any): Promise<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(`https://viacep.com.br/ws/${cep}/json/`, { headers })
      .toPromise()
      .then(response => {
        return response.json();
      });
  }
}
