import { Component, OnInit } from '@angular/core';

import { SinteseProposta, AlterSinteseProposta } from '../../../core/model';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AlterSintesePropostaFiltro, AlterSintesePropostaService } from '../../../core/services/alteracao-plano/alterSinteseProposta.service';
import { AuthService } from '../../../seguranca/auth.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-alterar-sintese-proposta',
  templateUrl: './alterar-sintese-proposta.component.html',
})
export class AlterarSintesePropostaComponent implements OnInit {
   
  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  sinteseProposta = new SinteseProposta();
  atualizar: boolean;

  loading
  alterSinteseProposta = new AlterSinteseProposta()

  constructor(
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private alterSintesePropostaService: AlterSintesePropostaService,
    private auth: AuthService 
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.sinteseProposta = this.planoTrabalhoHasProponente.sinteseProposta[0];
    this.procuraAlteracaoSintese()

  }

  // ==== PROCURAR SINTESE PROPOSTA ==== //
  procuraAlteracaoSintese(){
    let filtro = new AlterSintesePropostaFiltro
    filtro.sinteseProposta = this.sinteseProposta.id
    filtro.statusAlteracao = "2" //Submetido 

    this.alterSintesePropostaService.pesquisar(filtro)
    .then(retorno => {
      if(retorno.dado[0] != null){
        this.alterSinteseProposta = retorno.dado[0] 

        this.sinteseProposta.tituloProposta = this.alterSinteseProposta.tituloPropostaDepois
        this.sinteseProposta.identificacaoObjeto = this.alterSinteseProposta.identificacaoObjetoDepois
        this.sinteseProposta.objetivoGeralProposta = this.alterSinteseProposta.objetivoGeralPropostaDepois
        this.sinteseProposta.objetivoEspecificoProposta = this.alterSinteseProposta.objetivoEspecificoPropostaDepois
        this.sinteseProposta.justificativaProposta = this.alterSinteseProposta.justificativaPropostaDepois
        this.sinteseProposta.abrangenciaProposta = this.alterSinteseProposta.abrangenciaPropostaDepois
        this.sinteseProposta.publicoBeneficiario = this.alterSinteseProposta.publicoBeneficiarioDepois
        this.sinteseProposta.perfilPublicoBeneficiario = this.alterSinteseProposta.perfilPublicoBeneficiarioDepois
        this.sinteseProposta.metaAtendimento = this.alterSinteseProposta.metaAtendimentoDepois
        this.sinteseProposta.dataIniExecucao = this.alterSinteseProposta.dataIniExecucaoDepois
        this.sinteseProposta.dataFimExecucao = this.alterSinteseProposta.dataFimExecucaoDepois
        this.sinteseProposta.metodologiaAbordagemProposta = this.alterSinteseProposta.metodologiaAbordagemPropostaDepois

        this.atualizar = true
      }
      this.loading = true
      
    })
    .catch(erro => this.errorHandler.handle(erro));
  }
 
  // ==== MODIFICAR SINTESE PROPOSTA ==== //
  modificaSinteseProposta(){

    if(this.atualizar){
      this.alterSinteseProposta.data = new Date().getTime()
      this.alterSinteseProposta.usuarioSubmeteu = this.auth.jwtPayload.id

        this.alterSinteseProposta.tituloPropostaDepois = this.sinteseProposta.tituloProposta
        this.alterSinteseProposta.identificacaoObjetoDepois = this.sinteseProposta.identificacaoObjeto
        this.alterSinteseProposta.objetivoGeralPropostaDepois = this.sinteseProposta.objetivoGeralProposta
        this.alterSinteseProposta.objetivoEspecificoPropostaDepois = this.sinteseProposta.objetivoEspecificoProposta
        this.alterSinteseProposta.justificativaPropostaDepois = this.sinteseProposta.justificativaProposta
        this.alterSinteseProposta.abrangenciaPropostaDepois = this.sinteseProposta.abrangenciaProposta
        this.alterSinteseProposta.publicoBeneficiarioDepois = this.sinteseProposta.publicoBeneficiario
        this.alterSinteseProposta.perfilPublicoBeneficiarioDepois = this.sinteseProposta.perfilPublicoBeneficiario
        this.alterSinteseProposta.metaAtendimentoDepois = this.sinteseProposta.metaAtendimento
        this.alterSinteseProposta.dataIniExecucaoDepois = this.sinteseProposta.dataIniExecucao
        this.alterSinteseProposta.dataFimExecucaoDepois = this.sinteseProposta.dataFimExecucao
        this.alterSinteseProposta.metodologiaAbordagemPropostaDepois = this.sinteseProposta.metodologiaAbordagemProposta
      
      this.atualizaAlterSinteseProposta()
    }else{

      let asp = new AlterSinteseProposta()
      asp.versaoPlano = this.versaoPlano.id
      asp.usuarioSubmeteu = this.auth.jwtPayload.id  
      asp.pthpId = this.planoTrabalhoHasProponente.id    
      asp.data = new Date().getTime()
      asp.statusAlteracao = "2" //submetido
      asp.tipoAlteracao = "0" //Alterado

      asp.sinteseProposta = this.sinteseProposta.id
      asp.tituloPropostaDepois = this.sinteseProposta.tituloProposta
      asp.identificacaoObjetoDepois = this.sinteseProposta.identificacaoObjeto
      asp.objetivoGeralPropostaDepois = this.sinteseProposta.objetivoGeralProposta
      asp.objetivoEspecificoPropostaDepois = this.sinteseProposta.objetivoEspecificoProposta
      asp.justificativaPropostaDepois = this.sinteseProposta.justificativaProposta
      asp.abrangenciaPropostaDepois = this.sinteseProposta.abrangenciaProposta
      asp.publicoBeneficiarioDepois = this.sinteseProposta.publicoBeneficiario
      asp.perfilPublicoBeneficiarioDepois = this.sinteseProposta.perfilPublicoBeneficiario
      asp.metaAtendimentoDepois = this.sinteseProposta.metaAtendimento
      asp.dataIniExecucaoDepois = this.sinteseProposta.dataIniExecucao
      asp.dataFimExecucaoDepois = this.sinteseProposta.dataFimExecucao
      asp.metodologiaAbordagemPropostaDepois = this.sinteseProposta.metodologiaAbordagemProposta
  
      this.addAlterAlterSinteseProposta(asp)
    }
  }

  addAlterAlterSinteseProposta(asp){
    this.alterSintesePropostaService.adicionar(asp)
    .then(retorno => {
      this.atualizar = true
      this.alterSinteseProposta = retorno
      this.toasty.success("Ajuste realizado com Sucesso!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterSinteseProposta(){
    this.alterSintesePropostaService.atualizar(this.alterSinteseProposta)
    .then(() => {
      this.toasty.success("Ajuste realizado com Sucesso!");
    })
    .catch(erro => this.errorHandler.handle(erro));
  }


}
