import { ComissaoSelecaoCadastroComponent } from './comissao-selecao-cadastro/comissao-selecao-cadastro.component';
import { ComissaoSelecaoPesquisaComponent } from './comissao-selecao-pesquisa/comissao-selecao-pesquisa.component';
import { ComissaoSelecaoVincularComponent } from './comissao-selecao-vincular/comissao-selecao-vincular.component';

import { SharedsModule } from '../../shareds/shareds.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';
import { PaginationModule } from '../../core/pagination/pagination.module';
import { ComissaoSelecaoService } from './comissaoSelecao.service';
import { ComissaoSelecaoHasUsuarioConcedenteService } from './comissaoSelecaoHasUsuarioConcedente.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedsModule,
    PaginationModule,
    

    DragulaModule.forRoot()
  ],
  declarations: [
    ComissaoSelecaoCadastroComponent,
    ComissaoSelecaoPesquisaComponent,
    ComissaoSelecaoVincularComponent,
    
  ],
  exports: [],
  providers:[
    ComissaoSelecaoService,
    ComissaoSelecaoHasUsuarioConcedenteService,
    
  ]
})
export class ComissaoSelecaoModule { }
