import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ReportsComponent } from '../core/reports/reports.component';
import { AuthGuard } from "../seguranca/auth.guard";
import { AnaliseRepasseComponent } from './analise/analise-repasse/analise-repasse.component';
import { AnaliseComponent } from './analise/analise.component';
import { ExportarDocumentosComponent } from './exportar-documentos/exportar-documentos.component';
import { GerenciarComponent } from './gerenciar/gerenciar.component';
import { ListarComponent } from './listar/listar.component';
import { ParcialComponent } from './parcial/parcial.component';
import { RepassesComponent } from './analise/repasses/repasses.component';
import { VisualizarExecucaoComponent } from "./visualizar/visualizar-execucao.component";

const routers: Routes = [
  { path: '', component: GerenciarComponent, canActivate: [AuthGuard], children: [
      { path: 'listar/:tipo', component: ListarComponent, canActivate: [AuthGuard] },
    ]
  },

  { path: "visualizar", component: VisualizarExecucaoComponent,  children: [

    { path: 'parcial', component: ParcialComponent, canActivate: [AuthGuard], 
      children: [
        { path: 'analise-contas', component: AnaliseComponent, canActivate: [AuthGuard],
          children: [
            { path: 'report', component: ReportsComponent, canActivate: [AuthGuard]},
            { path: 'repasses', component: RepassesComponent, canActivate: [AuthGuard]},
            { path: 'analise-repasse', component: AnaliseRepasseComponent, canActivate: [AuthGuard]},
          ]
        },
        { path: 'exportar-documentos', component: ExportarDocumentosComponent, canActivate: [AuthGuard]},
      ] 
    },


    // ==> No final coloquei as mesmas coisas para funcionar a apresentacao, faltando apenas alguns ajustes

    // { path: 'final', component: ParcialComponent, canActivate: [AuthGuard], 
    //   children: [
       
    //     { path: 'relacao-pagamento', component: RelacaoPagamentoComponent, canActivate: [AuthGuard] },
    //     { path: 'execucao-financeira', component: ExecucaoFinanceiraComponent, canActivate: [AuthGuard] },
       
    //     { path: 'analise-contas', component: AnaliseComponent, canActivate: [AuthGuard],
    //       children: [
    //         { path: 'report', component: ReportsComponent, canActivate: [AuthGuard]},
    //         { path: 'repasses', component: RepassesComponent, canActivate: [AuthGuard]},
    //         { path: 'analise-repasse', component: AnaliseRepasseComponent, canActivate: [AuthGuard],
    //           children: [
    //             { path: 'analise-execucao/:tipo', component: AnaliseExecucaoComponent, canActivate: [AuthGuard], 
    //               children: [
    //                 { path: 'listar', component: AnaliseExecucaoListarComponent, canActivate: [AuthGuard] },
    //                 { path: 'emitir', component: RoteiroResponderComponent, canActivate: [AuthGuard] },
    //                 { path: 'visualizar', component: RoteiroVisualizarComponent, canActivate: [AuthGuard] },
    //               ]
    //             },
    //           ]
    //         },
    //         { path: 'execucao-objeto-financeiro', component: PrestacaoContasComponent, canActivate: [AuthGuard]},
    //       ]
    //     },
    //     { path: 'exportar-documentos', component: ExportarDocumentosComponent, canActivate: [AuthGuard]},
    //   ] 
    // },


    // { path: 'final', component: FinalComponent, canActivate: [AuthGuard], children: [

    //   { path: 'analise-contas', component: AnaliseComponent, canActivate: [AuthGuard],
    //     // children: [
    //     //   { path: 'analise/:tipo', component: AnaliseExecucaoComponent, canActivate: [AuthGuard], children: [
    //     //       { path: 'listar', component: AnaliseExecucaoListarComponent, canActivate: [AuthGuard] },
    //     //       { path: 'emitir', component: RoteiroResponderComponent, canActivate: [AuthGuard] },
    //     //       { path: 'visualizar', component: RoteiroVisualizarComponent, canActivate: [AuthGuard] },
    //     //     ]
    //     //   }
    //     // ]
    //   },

    // ] },
    

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routers)],
  exports: [RouterModule]
})
export class PrestacaoContasRoutingModule { }
