import { MyPipesModule } from './../core/my-pipes/my-pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedsModule } from '../shareds/shareds.module';
import { TrumbowygModule } from 'ng2-lazy-trumbowyg';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { RoteiroVisualizarComponent } from './roteiro-visualizar/roteiro-visualizar.component';
import { RoteiroResponderComponent } from './roteiro-responder/roteiro-responder.component';
import { RoteiroMultiploComponent } from './roteiro-multiplo/roteiro-multiplo.component';

import { RoteiroParecerService } from '../core/services/modelos-sistema/roteiroParecer.service';
import { ResponderRoteiroService } from '../core/services/modelos-sistema/responderRoteiro.service';
import { RespostaQuestaoParecerService } from '../core/services/modelos-sistema/respostaQuestaoParecer.service';
import { RoteiroAnaliseComponent } from './roteiro-analise/roteiro-analise.component';
import { HttpClientModule } from '@angular/common/http';
import { RelatorioService } from '../core/services/relatorio/relatorio.service';
import { RoteiroOpcaoComponent } from './roteiro-opcao/roteiro-opcao.component';
import { RoteiroVisualizarRespostasComponent } from './roteiro-visualizar-respostas/roteiro-visualizar-respostas.component';
import { RoteiroImprimirComponent } from './roteiro-imprimir/roteiro-imprimir.component';
import { PaginationModule } from '../core/pagination/pagination.module';
import { PopoverModule } from 'ngx-popover';
import { UploadServiceModule } from '../core/upload-service/upload-service.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    SharedsModule,
    MyPipesModule,
    PaginationModule,
    
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,

    PopoverModule,

    HttpClientModule,
    UploadServiceModule,
    
    TrumbowygModule.forRoot({
      plugins: ["colors", "noembed", "preformatted", "pasteimage", "upload"],
      version: "2.8.0"
    })
  ],
  exports: [
    RoteiroVisualizarComponent,
  ],
  declarations: [
    RoteiroVisualizarComponent,
    RoteiroResponderComponent,
    RoteiroMultiploComponent,
    RoteiroAnaliseComponent,
    RoteiroOpcaoComponent,
    RoteiroVisualizarRespostasComponent,
    RoteiroImprimirComponent
  ],
  providers: [ 
    RoteiroParecerService,
    ResponderRoteiroService,
    RespostaQuestaoParecerService,
    RelatorioService
  ]

})
export class RoteiroModule { }
