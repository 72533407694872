import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import "rxjs/add/operator/toPromise";
import { environment } from "../../../../environments/environment";


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class DocumentosPDFService {
  _Url: string;

  constructor(
    private http: HttpClient,
  ) {
    this._Url = `${environment.merge}`;
  }

  relatorioDeDocumentosMerge(data: any): Promise<any> {
    return (
      this.http.post(`${this._Url}/documentos`, JSON.stringify(data), httpOptions)
        .toPromise()
        .then(response => {
          return response;
        })
    );
  }

}
