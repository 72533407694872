import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../../core/error-handler.service';

import { ToastyService } from 'ng2-toasty';
import { environment } from '../../../environments/environment';
import { PlanoTrabalhoHasProponente, Usuario, VersaoPlano } from '../../core/model';
import { VersaoPlanoFiltro, VersaoPlanoService } from '../../core/services/alteracao-plano/versaoPlano.service';
import { AuthService } from '../../../app/seguranca/auth.service';
import { PlanoTrabalhoHasProponenteService } from '../../../app/core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-ajuste-plano-listar',
  templateUrl: './ajuste-plano-listar.component.html',
})
export class AjustePlanoListarComponent implements OnInit {

  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente;
  listVersoesPlano = [];

  loading
  versaoPlano = new VersaoPlano();
  openModalViewPDF: boolean;
  urlDown:string;
  constructor(
    private versaoPlanoService: VersaoPlanoService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private auth: AuthService,
    private toasty: ToastyService,
    private pthpService: PlanoTrabalhoHasProponenteService

  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.buscarVersoes()

  }

  buscarVersoes() {
    let filtro = new VersaoPlanoFiltro
    filtro.planoTrabalhoHasProponente = this.planoTrabalhoHasProponente.id

    this.versaoPlanoService.pesquisar(filtro)
      .then(retorno => {
        this.listVersoesPlano = retorno.dado
        this.loading = true
      })
      .catch(erro => this.errorHandler.handle(erro));

  }
  getDescricaoStatus(status:string) {
    switch (status) {
      case "Aguardando_avaliacao":
          return "Aguardando avaliação";
      case "Em_avaliacao":
          return "Em avaliação";
      case "Avaliado":
          return "Avaliado";
      case "Aceito":
          return "Aceito";
      case "Recusado":
          return "Recusado";
      default:
          return "Aguardando avaliação";
  }
  }

  alteracaoSelecionada(versaoPlano) {
    if (versaoPlano.status == 'Aguardando_avaliacao') {
      sessionStorage.setItem('versaoPlano', JSON.stringify(versaoPlano))
      let rota = sessionStorage.getItem("rotaModuloAjuste")
      this.router.navigate([rota+'/alterar']);
    }else{
      this.toasty.warning("Essa versão não pode mais ser alterada!")
    }

  }

  avaliarAlteracao(versaoPlano){
    if (versaoPlano.status == 'Aguardando_avaliacao') {
      sessionStorage.setItem('versaoPlano', JSON.stringify(versaoPlano))
      let rota = sessionStorage.getItem("rotaModuloAjuste")
      this.router.navigate([rota+'/avaliar']);
    }else{
      this.toasty.warning("Essa versão não pode mais ser alterada!")
    }
  }

  avaliarAlteracaoOpen(versaoPlano:any){
    if(versaoPlano.status != 'Aguardando_avaliacao' ){
      this.toasty.warning("Essa versão não pode mais ser alterada!")
      return;
    }else if(this.auth.jwtPayload.proponente != null && this.auth.jwtPayload.perfil_id != 1){
      this.toasty.warning("Usuário sem permissão para avaliar ajuste do plano de trabalho!")
      return;
    }

   this.versaoPlano = versaoPlano;
   this.openModalViewPDF = true 
   this.urlDown = environment.downloadFileUrl + "/" + versaoPlano.hashFile;
  }

  mudarStatus(status:string){
    let situacao = {}
    if(status == 'Aceito'){
      this.planoTrabalhoHasProponente.situacao = "Elaborando"
      situacao = "Elaborando";
    }else{
      this.planoTrabalhoHasProponente.situacao = "Submetido"
      situacao = "Submetido";
    }
    this.pthpService.alterarSituacao(this.planoTrabalhoHasProponente.id, situacao ).then(() => {
      sessionStorage.setItem("pthpModuloAjuste", JSON.stringify(this.planoTrabalhoHasProponente));
      this.atualizarVersaoPlano(status);

    }).catch(erro => this.errorHandler.handle(erro));

  }

  atualizarVersaoPlano(status:string){
    let pthp = new PlanoTrabalhoHasProponente();
    pthp.id = this.planoTrabalhoHasProponente.id;
    let usuario = new Usuario();
    usuario.id = this.auth.jwtPayload.id;
    this.versaoPlano.status = status
    this.versaoPlano.dataAvaliacao = new Date();
    this.versaoPlano.usuarioAvaliador = usuario
    this.versaoPlano.planoTrabalhoHasProponente = pthp;
    this.versaoPlanoService.atualizar(this.versaoPlano)
    .then(() => {
      this.buscarVersoes()
      this.openModalViewPDF = false;
    })
    .catch(erro => this.errorHandler.handle(erro));
  }


}
