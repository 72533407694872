import { Usuario } from "../model";
import { SetorEntidade } from "./setorEntidade.model";

export class UsuarioHasSetorEntidade {
    id: number;
    usuario= new Usuario()
    funcao: any;
    qualificacao: string;
    setorEntidade= new SetorEntidade();    
    dataCadastro: Date;
    usuarioCadastro= new Usuario()
    excluido:boolean;
  }