import { ErrorHandlerService } from './../../../core/error-handler.service';
import { AnaliseRepasse } from './../../../core/model';
import { AnaliseRepasseFiltro, AnaliseRepasseService } from './../../../core/services/financeiro/analise-remessa.service';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { ToastyService } from 'ng2-toasty';
import { AuthService } from "../../../seguranca/auth.service";
import { environment } from '../../../../environments/environment';

@Component({
  selector: "analise-repasse-relatorio",
  templateUrl: "./analise-repasse-relatorio.component.html"
})
export class AnaliseRepasseRelatorioComponent implements OnInit {


  analiseRepasse = new AnaliseRepasse;
  repasse;
  analiseRepasseStatus;
  openModalViewUpload: boolean;
  tipoUpload: any;
  dataUploadArray = [];
  displayAnexo: boolean;
  nomeAnexo: any;
  urlDocumento: string;
  viewDownload: boolean;


  analiseRepasseFiltro = new AnaliseRepasseFiltro()

  observacaoView: boolean = false;
  observacao: any = ''

  tipoRelatorio

  arquivo
  hashArquivo

  @Input() tipoRelatorioInput;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(
    public auth: AuthService,
    private location: Location,
    private toasty: ToastyService,
    private analiseRepasseService: AnaliseRepasseService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.repasse = JSON.parse(sessionStorage.getItem("repasse"));

    this.analiseRepasseStatus = 'Aguardando análise'
  }

  ngOnChanges(changes: any): void {
    if (changes.tipoRelatorioInput) {
      this.tipoRelatorio = this.selecionarTipoAnalise(changes.tipoRelatorioInput.currentValue);
    }
    setTimeout(
      function () {
        this.observacaoView = true;
        this.pesquisar(changes.tipoRelatorioInput.currentValue);
      }.bind(this), 10
    );
  }

  selecionarTipoAnalise(tipo) {
    if (tipo === 'relatorio_visita_in_loco') return 0;
    if (tipo === 'parecer_tecnico_prestacao_contas') return 1;
    if (tipo === 'relatorio_tecnico_monitoramento_avaliacao') return 2;
    if (tipo === 'analise_financeira') return 3;
    if (tipo === 'outras_pendencias') return 4;
  }


  pesquisar(tipoRelatorio) {
    this.analiseRepasseFiltro.repasseId = this.repasse.id;
    this.analiseRepasseFiltro.nomeAnaliseRepasse = tipoRelatorio;

    this.analiseRepasseService
      .pesquisar(this.analiseRepasseFiltro)
      .then(resultado => {
        if (resultado.content.length > 0) this.mapperAnalise(resultado.content[0]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  mapperAnalise(analiseRetornada) {
    this.analiseRepasse = analiseRetornada
    this.observacao = analiseRetornada.observacao
    this.changeStatus(analiseRetornada.status)
  }


  async salvar() {
    this.analiseRepasse.repasseId = this.repasse.id;
    this.analiseRepasse.planoTrabalhoHasProponenteId = null;

    this.analiseRepasse.status = this.analiseRepasse.status ? this.analiseRepasse.status : 0 // Aguardando_analise;

    this.analiseRepasse.observacao = this.observacao;
    this.analiseRepasse.nomeAnaliseRepasse = this.tipoRelatorio;
    this.analiseRepasse.usuarioId = this.auth.jwtPayload.id;

    if (this.dataUploadArray.length > 0) {
      this.analiseRepasse.anexo = this.dataUploadArray[0].originalname;
      this.analiseRepasse.hashAnexo = this.dataUploadArray[0].filename;
    }

    this.analiseRepasse.dataCadastro = new Date().getTime();

    //this.analiseRepasse.planoTrabalhoHasProponenteId   VERIFICAR AQUI 

    await this.analiseRepasseService
      .adicionar(this.analiseRepasse)
      .then(() => {
        this.toasty.success("Análise cadastrada com sucesso!");
        this.dataUploadArray = [];
      })
      .catch(erro => this.errorHandler.handle(erro));
  }



  goBack() {
    this.location.back();
  }

  //======== UPLOAD
  openModalUpload(tipo) {
    this.openModalViewUpload = true
    this.tipoUpload = tipo
  }


  adicionarArquivo() {
    let fileItem: any = JSON.parse(sessionStorage.getItem('upload'))
    sessionStorage.removeItem('upload');
    if (!fileItem || !fileItem.responseStatus)
      return this.toasty.error("É necessário efetivar o upload");

    this.dataUploadArray = []
    this.dataUploadArray.push(fileItem.response.data);

    this.openModalViewUpload = false
  }

  removeFileProposta(item, tipo) {
    if (tipo == "convenio") {
      var index = this.dataUploadArray.indexOf(item);
      if (index > -1) {
        this.dataUploadArray.splice(index, 1);
      }
    }
  }

  openModalAnexo() {
    this.displayAnexo = true

    this.nomeAnexo = this.analiseRepasse.anexo
    this.urlDocumento = environment.downloadFileUrl + "/" + this.analiseRepasse.hashAnexo;
    this.analiseRepasse.anexo.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }


  clickEventHandler() {
    var element = document.getElementById("btn-group-open-close");
    var check = document.getElementById("btn-group-open-close").classList.contains("open");
    check ? element.classList.remove("open") : element.classList.add("open");
  }

  changeStatus(status){
    if(status === 0 || status == 'Aguardando_analise'){
      this.analiseRepasseStatus = 'Aguardando análise'
      this.analiseRepasse.status = 0
    }
    if(status === 1 || status == 'Aprovado'){
      this.analiseRepasseStatus = 'Aprovado'
      this.analiseRepasse.status = 1
    }
    if(status === 2 || status == 'Aprovado_com_ressalvas'){
      this.analiseRepasseStatus = 'Aprovado com ressalvas'
      this.analiseRepasse.status = 2
    }
    if(status === 3 || status == 'Reprovado'){
      this.analiseRepasseStatus = 'Reprovado'
      this.analiseRepasse.status = 3
    }
  }

}