import { Component, OnInit } from "@angular/core";

declare let AdminLTE: any;

@Component({
  selector: "app-usuario-view",
  templateUrl: "./entidade-view.component.html"
})
export class EntidadeViewComponent implements OnInit {

  constructor( 

  ) {}

  ngOnInit() {
    AdminLTE.init();

  }

}
