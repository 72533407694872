import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ErrorHandlerService } from '../../core/error-handler.service';
import { TutPerfilHasConteudoFiltro, TutPerfilHasConteudoService } from "../../core/services/tutoriais/tutPerfilHasConteudoservice";
import { AuthService } from "../../seguranca/auth.service";


declare let AdminLTE: any;

@Component({
    selector: "app-consulta-publica-tutoriais",
    templateUrl: "./consulta-publica-tutoriais.component.html",
    styleUrls: ["./consulta-publica-tutoriais.component.css"]
})
export class ConsultaPublicaTutoriaisComponent implements OnInit {

    conteudo = []
    mostraConteudo: boolean;
    mostraVideo: boolean;
    caminhoVideo = '';


    constructor(
        public auth: AuthService,
        private tutPerfilHasConteudoService: TutPerfilHasConteudoService,
        private errorHandler: ErrorHandlerService,
    ) { }

    ngOnInit() {
        // Update the AdminLTE layouts
        AdminLTE.init();
       this.mostrarConteudoDoTopico()
    }


    mostrarConteudoDoTopico() {

        let filtro = new TutPerfilHasConteudoFiltro;
        this.tutPerfilHasConteudoService.pesquisaPublica(filtro)
            .then(res => {
                this.conteudo = res.content.map(x => x.tutConteudo)
                this.mostraConteudo = true
            }).catch(erro => this.errorHandler.handle(erro));
    }

    voltarTopicos() {
        this.mostraConteudo = false
    }

    visualizarVideo(conteudo) {
        this.mostraVideo = true
        this.caminhoVideo = environment.downloadFileUrl + "/" + conteudo.hashAnexo
    }

    closeModalVideo() {
        this.caminhoVideo = null
    }



}