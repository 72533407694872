import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { Entidade, EntidadeConcedenteHasUsuario, Osc, Usuario } from "../../../core/model";
import { ConcedenteFiltro, ConcedenteService } from "../../../core/services/concedente/concedente.service";
import { ProponenteFiltro, ProponenteService } from "../../../core/services/proponente/proponente.service";
import { AuthService } from "../../../seguranca/auth.service";
import { UsuarioService } from "../../../usuario/usuario.service";

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-usuario-view",
  templateUrl: "./usuario-view.component.html"
})
export class UsuarioViewComponent implements OnInit {
  
  usuario = new Usuario();

  filtroConcedente = new ConcedenteFiltro();
  filtroProponente = new ProponenteFiltro();

  concedente = new Entidade();
  proponente = new Osc();

  entidade;
  tipoEntidade;

  listaEntidades: any[];

  nomeEntidadePesquisa;
  listaEntidadesTitulo;

  entidadesDoConcedente: any;
  perfis = [];
  entidadeConcedenteHasUsuario = new EntidadeConcedenteHasUsuario();

  passwordAlso;
  password;
  confirmPassword;

  constructor(
    public auth: AuthService,
    private concedenteService: ConcedenteService,
    private proponenteService: ProponenteService,
    private usuarioService: UsuarioService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title
  ) {}

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.carregarPessoa(this.auth.jwtPayload.id);
    this.title.setTitle("Visualizando usuário");

    this.selecionarEntidade();
  }

  selecionarEntidade() {
    if (this.auth.jwtPayload.concedente){
      this.getConcedente(this.auth.jwtPayload.concedente);
    } 
    if (this.auth.jwtPayload.proponente){
      this.getProponente(this.auth.jwtPayload.proponente);
    }
  }

  getConcedente(id) {
    this.tipoEntidade = 'Concedente'
    this.concedenteService
      .buscarPorCodigo(id)
      .then(retorno => {
        this.entidade = retorno;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  getProponente(id) {
    this.tipoEntidade = 'Proponente'
    this.proponenteService
      .buscarPorCodigo(id)
      .then(retorno => {
        this.entidade = retorno;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }  

  carregarPessoa(codigo: number) {
    this.usuarioService
      .buscarPorCodigo(codigo)
      .then(pessoa => {
        this.usuario = pessoa;
        // //console.log(this.usuario)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizar() {
    if (this.password === this.confirmPassword) {
      this.usuario.senha = this.password;
      this.usuario.senha_antiga = this.passwordAlso;
      this.usuarioService
        .atualizar(this.usuario)
        .then(retorno => {
          this.usuario = retorno;
          this.toasty.success("Alteração realizada com sucesso!");
          this.router.navigate(["/home"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.errorHandler.handle("Senhas não conferem.");
    }
  }

  atualizarSenha() {
    if (this.password === this.confirmPassword) {
      this.usuario.senha = this.password;
      this.usuario.senha_antiga = this.passwordAlso;
      this.usuarioService
        .atualizarSenha(this.usuario)
        .then( () => {
          // this.usuario = pessoa;
          this.toasty.success("Senha alterada com sucesso!");
          this.router.navigate(["/home"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.errorHandler.handle("Senhas não conferem.");
    }
  }

}
