import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule, ButtonModule, CalendarModule, CheckboxModule, ConfirmDialogModule, DataGridModule, DataTableModule, DialogModule, DropdownModule, EditorModule, InputMaskModule, InputSwitchModule, InputTextareaModule, InputTextModule, MultiSelectModule, OverlayPanelModule, PanelModule, PasswordModule, SelectButtonModule, SharedModule, TabViewModule, TooltipModule } from 'primeng/primeng';
import { MessageComponent } from './message/message.component';
import { ModalComponent } from './components/modal/modal.component';


@NgModule({
  imports: [
    CommonModule,

    InputMaskModule,
    DropdownModule,
    SelectButtonModule,
    InputTextareaModule,
    TooltipModule,
    DataTableModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    SharedModule,
    EditorModule,
    PanelModule,
    TabViewModule,
    DialogModule,
    OverlayPanelModule,
    DataGridModule,
    AccordionModule,
    CheckboxModule,
    InputSwitchModule,
    ConfirmDialogModule,

    PasswordModule,
    MultiSelectModule,
  ],
  declarations: [
    MessageComponent,
    ModalComponent
  ],
  exports: [

    ModalComponent,


    InputMaskModule,
    DropdownModule,
    SelectButtonModule,
    InputTextareaModule,
    TooltipModule,
    DataTableModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    SharedModule,
    EditorModule,
    PanelModule,
    TabViewModule,
    DialogModule,
    OverlayPanelModule,
    DataGridModule,
    AccordionModule,
    CheckboxModule,
    InputSwitchModule,
    ConfirmDialogModule,

    PasswordModule,
    MultiSelectModule,
  ]
})
export class SharedsModule { }