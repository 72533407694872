import { NgModule } from "@angular/core";
import { PaginationComponent } from "./pagination.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    PaginationComponent
  ],
  providers: [],
  exports:[
    PaginationComponent
  ]
})
export class PaginationModule { }
