import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { AuthService } from "../../../seguranca/auth.service";
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { Page } from "../../../core/pagination/pagination.model";
import { Celebracao, Financeiro } from "../../../core/model";
import { ResponderRoteiroFiltro, ResponderRoteiroService } from "../../../core/services/modelos-sistema/responderRoteiro.service";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/primeng";
import { ToastyService } from "ng2-toasty";

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-analise-execucao-listar",
  templateUrl: "./analise-execucao-listar.component.html"
})
export class AnaliseExecucaoListarComponent implements OnInit {
  filtro = new ResponderRoteiroFiltro();
  financeiro = new Financeiro();
  responderRoteiro = [];
  tipoParecer: string;
  //parcerias = []

  constructor(
    private title: Title,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private responderRoteiroService: ResponderRoteiroService,
    private router: Router,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Listar Pareceres");

    this.financeiro = JSON.parse(sessionStorage.getItem("financeiroParaAnalise"));

    this.pesquisar();
    this.verificaTipoParecer()
  }

  verificaTipoParecer() {
    if (sessionStorage.getItem("tipoParecer") == '3') this.tipoParecer = 'relatorio-visita-loco'
    else if (sessionStorage.getItem("tipoParecer") == '4') this.tipoParecer = 'parecer-prestacao-contas'
    else if (sessionStorage.getItem("tipoParecer") == '5') this.tipoParecer = 'relatorio-monitoramento-avaliacao'
    else if (sessionStorage.getItem("tipoParecer") == '6') this.tipoParecer = 'analise-financeira'
    else if (sessionStorage.getItem("tipoParecer") == '7') this.tipoParecer = 'outras-pendencias'

  }
  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.filtro.itensPorPagina = event.size;
    this.filtro.pagina = event.page;
    this.responderRoteiroService.pesquisar(this.filtro)
      .then(res => {
        // //console.log(res)
        //this.page = res;
        //this.responderRoteiro = res.dado;
      });
  }

  pesquisar(pagina = 0) {

    //this.filtro.et1TipoDemanda = "Parceria";
    //this.filtro.status = "Celebracao";
    //if (this.auth.jwtPayload.concedente) {
    //this.filtro.et1EntidadeConcedente = this.auth.jwtPayload.concedente;
    //}
    this.filtro.pagina = pagina;
    this.filtro.financeiro = this.financeiro.id
    this.filtro.filtroTipoCelebracao = sessionStorage.getItem("tipoParecer")

    this.responderRoteiroService.pesquisar(this.filtro)
      .then(resultado => {
        this.ordenaLista(resultado.content)
        this.page = resultado;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  async ordenaLista(listaRR){
    let i = []
    await listaRR.forEach(element => {
      if(element.situacao == 'ATIVO'){
        i[i.length] = element
      }
    });
    listaRR.forEach(element => {
      if(element.situacao == 'INATIVO'){
        i[i.length] = element
      }
    });
    this.responderRoteiro = i;
  }

  retornaTipoCelebracao(celebracao: Celebracao) {
    let retorno;
    if (celebracao.et1DispensaInexigibilidade) {
      retorno = "Dispensa/Inexigibilidade";
    } else if (celebracao.et1ChamamentoPublico) {
      retorno = "Chamamento público";
    } else if (celebracao.et1EmendaParlamentar) {
      retorno = "Emenda Parlamentar";
    } else {
      retorno = "Desconhecido";
    }
    return retorno;
  }

  celebracaoSelecionada(celebracao) {
    //Seta o plano e celabração em sessionStorage
    sessionStorage.setItem('celebracao', JSON.stringify(celebracao))
  }
  verificaStatus(status) {
    return this.responderRoteiroService.retornaStatus(status)
  }

  emitirParecer(responderRoteiro) {
    sessionStorage.setItem('emitirParecer', JSON.stringify(responderRoteiro))
    this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoParecer}/emitir`]);
  }

  confirmaAlterarParecer(rr) {
    this.confirmation.confirm({
      message: 'Ao clicar em "sim", a análise atual ficará como obsoleta e será criada uma nova análise idêntica para que possa ser alterada. Deseja continuar?',
      accept: () => {
        this.alterarParecer(rr);
      },
    });
  }
  alterarParecer(rr) {
    this.responderRoteiroService.editarResponderRoteiro(rr.id)
      .then(async resultado => {
        sessionStorage.setItem('emitirParecer', JSON.stringify(resultado))
        this.toasty.success("Altere o seu parecer!");
        await this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoParecer}`]);
        await this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoParecer}/emitir`]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  visualizarResponderRoteiro(item){
    sessionStorage.setItem('visualizarParecer', JSON.stringify(item))
    this.router.navigate([`/execucao/visualizar/prestacao-contas/analise/${this.tipoParecer}/visualizar`]);
  }
  
}
