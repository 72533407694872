import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { HabilitacaoHasProponenteService } from '../core/services/habilitacao/habilitacao-has-proponente.service';
import { HabilitacaoService } from '../core/services/habilitacao/habilitacao.service';
import { QuestaoRequisitoHabilitacaoService } from '../core/services/habilitacao/questao-requisito-habilitacao.service';
import { RequisitoHabilitacaoService } from '../core/services/habilitacao/requisito-habilitacao.service';
import { RespostaQuestaoRequisitoHabilitacaoService } from '../core/services/habilitacao/resposta-questao-requisito-habilitacao.service';
import { ProponenteService } from '../core/services/proponente/proponente.service';
import { UploadServiceModule } from '../core/upload-service/upload-service.module';
import { HabilitacaoDocComponent } from '../habilitacao-doc/habilitacao-doc.component';
import { SharedsModule } from '../shareds/shareds.module';
import { MyPipesModule } from './../core/my-pipes/my-pipes.module';
import { RelatorioService } from './../core/services/relatorio/relatorio.service';
import { ConsultaCepimService } from './../core/services/utils/consulta-cepim.service';
import { SendMail } from './../core/services/utils/send-mail.service';
import { UsuarioService } from './../usuario/usuario.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpModule,

    SharedsModule,
    MyPipesModule,
    NgxMaskModule,

    UploadServiceModule,
    HttpClientModule,
  ],
  declarations: [
    HabilitacaoDocComponent
  ],
  providers: [
    HabilitacaoService,
    RequisitoHabilitacaoService,
    QuestaoRequisitoHabilitacaoService,
    RespostaQuestaoRequisitoHabilitacaoService,
    HabilitacaoHasProponenteService,
    UsuarioService,
    ConsultaCepimService,
    ProponenteService,
    RelatorioService,
    SendMail
  ],
  exports: [
    HabilitacaoDocComponent
  ]
})
export class HabilitacaoDocModule {}
