import { OscViewComponent } from "./proponente/osc-view/osc-view.component";
import { ConcedenteViewComponent } from "./concedente/concedente-view/concedente-view.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ModuloNaoDisponivelComponent } from "./core/not-found/modulo-nao-disponivel.component";
import { HomePageComponent } from "./home/home/home-page.component";
import { UsuarioViewComponent } from "./home/info/usuario-view/usuario-view.component";
import { MapaComponent } from "./mapa/mapa.component";
import { AuthGuard } from "./seguranca/auth.guard";
import { LoginFormComponent } from "./seguranca/login-form/login-form.component";
import { WrapperComponent } from "./wrapper/wrapper.component";
import { EntidadeViewComponent } from "./home/info/entidade-view/entidade-view.component";
import { TutoriaisComponent } from "./tutoriais/tutoriais.component";
import { AssinadorDocumentosComponent } from "./assinador-documentos/assinador-documentos.component";
import { ConsultaPublicaTutoriaisComponent } from "./tutoriais/consulta-publica/consulta-publica-tutoriais.component";

const routes: Routes = [
  { path: "login", component: LoginFormComponent },
  { path: "mapa", component: MapaComponent, canActivate: [AuthGuard] },
  {
    path: "outras-opcoes",
    loadChildren: "app/outras-opcoes/outras-opcoes.module#OutrasOpcoesModule",
  },
  { path: 'matricula', loadChildren: 'app/modulo-escolar/matricula-publica/matricula-publica.module#MatriculaPublicaModule' },
  {
    path: "",
    component: WrapperComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "home", component: HomePageComponent, canActivate: [AuthGuard] },
      {
        path: "usuario-view",
        component: UsuarioViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "entidade-view",
        component: EntidadeViewComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "concedente",
            component: ConcedenteViewComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "proponente",
            component: OscViewComponent,
            canActivate: [AuthGuard],
          },
        ],
      },

      // ===== LAZY-LOADINGS ==== //
      { path: "mapa", component: MapaComponent, canActivate: [AuthGuard] },

      {
        path: "tutoriais",
        component: TutoriaisComponent,
        canActivate: [AuthGuard],
      },

      {
        path: "assinador-documentos",
        component: AssinadorDocumentosComponent,
        canActivate: [AuthGuard],
      },

      {
        path: "cadastros",
        loadChildren: "app/cadastros/cadastros.module#CadastrosModule",
      },

      {
        path: "relatorios",
        loadChildren: "app/relatorios/relatorios.module#RelatoriosModule",
      },

      {
        path: "financeiro",
        loadChildren: "app/financeiro/financeiro.module#FinanceiroModule",
      },

      {
        path: "contabilidade",
        loadChildren:
          "app/contabilidade/contabilidade.module#ContabilidadeModule",
      },

      {
        path: "editais-credenciamento",
        loadChildren:
          "app/edital-credenciamento/edital-credenciamento.module#EditalCredenciamentoModule",
      },

      {
        path: "editais-chamamento",
        loadChildren:
          "app/edital-chamamento/editalChamamento.module#EditalChamamentoModule",
      },

      {
        path: "notificacao",
        loadChildren: "app/notificacao/notificacao.module#NotificacaoModule",
      },

      {
        path: "dispensa-inexigibilidade",
        loadChildren:
          "app/dispensa-inexigibilidade/dispensaInexigibilidade.module#DispensaInexigibilidadeModule",
      },

      {
        path: "preparacao",
        loadChildren: "app/preparacao/preparacao.module#PreparacaoModule",
      },

      {
        path: "projetos",
        loadChildren: "app/projetos/projeto.module#ProjetoModule",
      },

      {
        path: "celebracao",
        loadChildren: "app/celebracao/celebracao.module#CelebracaoModule",
      },

      { path: "planos", loadChildren: "app/plano/plano.module#PlanoModule" },

      {
        path: "patrocinio",
        loadChildren: "app/patrocinio/patrocinio.module#PatrocinioModule",
      },

      {
        path: "pmis",
        loadChildren:
          "app/manifestacao-interesse/manifestacao-interesse.module#ManifestacaoInteresseModule",
      },

      {
        path: "execucao",
        loadChildren: "app/execucao/execucao.module#ExecucaoModule",
      },

      {
        path: "prestacao-contas",
        loadChildren:
          "app/prestacao-contas/prestacao-contas.module#PrestacaoContasModule",
      },

      {
        path: "habilitacao",
        loadChildren: "app/habilitacao/habilitacao.module#HabilitacaoModule",
      },

      {
        path: "termos",
        loadChildren: "app/modelo-termo/modelo-termo.module#ModeloTermoModule",
      },

      {
        path: "controle-interno",
        loadChildren:
          "app/controle-interno/controle-interno.module#ControleInternoModule",
      },

      { path: "modelo", loadChildren: "app/modelo/modelo.module#ModeloModule" },

      {
        path: "tributos",
        loadChildren: "app/tributos/tributos.module#TributosModule",
      },

      { path: "frota", loadChildren: "app/frota/frota.module#FrotaModule" },

      {
        path: "perfil-permissao",
        loadChildren:
          "app/perfil-permissao/perfil-permissao.module#PerfilPermissaoModule",
      },

      {
        path: "administracao",
        loadChildren:
          "app/administracao/administracao.module#AdministracaoModule",
      },

      {
        path: "pendencia",
        loadChildren: "app/pendencia/pendencia.module#PendenciaModule",
      },

      { path: 'projetos-concluidos', loadChildren: 'app/projetos-concluidos/projetos-concluidos.module#ProjetosConcluidosModule' },
      
      // ===== MODULO ESCOLAR ==== //

      { path: 'educacao-escolar', loadChildren: 'app/modulo-escolar/escolar.module#EscolarModule' },
    ]
  },

  {
    path: "proposta",
    loadChildren: "app/proposta/proposta.module#PropostaModule",
  },
  { path: "tutoriais-publicos", component: ConsultaPublicaTutoriaisComponent },
  { path: "modulo-nao-disponivel", component: ModuloNaoDisponivelComponent },
  { path: "**", redirectTo: "modulo-nao-disponivel" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
