import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../seguranca/auth.guard';
import { PendenciaGerenciarComponent } from './pendencia-gerenciar/pendencia-gerenciar.component';
import { PendenciaListarComponent } from './pendencia-listar/pendencia-listar.component';
import { PendenciaCadastrarComponent } from './pendencia-cadastrar/pendencia-cadastar.component';

const routers: Routes = [
  { path: '', component: PendenciaGerenciarComponent, canActivate: [AuthGuard],
    children: [
      { path: 'listar', component: PendenciaListarComponent, canActivate: [AuthGuard] },
      { path: 'cadastrar', component: PendenciaCadastrarComponent, canActivate: [AuthGuard] },
    ]},
    
];

@NgModule({
  imports: [RouterModule.forChild(routers)],
  exports: [RouterModule],
})
export class PendenciaRoutingModule {}
