import { ModalidadeProposta } from '../../model';
import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class ModalidadePropostaFiltro {
  nome;
  tipoProposta;

  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ModalidadePropostaService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/modalidade-proposta`;
  }

  pesquisar(filtro:ModalidadePropostaFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set('nome', filtro.nome);
    }

    if (filtro.tipoProposta) {
      params.set('tipoProposta', filtro.tipoProposta);
    }
   

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade:ModalidadeProposta): Promise<ModalidadeProposta> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: ModalidadeProposta): Promise<ModalidadeProposta> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as ModalidadeProposta);
  }

  buscarPorCodigo(codigo: number): Promise<ModalidadeProposta> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as ModalidadeProposta;
        return entidade;
      });
  }

}
