import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

declare let AdminLTE: any;
declare var moment: any;

@Component({
  selector: "app-mandato-informacoes",
  templateUrl: "./mandato-informacoes.component.html"
})
export class MandatoInformacoesComponent implements OnInit {

  moment = moment().format("YYYY-MM-DD");
  constructor(
    private title: Title,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Informações");

  }























}
