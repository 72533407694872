import { Component, OnInit, ɵConsole } from '@angular/core';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AlterCronogramaFisicoService, AlterCronogramaFisicoFiltro } from '../../../core/services/alteracao-plano/alterCronogramaFisico.service';
import { AuthService } from '../../..//seguranca/auth.service';
import { AlterEtapaCronogramaFiltro, AlterEtapaCronogramaService } from '../../../core/services/alteracao-plano/alterEtapaCronograma.service';
import { PlanoTrabalhoHasProponenteService } from '../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';
import { AlterCronogramaFisico, AlterEtapaCronograma } from '../../../core/model';

declare let AdminLTE: any;

@Component({
  selector: 'app-avaliar-cronograma-fisico',
  templateUrl: './avaliar-cronograma-fisico.component.html',
})
export class AvaliarCronogramaFisicoComponent implements OnInit {

  loading

  versaoPlano: any
  planoTrabalhoHasProponente: any

  atualizar: number
  statusAlteracao: number;

  listCronogramaFisicoVigente = []
  listCronogramaFisicoAlterado = [];
  
  listaAlteracoesCronograma = []
  listaAlteracoesEtapa: any = []

  alteracoesMeta: boolean;
  alteracoesEtapa: boolean;
  
  modalMetaAlterada: boolean;
  modalEtapaAlterada: boolean;
  
  avaliarAlteracaoMeta = new AlterCronogramaFisico;
  avaliarAlteracaoEtapa = new AlterEtapaCronograma;

  tituloModalMeta: string;
  tituloModalEtapa: string;
  
  metaAlterada: boolean;
  metaExcluida: boolean;
  metaAdicionada: boolean;
  etapaAlterada: boolean;
  etapaExcluida: boolean;
  etapaAdicionada: boolean;

  constructor(
    private alterCronogramaFisicoService: AlterCronogramaFisicoService,
    private alterEtapaCronogramaService: AlterEtapaCronogramaService,
    private pthpService: PlanoTrabalhoHasProponenteService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.pthpBuscar()

  }

  pthpBuscar() {
    this.pthpService.todasMetasEtapas(this.planoTrabalhoHasProponente.id)
      .then(async retorno => {
        await this.eliminaExcluidas(retorno)
        await this.setaListaVigente()
        await this.setaListaAlterado()
        await this.limparCronogramaVigente()
        await this.procuraAlteracoesCronograma()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  async eliminaExcluidas(pthp){
    await pthp.cronogramaFisicoExecucaoObjeto.forEach(meta => {
      let index = pthp.cronogramaFisicoExecucaoObjeto.indexOf(meta)
      if(meta.excluido == true && meta.tipoAlteracao == null){
        pthp.cronogramaFisicoExecucaoObjeto.splice(index, 1)
        this.eliminaExcluidas(pthp) 
      }

      meta.etapaCronogramaFisico.forEach(etapa => {
        let iEtapa = pthp.cronogramaFisicoExecucaoObjeto[index].etapaCronogramaFisico.indexOf(etapa)
        if(etapa.excluido == true && etapa.tipoAlteracao == null){
          pthp.cronogramaFisicoExecucaoObjeto[index].etapaCronogramaFisico.splice(iEtapa, 1)
          this.eliminaExcluidas(pthp) 
        }
      });

    });

    this.planoTrabalhoHasProponente = pthp
    await sessionStorage.setItem('pthpModuloAjusteMetasEtapas', JSON.stringify(pthp))
  }
  async setaListaVigente() {
    let cronograma = JSON.parse(sessionStorage.getItem("pthpModuloAjusteMetasEtapas")).cronogramaFisicoExecucaoObjeto
    //Elimina aquelas que foram excluídas na avaliação
    await cronograma.forEach(meta => {
      let index = cronograma.indexOf(meta)
      if(meta.excluido == true){
        cronograma.splice(index, 1)
      }
      meta.etapaCronogramaFisico.forEach(etapa => {
        let iEtapa = cronograma[index].etapaCronogramaFisico.indexOf(etapa)
        if(etapa.excluido == true){
          cronograma[index].etapaCronogramaFisico.splice(iEtapa, 1)
        }
      });
    });
    this.listCronogramaFisicoVigente = cronograma
    
  }
  setaListaAlterado() {
    let cronograma = JSON.parse(sessionStorage.getItem("pthpModuloAjusteMetasEtapas")).cronogramaFisicoExecucaoObjeto
    this.listCronogramaFisicoAlterado = cronograma
  }
  async limparCronogramaVigente() {
    await this.listCronogramaFisicoVigente.forEach(meta => {
      let index = this.listCronogramaFisicoVigente.indexOf(meta)
      if (meta.tipoAlteracao == 'Adicionado') {
        this.listCronogramaFisicoVigente.splice(index, 1)
        this.limparCronogramaVigente()
      }
      meta.etapaCronogramaFisico.forEach(etapa => {
        let iEtapa = this.listCronogramaFisicoVigente[index].etapaCronogramaFisico.indexOf(etapa)
        if (etapa.tipoAlteracao == 'Adicionado') {
          this.listCronogramaFisicoVigente[index].etapaCronogramaFisico.splice(iEtapa, 1)
          this.limparCronogramaVigente()
        }
      });
    });
  }

  //BUSCAR CRONOGRAMA
  procuraAlteracoesCronograma() {
    //Procura por alterações ativas
    let filtro = new AlterCronogramaFisicoFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.versaoPlano = this.versaoPlano.id
    this.alterCronogramaFisicoService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesCronograma = retorno.dado
        this.procuraAlteracoesEtapa()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  procuraAlteracoesEtapa() {
    //Procura por alterações ativas
    let filtro = new AlterEtapaCronogramaFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.versaoPlano = this.versaoPlano.id

    this.alterEtapaCronogramaService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesEtapa = retorno.dado
        this.verificaAlteracoes()

      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaAlteracoes() {
    this.loading = true

    if (this.listaAlteracoesCronograma.length == 0 && this.listaAlteracoesEtapa.length == 0) {
      this.atualizar = 1 //Sem alterações
    } else {
      this.atualizar = 2 //Todas alterações AV
      this.verificaAlteracoesForamAvaliadas()
    }

  }

  verificaAlteracoesForamAvaliadas() {

    this.listaAlteracoesCronograma.forEach(alterMeta => {
      if (alterMeta.statusAlteracao == 'Submetido') {
        this.atualizar = 0 //existe alterações sem avaliar
      }
    });

    this.listaAlteracoesEtapa.forEach(alterEtapa => {
      if (alterEtapa.statusAlteracao == 'Submetido') {
        this.atualizar = 0 //existe alterações sem avaliar
      }
    });

    if (this.listaAlteracoesCronograma.length != 0) {

      this.alteracoesMeta = true
    } else {
      this.alteracoesMeta = false
    }

    if (this.listaAlteracoesEtapa.length != 0) {
      this.alteracoesEtapa = true
    } else {
      this.alteracoesEtapa = false
    }
    this.verificaAlteracoesCronograma()

  }

  //META
  async verificaAlteracoesCronograma() {

    await this.listaAlteracoesCronograma.forEach(cronoAlter => {
      this.listCronogramaFisicoAlterado.forEach(cronoPlano => {
        if (cronoAlter.cronogramaFisicoExecucaoObjeto == cronoPlano.id) {
          var index = this.listCronogramaFisicoAlterado.indexOf(cronoPlano)
          this.listCronogramaFisicoAlterado[index].alteracao = cronoAlter

          if (cronoAlter.tipoAlteracao == "Alterado") {

            this.listCronogramaFisicoAlterado[index].meta = cronoAlter.metaDepois
            this.listCronogramaFisicoAlterado[index].descricaoMeta = cronoAlter.descricaoMetaDepois
            this.listCronogramaFisicoAlterado[index].tipoAlteracao = "Alterado"
            
          } else if (cronoAlter.tipoAlteracao == "Excluido") {
            this.listCronogramaFisicoAlterado[index].tipoAlteracao = "Excluido"
          } else if (cronoAlter.tipoAlteracao == "Adicionado") {
            this.listCronogramaFisicoAlterado[index].tipoAlteracao = "Adicionado"
          }
        }

      });

    });

    await this.verificaMetasAdicionadasExcluidas()
    await this.verificaAlteracoesEtapa()
  }
  verificaMetasAdicionadasExcluidas(){
    //Elimina as metas que foram excluídas no momento da alteração ainda
    this.listCronogramaFisicoAlterado.forEach(meta => {
      var index = this.listCronogramaFisicoAlterado.indexOf(meta)
      if(meta.tipoAlteracao == 'Adicionado' && meta.alteracao == null){
        this.listCronogramaFisicoAlterado.splice(index, 1)
      }
    });
  }

  //ETAPA
  async verificaAlteracoesEtapa() {
    await this.listaAlteracoesEtapa.forEach(async alteracao => {
      await  this.listCronogramaFisicoAlterado.forEach(async meta => {

        var iMeta = this.listCronogramaFisicoAlterado.indexOf(meta)
        await meta.etapaCronogramaFisico.forEach(etapa => {
          
          if (etapa.id == alteracao.etapaCronogramaFisico) {
            var iEtapa = this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico.indexOf(etapa)
            this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].alteracao = alteracao

            if (alteracao.tipoAlteracao == "Alterado") {
              this.setaEtapaAlterada(alteracao, iMeta, iEtapa)
            } else if (alteracao.tipoAlteracao == "Excluido") {
              this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].tipoAlteracao = "Excluido"
            } else if (alteracao.tipoAlteracao == "Adicionado") {
              this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].tipoAlteracao = "Adicionado"
              
            }

          }

        });

      });
    });

  }

  setaEtapaAlterada(alteracao, iMeta, iEtapa) {
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].especificacao = alteracao.especificacaoDepois
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].descricao = alteracao.descricaoDepois
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].dataIni = alteracao.dataIniDepois
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].dataFim = alteracao.dataFimDepois
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].latitude = alteracao.latitudeDepois
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].longitude = alteracao.longitudeDepois
    this.listCronogramaFisicoAlterado[iMeta].etapaCronogramaFisico[iEtapa].tipoAlteracao = "Alterado"
  }

  //AVALIAR META
  openModalAvaliarAlteracaoMeta(meta) {
    if(meta.tipoAlteracao == 'Alterado'){
      if(meta.alteracao.statusAlteracao == 'Submetido'){
        this.tituloModalMeta = 'Avaliar alteração na meta'
      }else{
        this.tituloModalMeta = 'Alterar avaliação da alteração na meta'
      }
      this.metaAlterada = true
      this.metaExcluida = false  
      this.metaAdicionada = false
    }else if(meta.tipoAlteracao == 'Adicionado'){
      if(meta.alteracao.statusAlteracao == 'Submetido' ){
        this.tituloModalMeta = 'Avaliar adição de meta'
      }else{
        this.tituloModalMeta = 'Alterar avaliação da adição de meta'
      }
      this.metaAlterada = false
      this.metaExcluida = false  
      this.metaAdicionada = true
    }else if(meta.tipoAlteracao == 'Excluido'){
      if(meta.alteracao.statusAlteracao == 'Submetido' ){
        this.tituloModalMeta = 'Avaliar exclusão de meta'
      }else{
        this.tituloModalMeta = 'Alterar avaliação da exclusão de meta'
      }
      this.metaAlterada = false
      this.metaExcluida = true  
      this.metaAdicionada = false
    }

    this.avaliarAlteracaoMeta = meta.alteracao
    this.statusAlteracao = null
    this.modalMetaAlterada = true
  }
  avaliarMetaAlterada() {
    this.loading = false
    if (this.statusAlteracao != null) {
      this.avaliarAlteracaoMeta.statusAlteracao = this.statusAlteracao
      this.avaliarAlteracaoMeta.usuarioAvaliou = this.auth.jwtPayload.id
      this.avaliarAlteracaoMeta.dataAvaliacao = new Date().getTime()

      this.alterCronogramaFisicoService.avaliar(this.avaliarAlteracaoMeta)
        .then(() => {
          this.modalMetaAlterada = false
          this.pthpBuscar()
          this.toasty.success("Avaliação realizada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
      this.loading = true
    }
  }

  //AVALIAR ETAPA
  openModalAvaliarAlteracaoEtapa(etapa) {
    if(etapa.tipoAlteracao == 'Alterado'){
      if(etapa.alteracao.statusAlteracao == 'Submetido'){
        this.tituloModalEtapa = 'Avaliar alteração na etapa'
      }else{
        this.tituloModalEtapa = 'Alterar avaliação da alteração na etapa'
      }
      this.etapaAlterada = true
      this.etapaExcluida = false  
      this.etapaAdicionada = false
    }else if(etapa.tipoAlteracao == 'Adicionado'){
      if(etapa.alteracao.statusAlteracao == 'Submetido' ){
        this.tituloModalEtapa = 'Avaliar adição de etapa'
      }else{
        this.tituloModalEtapa = 'Alterar avaliação da adição de etapa'
      }
      this.etapaAlterada = false
      this.etapaExcluida = false  
      this.etapaAdicionada = true
    }else if(etapa.tipoAlteracao == 'Excluido'){
      if(etapa.alteracao.statusAlteracao == 'Submetido' ){
        this.tituloModalEtapa = 'Avaliar exclusão de etapa'
      }else{
        this.tituloModalEtapa = 'Alterar avaliação da exclusão de etapa'
      }
      this.etapaAlterada = false
      this.etapaExcluida = true  
      this.etapaAdicionada = false
    }

    this.avaliarAlteracaoEtapa = etapa.alteracao
    this.statusAlteracao = null
    this.modalEtapaAlterada = true
  }
  avaliarEtapaAlterada() {
    this.loading = false
    if (this.statusAlteracao != null) {
      this.avaliarAlteracaoEtapa.statusAlteracao = this.statusAlteracao
      this.avaliarAlteracaoEtapa.usuarioAvaliou = this.auth.jwtPayload.id
      this.avaliarAlteracaoEtapa.dataAvaliacao = new Date().getTime()

      this.alterEtapaCronogramaService.avaliar(this.avaliarAlteracaoEtapa)
        .then(() => {
          this.modalEtapaAlterada = false
          this.pthpBuscar()
          this.toasty.success("Avaliação realizada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
      this.loading = true
    }
  }

  //UTILS
  aprovarAvMeta() {
    this.statusAlteracao = 0
  }
  reprovarAvMeta() {
    this.statusAlteracao = 1
  }

  retornaStatusAlteracao(obj){
    let i
    if(obj.alteracao != null){
      if(obj.alteracao.statusAlteracao == 'Submetido'){
        i = 'Submetida'
      }else if(obj.alteracao.statusAlteracao == 'Aprovado'){
        i = 'Aprovada'
      }else if(obj.alteracao.statusAlteracao == 'Reprovado'){
        i = 'Reprovada'
      }
    }else{
      i = '-'
    }
    return i
  }

  retornaTipoAlteracao(tipo){
    let i 
    if(tipo == 'Alterado'){
      i = 'Alterada'
    }else if(tipo == 'Excluido'){
      i = 'Excluída'
    }else if(tipo == 'Adicionado'){
      i = 'Adicionada'
    }else if(tipo == null){
      i = '-'
    }else{
      i = 'Indefinido'
    }
    return i
  }

}
