import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FooterComponent } from "../core/footer/footer.component";
import { HeaderComponent } from "../core/header/header.component";
import { LeftSideComponent } from "../core/left-side/left-side.component";
import { WrapperComponent } from "./wrapper.component";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [
    WrapperComponent,     
    HeaderComponent,
    LeftSideComponent,
    FooterComponent,
  ],
  exports: [
    HeaderComponent,
    LeftSideComponent,
    FooterComponent,
  ]
})
export class WrapperModule {}
