import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TrumbowygModule } from "ng2-lazy-trumbowyg";
import { NgxMaskModule } from 'ngx-mask';
import { EntidadeProponenteHasAreaDeAtuacaoService } from '../area-de-atuacao/entidadeProponenteHasAreaAtuacao.service';
import { PaginationModule } from '../core/pagination/pagination.module';
import { ProponenteService } from '../core/services/proponente/proponente.service';
import { SharedsModule } from '../shareds/shareds.module';
import { UsuarioService } from '../usuario/usuario.service';
import { ConviteOSCComponent } from "./convite-osc/convite-osc.component";
import { EmitirCertificadoComponent } from './emitir-certificado/emitir-certificado.component';
import { MandatoCadastrarComponent } from './mandato/mandato-cadastrar/mandato-cadastrar.component';
import { MandatoGerenciarComponent } from './mandato/mandato-gerenciar/mandato-gerenciar.component';
import { MandatoInformacoesComponent } from './mandato/mandato-informacoes/mandato-informacoes.component';
import { MandatoListarComponent } from './mandato/mandato-listar/mandato-listar.component';
import { MandatoVisualizarComponent } from './mandato/mandato-visualizar/mandato-visualizar.component';
import { NovaEntidadeComponent } from './nova-entidade/nova-entidade.component';
import { OscCadastroComponent } from './osc-cadastrar-editar/osc-cadastro.component';
import { OscGerenciarComponent } from './osc-gerenciar/osc-gerenciar.component';
import { OscListarComponent } from './osc-listar/osc-listar.component';
import { OscViewComponent } from './osc-view/osc-view.component';
import { OscRoutingModule } from './osc.routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    SharedsModule,
    NgxMaskModule,
    PaginationModule,

    OscRoutingModule,
    TrumbowygModule.forRoot({
      plugins: ["colors", "noembed", "preformatted", "pasteimage", "upload"],
      version: "2.8.0"
    }) //Optional config : plug-ins and version
  ],
  declarations: [
    OscGerenciarComponent,
    OscCadastroComponent,
    ConviteOSCComponent,
    EmitirCertificadoComponent,
    OscListarComponent,
    NovaEntidadeComponent,
    OscViewComponent,
    
    //Mandato
    MandatoGerenciarComponent,
    MandatoListarComponent,
    MandatoCadastrarComponent,
    MandatoVisualizarComponent,
    MandatoInformacoesComponent,
  ],
  providers: [
    UsuarioService,
    ProponenteService,
    EntidadeProponenteHasAreaDeAtuacaoService
  ],
})
export class OscModule { }
