import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadServiceComponent } from './upload-service.component';
import { NgxUploaderModule } from 'ngx-uploader';

@NgModule({
  declarations: [UploadServiceComponent],
  imports: [
    CommonModule,
    NgxUploaderModule
  ],
  exports:[UploadServiceComponent]
})
export class UploadServiceModule { }
