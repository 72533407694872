import { URLSearchParams } from '@angular/http';
import { Injectable, EventEmitter } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { VersaoPlano } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class VersaoPlanoFiltro {
  versao;
	planoTrabalhoHasProponente;
	usuarioSolicitante;
	usuarioAvaliador;
	status;
  
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class VersaoPlanoService {

  url: string;

  static eventoVersaoPlano = new EventEmitter<any>();

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/versao-plano`;
  }

  pesquisar(filtro: VersaoPlanoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.versao) {
      params.set('versao', filtro.versao);
    }
    if (filtro.usuarioSolicitante) {
      params.set('usuarioSolicitante', filtro.usuarioSolicitante);
    }
    if (filtro.planoTrabalhoHasProponente) {
      params.set('planoTrabalhoHasProponente', filtro.planoTrabalhoHasProponente);
    }
    if (filtro.usuarioAvaliador) {
      params.set('usuarioAvaliador', filtro.usuarioAvaliador);
    }
    if (filtro.status) {
      params.set('status', filtro.status);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: VersaoPlano): Promise<VersaoPlano> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: VersaoPlano): Promise<VersaoPlano> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as VersaoPlano);
  }

  buscarPorCodigo(codigo: number): Promise<VersaoPlano> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as VersaoPlano;
        return entidade;
      });
  }

}
