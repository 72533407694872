import
{ NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AssinadorDocumentosComponent } from "./assinador-documentos.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MyPipesModule } from "../core/my-pipes/my-pipes.module";
import { NgxUploaderModule } from "ngx-uploader";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {ReactiveFormsModule} from "@angular/forms";
import {RichTextEditorModule} from "@syncfusion/ej2-angular-richtexteditor";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PdfViewerModule,
        MyPipesModule,
        NgxUploaderModule,
        DropdownModule,
        InputTextModule,
        ReactiveFormsModule,
        RichTextEditorModule,
        FormsModule,
    ],
  declarations: [AssinadorDocumentosComponent],
})
export class AssinadorDocumentosModule {}
