import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from 'primeng/components/common/api';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ComissaoAcompanhamento, ComissaoAcompanhamentoHasUsuarioConcedente } from '../../../core/model';
import { UsuarioFiltro, UsuarioService } from '../../../usuario/usuario.service';
import { ComissaoAcompanhamentoService } from '../comissaoAcompanhamento.service';
import { ComissaoAcompanhamentoHasUsuarioConcedenteFiltro, ComissaoAcompanhamentoHasUsuarioConcedenteService } from '../comissaoAcompanhamentoHasUsuarioConcedente.service';
import { AuthService } from './../../../seguranca/auth.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-comissao-acompanhamento-editar',
  templateUrl: './comissao-acompanhamento-editar.component.html',
})
export class ComissaoAcompanhamentoEditarComponent implements OnInit {
  usuariosLista: any = [];
  comissaoAcompanhamento = new ComissaoAcompanhamento();
  entidades = [];
  existeEfetivo: boolean;

  cshsc = new ComissaoAcompanhamentoHasUsuarioConcedente();

  //pesquisar pessoas
  totalRegistros = 0;
  filtroUsuario = new UsuarioFiltro();
  pessoas = [];

  etapa1;
  etapa2;
  etapa3;

  viewQualificacao;
  qualificacao

  MANY_ITEMS = "MANY_ITEMS";
  many1 = [];
  many2 = [];
  many3 = [];
  many4 = [];
  many5 = []

  pt: any;
  listUsuariosVinculados: any;

  filtroUsuarioCpf = new UsuarioFiltro();

  constructor(
    private comissaoAcompanhamentoHasUsuarioConcedenteService: ComissaoAcompanhamentoHasUsuarioConcedenteService,
    private comissaoAcompanhamentoService: ComissaoAcompanhamentoService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private usuarioService: UsuarioService,
    private confirmation: ConfirmationService,
    private dragulaService: DragulaService,
    public auth: AuthService,
    private location: Location
  ) {
    this.dragAndDrop();
  }

  async ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle("Gerenciar comissao de acompanhamento");

    this.comissaoAcompanhamento = JSON.parse(sessionStorage.getItem('comissaoAcompCompPesquisa'))
    await this.carregarUsuariosConcedentes();
    this.carregarJaVinculados()
  }

  goBack() {
    this.location.back();
  }

  carregarUsuariosConcedentes() {
    return this.usuarioService
      .listarTodosConcedente(this.comissaoAcompanhamento.entidadeConcedente.id)
      .then(usuarios => {
        this.usuariosLista = usuarios
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //****JÁ VINCULADOS */
  carregarJaVinculados() {
    let filtro = new ComissaoAcompanhamentoHasUsuarioConcedenteFiltro();
    filtro.comissaoAcompanhamento = this.comissaoAcompanhamento.id;
    this.comissaoAcompanhamentoHasUsuarioConcedenteService
      .pesquisar(filtro)
      .then(resultado => {
        this.listUsuariosVinculados = resultado.retorno;

        this.verfificaVinculadosENaoVinculados();
        this.verificaExisteEfetivo(resultado.retorno);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verfificaVinculadosENaoVinculados() {

    this.many1 = this.usuariosLista
    this.many2 = []
    this.many3 = []
    this.many4 = []

    this.listUsuariosVinculados.forEach(vinculado => {

      this.usuariosLista.forEach(usuario => {
        if (usuario.id == vinculado.usuario.id) {
          let i = this.many1.indexOf(usuario)
          this.many1.splice(i, 1)
        }
      });

      if (vinculado.funcao == "Chefe") {
        this.many2.push(vinculado.usuario);
      }
      if (vinculado.funcao == "DemaisMembros") {
        this.many3.push(vinculado.usuario);
      }
      if (vinculado.funcao == "Gestor") {
        this.many4.push(vinculado.usuario);
      }

    });


    /** 
        this.usuariosLista.forEach(usuario => {
          let flag = 0
          this.listUsuariosVinculados.forEach(vinculado => {
            if (usuario.id == vinculado.usuario.id) {
              if (vinculado.funcao == "Chefe") {
                flag = 1
                this.many2.push(usuario);
              }
              if (vinculado.funcao == "Gestor") {
                flag = 1
                this.many4.push(usuario);
              }
              if (vinculado.funcao == "DemaisMembros") {
                flag = 1
                this.many3.push(usuario);
              }
            }
          });
          if (flag == 0)
            this.many1.push(usuario);
    
        });
        */
  }

  verificaExisteEfetivo(listaVinculados) {
    this.existeEfetivo = false;
    listaVinculados.forEach(item => {
      if (item.usuario.efetivo == true && item.funcao != "Gestor") {
        this.existeEfetivo = true;
      }
    });
  }

  salvar() {
    this.comissaoAcompanhamentoService
      .atualizar(this.comissaoAcompanhamento)
      .then(comissaoAcompanhamento => {
        this.comissaoAcompanhamento = comissaoAcompanhamento;
        this.toasty.success('Comissão de seleção alterada com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  finalizar() {
    this.toasty.success('Cadastro finalizado com sucesso!');
    this.router.navigate(['/cadastros/concedente/comissao-acompanhamento']);
  }


  verificaFuncao(funcao) {
    if (funcao == "Tecnico") {
      funcao = "Analista Técnico";
    } else if (funcao == "Juridico") {
      funcao = "Analista Jurídico";
    } else if (funcao == "Chefe") {
      funcao = "Chefe da Comissão";
    } else if (funcao == "DemaisMembros") {
      funcao = "Demais membros";
    } else if (funcao == "Gestor") {
      funcao = "Gestor da Parceria";
    }
    return funcao;
  }

  /*****VINCULAR USUÁRIOS */


  vincularUsuario() {

    this.viewQualificacao = false
    this.cshsc.qualificacao = this.qualificacao

    if (this.cshsc.qualificacao == null || this.cshsc.qualificacao == "") {
      return this.toasty.warning("Informe a qualificacao do usuário!");
    }
    if (this.cshsc.funcao == null) {
      return this.toasty.warning("Informe a função do usuário!");
    }

    //verifica se o usuário já foi vinculado
    let filtro = new ComissaoAcompanhamentoHasUsuarioConcedenteFiltro();
    filtro.comissaoAcompanhamento = this.comissaoAcompanhamento.id;
    filtro.usuario = this.cshsc.usuario.id;
    this.comissaoAcompanhamentoHasUsuarioConcedenteService
      .pesquisar(filtro)
      .then(resultado => {
        if (resultado.retorno.length != 0) {
          this.toasty.error('Usuário já vinculado!');
        } else {
          this.adicionaUsuarioComissao();
        }
      })
      .catch(erro => this.errorHandler.handle(erro));

  }

  removeUsuarioArray(){
    let i
    if(this.cshsc.funcao == 1){
     this.many4.forEach(item => {
        if(item.id == this.cshsc.usuario.id){
          i = this.many4.indexOf(item)
        }
      });
      this.many4.splice(i, 1)
    }else if(this.cshsc.funcao == 2){
      this.many3.forEach(item => {
        if(item.id == this.cshsc.usuario.id){
          i = this.many3.indexOf(item)
        }
      });
      this.many3.splice(i, 1)
    }else if(this.cshsc.funcao == 0){
      this.many2.forEach(item => {
        if(item.id == this.cshsc.usuario.id){
          i = this.many2.indexOf(item)
        }
      });
      this.many2.splice(i, 1)
    }    
  }

  adicionaUsuarioComissao() {
    this.cshsc.comissaoAcompanhamentoGestao = this.comissaoAcompanhamento;
    this.comissaoAcompanhamentoHasUsuarioConcedenteService
      .adicionar(this.cshsc)
      .then(retorno => {
        this.cshsc = new ComissaoAcompanhamentoHasUsuarioConcedente();
        this.toasty.success('Usuário vinculado com sucesso!');
        this.carregarJaVinculados();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  confirmaExclusao(entidade: ComissaoAcompanhamentoHasUsuarioConcedente) {
    this.confirmation.confirm({
      message: `
        <br>Tem certeza que deseja desvincular o usuário:<br>
        <b>${entidade.usuario.nome}</b><br> 
        da comissão: <br><b>${entidade.comissaoAcompanhamentoGestao.nome}</b>
      `,
      accept: () => {
        this.excluirVinculo(entidade);
      },
    });
  }

  excluirVinculo(entidade) {
    this.comissaoAcompanhamentoHasUsuarioConcedenteService
      .excluir(entidade.id)
      .then(async () => {
        this.toasty.success('Usuário desvinculado com sucesso!');
        await this.carregarUsuariosConcedentes();
        this.carregarJaVinculados();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }



  atualizarUsuarioComissao(entidade: ComissaoAcompanhamentoHasUsuarioConcedente) {

    if (entidade.qualificacao == null || entidade.qualificacao == "") {
      return this.toasty.warning("Informe a qualificacao do usuário!");
    }

    //entidade.comissaoSelecao = this.comissaoSelecao;
    this.comissaoAcompanhamentoHasUsuarioConcedenteService
      .atualizar(entidade)
      .then(retorno => {
        this.cshsc = new ComissaoAcompanhamentoHasUsuarioConcedente();
        this.toasty.success("Usuário alterado com sucesso!");
        this.carregarJaVinculados();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  subs = new Subscription();
  dragAndDrop() {
    this.subs.add(
      this.dragulaService
        .dropModel(this.MANY_ITEMS)
        .subscribe(({ el, target, source, sourceModel, targetModel, item }) => {

          this.qualificacao = null

          this.cshsc.usuario.id = item.id
          if (target.id == "many2")
            this.cshsc.funcao = 0
          if (target.id == "many3")
            this.cshsc.funcao = 2
          if (target.id == "many4")
            this.cshsc.funcao = 1

          if (source.id == "many2" || source.id == "many3" || source.id == "many4") {
            this.listUsuariosVinculados.forEach(element => {
              if (element.usuario.id == item.id) {

                if (target.id == "many2") {
                  element.funcao = 0
                  return this.atualizarUsuarioComissao(element)
                }
                if (target.id == "many3") {
                  element.funcao = 2
                  return this.atualizarUsuarioComissao(element)
                }
                if (target.id == "many4") {
                  element.funcao = 1
                  return this.atualizarUsuarioComissao(element)
                }

              }
            });
          }

          // === Verifica se está removendo dos membros da comissao
          if (target.id == "many1") {
            this.listUsuariosVinculados.forEach(element => {
              if (element.usuario.id == item.id) {
                return this.confirmaExclusao(element)
              }
            });
          }

          // === Verifica se o usuario tem qualificacao
          if (item.qualificacao == null || item.qualificacao == "") {

            if (source.id == "many2")
              return this.viewQualificacao = false;
            if (source.id == "many3")
              return this.viewQualificacao = false;
            if (source.id == "many4")
              return this.viewQualificacao = false;

            this.viewQualificacao = true;
          } else {
            this.vincularUsuario();
          }



        })
    );

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  //=== Usuário CPF ===//
  pesquisarUsuarioCpf() {
    if (this.filtroUsuarioCpf.cpf) {
      let i = this.filtroUsuarioCpf.cpf.toString().length
      if (i != 11) {
        this.toasty.info("CPF deve conter 11 caracteres!")
      } else {

        this.usuarioService.pesquisar(this.filtroUsuarioCpf)
          .then(usuario => {
            if (usuario.content.length == 0) {
              this.toasty.info("Usuário não encontrado!")
            }
            this.many5 = usuario.content
          })
          .catch(erro => this.errorHandler.handle(erro));
      }
    } else {
      this.toasty.info("Preencha o campo de pesquisa!")
    }
  }

  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
  }

}
