import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TutConteudoService } from "../core/services/tutoriais/tutConteudo.service";
import { TutPerfilHasTopicoService } from "../core/services/tutoriais/tutPerfilHasTopico.service";
import { SharedsModule } from "../shareds/shareds.module";
import { TutoriaisComponent } from "./tutoriais.component";
import { ConsultaPublicaTutoriaisComponent } from "./consulta-publica/consulta-publica-tutoriais.component";
import { TutPerfilHasConteudoService } from "../core/services/tutoriais/tutPerfilHasConteudoservice";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedsModule,
    RouterModule,
  ],
  declarations: [
    ConsultaPublicaTutoriaisComponent,
    TutoriaisComponent,
  ],
  providers: [
    TutPerfilHasTopicoService,
    TutPerfilHasConteudoService,
    TutConteudoService
  ]
})
export class TutoriaisModule {}
