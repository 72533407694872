import { AuthService } from './../../../seguranca/auth.service';
import { environment } from "../../../../environments/environment";
import { ResponseContentType, Http, RequestMethod } from "@angular/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/toPromise";

import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class RelatorioService {
  _Url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private _http: Http
  ) {
    this._Url = `${environment.report}`;
  }

  // relatorioRoteiro_(content: any): Promise<any> {
  //   let url = `${this._Url}/roteiro`;
  //   let data= {
  //     usuario: this.auth.jwtPayload.nome
  //     // content: content
  //   }
  //   //console.log(JSON.stringify(data));
  //   return this.http
  //     .post(url, data,{responseType: 'blob'})
  //     .post(url, JSON.stringify(data), {responseType: ResponseContentType.Blob})
  //     .toPromise()
  //     .then(response => {
  //      new Blob([response], {type: 'application/pdf'})

  // let file = {
  //   filename: 'filename.pdf',
  //   data: response
  // };
  //       //return response;
  //       let file = {
  //         filename: 'filename.pdf',
  //         data: response.blob()
  //       };
  //       return file;
  //     });
  // }

  relatorioRoteiro(data: any): Promise<any> {
    return (
      this.http.post(`${this._Url}/roteiro`, JSON.stringify(data), httpOptions)
        .toPromise()
        .then(response => {
          return response;
        })
    );
  }
  relatorioCertidao(data: any): Promise<any> {
    return (
      this.http.post(`${this._Url}/certidao`, JSON.stringify(data), httpOptions)
        .toPromise()
        .then(response => {
          return response;
        })
    );
  }
  relatorioChamamentoResultado(data: any): Promise<any> {
    return (
      this.http.post(`${this._Url}/chamamento-resultado`, JSON.stringify(data), httpOptions)
        .toPromise()
        .then(response => {
          return response;
        })
    );
  }
  relatorioControle(data: any): Promise<any> {
    return (
      this.http.post(`${this._Url}/controle`, JSON.stringify(data), httpOptions)
        .toPromise()
        .then(response => {
          return response;
        })
    );
  }

  // relatorioRoteiro1(content: any): Promise<any> {
  //   let url = "http://localhost:8000/login";

  //   let data = {
  //     user: this.auth.jwtPayload.nome,
  //     password: "teste"
  //   };

  //   //console.log(JSON.stringify(data));

  //   return this.http
  //     .post(url, JSON.stringify(data), httpOptions)
  //     .toPromise()
  //     .then(response => {
  //       //console.log("login efetuado com sucesso");
  //     });
  // }
}
