import { Component, OnInit } from '@angular/core';

import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from 'primeng/primeng';
import { CronogramaFisicoExecucaoObjeto, EtapaCronogramaFisico, AlterCronogramaFisico, AlterEtapaCronograma } from '../../../core/model';
import { FormControl } from '@angular/forms';
import { AlterCronogramaFisicoService, AlterCronogramaFisicoFiltro } from '../../../core/services/alteracao-plano/alterCronogramaFisico.service';
import { AuthService } from '../../..//seguranca/auth.service';
import { AlterEtapaCronogramaFiltro, AlterEtapaCronogramaService } from '../../../core/services/alteracao-plano/alterEtapaCronograma.service';
import * as moment from "moment";
import { PlanoTrabalhoHasProponenteService } from '../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-alterar-cronograma-fisico',
  templateUrl: './alterar-cronograma-fisico.component.html',
})
export class AlterarCronogramaFisicoComponent implements OnInit {

  loading

  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  listCronogramaFisico = [];
  cronogramaFisicoExecucaoObjetoMeta: any;
  cronogramaFisicoExecucaoObjetoDescricaoMeta: any;
  cronogramaFisicoExecucaoObjetoEditar = new CronogramaFisicoExecucaoObjeto();
  modalEditarCronogramaFisicoExecucaoObjeto: boolean;
  editandoCronogramaFisicoExecucaoObjeto: boolean;
  modalAdicionarCronogramaFisicoExecucaoObjeto: boolean;
  cronogramaFisicoExecucaoObjetoAdd = new CronogramaFisicoExecucaoObjeto()
  listAlteracoesAtivasMeta = [];
  listAlteracoesInativasMeta = [];
  listDropDownCronogramaFisicoExecucaoObjeto: { label: any; value: any; }[];
  etapaCronogramaFisicoAdd = new EtapaCronogramaFisico()
  modalAdicionarEtapaCronogramaFisico: boolean;
  listaAlteracoesCronograma: any;
  index: number = null;
  listaAlteracoesEtapa = [];
  etapaCronogramaFisicoEditar = new EtapaCronogramaFisico();
  etapaCronogramaFisicoEspecificacao: any;
  etapaCronogramaFisicoDataIni: any;
  etapaCronogramaFisicoDataFim: any;
  modalEditarEtapaCronogramaFisico: boolean;
  editandoEtapaCronogramaFisico: boolean;
  etapaCronogramaFisicoDescricao: any;

  constructor(
    private alterCronogramaFisicoService: AlterCronogramaFisicoService,
    private alterEtapaCronogramaService: AlterEtapaCronogramaService,
    private pthpService: PlanoTrabalhoHasProponenteService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private confirmation: ConfirmationService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));
    this.listCronogramaFisico = this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto

    this.pthpBuscar()

  }

  pthpBuscar() {
    this.pthpService.buscarPorCodigo(this.planoTrabalhoHasProponente.id)
      .then(retorno => {
        this.planoTrabalhoHasProponente = retorno
        this.listCronogramaFisico = this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto
        this.procuraAlteracoesCronograma()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //BUSCAR CRONOGRAMA
  procuraAlteracoesCronograma() {
    //Procura por alterações ativas
    let filtro = new AlterCronogramaFisicoFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.statusAlteracao = "2" //Submetido    
    this.alterCronogramaFisicoService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesCronograma = retorno.dado
        this.procuraAlteracoesEtapa()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  procuraAlteracoesEtapa() {
    //Procura por alterações ativas
    let filtro = new AlterEtapaCronogramaFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.statusAlteracao = "2" //Submetido    
    this.alterEtapaCronogramaService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesEtapa = retorno.dado
        this.verificaAlteracoesCronograma()

      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaAlteracoesCronograma() {
    this.listaAlteracoesCronograma.forEach(cronoAlter => {
      this.listCronogramaFisico.forEach(cronoPlano => {
        //Verifica cronogrmaa
        if (cronoAlter.cronogramaFisicoExecucaoObjeto == cronoPlano.id) {
          var index = this.listCronogramaFisico.indexOf(cronoPlano)

          if (cronoAlter.tipoAlteracao == "Alterado") {

            this.listCronogramaFisico[index].meta = cronoAlter.metaDepois
            this.listCronogramaFisico[index].descricaoMeta = cronoAlter.descricaoMetaDepois
            this.listCronogramaFisico[index].tipoAlteracao = "Alterado"

          } else if (cronoAlter.tipoAlteracao == "Excluido") {
            this.listCronogramaFisico.splice(index, 1)
            this.verificaAlteracoesCronograma()
          }
        }



      });

    });

    this.verificaAlteracoesEtapa()
  }

  verificaAlteracoesEtapa() {
    this.listCronogramaFisico.forEach(cronoPlano => {
      //Verifica etapa
      cronoPlano.etapaCronogramaFisico.forEach(etapa => {
        this.listaAlteracoesEtapa.forEach(alteracaoEtapa => {
          if (alteracaoEtapa.etapaCronogramaFisico == etapa.id) {

            var index = this.listCronogramaFisico.indexOf(cronoPlano)
            var i = this.listCronogramaFisico[index].etapaCronogramaFisico.indexOf(etapa)

            if (alteracaoEtapa.tipoAlteracao == "Alterado") {

              this.listCronogramaFisico[index].etapaCronogramaFisico[i].especificacao = alteracaoEtapa.especificacaoDepois
              this.listCronogramaFisico[index].etapaCronogramaFisico[i].descricao = alteracaoEtapa.descricaoDepois
              this.listCronogramaFisico[index].etapaCronogramaFisico[i].dataIni = alteracaoEtapa.dataIniDepois
              this.listCronogramaFisico[index].etapaCronogramaFisico[i].dataFim = alteracaoEtapa.dataFimDepois
              this.listCronogramaFisico[index].etapaCronogramaFisico[i].tipoAlteracao = "Alterado"

            } else if (alteracaoEtapa.tipoAlteracao == "Excluido") {
              this.listCronogramaFisico[index].etapaCronogramaFisico.splice(i, 1)
              this.verificaAlteracoesEtapa()
            }
          }
        });
      });


    });

    this.loading = true
  }

  //ALTERAR CRONOGRAMA
  openModalEditarCronogramaFisicoExecucaoObjeto(cronograma) {
    this.cronogramaFisicoExecucaoObjetoMeta = cronograma.meta;
    this.cronogramaFisicoExecucaoObjetoDescricaoMeta = cronograma.descricaoMeta;

    this.cronogramaFisicoExecucaoObjetoEditar = cronograma;
    this.modalEditarCronogramaFisicoExecucaoObjeto = true;
    this.editandoCronogramaFisicoExecucaoObjeto = true;
  }
  closeModalEditarCronogramaFisicoExecucaoObjeto() {
    if (this.editandoCronogramaFisicoExecucaoObjeto) {
      this.cronogramaFisicoExecucaoObjetoEditar.meta = this.cronogramaFisicoExecucaoObjetoMeta;
      this.cronogramaFisicoExecucaoObjetoEditar.descricaoMeta = this.cronogramaFisicoExecucaoObjetoDescricaoMeta;
    }
  }

  alterarCronogramaFisicoExecucaoObjeto() {
    let acf = new AlterCronogramaFisico();
    acf.versaoPlano = this.versaoPlano.id
    acf.usuarioSubmeteu = this.auth.jwtPayload.id
    acf.cronogramaFisicoExecucaoObjeto = this.cronogramaFisicoExecucaoObjetoEditar.id
    acf.pthpId = this.planoTrabalhoHasProponente.id
    acf.data = new Date().getTime()
    acf.statusAlteracao = "2" //submetido
    acf.tipoAlteracao = "0" //Alterado

    acf.metaDepois = this.cronogramaFisicoExecucaoObjetoEditar.meta
    acf.descricaoMetaDepois = this.cronogramaFisicoExecucaoObjetoEditar.descricaoMeta

    if (this.cronogramaFisicoExecucaoObjetoEditar.tipoAlteracao == "Alterado") {
      //atualizar
      this.listaAlteracoesCronograma.forEach(cron => {
        if (cron.cronogramaFisicoExecucaoObjeto == this.cronogramaFisicoExecucaoObjetoEditar.id) {
          acf.id = cron.id
          acf.metaAntes = cron.metaAntes
          acf.descricaoMetaAntes = cron.descricaoMetaAntes
        }
      });

      this.atualizaAlterCrononamaFisico(acf)

    } else if (this.cronogramaFisicoExecucaoObjetoEditar.tipoAlteracao == "Adicionado") {
      this.listaAlteracoesCronograma.forEach(cron => {
        if (cron.cronogramaFisicoExecucaoObjeto == this.cronogramaFisicoExecucaoObjetoEditar.id) {
          acf.id = cron.id
          acf.metaAntes = cron.metaAntes
          acf.descricaoMetaAntes = cron.descricaoMetaAntes
        }
      });

      acf.tipoAlteracao = "1" //Adicionado
      this.atualizaAlterCrononamaFisicoAtualizaCron(acf)

    } else {
      //adicionar
      acf.metaAntes = this.cronogramaFisicoExecucaoObjetoMeta
      acf.descricaoMetaAntes = this.cronogramaFisicoExecucaoObjetoDescricaoMeta
      this.adicionaAlterCrononamaFisico(acf)
    }

  }
  setaCronograma(cronoRetorno) {
    this.listCronogramaFisico.forEach(cron => {
      if (cron.id == cronoRetorno.cronogramaFisicoExecucaoObjeto) {
        var index = this.listCronogramaFisico.indexOf(cron)
        this.listCronogramaFisico[index].meta = cronoRetorno.metaDepois
        this.listCronogramaFisico[index].descricaoMeta = cronoRetorno.descricaoMetaDepois
        this.listCronogramaFisico[index].tipoAlteracao = "Alterado"
      }
    });
    this.editandoCronogramaFisicoExecucaoObjeto = false;
    this.modalEditarCronogramaFisicoExecucaoObjeto = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }

  //ADICIONAR CRONOGRAMA
  openModalAddCronogramaFisicoExecucaoObjeto(form: FormControl) {
    form.reset();
    this.modalAdicionarCronogramaFisicoExecucaoObjeto = true;
  }
  adicionarCronogramaFisicoExecucaoObjeto() {
    let acf = new AlterCronogramaFisico();
    acf.versaoPlano = this.versaoPlano.id
    acf.usuarioSubmeteu = this.auth.jwtPayload.id
    acf.pthpId = this.planoTrabalhoHasProponente.id
    acf.data = new Date().getTime()
    acf.statusAlteracao = "2" //submetido
    acf.tipoAlteracao = "1" //Adicionado
    acf.metaDepois = this.cronogramaFisicoExecucaoObjetoAdd.meta
    acf.descricaoMetaDepois = this.cronogramaFisicoExecucaoObjetoAdd.descricaoMeta

    this.alterCronogramaFisicoService.addCronogramaAddAlter(acf)
      .then(retorno => {
        let meta = this.setaAtributosCronograma(retorno)

        let i = this.listCronogramaFisico.length;

        this.listCronogramaFisico[i] = meta;
        this.modalAdicionarCronogramaFisicoExecucaoObjeto = false;
        this.toasty.success("Meta salva com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  setaAtributosCronograma(obj: AlterCronogramaFisico) {
    let cron = new CronogramaFisicoExecucaoObjeto()

    cron.id = obj.cronogramaFisicoExecucaoObjeto
    cron.meta = obj.metaDepois
    cron.descricaoMeta = obj.descricaoMetaDepois
    cron.latitude = obj.latitudeDepois
    cron.longitude = obj.longitudeDepois
    cron.tipoAlteracao = obj.tipoAlteracao

    let i = this.listaAlteracoesCronograma.length
    this.listaAlteracoesCronograma[i] = obj

    return cron;
  }

  //EXCLUIR CRONOGRAMA
  confirmarExclusaoCronogramaFisicoExecucaoObjeto(cronograma) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluirCronograma(cronograma);
      }
    });
  }
  excluirCronograma(cronograma) {
    let acf = new AlterCronogramaFisico();
    acf.versaoPlano = this.versaoPlano.id
    acf.usuarioSubmeteu = this.auth.jwtPayload.id
    acf.cronogramaFisicoExecucaoObjeto = cronograma.id
    acf.pthpId = this.planoTrabalhoHasProponente.id
    acf.data = new Date().getTime()
    acf.statusAlteracao = "2" //submetido
    acf.tipoAlteracao = "2" //Excluido

    if (cronograma.tipoAlteracao == "Alterado") {
      //é de uma versao
      this.listaAlteracoesCronograma.forEach(alterCrono => {
        if (alterCrono.cronogramaFisicoExecucaoObjeto == cronograma.id) {
          acf.metaAntes = alterCrono.metaAntes
          acf.descricaoMetaAntes = alterCrono.descricaoMetaAntes
          acf.id = alterCrono.id
        }
      });
      this.atualizaAlterCrononamaFisico(acf)
    } else if (cronograma.tipoAlteracao == "Adicionado") {
      this.listaAlteracoesCronograma.forEach(alterCrono => {
        if (alterCrono.cronogramaFisicoExecucaoObjeto == cronograma.id) {
          acf.metaAntes = alterCrono.metaAntes
          acf.descricaoMetaAntes = alterCrono.descricaoMetaAntes
          acf.id = alterCrono.id
        }
      });
      this.delAlterDelCronogramaDelEtapa(acf)
    } else {
      //é original do plano 
      acf.metaAntes = cronograma.meta
      acf.descricaoMetaAntes = cronograma.descricaoMeta
      this.adicionaAlterCrononamaFisico(acf)

    }
  }
  delAlterDelCronogramaDelEtapa(acf) {
    this.alterCronogramaFisicoService.excluir(acf.id)
      .then(() => {
        this.removeCronogramaArrayPrincipal(acf)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  removeCronogramaArrayPrincipal(alterCrono) {

    this.listCronogramaFisico.forEach(cron => {
      if (cron.id == alterCrono.cronogramaFisicoExecucaoObjeto) {
        this.index = this.listCronogramaFisico.indexOf(cron)
      }
    });
    this.listCronogramaFisico.splice(this.index, 1)
    this.toasty.success("Meta excluída com sucesso!");
  }

  //UTILS CRONOGRAMA 
  adicionaAlterCrononamaFisico(acf) {
    this.alterCronogramaFisicoService.adicionar(acf)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeCronogramaArrayPrincipal(retorno)
        } else {
          var index = this.listaAlteracoesCronograma.length
          this.listaAlteracoesCronograma[index] = retorno

          this.setaCronograma(retorno)

        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterCrononamaFisico(acf) {
    this.alterCronogramaFisicoService.atualizar(acf)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeCronogramaArrayPrincipal(retorno)
        } else {
          this.editandoCronogramaFisicoExecucaoObjeto = false;
          this.modalEditarCronogramaFisicoExecucaoObjeto = false;
          this.toasty.success("Alteração realizada com sucesso!");
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterCrononamaFisicoAtualizaCron(acf) {
    this.alterCronogramaFisicoService.atualizaAlterAtualCrono(acf)
      .then(() => {
        this.editandoCronogramaFisicoExecucaoObjeto = false;
        this.modalEditarCronogramaFisicoExecucaoObjeto = false;
        this.toasty.success("Alteração realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  // ==== ADD ETAPA  === //
  openModalAddEtapaCronogramaFisico(form: FormControl, meta = null) {
    form.reset();
    this.listDropDownCronogramaFisicoExecucaoObjeto = this.listCronogramaFisico.map(
      c => ({ label: c.meta, value: c.id })
    );
    if (meta) {
      this.etapaCronogramaFisicoAdd.cronogramaFisicoExecucaoObjeto.id = meta.id;
    }

    this.modalAdicionarEtapaCronogramaFisico = true;
  }
  adicionarEtapaCronogramaFisico() {

    this.etapaCronogramaFisicoAdd.dataFim = new Date(this.etapaCronogramaFisicoAdd.dataFim).getTime()
    this.etapaCronogramaFisicoAdd.dataIni = new Date(this.etapaCronogramaFisicoAdd.dataIni).getTime()

    let aec = new AlterEtapaCronograma();
    aec.versaoPlano = this.versaoPlano.id
    aec.usuarioSubmeteu = this.auth.jwtPayload.id
    aec.pthpId = this.planoTrabalhoHasProponente.id
    aec.data = new Date().getTime()
    aec.statusAlteracao = "2" //submetido
    aec.tipoAlteracao = "1" //Adicionado
    aec.dataIniDepois = this.etapaCronogramaFisicoAdd.dataIni
    aec.dataFimDepois = this.etapaCronogramaFisicoAdd.dataFim
    aec.descricaoDepois = this.etapaCronogramaFisicoAdd.descricao
    aec.especificacaoDepois = this.etapaCronogramaFisicoAdd.especificacao
    aec.cronogramaFisicoExecucaoObjeto = this.etapaCronogramaFisicoAdd.cronogramaFisicoExecucaoObjeto.id

    this.alterEtapaCronogramaService.addEtapaAddAlter(aec)
      .then(retorno => {
        let etapa = this.setaAtributosEtapa(retorno)

        this.listCronogramaFisico.forEach(crono => {
          if (crono.id == retorno.cronogramaFisicoExecucaoObjeto) {
            let i = crono.etapaCronogramaFisico.length
            let index = this.listCronogramaFisico.indexOf(crono)
            this.listCronogramaFisico[index].etapaCronogramaFisico[i] = etapa
          }
        });

        this.modalAdicionarEtapaCronogramaFisico = false;
        this.toasty.success("Etapa salva com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));

  }
  setaAtributosEtapa(obj: AlterEtapaCronograma) {
    let etapa = new EtapaCronogramaFisico()

    etapa.cronogramaFisicoExecucaoObjeto.id = obj.cronogramaFisicoExecucaoObjeto
    etapa.id = obj.etapaCronogramaFisico
    etapa.especificacao = obj.especificacaoDepois
    etapa.descricao = obj.descricaoDepois
    etapa.dataIni = obj.dataIniDepois
    etapa.dataFim = obj.dataFimDepois

    etapa.latitude = obj.latitudeDepois
    etapa.longitude = obj.longitudeDepois
    etapa.tipoAlteracao = obj.tipoAlteracao

    let i = this.listaAlteracoesEtapa.length
    this.listaAlteracoesEtapa[i] = obj

    return etapa;
  }

  // ALTERAR ETAPA
  openModalEditarEtapaCronogramaFisico(etapa, idCronograma) {
    etapa.dataIni = moment(etapa.dataIni).format("YYYY-MM-DDTHH:mm");
    etapa.dataFim = moment(etapa.dataFim).format("YYYY-MM-DDTHH:mm");

    this.etapaCronogramaFisicoEditar = etapa;

    this.listDropDownCronogramaFisicoExecucaoObjeto = this.listCronogramaFisico.map(
      c => ({ label: c.meta, value: c.id })
    );

    let cfeo = new CronogramaFisicoExecucaoObjeto();
    cfeo.id = idCronograma;
    this.etapaCronogramaFisicoEditar.cronogramaFisicoExecucaoObjeto = cfeo;

    this.etapaCronogramaFisicoEspecificacao = etapa.especificacao;
    this.etapaCronogramaFisicoDescricao = etapa.descricao
    this.etapaCronogramaFisicoDataIni = etapa.dataIni;
    this.etapaCronogramaFisicoDataFim = etapa.dataFim;

    this.modalEditarEtapaCronogramaFisico = true;
    this.editandoEtapaCronogramaFisico = true;
  }
  closeModalEditarEtapaCronogramaFisico() {
    if (this.editandoEtapaCronogramaFisico) {
      this.etapaCronogramaFisicoEditar.especificacao = this.etapaCronogramaFisicoEspecificacao;
      this.etapaCronogramaFisicoEditar.descricao = this.etapaCronogramaFisicoDescricao
      this.etapaCronogramaFisicoEditar.dataIni = this.etapaCronogramaFisicoDataIni;
      this.etapaCronogramaFisicoEditar.dataFim = this.etapaCronogramaFisicoDataFim;
    }
  }

  alterarEtapaCronogramaFisico() {
    let aec = new AlterEtapaCronograma();
    aec.versaoPlano = this.versaoPlano.id
    aec.usuarioSubmeteu = this.auth.jwtPayload.id
    aec.etapaCronogramaFisico = this.etapaCronogramaFisicoEditar.id
    aec.cronogramaFisicoExecucaoObjeto = this.etapaCronogramaFisicoEditar.cronogramaFisicoExecucaoObjeto.id
    aec.pthpId = this.planoTrabalhoHasProponente.id
    aec.data = new Date().getTime()
    aec.statusAlteracao = "2" //submetido
    aec.tipoAlteracao = "0" //Alterado

    aec.especificacaoDepois = this.etapaCronogramaFisicoEditar.especificacao
    aec.descricaoDepois = this.etapaCronogramaFisicoEditar.descricao
    aec.dataIniDepois = new Date(this.etapaCronogramaFisicoEditar.dataIni).getTime()
    aec.dataFimDepois = new Date(this.etapaCronogramaFisicoEditar.dataFim).getTime()

    if (this.etapaCronogramaFisicoEditar.tipoAlteracao == "Alterado") {
      //atualizar
      this.listaAlteracoesEtapa.forEach(alterEtapa => {
        if (alterEtapa.etapaCronogramaFisico == this.etapaCronogramaFisicoEditar.id) {
          aec.id = alterEtapa.id
          aec.especificacaoAntes = alterEtapa.especificacaoAntes
          aec.descricaoAntes = alterEtapa.descricaoAntes
          aec.dataIniAntes = alterEtapa.dataIniAntes
          aec.dataFimAntes = alterEtapa.dataFimAntes
        }
      });

      this.atualizaAlterEtapaFisico(aec)

    } else if (this.etapaCronogramaFisicoEditar.tipoAlteracao == "Adicionado") {
      this.listaAlteracoesEtapa.forEach(alterEtapa => {
        if (alterEtapa.etapaCronogramaFisico == this.etapaCronogramaFisicoEditar.id) {
          aec.id = alterEtapa.id
          aec.especificacaoAntes = alterEtapa.especificacaoAntes
          aec.descricaoAntes = alterEtapa.descricaoAntes
          aec.dataIniAntes = alterEtapa.dataIniAntes
          aec.dataFimAntes = alterEtapa.dataFimAntes
        }
      });

      aec.tipoAlteracao = "1" //Adicionado
      this.atualizaAlterEtapaCrononamaAtualizaCron(aec)

    } else {
      //adicionar
      aec.especificacaoAntes = this.etapaCronogramaFisicoEspecificacao
      aec.descricaoAntes = this.etapaCronogramaFisicoDescricao
      aec.dataIniAntes = new Date(this.etapaCronogramaFisicoDataIni).getTime()  
      aec.dataFimAntes = new Date(this.etapaCronogramaFisicoDataFim).getTime()
      this.adicionaAlterEtapaCrononama(aec)
    }

  }

  //EXCLUIR ETAPA
  confirmarExclusaoEtapaCronogramaFisico(etapa, meta) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir a etapa?",
      accept: () => {
        this.excluirEtapa(etapa, meta);
      }
    });
  }
  async excluirEtapa(etapa, meta) {
    let aec = new AlterEtapaCronograma();
    aec.versaoPlano = this.versaoPlano.id
    aec.usuarioSubmeteu = this.auth.jwtPayload.id
    aec.etapaCronogramaFisico = etapa.id
    aec.cronogramaFisicoExecucaoObjeto = meta.id
    aec.pthpId = this.planoTrabalhoHasProponente.id
    aec.data = new Date().getTime()
    aec.statusAlteracao = "2" //submetido
    aec.tipoAlteracao = "2" //Excluido

    if (etapa.tipoAlteracao == "Alterado") {
      //é de uma versao
      await this.listaAlteracoesEtapa.forEach(alterEtapa => {
        if (alterEtapa.etapaCronogramaFisico == etapa.id) {
          aec.id = alterEtapa.id
          aec.especificacaoAntes = alterEtapa.especificacaoAntes 
          aec.descricaoAntes = alterEtapa.descricaoAntes
          aec.dataIniAntes = alterEtapa.dataIniAntes
          aec.dataFimAntes = alterEtapa.dataFimAntes
        }
      });

      this.atualizaAlterEtapaFisico(aec)
    } else if (etapa.tipoAlteracao == "Adicionado") {
      await this.listaAlteracoesEtapa.forEach(alterEtapa => {
        if (alterEtapa.etapaCronogramaFisico == etapa.id) {
          aec.id = alterEtapa.id
          aec.especificacaoAntes = alterEtapa.especificacaoAntes 
          aec.descricaoAntes = alterEtapa.descricaoAntes
          aec.dataIniAntes = alterEtapa.dataIniAntes
          aec.dataFimAntes = alterEtapa.dataFimAntes
        }
      });
      this.delAlterDelEtapa(aec)
    } else {
      //é original do plano      
      aec.especificacaoAntes = etapa.especificacao 
      aec.descricaoAntes = etapa.descricao
      aec.dataIniAntes = etapa.dataIni
      aec.dataFimAntes = etapa.dataFim
      this.adicionaAlterEtapaCrononama(aec)

    }
  }
  delAlterDelEtapa(aec) {
    this.alterEtapaCronogramaService.excluir(aec.id)
      .then(() => {
        this.removeEtapaArrayPrincipal(aec)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  removeEtapaArrayPrincipal(alterEtapa) {

    this.listCronogramaFisico.forEach(cron => {
      if (cron.id == alterEtapa.cronogramaFisicoExecucaoObjeto) {
        var index = this.listCronogramaFisico.indexOf(cron)

        this.listCronogramaFisico[index].etapaCronogramaFisico.forEach(etapa => {
          if (etapa.id == alterEtapa.etapaCronogramaFisico) {

            var i = this.listCronogramaFisico[index].etapaCronogramaFisico.indexOf(etapa)
            this.listCronogramaFisico[index].etapaCronogramaFisico.splice(i, 1)
            this.toasty.success("Etapa excluída com sucesso!");
          }
        });
      }
    });

  }

  //UTILS ETAPA 
  adicionaAlterEtapaCrononama(aec: AlterEtapaCronograma) {
    this.alterEtapaCronogramaService.adicionar(aec)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeEtapaArrayPrincipal(retorno)
        } else {
          var i = this.listaAlteracoesEtapa.length
          this.listaAlteracoesEtapa[i] = retorno
          this.setaEtapaCronograma(retorno)

        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterEtapaFisico(aec) {
    this.alterEtapaCronogramaService.atualizar(aec)
      .then(retorno => {
        if (retorno.tipoAlteracao == "Excluido") {
          this.removeEtapaArrayPrincipal(retorno)
        } else {
          this.editandoEtapaCronogramaFisico = false;
          this.modalEditarEtapaCronogramaFisico = false;
          this.toasty.success("Alteração realizada com sucesso!");
        }

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  atualizaAlterEtapaCrononamaAtualizaCron(aec) {
    this.alterEtapaCronogramaService.atualizaAlterAtualEtapa(aec)
      .then(() => {
        this.editandoEtapaCronogramaFisico = false;
        this.modalEditarEtapaCronogramaFisico = false;
        this.toasty.success("Alteração realizada com sucesso!");
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  setaEtapaCronograma(aec: AlterEtapaCronograma) {
    this.listCronogramaFisico.forEach(cron => {
      if (cron.id == aec.cronogramaFisicoExecucaoObjeto) {
        var index = this.listCronogramaFisico.indexOf(cron)

        cron.etapaCronogramaFisico.forEach(etapa => {
          if (etapa.id == aec.etapaCronogramaFisico) {
            var i = this.listCronogramaFisico[index].etapaCronogramaFisico.indexOf(etapa)

            this.listCronogramaFisico[index].etapaCronogramaFisico[i].especificacao = aec.especificacaoDepois
            this.listCronogramaFisico[index].etapaCronogramaFisico[i].descricao = aec.descricaoDepois
            this.listCronogramaFisico[index].etapaCronogramaFisico[i].dataIni = aec.dataIniDepois
            this.listCronogramaFisico[index].etapaCronogramaFisico[i].dataFim = aec.dataFimDepois
            this.listCronogramaFisico[index].etapaCronogramaFisico[i].tipoAlteracao = "Alterado"

          }
        });

      }
    });
    this.editandoEtapaCronogramaFisico = false;
    this.modalEditarEtapaCronogramaFisico = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }

}
