import { Entidade, Usuario } from "../model";

export class SetorEntidade {
    id: number;
    nome: string;
    sigla: string;
    email: string;
    entidadeConcedente = new Entidade();
    entidadeProponente = new Entidade();
    habilitado:boolean;
    dataCadastro: Date;
    usuarioCadastro= new Usuario()
    excluido:boolean;
  }