import { Perfil } from "../model";

export class TutTopico {
    id;
    titulo;
    descricao;
}

export class TutConteudo {
    id;
    descricao;
    anexo;
    hashAnexo;
    tamanhoAnexo;
    tutTopico = new TutTopico();
}

export class TutPerfilHasTopico {
    id;
    tutTopico = new TutTopico();
    perfil = new Perfil();
}

export class TutPerfilHasConteudo {
    id;
    tutConteudo = new TutConteudo();
    perfil = new Perfil();
}