import { URLSearchParams } from "@angular/http";
import { Injectable, EventEmitter } from "@angular/core";

import { AuthHttp } from "angular2-jwt";
import "rxjs/add/operator/toPromise";

import { PlanoTrabalhoHasProponente, AvaliarPlanoTrabalho, AnaliseRecurso, ModelAvPlanoTrabalho } from "../../model";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../seguranca/auth.service";

export class PlanoTrabalhoHasProponenteFiltro {
  entidadeProponente;
  planoTrabalho;

  categoria;
  planoTrabalhoHasProponente;
  celetista;
  horista;
  tipo;

  situacao;
  status;
  pagina = 0;
  itensPorPagina = 20;
}

export class IniFimProjeto{
  ini 
  fim
}

@Injectable()
export class PlanoTrabalhoHasProponenteService {
  url: string;

  static alteradoPlano = new EventEmitter<void>();

  static avaliacaoExecutada = new EventEmitter<any>();

  static analiseRecursoExecutada = new EventEmitter<any>();

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/plano-trabalho-has-proponente`;
  }

  pesquisar(filtro: PlanoTrabalhoHasProponenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }
    if (filtro.planoTrabalho) {
      params.set("planoTrabalho", filtro.planoTrabalho);
    }
    if (filtro.situacao) {
      params.set("situacao", filtro.situacao);
    }
    if (filtro.status) {
      params.set("status", filtro.status);
    }
    if (filtro.tipo) {
      params.set("tipo", filtro.tipo);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  buscarPlanosComRecurso(filtro: PlanoTrabalhoHasProponenteFiltro, idUsuario): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }
    if (filtro.planoTrabalho) {
      params.set("planoTrabalho", filtro.planoTrabalho);
    }
    if (filtro.situacao) {
      params.set("situacao", filtro.situacao);
    }
    if (filtro.status) {
      params.set("status", filtro.status);
    }
    if (filtro.tipo) {
      params.set("tipo", filtro.tipo);
    }

    return this.http
      .get(`${this.url}/planos-com-recurso/${idUsuario}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  planosParaResultadoFinal(filtro: PlanoTrabalhoHasProponenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }
    if (filtro.planoTrabalho) {
      params.set("planoTrabalho", filtro.planoTrabalho);
    }
    if (filtro.situacao) {
      params.set("situacao", filtro.situacao);
    }
    if (filtro.status) {
      params.set("status", filtro.status);
    }
    if (filtro.tipo) {
      params.set("tipo", filtro.tipo);
    }

    return this.http
      .get(`${this.url}/planos-para-resultados-finais`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  pesquisarPorTipo(filtro: PlanoTrabalhoHasProponenteFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }
    if (filtro.planoTrabalho) {
      params.set("planoTrabalho", filtro.planoTrabalho);
    }
    if (filtro.situacao) {
      params.set("situacao", filtro.situacao);
    }
    if (filtro.status) {
      params.set("status", filtro.status);
    }
    if (filtro.tipo) {
      params.set("tipo", filtro.tipo);
    }

    return this.http
      .get(`${this.url}/tipo`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    return this.http
      .get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(
    entidade: PlanoTrabalhoHasProponente
  ): Promise<PlanoTrabalhoHasProponente> {
    return this.http
      .post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(
    entidade: PlanoTrabalhoHasProponente
  ): Promise<PlanoTrabalhoHasProponente> {
    return this.http
      .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => { 
        PlanoTrabalhoHasProponenteService.alteradoPlano.emit()
        return response.json() as PlanoTrabalhoHasProponente
      });
  }

  buscarPorCodigo(codigo: number): Promise<PlanoTrabalhoHasProponente> {
    if(codigo){
      return this.http
        .get(`${this.url}/${codigo}`)
        .toPromise()
        .then(response => {
          const entidade = response.json() as PlanoTrabalhoHasProponente;
          return entidade;
        });
      }
  }

  buscarPorCodigoProjeto(codigo: number): Promise<PlanoTrabalhoHasProponente> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        return response.json();
      });
  }

  todasMetasEtapas(codigo: number): Promise<PlanoTrabalhoHasProponente> {
    return this.http
      .get(`${this.url}/metas-etapas/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as PlanoTrabalhoHasProponente;
        return entidade;
      });
  }

  todosDetalhamentos(codigo: number): Promise<PlanoTrabalhoHasProponente> {
    return this.http
      .get(`${this.url}/detalhamento/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as PlanoTrabalhoHasProponente;
        return entidade;
      });
  }

  avaliarPlanoTrabalho(mapt: ModelAvPlanoTrabalho): Promise<void> {
    return this.http.put(`${this.url}/avalia-plano`, mapt)
      .toPromise()
      .then(() => {
        PlanoTrabalhoHasProponenteService.avaliacaoExecutada.emit(true)
      });
  }


  finalizarAvaliacao(codigo: number, nota): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/fim-avaliacao`, nota)
      .toPromise()
      .then(() => null);
  }

  alterarAvaliacao(codigo: number): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/altera-avaliacao`, 'd')
      .toPromise()
      .then(() => null);
  }

  alterarSituacao(codigo: number, situacao:any): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/altera-situacao?situacao=${situacao}`, 'd')
      .toPromise()
      .then(() => null);
  }


  enviarRecurso(codigo: number, recurso): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/recurso`, recurso)
      .toPromise()
      .then(() => null);
  }

  condicaoAlterarPlano(codigo: number, alterarPlano): Promise<PlanoTrabalhoHasProponente> {
    return this.http.put(`${this.url}/${codigo}/alterar-plano`, alterarPlano)
      .toPromise()
      .then(response => response.json() as PlanoTrabalhoHasProponente);
  }

  analiseRecurso(codigo: number, analise: AnaliseRecurso): Promise<void> {
    return this.http.put(`${this.url}/${codigo}/analise-recurso`, analise)
      .toPromise()
      .then(() => 
      PlanoTrabalhoHasProponenteService.analiseRecursoExecutada.emit(true)
      );
  }

  enumResultadoAvPlano(resultado) {
    let ret
    if (resultado == 'Aprovado_integral') {
      // ret = 'Aprovado integral'
      ret = 'Classificado'
    } else if (resultado == 'Aprovado_recomendacao') {
      // ret = 'Aprovado com recomendação'
      ret = 'Classificado'
    } else if (resultado == 'Reprovado') {
      ret = 'Desclassificado'
    }
    return ret
  }

  enumSituacao(situacao) {
    let sit
    if (situacao == 'Em_avaliacao') {
      sit = 'Em avaliação'
    } else {
      sit = situacao
    }
    return sit
  }

  retornaIniFimPlano(pthp: PlanoTrabalhoHasProponente){
    let iniMeta;
    let fimMeta;

    let iniProjeto;
    let fimProjeto;

    pthp.cronogramaFisicoExecucaoObjeto.forEach(
      meta => {
        meta.etapaCronogramaFisico.forEach(etapa => {
          //Descobrir data inicial da meta
          if (iniMeta) {
            if (iniMeta > etapa.dataIni) {
              iniMeta = etapa.dataIni;
            }
          } else {
            iniMeta = etapa.dataIni;
          }

          //Descobrir data final da meta
          if (fimMeta) {
            if (fimMeta < etapa.dataFim) {
              fimMeta = etapa.dataFim;
            }
          } else {
            fimMeta = etapa.dataFim;
          }

          //Descobrir data inicial do projeto
          if (iniProjeto) {
            if (iniProjeto > etapa.dataIni) {
              iniProjeto = etapa.dataIni;
            }
          } else {
            iniProjeto = etapa.dataIni;
          }

          //Descobrir data final do projeto
          if (fimProjeto) {
            if (fimProjeto < etapa.dataFim) {
              fimProjeto = etapa.dataFim;
            }
          } else {
            fimProjeto = etapa.dataFim;
          }
        });

        iniMeta = null;
        fimMeta = null;
      }
    )

    let retorno = new IniFimProjeto()
    retorno.ini = iniProjeto
    retorno.fim = fimProjeto
    
    return retorno
  }


  // ===== CALCULOS EXECUCAO
  calculosExecucao(codigoPlanoTrabalhoHasProponente: number, codigoCelebracao: number): Promise<any> {
    return this.http
      .get(`${this.url}/calculos-execucao/${codigoPlanoTrabalhoHasProponente}/${codigoCelebracao}` )
      .toPromise()
      .then(response => response.json());
  }



}