import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CertidaoComponent } from './certidao/certidao.component';
import { ControleComponent } from './controle/controle.component';
import { NovaEntidadeComponent } from './nova-entidade/nova-entidade.component';
import { OutrasOpcoesComponent } from './outras-opcoes/outras-opcoes.component';
import { ValidacaoComponent } from './validacao/validacao.component';

const routers = [
    { path: "", component: OutrasOpcoesComponent, 
        children: [
            // { path: 'nova-entidade', component: NovaEntidadeComponent }
        ]
    },
    { path: "validacao", component: ValidacaoComponent,
        children: [
            { path: 'certidao', component: CertidaoComponent },
            { path: 'certidao/:hash', component: CertidaoComponent },
            { path: 'controle-interno', component: ControleComponent },
            { path: 'controle-interno/:hash', component: ControleComponent }
        ]
    },
    { path: 'nova-entidade', component: NovaEntidadeComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routers)],
    exports: [RouterModule]
})
export class OutrasOpcoesRoutingModule { }