import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../seguranca/auth.service";
import { Title } from "@angular/platform-browser";

declare let AdminLTE: any;

@Component({
  selector: "app-concedente-gerenciar",
  templateUrl: "./concedente-gerenciar.component.html"
})
export class ConcedenteGerenciarComponent implements OnInit {

  showDefaultMessage

  constructor(
    private title: Title, 
    public auth: AuthService
  ) { }

  ngOnInit() {
    AdminLTE.init();
    this.title.setTitle("Gerenciar Concedente");
  }
}
