import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { RespostaQuestaoRequisitoHabilitacao } from '../../model';
import { AuthHttp } from 'angular2-jwt';
import { AuthService } from '../../../seguranca/auth.service';
import { environment } from '../../../../environments/environment';
import { URLSearchParams } from '@angular/http';

export class RespostaQuestaoRequisitoHabilitacaoFiltro {
  status;
  situacao;
  habilitacao;
  entidadeProponente;
  questaoRequisitoHabilitacao;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class RespostaQuestaoRequisitoHabilitacaoService {
  url: string;
  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/resposta-questao-requisito-habilitacao`;
  }
  pesquisar(filtro: RespostaQuestaoRequisitoHabilitacaoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.status) {
      params.set('status', filtro.status);
    }
    if (filtro.situacao) {
      params.set('situacao', filtro.situacao);
    }

    if (filtro.habilitacao) {
      params.set('habilitacao', filtro.habilitacao);
    }

    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const itens = responseJson.content;
        const resultado = {
          itens,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }
  buscarPorCodigo(
    codigo: number
  ): Promise<RespostaQuestaoRequisitoHabilitacao> {
    return this.http
      .get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as RespostaQuestaoRequisitoHabilitacao;
        return entidade;
      });
  }
  adicionar(
    entidade: RespostaQuestaoRequisitoHabilitacao
  ): Promise<RespostaQuestaoRequisitoHabilitacao> {
    return this.http
      .post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }
  atualizar(
    entidade: RespostaQuestaoRequisitoHabilitacao
  ): Promise<RespostaQuestaoRequisitoHabilitacao> {
    return this.http
      .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as RespostaQuestaoRequisitoHabilitacao);
  }
  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }
}
