import { Component, OnInit } from '@angular/core';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-emitir-certificado',
  templateUrl: './emitir-certificado.component.html'
})
export class EmitirCertificadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
  }

}