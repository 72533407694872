import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { AvaliarProposta, DispensaInexigibilidade, PlanoTrabalho } from '../../../core/model';
import { AnexoPropostaFiltro, AnexoPropostaService } from '../../../core/services/dispensaInexigibilidade/anexo-proposta.service';
import { DispensaInexigibilidadeService } from "../../../core/services/dispensaInexigibilidade/dispensaInexigibilidade.service";
import { AuthService } from "../../../seguranca/auth.service";

@Component({
  selector: "app-parcerias-avaliacao",
  templateUrl: "./parcerias-avaliacao.component.html"
})
export class ParceriasAvaliacaoComponent implements OnInit {

  dispensaInexigibilidade = new DispensaInexigibilidade();
  planoTrabalho = new PlanoTrabalho();
  anexosSalvos = []
  displayAnexo: boolean;
  urlDocumento
  viewDownload
  nomeAnexo
  anexoFiltro = new AnexoPropostaFiltro

  avaliacao = new AvaliarProposta()
  modalidades = [];

  constructor(
    public auth: AuthService,
    private dispensaInexigibilidadeService: DispensaInexigibilidadeService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private anexoPropostaService: AnexoPropostaService,
  ) { }

  ngOnInit() {
    this.title.setTitle("Avaliar Proposta");

    this.dispensaInexigibilidade = JSON.parse(sessionStorage.getItem('proposta'))
    this.planoTrabalho = this.dispensaInexigibilidade.planoTrabalho[0]

    this.carregarAnexos(this.dispensaInexigibilidade.id)

    this.avaliacao.justificativa = ""
    this.avaliacao.modalidade = ""
  }

  carregarAnexos(idProposta) {
    this.anexoFiltro.dispensaInexigibilidade = idProposta
    this.anexoPropostaService.pesquisar(this.anexoFiltro).then(anexos => {
      this.anexosSalvos = anexos.content
    }).catch(erro => this.errorHandler.handle(erro));
  }

  //ANEXO 
  openModalAnexo(anexo) {
    this.displayAnexo = true
    this.nomeAnexo = anexo.proposta
    this.urlDocumento = environment.downloadFileUrl + "/" + anexo.hashFile;
    anexo.hashFile.includes(".pdf") ? this.viewDownload = true : this.viewDownload = false
  }

  //AVALIAR
  salvar() {
    this.avaliacao.modalidade = null;
    this.avaliacao.statusProposta = "2";
    this.dispensaInexigibilidadeService.avaliarProposta(this.dispensaInexigibilidade.id, this.avaliacao)
      .then(() => {
        this.toasty.success("Avaliação executada com sucesso!")
        this.router.navigate(['proposta/parcerias/listar']);
      }).catch(erro => this.errorHandler.handle(erro));
  }

  aprovado() {
    this.avaliacao.statusAvaliacao = '0'
  }

  reprovado() {
    this.avaliacao.statusAvaliacao = '1'
  }

}
