import { Pipe, PipeTransform, ElementRef } from "@angular/core";

@Pipe({
  name: "gototop"
})
export class GototopPipe implements PipeTransform {

  transform(value:HTMLInputElement): any {
    
    // //console.log(value);

    var teste: HTMLInputElement = value

    // //console.log(teste)

    let tpl = document.querySelector('#viewTodoTop');
    // //console.log(tpl)

    // setTimeout(
    //   () => document.getElementById(value).scrollIntoView({behavior: "smooth"}) ,10
    // );

    return null;
  }
}
