import { ErrorHandlerService } from '../../core/error-handler.service';

import { ToastyService } from 'ng2-toasty';

import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: 'app-noticias-cadastro',
  templateUrl: './noticias-cadastro.component.html',
  styleUrls: ['./noticias-cadastro.component.css']
})
export class NoticiasCadastroComponent implements OnInit {


  opcoes = [
    { label: 'Para todos', value: '1' },
    { label: 'Para proponentes', value: '2' },
    { label: 'Para concedentes', value: '3' },
    { label: 'Para cidadões', value: '4' },
    { label: 'Para áreas de atuações', value: '5' },
    { label: 'Para parceria', value: '6' }
  ]

  constructor() { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
  }

}
