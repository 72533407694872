import { Location } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "../../../seguranca/auth.service";

@Component({
  selector: "analise",
  templateUrl: "./analise-repasse.component.html"
})
export class AnaliseRepasseComponent implements OnInit {

  repasse;
  periodoInicial;
  periodoFinal;

  relatorioVisitaInLoco: boolean = false;
  parecerTecnicoPrestacaoContas: boolean = false;
  relatorioTecnicoMonitoramentoAvaliacao: boolean = false;
  analiseFinanceira: boolean = false;
  outrasPendencias: boolean = false;

  relatorioPrestacaoContas: boolean = false;
  relatorioReceitaDespesa: boolean = false;
  relatorioPagamentosEfetuados: boolean = false;
  relatorioNotasComprovantes: boolean = false;
  relatorioOrdemBancariaComprovanteTransferencia: boolean = false;
  relatorioCotacaoPreco: boolean = false;
  relatorioExtratoBancario: boolean = false;

  constructor(
    public auth: AuthService,
    private location: Location
  ) { }


  ngOnInit() {
    this.repasse = JSON.parse(sessionStorage.getItem("repasse"));

    this.periodoInicial = moment(this.repasse.obDataTransf).format("YYYY-MM-DD")
    this.periodoFinal = moment().format("YYYY-MM-DD")
  }

  goBack() {
    this.location.back();
  }

  closeOthers(item) {
    this.relatorioVisitaInLoco = false;
    this.parecerTecnicoPrestacaoContas = false;
    this.relatorioTecnicoMonitoramentoAvaliacao = false;
    this.analiseFinanceira = false;
    this.outrasPendencias = false;

    if (item === 0) this.relatorioVisitaInLoco = true;
    if (item === 1) this.parecerTecnicoPrestacaoContas = true;
    if (item === 2) this.relatorioTecnicoMonitoramentoAvaliacao = true;
    if (item === 3) this.analiseFinanceira = true;
    if (item === 4) this.outrasPendencias = true;
  }

}