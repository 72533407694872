import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { ModelResponderRoteiro, ResponderRoteiro, RoteiroParecer } from "../../core/model";
import { ResponderRoteiroFiltro, ResponderRoteiroService } from "../../core/services/modelos-sistema/responderRoteiro.service";
import { RespostaQuestaoParecerFiltro, RespostaQuestaoParecerService } from "../../core/services/modelos-sistema/respostaQuestaoParecer.service";
import { RoteiroParecerFiltro, RoteiroParecerService } from "../../core/services/modelos-sistema/roteiroParecer.service";
import { AuthService } from "../../seguranca/auth.service";


// Variable in assets/js/scripts.js file
declare let AdminLTE: any;

@Component({
  selector: "app-roteiro-visualizar-respostas",
  templateUrl: "./roteiro-visualizar-respostas.component.html"
})
export class RoteiroVisualizarRespostasComponent implements OnInit {

  roteiro = new RoteiroParecer
  modelResponderRoteiro = new ModelResponderRoteiro
  responderRoteiro: ResponderRoteiro;
  finalizarRoteiro: boolean;
  roteiroJaSubmetido: boolean;
  respostas = []

  loading

  constructor(
    private title: Title,
    private roteiroParecerService: RoteiroParecerService,
    private responderRoteiroService: ResponderRoteiroService,
    private respostaQuestaoParecerService: RespostaQuestaoParecerService,
    private errorHandler: ErrorHandlerService,
    private auth: AuthService,
    private toasty: ToastyService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.title.setTitle('Visualizar Roteiro');

    this.modelResponderRoteiro = JSON.parse(sessionStorage.getItem('respondeRoteiro'))
    if (this.modelResponderRoteiro != null) {
      this.pesquisaRoteiro()
    }
  }

  pesquisaRoteiro() {
    // //console.log(this.modelResponderRoteiro.idResponderRoteiro)

    let filtro = new RoteiroParecerFiltro
    filtro.moduloParecer = this.modelResponderRoteiro.modulo
    filtro.submodulRoteiro = this.modelResponderRoteiro.submodulo
    filtro.tipoRoteiro = this.modelResponderRoteiro.tipoRoteiro
    filtro.status = 'Ativo'

    this.roteiroParecerService.pesquisar(filtro)
      .then(retorno => {
        this.roteiro = retorno.dado[0]
        this.verificaResponderRoteiro()
      })
      .catch(erro => this.errorHandler.handle(erro));


  }

  verificaResponderRoteiro() {

    if (this.modelResponderRoteiro.idResponderRoteiro) {
      this.responderRoteiroService.buscarPorCodigo(this.modelResponderRoteiro.idResponderRoteiro)
        .then(retorno => {
          this.responderRoteiro = retorno
          this.buscarRespostas()
        })
        .catch(erro => this.errorHandler.handle(erro));

    } else {

      let filtro = new ResponderRoteiroFiltro
      filtro.roteiroParecer = this.roteiro.id

      switch (this.modelResponderRoteiro.campoProcesso) {
        case 'planoTrabalho': {
          filtro.planoTrabalho = this.modelResponderRoteiro.idProcesso
          break;
        }
      }

      this.responderRoteiroService.pesquisar(filtro)
        .then(retorno => {
          if (retorno.content.length != 0) {
            //Buscar respostas
            this.responderRoteiro = retorno.content[0]
            this.buscarRespostas()
            if (this.responderRoteiro.status == 'Submetido') {
              this.roteiroJaSubmetido = true
            }
          }
        })
        .catch(erro => this.errorHandler.handle(erro));
    }



  }

  buscarRespostas() {
    let filtro = new RespostaQuestaoParecerFiltro
    filtro.responderRoteiro = this.responderRoteiro.id
    this.respostaQuestaoParecerService.pesquisar(filtro)
      .then(retorno => {

        this.loading = true

        if (retorno.dado.length != 0) {
          this.respostas = retorno.dado
          this.setaRespostasNasQuestoes()
          this.verificaSeTodasForamRespondidas()
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  setaRespostasNasQuestoes() {
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            resposta.questaoParecer.secao = null
            resposta.responderRoteiro = null
            questao.resposta = resposta
          }
        });
      });
    });
  }

  verificaSeTodasForamRespondidas() {
    let faltaResponder = false
    this.roteiro.secao.forEach(secao => {
      secao.questaoParecer.forEach(questao => {
        this.respostas.forEach(resposta => {
          if (resposta.questaoParecer.id == questao.id) {
            questao.resposta = resposta
          }
        });
        if (questao.resposta == null) {
          faltaResponder = true
        }
      });
    });
    if (!faltaResponder) {
      this.finalizarRoteiro = true
    }
  }

}
