import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ToastyService } from 'ng2-toasty';
import { AuthService } from '../../../seguranca/auth.service';
import { AlterDetalhamentoFinanceiroFiltro, AlterDetalhamentoFinanceiroService } from '../../../core/services/alteracao-plano/alterDetalhamentoFinanceiro.service';
import { AlterDetalhamentoHasEtapaFiltro, AlterDetalhamentoHasEtapaService } from '../../../core/services/alteracao-plano/alterDetalhamentoHasEtapa.service';
import { CategoriaFiltro, CategoriaService } from '../../../core/services/fornecedor/categoria.service';
import {
  DetalhamentoFinanceiro,
  AlterDetalhamentoFinanceiro,
  AlterDetalhamentoHasEtapa,
  DetalhamentoFinanceiroHasEtapaCronogramaFisico
} from '../../../core/model';
import { ConfirmationService } from 'primeng/primeng';
import { DetalhamentoFinanceiroHasEtapaCronogramaFisicoService } from '../../../core/services/plano-trabalho/detalhamentoFinanceiroHasEtapaCronogramaFisico.service';
import { CronogramaFisicoExecucaoObjetoService } from '../../../core/services/plano-trabalho/cronogramaFisicoExecucaoObjeto.service';
import { PlanoTrabalhoHasProponenteService } from '../../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-avaliar-detalhamento-financeiro',
  templateUrl: './avaliar-detalhamento-financeiro.component.html',
})
export class AvaliarDetalhamentoFinanceiroComponent implements OnInit {

  versaoPlano: any;
  planoTrabalhoHasProponente: any;

  loading

  listDetalhamentoFinanceiro = [];
  listaAlteracoesDetalhamentoFin = [];
  listaAlteracoesDetalhamentoHas = [];

  idDespesaIterada = 0;

  listaCategorias: any;
  listaCategoriasVer = [];
  listaCategoriasEditar: any;
  pessoaCeletistaSelecionada: boolean;

  modalAdicionarDetalhamentoFinanceiro: boolean;
  detalhamentoFinanceiroAdd = new DetalhamentoFinanceiro()

  tipoDespesa = [
    { label: "Material de consumo", value: "0" },
    { label: "Pessoa Celetista", value: "1" },
    { label: "Pessoa Horista", value: "2" },
    { label: "Diária", value: "3" },
    { label: "Premiações", value: "4" },
    { label: "Passagens", value: "5" },
    { label: "Consultorias", value: "6" },
    { label: "Serviços de Terceiros Pessoa Jurídica", value: "7" },
    { label: "Equipamento e Material Permanente", value: "8" },
    // { label: "Despesas e Encargos de Projetos Anteriores", value: "9" },
    { label: "Benefícios", value: "10" },
  ];
  categoriaReduzida: boolean;
  todasCategorias: any;

  detalhamentoFinanceiroCodigo: any;
  detalhamentoFinanceiroEspecificacao: any;
  detalhamentoFinanceiroTotal: any;
  detalhamentoFinanceiroTipo: any;
  detalhamentoFinanceiroCategoria: any;
  pessoaSelecionada: boolean;
  todasCategoriasEditar: any;
  detalhamentoFinanceiroEditar = new DetalhamentoFinanceiro();
  modalEditarDetalhamentoFinanceiro: boolean;
  editandoDetalhamentoFinanceiro: boolean;

  etapaEditarNgModel: any;
  metaEditarNgModel: any;
  listaEtapaCronogramaFisicoEditar: any[];
  idDetalhamentoFinanceiro: any;
  detalhamentoNomeEtapa: any;
  detalhamentoEspecificacao: any;
  detalhamentoUn: any;
  detalhamentoQuantidade: any;
  detalhamentoValorUnitario: any;
  detalhamentoValorTotal: any;
  detalhamentoMaterialPermanente: any;
  detalhamentoEditar = new DetalhamentoFinanceiroHasEtapaCronogramaFisico()
  mostraAtrEquipMatPerm: boolean;
  modalEditarDetalhamento: boolean;
  editandoDetalhamento: boolean;
  listaMetaCronogramaFisico: any;
  listaMetas: any;
  detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd = new DetalhamentoFinanceiroHasEtapaCronogramaFisico()
  modalAdicionarDetalhamentoFinanceiroHasEtapaCronogramaFisico: boolean;
  listaEtapaCronogramaFisico: any;

  metaNgModel: any;
  inicioProjeto: any;
  fimProjeto: any;











  listDetalhamentoFinanceiroVigente = [];
  listDetalhamentoFinanceiroAlterado = [];

  listaAlteracoesDespesa = [];
  listaAlteracoesDetalhamento = [];

  atualizar: number;
  statusAlteracao: any;

  alteracoesDespesa: boolean;
  alteracoesDetalhamento: boolean;

  avaliarAlteracaoDespesa = new AlterDetalhamentoFinanceiro;
  avaliarAlteracaoDetalhamento = new AlterDetalhamentoHasEtapa;

  despesaAdicionada: boolean;
  despesaAlterada: boolean;
  despesaExcluida: boolean;

  modalAvaliarDespesa: boolean;
  modalDetalhamentoAlterado: boolean;

  tituloModalDespesa: string;
  tituloModalDetalhamento: string;

  despesaSelecionada = new DetalhamentoFinanceiro;
  valorTotalDespesa: string;

  detalhamentoaAlterado: boolean;
  detalhamentoExcluido: boolean;
  detalhamentoAdicionado: boolean;

  constructor(
    private planoTrabalhoHasProponenteService: PlanoTrabalhoHasProponenteService,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private categoriaService: CategoriaService,
    private alterDetalhamentoFinanceiroService: AlterDetalhamentoFinanceiroService,
    private alterDetalhamentoHasEtapaService: AlterDetalhamentoHasEtapaService,
    private confirmation: ConfirmationService,
    private detalhamentoHasEtapaService: DetalhamentoFinanceiroHasEtapaCronogramaFisicoService,
    private cfeoService: CronogramaFisicoExecucaoObjetoService
  ) { }

  async ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.versaoPlano = JSON.parse(sessionStorage.getItem("versaoPlano"));
    this.planoTrabalhoHasProponente = JSON.parse(sessionStorage.getItem("pthpModuloAjuste"));

    await this.carregaCategorias()
    await this.carregaMetasEtapas()
    await this.verificaIniFimProjeto()

    await this.pthpBuscar()

  }

  //CARREGAR
  pthpBuscar() {
    this.planoTrabalhoHasProponenteService.todosDetalhamentos(this.planoTrabalhoHasProponente.id)
      .then(async retorno => {
        this.loading = false
        await this.eliminaDespesasExcluidas(retorno)
        await this.setaListaVigente()
        await this.setaListaAlterado()
        await this.limparListaVigenteDespesa()
        await this.limparListaVigenteDetalhamento()
        await this.procuraAlteracoesDespesa()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  async eliminaDespesasExcluidas(pthp) {
    await pthp.detalhamentoFinanceiro.forEach(despesa => {
      let index = pthp.detalhamentoFinanceiro.indexOf(despesa)
      if (despesa.excluido == true && despesa.tipoAlteracao == null) {
        pthp.detalhamentoFinanceiro.splice(index, 1)
        this.eliminaDespesasExcluidas(pthp)
      }
    });

    await this.eliminaDetalhamentosExcluidos(pthp)
  }
  async eliminaDetalhamentosExcluidos(pthp) {
    await pthp.detalhamentoFinanceiro.forEach(despesa => {
      let index = pthp.detalhamentoFinanceiro.indexOf(despesa)
      despesa.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
        let iDetal = pthp.detalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)
        if (detalhamento.excluido == true && detalhamento.tipoAlteracao == null) {
          pthp.detalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.splice(iDetal, 1)
          this.eliminaDetalhamentosExcluidos(pthp)
        }
      });

    });

    this.planoTrabalhoHasProponente = pthp
    await sessionStorage.setItem('pthpModuloAjusteDetalhamento', JSON.stringify(pthp))
  }






  async setaListaVigente(flag = false) {
    if (flag == false) {
      this.listDetalhamentoFinanceiroVigente = JSON.parse(sessionStorage.getItem("pthpModuloAjusteDetalhamento")).detalhamentoFinanceiro
    }
    //Elimina aquelas que foram excluídas na avaliação

    await this.listDetalhamentoFinanceiroVigente.forEach(despesa => {
      let index = this.listDetalhamentoFinanceiroVigente.indexOf(despesa)
      if (despesa.excluido == true) {
        this.listDetalhamentoFinanceiroVigente.splice(index, 1)
        this.setaListaVigente(true)
      }
      despesa.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
        let iDetal = this.listDetalhamentoFinanceiroVigente[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)
        if (detalhamento.excluido == true) {
          this.listDetalhamentoFinanceiroVigente[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.splice(iDetal, 1)
          this.setaListaVigente(true)
        }
      });
    });

  }
  setaListaAlterado() {
    let despesas = JSON.parse(sessionStorage.getItem("pthpModuloAjusteDetalhamento")).detalhamentoFinanceiro
    this.listDetalhamentoFinanceiroAlterado = despesas
  }
  async limparListaVigenteDespesa() {
    await this.listDetalhamentoFinanceiroVigente.forEach(despesa => {
      let index = this.listDetalhamentoFinanceiroVigente.indexOf(despesa)
      if (despesa.tipoAlteracao == 'Adicionado') {
        this.listDetalhamentoFinanceiroVigente.splice(index, 1)
        this.limparListaVigenteDespesa()
      }
    });

  }

  async limparListaVigenteDetalhamento() {
    await this.listDetalhamentoFinanceiroVigente.forEach(despesa => {
      let index = this.listDetalhamentoFinanceiroVigente.indexOf(despesa)
      despesa.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
        let iDetal = this.listDetalhamentoFinanceiroVigente[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)
        if (detalhamento.tipoAlteracao == 'Adicionado') {
          this.listDetalhamentoFinanceiroVigente[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.splice(iDetal, 1)
          this.limparListaVigenteDetalhamento()
        }
      });
    });

  }

  //BUSCAR ALTERAÇÕES
  procuraAlteracoesDespesa() {
    //Procura por alterações ativas
    let filtro = new AlterDetalhamentoFinanceiroFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.versaoPlano = this.versaoPlano.id
    this.alterDetalhamentoFinanceiroService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesDespesa = retorno.dado
        this.procuraAlteracoesDetalhamento()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  procuraAlteracoesDetalhamento() {
    //Procura por alterações ativas
    let filtro = new AlterDetalhamentoHasEtapaFiltro
    filtro.pthpId = this.planoTrabalhoHasProponente.id
    filtro.versaoPlano = this.versaoPlano.id
    this.alterDetalhamentoHasEtapaService.pesquisar(filtro)
      .then(retorno => {
        this.listaAlteracoesDetalhamento = retorno.dado
        this.verificaAlteracoes()
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  verificaAlteracoes() {
    this.loading = true

    if (this.listaAlteracoesDespesa.length == 0 && this.listaAlteracoesDetalhamento.length == 0) {
      this.atualizar = 1 //Sem alterações
    } else {
      this.atualizar = 2 //Todas alterações AV
      this.verificaAlteracoesForamAvaliadas()
    }

  }

  verificaAlteracoesForamAvaliadas() {

    this.listaAlteracoesDespesa.forEach(alterDespesa => {
      if (alterDespesa.statusAlteracao == 'Submetido') {
        this.atualizar = 0 //existe alterações sem avaliar
      }
    });

    this.listaAlteracoesDetalhamento.forEach(alterDetal => {
      if (alterDetal.statusAlteracao == 'Submetido') {
        this.atualizar = 0 //existe alterações sem avaliar
      }
    });

    if (this.listaAlteracoesDespesa.length != 0) {

      this.alteracoesDespesa = true
    } else {
      this.alteracoesDespesa = false
    }

    if (this.listaAlteracoesDetalhamento.length != 0) {
      this.alteracoesDetalhamento = true
    } else {
      this.alteracoesDetalhamento = false
    }
    this.verificaAlteracoesDespesa()

  }

  //DESPESA
  async verificaAlteracoesDespesa() {
    await this.listaAlteracoesDespesa.forEach(despesaAlter => {
      this.listDetalhamentoFinanceiroAlterado.forEach(despesa => {

        if (despesaAlter.detalhamentoFinanceiro == despesa.id) {
          var index = this.listDetalhamentoFinanceiroAlterado.indexOf(despesa)
          this.listDetalhamentoFinanceiroAlterado[index].alteracao = despesaAlter

          if (despesaAlter.tipoAlteracao == "Alterado") {

            this.listDetalhamentoFinanceiroAlterado[index].codigo = despesaAlter.codigoDepois
            this.listDetalhamentoFinanceiroAlterado[index].especificacao = despesaAlter.especificacaoDepois
            this.listDetalhamentoFinanceiroAlterado[index].total = despesaAlter.totalDepois
            this.listDetalhamentoFinanceiroAlterado[index].tipoAlteracao = "Alterado"
          } else if (despesaAlter.tipoAlteracao == "Excluido") {
            this.listDetalhamentoFinanceiroAlterado[index].tipoAlteracao == "Excluido"
          } else if (despesaAlter.tipoAlteracao == "Adicionado") {
            this.listDetalhamentoFinanceiroAlterado[index].tipoAlteracao == "Adicionado"
          }
        }
      });

    });

    await this.verificaDespesasAdicionadasExcluidas()
    await this.verificaAlteracoesDetalhamento()

  }
  verificaDespesasAdicionadasExcluidas() {
    //Elimina as metas que foram excluídas no momento da alteração ainda
    this.listDetalhamentoFinanceiroAlterado.forEach(despesa => {
      var index = this.listDetalhamentoFinanceiroAlterado.indexOf(despesa)
      if (despesa.tipoAlteracao == 'Adicionado' && despesa.alteracao == null) {
        this.listDetalhamentoFinanceiroAlterado.splice(index, 1)
      }
    });
  }


  //DETALHAMENTO
  async verificaAlteracoesDetalhamento() {

    await this.listaAlteracoesDetalhamento.forEach(alteracao => {
      this.listDetalhamentoFinanceiroAlterado.forEach(despesa => {
        var iDes = this.listDetalhamentoFinanceiroAlterado.indexOf(despesa)

        despesa.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {

          if (detalhamento.id == alteracao.detalhamentoHasEtapa) {
            var iDetal = this.listDetalhamentoFinanceiroAlterado[iDes].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)
            this.listDetalhamentoFinanceiroAlterado[iDes].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].alteracao = alteracao

            if (alteracao.tipoAlteracao == "Alterado") {
              this.setaDetalhamentoAlterado(alteracao, iDes, iDetal)
            } else if (alteracao.tipoAlteracao == "Excluido") {
              this.listDetalhamentoFinanceiroAlterado[iDes].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].tipoAlteracao = "Excluido"
            } else if (alteracao.tipoAlteracao == "Adicionado") {
              this.listDetalhamentoFinanceiroAlterado[iDes].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].tipoAlteracao = "Adicionado"
            }

          }

        });

      });
    });

    this.loading = true
  }

  setaDetalhamentoAlterado(alteracao, iDespesa, iDetal) {
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].especificacao = alteracao.especificacaoDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].un = alteracao.unDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].funcao = alteracao.funcaoDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].mes = alteracao.mesDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].mesAnoContratacao = alteracao.mesAnoContratacaoDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].mesAnoDispensa = alteracao.mesAnoDispensaDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].quantidade = alteracao.quantidadeDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].salario = alteracao.salarioDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].inss = alteracao.inssDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].terceiro = alteracao.terceiroDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].fgts = alteracao.fgtsDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].pis = alteracao.pisDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].umDozeDeFerias = alteracao.umDozeDeFeriasDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].umTresDeFerias = alteracao.umTresDeFeriasDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].umDozeDeTrezeSalario = alteracao.umDozeDeTrezeSalarioDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].multaRecisoria = alteracao.multaRecisoriaDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].encargoMensal = alteracao.encargoMensalDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].valorUnitario = alteracao.valorUnitarioDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].totalUnitario = alteracao.totalUnitarioDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].valorTotal = alteracao.valorTotalDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].nomeEtapa = alteracao.nomeEtapaDepois
    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].mes = alteracao.mesDepois

    this.listDetalhamentoFinanceiroAlterado[iDespesa].detalhamentoFinanceiroHasEtapaCronogramaFisico[iDetal].tipoAlteracao = "Alterado"
  }



  //AVALIAR DESPESA
  openModalAvaliarDespesa(despesa) {
    this.despesaSelecionada = despesa
    this.calculaDespesaModal()

    if (despesa.alteracao.tipoAlteracao == "Adicionado") {
      if (despesa.alteracao.statusAlteracao == 'Submetido') {
        this.tituloModalDespesa = "Avaliar adição de despesa"
      } else {
        this.tituloModalDespesa = "Alterar avaliação da adição de despesa"
      }
      this.despesaAdicionada = true
      this.despesaAlterada = false
      this.despesaExcluida = false
    } else if (despesa.alteracao.tipoAlteracao == "Alterado") {
      if (despesa.alteracao.statusAlteracao == 'Submetido') {
        this.tituloModalDespesa = "Avaliar alteração da despesa"
      } else {
        this.tituloModalDespesa = "Alterar avaliação da alteração da despesa"
      }
      this.despesaAdicionada = false
      this.despesaAlterada = true
      this.despesaExcluida = false
    } else if (despesa.alteracao.tipoAlteracao == "Excluido") {
      if (despesa.alteracao.statusAlteracao == 'Submetido') {
        this.tituloModalDespesa = 'Avaliar exclusão de despesa'
      } else {
        this.tituloModalDespesa = 'Alterar avaliação da exclusão da despesa'
      }
      this.despesaExcluida = true
      this.despesaAlterada = false
      this.despesaAdicionada = false
    }

    this.avaliarAlteracaoDespesa = despesa.alteracao
    this.statusAlteracao = null
    this.modalAvaliarDespesa = true
  }
  avaliarDespesa() {
    this.loading = false
    if (this.statusAlteracao != null) {
      this.avaliarAlteracaoDespesa.statusAlteracao = this.statusAlteracao
      this.avaliarAlteracaoDespesa.usuarioAvaliou = this.auth.jwtPayload.id
      this.avaliarAlteracaoDespesa.dataAvaliacao = new Date().getTime()

      this.alterDetalhamentoFinanceiroService.avaliar(this.avaliarAlteracaoDespesa)
        .then(() => {
          this.modalAvaliarDespesa = false
          this.pthpBuscar()
          this.toasty.success("Avaliação realizada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
      this.loading = true
    }
  }

  //AVALIAR DETALHAMENTO
  openModalAvaliarAlteracaoDetalhamento(detalhamento) {
    if (detalhamento.tipoAlteracao == 'Alterado') {
      if (detalhamento.alteracao.statusAlteracao == 'Submetido') {
        this.tituloModalDetalhamento = 'Avaliar alteração no detalhamento'
      } else {
        this.tituloModalDetalhamento = 'Alterar avaliação da alteração no detalhamento'
      }
      this.detalhamentoaAlterado = true
      this.detalhamentoExcluido = false
      this.detalhamentoAdicionado = false
    } else if (detalhamento.tipoAlteracao == 'Adicionado') {
      if (detalhamento.alteracao.statusAlteracao == 'Submetido') {
        this.tituloModalDetalhamento = 'Avaliar adição do detalhamento'
      } else {
        this.tituloModalDetalhamento = 'Alterar avaliação da adição do detalhamento'
      }
      this.detalhamentoaAlterado = false
      this.detalhamentoExcluido = false
      this.detalhamentoAdicionado = true
    } else if (detalhamento.tipoAlteracao == 'Excluido') {
      if (detalhamento.alteracao.statusAlteracao == 'Submetido') {
        this.tituloModalDetalhamento = 'Avaliar exclusão do detalhamento'
      } else {
        this.tituloModalDetalhamento = 'Alterar avaliação da exclusão do detalhamento'
      }
      this.detalhamentoaAlterado = false
      this.detalhamentoExcluido = true
      this.detalhamentoAdicionado = false
    }

    this.avaliarAlteracaoDetalhamento = detalhamento.alteracao
    this.statusAlteracao = null
    this.modalDetalhamentoAlterado = true

    //console.log(detalhamento)

  }

  avaliarDetalhamentoAlterado() {
    this.loading = false
    if (this.statusAlteracao != null) {
      this.avaliarAlteracaoDetalhamento.statusAlteracao = this.statusAlteracao
      this.avaliarAlteracaoDetalhamento.usuarioAvaliou = this.auth.jwtPayload.id
      this.avaliarAlteracaoDetalhamento.dataAvaliacao = new Date().getTime()

      this.alterDetalhamentoHasEtapaService.avaliar(this.avaliarAlteracaoDetalhamento)
        .then(() => {
          this.modalDetalhamentoAlterado = false
          this.pthpBuscar()
          this.toasty.success("Avaliação realizada com sucesso!");
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.toasty.warning("Informe se foi aprovado ou reprovado!");
      this.loading = true
    }
  }












  //TODO: verificar carregamento de metas e etapas
  carregaMetasEtapas() {
    this.cfeoService.pesquisarAlteracoes(this.planoTrabalhoHasProponente.id)
      .then(retorno => {
        this.listaMetas = retorno
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


































  //ALTERAR DESPESA
  setaDespesa(AlterDetal) {
    this.listDetalhamentoFinanceiro.forEach(detal => {
      if (detal.id == AlterDetal.detalhamentoFinanceiro) {
        var index = this.listDetalhamentoFinanceiro.indexOf(detal)

        this.listDetalhamentoFinanceiro[index].codigo = AlterDetal.codigoDepois
        this.listDetalhamentoFinanceiro[index].especificacao = AlterDetal.especificacaoDepois
        this.listDetalhamentoFinanceiro[index].total = AlterDetal.totalDepois
        this.listDetalhamentoFinanceiro[index].tipoAlteracao = "Alterado"
      }
    });
    this.editandoDetalhamentoFinanceiro = false;
    this.modalEditarDetalhamentoFinanceiro = false;
    this.toasty.success("Alteração realizada com sucesso!");
  }

  //ADICIONAR DESPESA
  setaAtributosdetalhamentoFin(obj: AlterDetalhamentoFinanceiro) {
    let detal = new DetalhamentoFinanceiro()

    detal.id = obj.detalhamentoFinanceiro
    detal.codigo = obj.codigoDepois
    detal.especificacao = obj.especificacaoDepois
    detal.total = obj.totalDepois
    detal.tipoAlteracao = obj.tipoAlteracao
    detal.categoria.id = obj.categoria
    detal.tipo = obj.tipo
    detal.detalhamentoFinanceiroHasEtapaCronogramaFisico = []

    let i = this.listaAlteracoesDetalhamentoFin.length
    this.listaAlteracoesDetalhamentoFin[i] = obj

    return detal;
  }

  //EXCLUIR DESPESA
  delAlterDelDespesaDeldetalhamento(adf) {
    this.alterDetalhamentoFinanceiroService.excluir(adf.id)
      .then(() => {
        this.removeDespesaArrayPrincipal(adf)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeDespesaArrayPrincipal(alterDetal) {
    let index
    this.listDetalhamentoFinanceiro.forEach(detal => {
      if (detal.id == alterDetal.detalhamentoFinanceiro) {
        index = this.listDetalhamentoFinanceiro.indexOf(detal)
      }
    });
    this.listDetalhamentoFinanceiro.splice(index, 1)
    this.toasty.success("Despesa excluída com sucesso!");
  }

  //EXCLUIR DETALHAMENTO
  delAlterDelDetalhamentoHas(adhe) {
    this.alterDetalhamentoHasEtapaService.excluir(adhe.id)
      .then(() => {
        this.removeDetalhamentoArrayPrincipal(adhe)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  removeDetalhamentoArrayPrincipal(alterDetalhamento: AlterDetalhamentoHasEtapa) {

    this.listDetalhamentoFinanceiro.forEach(despesa => {
      if (despesa.id == alterDetalhamento.detalhamentoFinanceiro) {
        var index = this.listDetalhamentoFinanceiro.indexOf(despesa)

        this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(detalhamento => {
          if (detalhamento.id == alterDetalhamento.detalhamentoHasEtapa) {

            var i = this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.indexOf(detalhamento)
            this.listDetalhamentoFinanceiro[index].detalhamentoFinanceiroHasEtapaCronogramaFisico.splice(i, 1)
            this.toasty.success("Detalhamento excluído com sucesso!");
          }
        });
      }
    });

  }


  //UTILS
  retornaDespesaIterada() {
    if (this.idDespesaIterada >= this.listDetalhamentoFinanceiro.length) {
      this.idDespesaIterada = 0;
    }
    this.idDespesaIterada = this.idDespesaIterada + 1;
    return this.idDespesaIterada;
  }
  soma20Inss(qtd, valorUn) {
    return (valorUn / 100) * 20 * qtd;
  }
  tipoDespesaSelecionada(tipo) {
    if (tipo == 1) {
      this.categoriaReduzida = false;
      if (this.todasCategorias) {
        this.listaCategorias = this.todasCategorias;
      }
      this.detalhamentoFinanceiroAdd.categoria.id = 43;
      this.pessoaCeletistaSelecionada = true;
    } else if (tipo == 2) {
      this.categoriaReduzida = false;
      if (this.todasCategorias) {
        this.listaCategorias = this.todasCategorias;
      }
      this.pessoaCeletistaSelecionada = true;
      this.detalhamentoFinanceiroAdd.categoria.id = 44;
    } else {
      this.todasCategorias = this.listaCategorias;
      let u = 0;
      let i = [];
      this.pessoaCeletistaSelecionada = false;
      this.listaCategorias.forEach(categoria => {
        if (categoria.value != 43 && categoria.value != 44) {
          i[u] = categoria;
          u++;
        }
      });
      this.listaCategorias = i;
    }
  }
  setaMetaEtapa(detalhamento: DetalhamentoFinanceiroHasEtapaCronogramaFisico) {
    this.listaMetaCronogramaFisico = this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.map(
      c => ({ label: c.meta, value: c.id })
    );

    if (detalhamento.etapaCronogramaFisico != null) {
      this.etapaEditarNgModel = detalhamento.etapaCronogramaFisico.id;
      this.metaEditarNgModel =
        detalhamento.etapaCronogramaFisico.cronogramaFisicoExecucaoObjeto.id;

      this.metaSelecionadaEditar(this.metaEditarNgModel);
    }
  }
  metaSelecionadaEditar(idMeta) {
    this.planoTrabalhoHasProponente.cronogramaFisicoExecucaoObjeto.forEach(
      meta => {
        if (meta.id == idMeta) {
          this.listaEtapaCronogramaFisicoEditar = meta.etapaCronogramaFisico.map(
            c => ({ label: c.especificacao, value: c.id })
          );
        }
      }
    );
  }
  verificaEquipamentoMaterialPermanente() {
    if (this.detalhamentoEditar.equipamentoMaterialPermanente == true) {
      this.detalhamentoEditar.equipamentoMaterialPermanente = "true";
    } else if (this.detalhamentoEditar.equipamentoMaterialPermanente == false) {
      this.detalhamentoEditar.equipamentoMaterialPermanente = "false";
    }
  }
  somarValorTotal() {
    let i;
    i =
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.quantidade *
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.valorUnitario;
    if (i != 0) {
      this.detalhamentoFinanceiroHasEtapaCronogramaFisicoAdd.valorTotal = i;
    }

    let p;
    p =
      this.detalhamentoEditar.quantidade *
      this.detalhamentoEditar.valorUnitario;
    if (p != 0) {
      this.detalhamentoEditar.valorTotal = p;
    }
  }
  metaSelecionada(idMeta) {
    this.listaMetas.forEach(
      meta => {
        if (meta.id == idMeta) {
          this.listaEtapaCronogramaFisico = meta.etapaCronogramaFisico.map(
            c => ({ label: c.especificacao, value: c.id })
          );
        }
      }
    );
  }

  etapaSelecionada(value) {
    return value;
  }

  verificaIniFimProjeto() {
    let i = this.planoTrabalhoHasProponenteService.retornaIniFimPlano(this.planoTrabalhoHasProponente)
    this.inicioProjeto = i.ini
    this.fimProjeto = i.fim
  }






  //UTILS
  carregaCategorias() {
    let i = new CategoriaFiltro();
    i.itensPorPagina = 1000;
    this.categoriaService.pesquisar(i)
      .then(retorno => {
        this.listaCategorias = retorno.dado.map(c => ({
          label: c.categoria,
          value: c.id
        }));
        this.listaCategoriasVer = retorno.dado.map(c => ({
          label: c.categoria,
          value: c.id
        }));
        this.listaCategoriasEditar = retorno.dado.map(c => ({
          label: c.categoria,
          value: c.id
        }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  aprovarAv() {
    this.statusAlteracao = 0
  }
  reprovarAv() {
    this.statusAlteracao = 1
  }

  retornaTipo(tipo) {
    if (tipo == null) {
      tipo = this.despesaSelecionada.tipo
    }
    let r
    if (tipo == "Padrao") {
      r = "Material de consumo"
    } else if (tipo == "Pessoa_celetista") {
      r = "Pessoa Celetista"
    } else if (tipo == "Pessoa_horista") {
      r = "Pessoa Horista"
    } else if (tipo == "Diaria") {
      r = "Diária"
    } else if (tipo == "Premiacoes") {
      r = "Premiações"
    } else if (tipo == "Passagens") {
      r = "Passagens"
    } else if (tipo == "Consultorias") {
      r = "Consultorias"
    } else if (tipo == "Serv_ter_pessoa_jurd") {
      r = "Serviços de Terceiros Pessoa Jurídica"
    } else if (tipo == "Equip_mat_perm") {
      r = "Equipamento e Material Permanente"
    } else if (tipo == "Despesas_encar_proj_ant") {
      r = "Despesas e Encargos de Projetos Anteriores"
    }
    return r
  }
  verificaCategoria(idCategoria) {
    let retorno;
    this.listaCategoriasVer.forEach(categoria => {
      if (categoria.value == idCategoria) {
        retorno = categoria.label;
      }
    });
    return retorno;
  }
  verificaCategoriaModal() {
    let retorno;
    if (this.despesaSelecionada.categoria) {
      let i = this.despesaSelecionada.categoria.id
      this.listaCategoriasVer.forEach(categoria => {
        if (categoria.value == i) {
          retorno = categoria.label;
        }
      });
    } else {
      retorno = 'Não definida'
    }
    return retorno;
  }
  calculaDespesa(df: DetalhamentoFinanceiro) {
    let i = 0;
    df.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(especificacao => {
      i = i + especificacao.valorTotal;
    });
    return i;
  }

  calculaDespesaModal() {
    let df = this.despesaSelecionada
    let i = 0;
    df.detalhamentoFinanceiroHasEtapaCronogramaFisico.forEach(especificacao => {
      i = i + especificacao.valorTotal;
    });
    this.valorTotalDespesa = i.toString()

  }

  retornaTipoAlteracao(tipo) {
    let i
    if (tipo == 'Alterado') {
      i = 'Alterada'
    } else if (tipo == 'Excluido') {
      i = 'Excluída'
    } else if (tipo == 'Adicionado') {
      i = 'Adicionada'
    } else if (tipo == null) {
      i = '-'
    } else {
      i = 'Indefinido'
    }
    return i
  }
  retornaStatusAlteracao(obj) {
    let i
    if (obj.alteracao != null) {

      if (obj.alteracao.statusAlteracao == 'Submetido') {
        i = 'Submetida'
      } else if (obj.alteracao.statusAlteracao == 'Aprovado') {
        i = 'Aprovada'
      } else if (obj.alteracao.statusAlteracao == 'Reprovado') {
        i = 'Reprovada'
      }
    } else {
      i = '-'
    }
    return i
  }



}
