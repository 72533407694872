import { Injectable } from "@angular/core";
import { URLSearchParams } from "@angular/http";
import { AuthHttp } from "angular2-jwt";
import "rxjs/add/operator/toPromise";
import { environment } from "../../../../environments/environment";
import { EixoProgramaHasProponenteCredenciado } from "../../../core/model";
import { AuthService } from "../../../seguranca/auth.service";

export class EixoProgramaHasProponenteCredenciadoFiltro {
  eixoPrograma: any;
  entidadeProponente: any;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class EixoProgramaHasProponenteCredenciadoService {
  url: string;

  constructor(private http: AuthHttp, public auth: AuthService) {
    this.url = `${environment.apiUrl}/eixo-programa-has-proponente-credenciado`;
  }

  pesquisar(filtro: EixoProgramaHasProponenteCredenciadoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set("page", filtro.pagina.toString());
    params.set("size", filtro.itensPorPagina.toString());

    if (filtro.eixoPrograma) {
      params.set("eixoPrograma", filtro.eixoPrograma);
    }
    if (filtro.entidadeProponente) {
      params.set("entidadeProponente", filtro.entidadeProponente);
    }

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const entidades = responseJson.content;

        const resultado = {
          entidades,
          total: responseJson.totalElements
        };
        return resultado;
      });
  }

  listarTodos(): Promise<any> {
    return this.http
      .get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  buscarProponentesEixo(id: any): Promise<any> {
    const params = new URLSearchParams();
    params.set("eixoPrograma", id);

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  verificaProponenteCredenciado(idProponente: any, idEixo: any): Promise<any> {
    const params = new URLSearchParams();
    params.set("eixoPrograma", idEixo);
    params.set("entidadeProponente", idProponente);

    return this.http
      .get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(
    objeto: EixoProgramaHasProponenteCredenciado
  ): Promise<EixoProgramaHasProponenteCredenciado> {
    return this.http
      .post(this.url, JSON.stringify(objeto))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(
    objeto: EixoProgramaHasProponenteCredenciado
  ): Promise<EixoProgramaHasProponenteCredenciado> {
    return this.http
      .put(`${this.url}/${objeto.id}`, JSON.stringify(objeto))
      .toPromise()
      .then(
        response => response.json() as EixoProgramaHasProponenteCredenciado
      );
  }

  buscarPorCodigo(id: number): Promise<EixoProgramaHasProponenteCredenciado> {
    return this.http
      .get(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        const objeto = response.json() as EixoProgramaHasProponenteCredenciado;

        return objeto;
      });
  }
}
