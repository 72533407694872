import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { AuthService } from '../../seguranca/auth.service';
import { Celebracao } from '../../core/model';
import { Page } from '../../core/pagination/pagination.model';
import { CelebracaoFiltro, CelebracaoService } from '../../core/services/celebracao/celebracao.service';
import { PlanoTrabalhoHasProponenteService } from '../../core/services/plano-trabalho/planoTrabalhoHasProponente.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-listar',
  templateUrl: './listar.component.html',
})
export class ListarComponent implements OnInit {

  param
  proponente

  loading

  celebracaoLista = [];
  filtro = new CelebracaoFiltro();

  constructor(
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private celebracaoService: CelebracaoService,
    private planoTrabalhoHasProponenteService: PlanoTrabalhoHasProponenteService
  ) { }


  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.route.params.subscribe(
      (params: any) => {
        this.param = params["tipo"]
        this.pesquisar()
      }
    );

    this.proponente = this.auth.jwtPayload.proponente;
  }


  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.filtro.itensPorPagina = event.size;
    this.filtro.pagina = event.page;
    this.celebracaoService.pesquisar(this.filtro).then(res => {
      this.page = res;
      this.celebracaoLista = res.content;
    });
  }

  pesquisar(pagina = 0) {

    this.loading = false;

    if (this.param == 'parcerias')
      this.filtro.et1TipoDemanda = "Parceria";

    if (this.param == 'convenios')
      this.filtro.et1TipoDemanda = "Convenio";

    this.filtro.pagina = pagina;
    this.filtro.status = "Execucao";

    if (this.auth.jwtPayload.concedente) {
      this.filtro.et1EntidadeConcedente = this.auth.jwtPayload.concedente;
    }

    this.celebracaoService
      .pesquisar(this.filtro)
      .then(resultado => {
        this.celebracaoLista = resultado.content;
        this.page = resultado;
        this.loading = true;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  retornaTipoCelebracao(celebracao: Celebracao) {
    let retorno;
    if (celebracao.et1DispensaInexigibilidade) {
      retorno = "Dispensa/Inexigibilidade";
    } else if (celebracao.et1ChamamentoPublico) {
      retorno = "Chamamento público";
    } else if (celebracao.et1EmendaParlamentar) {
      retorno = "Emenda Parlamentar";
    } else {
      retorno = "Desconhecido";
    }
    return retorno;
  }


  async gotoExecucaoVisualizar(celebracao) {
    await this.buscarPthp(celebracao.pthpId)
    sessionStorage.setItem('processoExecucao', celebracao.id)
    sessionStorage.setItem('modExecucaoCelebracao', JSON.stringify(celebracao))
    this.router.navigate(["/prestacao-contas/visualizar"]);
  }

  async buscarPthp(pthpId) {
    await this.planoTrabalhoHasProponenteService.buscarPorCodigo(pthpId)
      .then(pthp => {
        sessionStorage.setItem('pthpModuloAjuste', JSON.stringify(pthp))
        sessionStorage.setItem('planoTrabalhoHasProponenteId', JSON.stringify(pthp.id))
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


}
