import { Component } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from "../../../environments/environment";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { EsqueciSenha } from "../../core/model";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.css"]
})
export class LoginFormComponent {
  esqueciSenha = new EsqueciSenha();
  usuarioUrl;
  confirmeSenha;
  reCaptcha;
  documentRecaptcha;
  loading;
  logo = environment.logo

  constructor(
    private http: Http,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private title: Title,
    private toasty: ToastyService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {

    this.loading = true

    this.title.setTitle("Login");
    this.usuarioUrl = `${environment.apiUrl}/usuarios`;

    this.reCaptchaV3Service.execute(
      "6LdCbIoUAAAAAJL-BVvqkCR5fhjEg84kUXNS2MOr",
      "login",
      token => {
        this.reCaptcha = token;
        this.documentRecaptcha = document.getElementsByClassName(
          "grecaptcha-badge"
        );
        // //console.log("This is your token-recaptcha: ", token);
        // //console.log(this.documentRecaptcha)
        this.loading = false
        this.openLoginView();
      }
    );

  }

  login(usuario: string, senha: string) {
    this.openLogin = false
    this.loading = true
    usuario = usuario.toLowerCase();
    if (this.reCaptcha != null) {
      this.auth
        .login(usuario, senha)
        .then(() => {
          this.documentRecaptcha[0].style.display = "none";
          this.router.navigate(["/home"]);
          sessionStorage.setItem("urlLogin", "/login");
          this.loading = false
          this.openLogin = true
        })
        .catch(erro => {
          this.loading = false
          this.openLogin = true
          this.errorHandler.handle(erro);
        });
    } else {
      this.errorHandler.handle("É necessário a confirmação do CAPCTHA.");
    }
  }

  openLogin;
  openForgotPass;
  openToken;
  openNewPass;
  openLoginView() {
    this.openLogin = true;
    this.openForgotPass = false;
    this.openToken = false;
    this.openNewPass = false;
  }
  openForgotPassVIew() {
    this.openLogin = false;
    this.openForgotPass = true;
    this.openToken = false;
    this.openNewPass = false;
  }
  openTokenView() {
    this.openLogin = false;
    this.openForgotPass = false;
    this.openToken = true;
    this.openNewPass = false;
  }
  openNewPassView() {
    if (this.esqueciSenha.token.length < 10) {
      return this.toasty.error("Digite um código válido!");
    } else {
      this.openLogin = false;
      this.openForgotPass = false;
      this.openToken = false;
      this.openNewPass = true;
    }
  }


  novaSolicitacao() {
    if (this.reCaptcha != null) {
      this.loading = true;
      this.solicitarAlteracao(this.esqueciSenha)
        .then(retorno => {
          this.loading = false;

          if (retorno._body != "") {
            this.openTokenView();
            this.toasty.success("Solicitação encaminhada com sucesso!");
          } else {
            this.esqueciSenha.email = "";
            this.toasty.error("E-mail não encontrado!");
          }

          this.router.navigate(["/login"]);
        })
        .catch(erro => {
          this.errorHandler.handle(erro);
        });
    } else {
      this.errorHandler.handle("É necessário a confirmação do CAPCTHA.");
    }
  }

  confirmarAlteracao() {
    if (this.reCaptcha != null) {
      if (this.esqueciSenha.senha == this.confirmeSenha) {
        this.salvarNovaSenha(this.esqueciSenha)
          .then(() => {
            this.toasty.success("Alteração confirmada com sucesso!");
            this.openLoginView();
          })
          .catch(erro => this.errorHandler.handle(erro));
      } else {
        this.toasty.error("As senhas digitadas não conferem.");
      }
    } else {
      this.errorHandler.handle("É necessário a confirmação do CAPCTHA.");
    }
  }

  solicitarAlteracao(esqueciSenha: EsqueciSenha): Promise<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(`${environment.token}`, JSON.stringify(esqueciSenha), { headers })
      .toPromise()
      .then(response => {
        return response;
      });
  }

  salvarNovaSenha(esqueciSenha: EsqueciSenha): Promise<EsqueciSenha> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(`${this.usuarioUrl}/alteracao`, JSON.stringify(esqueciSenha), {
        headers
      })
      .toPromise()
      .then(response => response.json());
  }

}
